import { useEffect, useRef } from "react"
import { useState } from "react"
import { IVocabulary } from "../common/interfaces/vocabulary/IVocabulary"
// import { checkIfIsOtherReason } from "../utils/CheckOtherReason"
import { IValidationBody } from "../common/interfaces/IValidation"
import { checkIfIsOtherReason } from "../utils/CheckOtherReason"
import { highlightError, removeHighlightError } from "../utils/Validation"

export const useReason = (vocabulary?: IVocabulary[]) => {
    const [reason, setReason] = useState<IVocabulary | string>({} as IVocabulary)
    const [reasons, setReasons] = useState<IVocabulary[]>(vocabulary ? vocabulary : [] as IVocabulary[])
    const [isOtherReason, setIsOtherReason] = useState<boolean>(false)
    const textareaRef = useRef<HTMLTextAreaElement>(null)
    const otherReason = [{id: 0, name: "Other"}] as IVocabulary[]

    useEffect(() => {
        if ((reason as IVocabulary)?.id === 0) {
            setIsOtherReason(true)
            setReason("")
        } else if (typeof reason === 'string') {
            setIsOtherReason(true)
        } else setIsOtherReason(false)
    }, [reason])

    useEffect(() => {
        setReasons(vocabulary ? [...vocabulary, ...otherReason] : [] as IVocabulary[])
    }, [vocabulary])

    const clearReasonStates = () => {
        setIsOtherReason(false)
        setReason({} as IVocabulary)
    }

    const mergeReasonsWithOther = (reasons: IVocabulary[]) => {
        setReasons(reasons ? [...reasons, ...otherReason] : [] as IVocabulary[])
    }
    
    const onReasonSelect = (value: string) => {
        if (!reasons) return;
        const selectedReason = reasons.find(item => +item.id === +value)
        selectedReason && setReason(selectedReason);
    }

    const reasonIsEmpty = (classForDropdown: string, classForTextarea: string, showError = true, callBack?: (error: IValidationBody[]) => void) => {
        if (Object.keys(reason).length === 0 || reason === '') {
            const error = [{ fieldName: classForDropdown, errorMessage: '' }]
            callBack && callBack(error)
            showError && highlightError(error)
            return true;
        }
        if ((checkIfIsOtherReason(reason)|| (reason as IVocabulary).id === 0) && textareaRef.current?.value === "") {
            const error = [{ fieldName: classForTextarea, errorMessage: '' }]
            callBack && callBack(error)
            showError && highlightError(error)
            return true;
        }
        showError && removeHighlightError()
        return false
    }

    return {
        reason, onReasonSelect, reasonIsEmpty, setReason, setReasons,
        textareaRef, reasons, isOtherReason, mergeReasonsWithOther, clearReasonStates
    }
}