
import React from 'react'
import { FC } from 'react'
import {IAvailableGuard, IGuardDevice} from '../../common/interfaces/guards/IGuard'
import SimpleBar from 'simplebar-react'
import { IVocabulary } from '../../common/interfaces/vocabulary/IVocabulary'
import { IJobSchedule, IJobShiftsInNeedOfGuards, IJobsRightNow } from '../../common/interfaces/jobs/IJob'
import { highlightChoose } from '../../utils/HighlightChoose'
import DataTable from "../../components/tables/DataTable";
import {useTableList} from "../../customHooks/useTableList";

type Props = {
    onSubmit: (guard: IAvailableGuard ) => void
    selectedItem: IJobShiftsInNeedOfGuards | IJobSchedule | IJobsRightNow
    title: string
    showReasons?: boolean
    reasons?: IVocabulary[]
    reasonsHook?: any
}

const WhithoutDevices: FC<Props> = ({ onSubmit, title }) => {
    const {tableData, isLoading} = useTableList<IGuardDevice>('guard/withoutdevices',)

    return (
        <div className="row align-items-start">

            <div className="col-12 assign-guard-guards">
                <h4 className="mb-3" dangerouslySetInnerHTML={{ __html: title }} />

                <SimpleBar style={{ maxHeight: 350 }} autoHide={false}>
                    <DataTable
                        thead={[{ label: "Guard Name" }]}
                        tbody={tableData ? tableData.map(item => (
                            {
                                id: item.guardId,
                                firstName: item.firstName,
                                fullName: `${item.firstName} ${item.middleName || ""} ${item.lastName}`,
                            }
                        )) : []}
                        onColClick={(key, id, rowItem) => {
                            onSubmit(rowItem)
                            id && highlightChoose(id)
                        }}
                        ignoreCols={[0, 1]}
                        tableClass={"guard-for-schedule mb-0"}
                        isLoading={isLoading}
                    />
                </SimpleBar>
            </div>

        </div>
    )
}

export default WhithoutDevices
