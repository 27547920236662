import React, { FC, useContext } from 'react'
import { SVGCalendar, SVGEraser, SVGPlus } from 'assets/icons/SvgIcons';
import { IDeactivatedShift } from 'common/interfaces/jobs/IShifts';
import { Vocabulary } from 'common/models/Vocabulary';
import CustomSelectControl from 'components/custom-select/CustomSelectControl';
import DataTable from 'components/tables/DataTable';
import { addShiftToScheduleThead } from 'content/dashboard/TablesContent';
import { useListFilter } from 'customHooks/useListFilter';
import { useTableList } from 'customHooks/useTableList';
import { datePickerFormat, filterDateFormat, momentDateFormat, momentHoursFormat } from 'utils/DateFormatting';
import { getHolidayField } from 'utils/GetFieldFromTable';
import { matchOptionWithName } from 'utils/MatchOptionWithName';
import BaseModal from '../BaseModal';
import DatePicker from 'react-datepicker';
import { IActiveJobSchedules, IJobSchedule } from 'common/interfaces/jobs/IJob';
import { useDatapickerSvg } from 'customHooks/useDatapickerSvg';
import { IBaseModalProps } from 'common/interfaces/IModal';
import AppContext from 'context/AppContext';
import { NotificationTypes } from 'common/interfaces/INotification';
import { DataService } from 'common/services/DataService';
import { AddShiftToScheduleModals } from 'common/models/Modals';
import JobDetailsTabsContext from 'context/jobs/JobDetailsTabsContext';
import EditShiftModal from './EditShiftModal';
import { useModals } from 'customHooks/modals/useModals';

interface IProps extends IBaseModalProps {
    jobName: string
    jobSchedule: IJobSchedule | IActiveJobSchedules
    shiftVocabulary: Vocabulary[]
    successCallback?: () => Promise<any>
}

const AddShiftToScheduleModal: FC<IProps> = ({ jobName, jobSchedule, shiftVocabulary, onCancel, successCallback }) => {
    const { showLoader, showNotification } = useContext(AppContext)
    const baseUrl = `shift/schedule?ScheduleId=${jobSchedule.scheduleId
        ? jobSchedule.scheduleId
        : jobSchedule.id}&Deactivated=true`
    const { tableData: deactivatedShifts, isLoading, currentPage, perPage, allRecords, onPagination,
        onPerPage, fetchData, onClearFilterQuery, onSortCall } = useTableList<IDeactivatedShift>(baseUrl, 24, true)
    const { generateFilterQuery, onFilterDropdownChange, filter, onFilterClearClick } = useListFilter(baseUrl,0,0,true, {
        startDate: jobSchedule.startDate, endDate: jobSchedule.endsOn
    })
    const { showDatapicker, changeDatapicker } = useDatapickerSvg()
    const { fetchData: fetchJobDetails, job } = useContext(JobDetailsTabsContext);
    const svc = new DataService({ url: 'shift' })

    const { setModals, modals, itemInFocus: selectedShift, setItemInFocus } = useModals<AddShiftToScheduleModals, IDeactivatedShift>({
        defaultState: new AddShiftToScheduleModals({ showAddModal: true }),
        defaultSelectedItem: jobSchedule
    })

    const onAddShift = async (shift: IDeactivatedShift) => {
        setItemInFocus(shift)
        setModals(new AddShiftToScheduleModals({ showEditShiftModal: true }))
    }

    const onEditShiftSubmit = async (shift: IDeactivatedShift) => {
       showLoader(true)
        try {
            const body = {
                timeFrom: shift.timeFrom,
                timeTo: shift.timeTo,
                guardId: shift.guardId
            }
            setItemInFocus(shift)
            await svc.update(body, shift.id, 'reactivate')

            successCallback
            ? await successCallback()
            : await fetchJobDetails(job.id!)

            setModals(new AddShiftToScheduleModals({ showAddModalSuccess: true }))
            showLoader(false)
        } catch (error: any) {
            showLoader(false)
            showNotification(NotificationTypes.danger, error.message)
        }
    }

    const onCloseSuccessMessage = async () => {
        await fetchData(1, 24, generateFilterQuery(true, false))
        setModals(new AddShiftToScheduleModals({ showAddModal: true }))
    }

    return (
        <>
            <BaseModal
                show={modals.showAddModal}
                onCancel={onCancel}
                showExitBtn
                className='modal--large'
            >
                <div className="d-flex flex-row align-items-center justify-content-between mb-4">
                    <h4 className="mb-0">View Deactivated Shifts for <span className="font-weight-bold">{jobName}</span></h4>
                    <button className="btn btn-outline-aqua-blue" onClick={() => onCancel()}>Close</button>
                </div>

                <form className="filters-form-group filters-form-group--dashboard mb-4" onKeyDown={(event) => {
                    if (event.key === 'Enter') fetchData(1, 24, generateFilterQuery(true, false))
                }}>
                    <div className="row">
                        <div className="col-12">
                            <h5 className='font-weight-bold'>Filter Shift List</h5>
                        </div>
                        <div className="col-12 d-flex flex-wrap">

                            <CustomSelectControl
                                isClearable={true}
                                options={shiftVocabulary}
                                value={filter.shiftPeriod}
                                placeholder={"Select Shift Period"}
                                onChange={e => onFilterDropdownChange(e, "shiftPeriod")}
                                className="flex-grow-0 mb-2"
                            />

                            <div className="form-group ml-2">
                                <div className="react-datepicker-custom-wrapper react-datepicker-custom-wrapper--full-width d-inline-block w-100">
                                    <DatePicker
                                        selected={datePickerFormat(filter.startDate)}
                                        onChange={date => {
                                            date && onFilterDropdownChange(filterDateFormat(date as Date), 'startDate')
                                            changeDatapicker(1)
                                        }}
                                        dateFormat="dd/MM/yyyy"
                                        onInputClick={() => changeDatapicker(1)}
                                        open={showDatapicker[1]}

                                        onClickOutside={() => changeDatapicker(1)}
                                    />
                                    <div onClick={() => changeDatapicker(1)}><SVGCalendar /></div>
                                </div>
                            </div>

                            <div className="form-group ml-2">
                                <div className="react-datepicker-custom-wrapper react-datepicker-custom-wrapper--full-width d-inline-block w-100">
                                    <DatePicker
                                        selected={datePickerFormat(filter.endDate)}
                                        onChange={date => {
                                            date && onFilterDropdownChange(filterDateFormat(date as Date), 'endDate')
                                            changeDatapicker(2)
                                        }}
                                        dateFormat="dd/MM/yyyy"
                                        onInputClick={() => changeDatapicker(2)}
                                        open={showDatapicker[2]}
                                        onClickOutside={() => changeDatapicker(2)}
                                    />
                                    <div onClick={() => changeDatapicker(2)}><SVGCalendar /></div>
                                </div>
                            </div>

                            <button type="button" className="btn btn-aqua-blue ml-2"
                                onClick={() => fetchData(1, 24, generateFilterQuery())}>
                                Filter Shifts
                            </button>

                            {
                                filter.hasValue &&
                                <button type="button" className="btn btn-aqua-blue ml-2"
                                    onClick={() => {
                                        onClearFilterQuery();
                                        onFilterClearClick();
                                    }}>
                                    <SVGEraser />
                                </button>
                            }
                        </div>
                    </div>
                </form>

                {
                    React.useMemo(() => (
                        <DataTable
                            thead={addShiftToScheduleThead.thead}
                            tbody={
                                deactivatedShifts ?
                                    deactivatedShifts.map(shift => ({
                                        id: shift.id,
                                        startDate: momentDateFormat(shift.startDate),
                                        GuardLink: (shift.guardName && shift.guardId)
                                            ? `<a href="#/guards/${shift.guardId}/details">${shift.guardName}</a>` : '-',
                                        aspStartDate: momentHoursFormat(shift.aspStartDate!),
                                        timeFrom: momentHoursFormat(shift.timeFrom),
                                        timeTo: momentHoursFormat(shift.timeTo),
                                        shiftPeriod: matchOptionWithName(shift.shiftPeriod, shiftVocabulary)
                                    }))
                                    : []
                            }
                            ignoreCols={[0]}
                            defaultSortedColumn={0}
                            isLoading={isLoading}
                            tableClass={'table-info--notFixed table-info--lastColHolidays'}
                            currentPage={currentPage}
                            itemsCount={allRecords}
                            itemsPerPage={+perPage}
                            tableName={"Deactivated  Shifts"}
                            onPager={page => onPagination(page)}
                            onPerPage={value => onPerPage(value)}
                            onSort={(orderBy, desc) => onSortCall(orderBy, desc, filter.keyWord)}
                            showTableLengthData
                            sortOnBackend
                            pagination
                        >
                            {
                                (id, _rowItem, rowIndex) => (
                                    <>
                                        <td className="aling-middle">
                                            <div>
                                                <button
                                                    className={`btn btn-aqua-blue mr-1`}
                                                    onClick={() => onAddShift(deactivatedShifts[rowIndex])}
                                                >
                                                    <SVGPlus />
                                                </button>
                                            </div>
                                        </td>
                                        <td className="align-middle">
                                            <div>
                                                {getHolidayField(id, deactivatedShifts)}
                                            </div>
                                        </td>
                                    </>
                                )
                            }
                        </DataTable>
                    ), [isLoading, deactivatedShifts])
                }

            </BaseModal>
            
            { modals.showEditShiftModal &&
                <EditShiftModal
                    show={modals.showEditShiftModal}
                    onClose={() => setModals(new AddShiftToScheduleModals({ showAddModal: true }))}
                    shift={selectedShift}
                    onSubmit={shift => onEditShiftSubmit(shift as any)}
                    jobName={jobName}
                    modalTitle={'Re-Activate Job Shift'}
                />
            }

            <BaseModal
                show={modals.showAddModalSuccess}
                onCancel={() => onCloseSuccessMessage()}
                cancelBtnText='Close'
            >
                <h4 className="text-left mb-4">
                    You have successfully added this job shift for <span className="font-weight-bold"> { jobName } </span>
                </h4>

                <div className="row mb-3">
                    <div className="col-md-4 form-group details-information__title mb-0 d-flex align-items-center">
                        <h6>Assigned Guard: </h6>
                    </div>
                    <div className="col-md-8 form-group mb-0">
                        { selectedShift.assignedGuard && <p className="mb-0">{selectedShift.assignedGuard || '-'}</p> }
                        { selectedShift.guardName && <p className="mb-0">{selectedShift.guardName || '-'}</p> }
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-4 form-group details-information__title mb-0 d-flex align-items-center">
                        <h6>Date: </h6>
                    </div>
                    <div className="col-md-8 form-group mb-0">
                        <p className="mb-0">{momentDateFormat(selectedShift.startDate)}</p>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-4 form-group details-information__title mb-0 d-flex align-items-center">
                        <h6>Start Time: </h6>
                    </div>
                    <div className="col-md-8 form-group mb-0">
                        <p className="mb-0">{momentHoursFormat(selectedShift.timeFrom)}</p>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-4 form-group details-information__title mb-0 d-flex align-items-center">
                        <h6>Start To: </h6>
                    </div>
                    <div className="col-md-8 form-group mb-0">
                        <p className="mb-0">{momentHoursFormat(selectedShift.timeTo)}</p>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-4 form-group details-information__title mb-0 d-flex align-items-center">
                        <h6>Shift Period: </h6>
                    </div>
                    <div className="col-md-8 form-group mb-0">
                        <p className="mb-0">{matchOptionWithName(selectedShift.shiftPeriod, shiftVocabulary)}</p>
                    </div>
                </div>

            </BaseModal>


        </>
    )
}

export default AddShiftToScheduleModal
