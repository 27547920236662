import React, { useContext, useEffect, useState } from 'react'
import {Link} from 'react-router-dom'
import {SVGApprove, SVGEraser} from '../../assets/icons/SvgIcons'
import { IJobRequested, } from '../../common/interfaces/jobs/IJob'
import Layout from '../../components/layout/Layout'
import BaseModal from '../../components/modal/BaseModal'
import DataTable from '../../components/tables/DataTable'
import SectionTitle from '../../components/titles/SectionTitle'
import {
    deactivatedJobsContent
} from "../../content/jobs/JobsContent";
import useActivateDeactivate from '../../customHooks/useActivateDeactivate'
import { useTableList } from '../../customHooks/useTableList'
import {momentDateFormat} from '../../utils/DateFormatting'
import CustomSelectControl from "../../components/custom-select/CustomSelectControl";
import {useListFilter} from "../../customHooks/useListFilter";
import { ActivationDeactivation } from '../../common/enums/Actions'
import DeactivateModal from '../../components/modal/users/DeactivateModal'
import { VocabularyEnum } from '../../common/enums/VocabularyEnum'
import { useVocabulary } from '../../customHooks/vocabulary/useVocabulary'
import { IVocabulary } from '../../common/interfaces/vocabulary/IVocabulary'
import { matchOptionWithName } from '../../utils/MatchOptionWithName'
import { AuthService } from '../../common/auth/AuthService'
import { Roles } from '../../common/enums/Roles'
import AppContext from '../../context/AppContext'
import AutocompleteField from '../../components/autosuggestField/AutocompleteField'
import { useSchoolYear } from '../../customHooks/useSchoolYear'

const DeactivedJobs = () => {
    const { tableData, isLoading, handleColClick, setTableData, fetchData, onClearFilterQuery } = useTableList<IJobRequested>('job/inactive', 0)
    const { onRequestHandler, onRequestSubmitHandler, requestModals, modalText, onCloseRequestModals, itemInFocus, onSuccessResponse } = useActivateDeactivate('job');
    const { vocabulary } = useVocabulary(VocabularyEnum.jobActivation, true)
    const { vocabulary: reasonsForDeactivation, getVocabulary } = useVocabulary(VocabularyEnum.jobDeactivation, true)
    const [searchIsActive, setSearchIsActive] = useState<boolean>(false)
    const {
        generateFilterQuery, filter, onFilterDropdownChange, onFilterClearClick, schoolBoards,
        cities, onSearchChange, getCities, getSchoolBoard, onFilterSearchClearClick, onFilterDefaultSchoolYear
    } = useListFilter("job/inactive")
    const {getSchoolYears: setDefaultSchoolYear, schoolYears} = useSchoolYear();
    useEffect(() => {
        getCities()
        getSchoolBoard()
        setDefaultSchoolYear(onFilterDefaultSchoolYear)
        getVocabulary()
    }, [])

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if(event.key === 'Enter'){
            filter.keyWord !== "" && setSearchIsActive(true)
            filter.keyWord !== "" && fetchData(1, 0, generateFilterQuery(false), false, true)
        }
    }

    const { currentUser } = useContext(AppContext)
    const itemsIsVisible = AuthService.checkIfUserHasPermission([Roles.Admin], currentUser.role)

    return (
        <Layout
            breadcrumbs={{
                links: [
                    {
                        title: 'Jobs',
                        link: 'jobs'
                    },
                    {
                        title: 'Deactivate Jobs',
                        link: 'jobs/deactivate'
                    },
                ],
                currentPageTitle: 'Deactivated Jobs'
            }}
        >
            <div className="row">
                <div className="col-12 mb-4 d-flex flex-row align-items-center justify-content-between">
                    <SectionTitle title="Deactivated Jobs"/>
                    <Link to="/jobs/deactivate" className="btn btn-outline-aqua-blue ml-2 ml-sm-3 mt-2 mt-sm-0">View job deactivation requests</Link>
                </div>
            </div>

            {/* Start Filters */}
            <div className="row mb-4">
                <div className="col-12">
                    {
                        React.useMemo(() => (
                            <form className="filters-form-group mb-0" onKeyDown={(event) => {
                                if (event.key === 'Enter') fetchData(1, 0, generateFilterQuery(true, false))
                            }}>
                                <div className="">
                                    <h5>Filter Job List</h5>
                                </div>
                                <div className=" d-flex flex-row flex-wrap">
                                    <div className="d-flex d-md-none flex-row ml-0 w-100 ml-md-auto mb-2 mb-md-0">
                                        <div className="form-group mb-0 mx-0 w-100 mx-md-2">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={filter.keyWord}
                                                placeholder={'Enter keyword'}
                                                onChange={e => onSearchChange(e.target.value, "keyWord")}
                                                onKeyPress={(e) => handleKeyPress(e)}
                                            />
                                        </div>
                                        <button
                                            type="button"
                                            className="btn btn-aqua-blue ml-2 ml-md-auto"
                                            onClick={() => {
                                                setSearchIsActive(true);
                                                fetchData(1, 0, generateFilterQuery(false), false, true)}
                                        }>
                                            Search
                                        </button>
                                        {
                                            filter.keyWord !== "" &&
                                            <button
                                                type="button"
                                                className="btn btn-aqua-blue ml-2"
                                                onClick={() => {
                                                    setSearchIsActive(false);
                                                    onFilterSearchClearClick()
                                                    onClearFilterQuery()
                                                }}
                                            >
                                                <SVGEraser/>
                                            </button>
                                        }
                                    </div>
                                    <CustomSelectControl
                                        isClearable={true}
                                        options={cities || []}
                                        value={filter.zone}
                                        placeholder={"Select Zone"}
                                        onChange={e => onFilterDropdownChange(e, "zone")}
                                        className="flex-grow-0 mb-2 mb-lg-0"
                                    />
                                    <CustomSelectControl
                                        isClearable={true}
                                        options={schoolBoards || []}
                                        value={filter.schoolBoardId}
                                        placeholder={"Select School Board"}
                                        onChange={e => onFilterDropdownChange(e, "schoolBoardId")}
                                        className="flex-grow-0 ml-0 ml-lg-2 mb-2 mb-lg-0"
                                    />

                                    <CustomSelectControl
                                        options={schoolYears || []}
                                        value={filter.schoolYearId}
                                        placeholder={"Select School Year"}
                                        onChange={e => onFilterDropdownChange(e, "schoolYearId")}
                                        className="flex-grow-0 ml-0 ml-lg-2 mb-2 mb-lg-0"
                                    />

                                    <button
                                        type="button"
                                        className="btn btn-aqua-blue ml-auto ml-md-0 ml-lg-2 mb-2 mb-md-0 mt-2 mt-lg-0"
                                        onClick={() =>
                                            fetchData(1, 0, generateFilterQuery())}
                                    >
                                        Filter Jobs
                                    </button>
                                    {
                                        filter.hasValue &&
                                        <button
                                            type="button"
                                            className="btn btn-aqua-blue ml-2 mb-2 mb-md-0 mt-2 mt-lg-0"
                                            onClick={() => {
                                                onFilterClearClick(() => fetchData(1, 0));
                                                onClearFilterQuery()
                                                setDefaultSchoolYear(onFilterDefaultSchoolYear)
                                            }}
                                        >
                                            <SVGEraser/>
                                        </button>
                                    }

                                    <div className="d-none d-md-flex flex-row ml-auto mt-2 mt-xl-0">
                                        <div className="form-group mb-0 mx-2">
                                            <AutocompleteField
                                                includes={'Job'}
                                                isAsync={true}
                                                placeholder={'Enter keyword'}
                                                onClear={() => {
                                                    setSearchIsActive(false);
                                                    onFilterSearchClearClick()
                                                    fetchData(1, 0, generateFilterQuery(true))
                                                }}
                                                onSearch={ async (value) => {
                                                    setSearchIsActive(true);
                                                    onFilterDropdownChange(value, 'keyWord')
                                                    await fetchData(1, 0, generateFilterQuery(false, true,  value), false, true)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        ), [filter, cities, schoolBoards, schoolYears])
                    }
                </div>
            </div>
            {/* End Filters */}

            <div className="row">
                <div className="col-12">

                    {
                        React.useMemo(() => (
                        <DataTable
                            thead={deactivatedJobsContent.thead}
                            tbody={tableData ?
                                tableData.map(item => ({
                                    id: item.id,
                                    jobNameLink: `<a href="#/jobs/${item.id}/details">${item.jobName}</a>`,
                                    deactivationDate: item.statusChangeDate ? momentDateFormat(item.statusChangeDate) : '-',
                                    approveBy: item.statusChangeBy || '-',
                                    requestDate: item.statusRequestDate ? momentDateFormat(item.statusRequestDate) : '-',
                                    requestedBy: item.statusRequestedBy || '-',
                                    statusChangeReason: item.statusChangeReason ? matchOptionWithName(item.statusChangeReason, reasonsForDeactivation) : '-',
                                }))
                                : []}
                            ignoreCols={[0]}
                            // onColClick={(key, trIndx) => handleColClick(key, trIndx, 'jobNameLink', 'jobs')}
                            tableClass={'table-info--notFixed'}
                            searchIsActive={searchIsActive}
                        >
                            {
                                (_id, rowItem) => (
                                    <td className="aling-middle">
                                        <div>
                                            <button
                                                className={`btn ${itemsIsVisible ? 'btn-aqua-blue' : 'btn-disabled'}`}
                                                onClick={() => {
                                                    itemsIsVisible && onRequestHandler(
                                                    {
                                                        title: `Are you sure you want to reactivate <span class="font-weight-bold">${rowItem.jobNameLink}</span>?`,
                                                        itemInFocus: rowItem,
                                                        type: ActivationDeactivation.activate
                                                    })
                                                }}
                                            >
                                                <SVGApprove />
                                            </button>
                                        </div>
                                    </td>
                                )
                            }
                        </DataTable>
                        ), [tableData, isLoading, vocabulary])
                    }
                </div>
            </div>

            { (requestModals.showRequest) &&
                <DeactivateModal
                    onCancel={() => onCloseRequestModals()}
                    onSubmit={(reason: string | IVocabulary) => onRequestSubmitHandler(ActivationDeactivation.activate,
                        { itemData: null, successCallback: id => setTableData(onSuccessResponse<IJobRequested>(id, tableData)) },
                        `<span class="font-weight-bold">${itemInFocus.jobNameLink}</span> has been Activated.`,
                        reason
                    )}
                    title={modalText.title}
                    vocabulary={vocabulary}
                />
            }

            {
                requestModals.showSuccess && <BaseModal
                    show={requestModals.showSuccess}
                    onCancel={() => onCloseRequestModals()}
                    cancelBtnText={"Close"}
                >
                    <h4 className="text-left mb-4" dangerouslySetInnerHTML={{ __html: modalText.title }} />
                </BaseModal>
            }

        </Layout>
    )
}

export default DeactivedJobs
