import { IBaseShift } from 'common/interfaces/jobs/IShifts'
import { FC } from 'react'
import { IJobModalProps } from '../../../common/interfaces/jobs/IJobModal'
import BaseModal from '../BaseModal'

const EditShiftSuccessModal: FC<IJobModalProps<IBaseShift>> = ({ show, onClose, jobName }) => {
    return (
        <BaseModal
            show={show}
            onCancel={() => onClose()}
            cancelBtnText={"Close"}
        >
            <h4 className="text-left mb-4">
                You have successfully updated this job shift for <span className="font-weight-bold">{jobName}</span>
            </h4>
        </BaseModal>
    )
}

export default EditShiftSuccessModal
