import React, {useContext} from 'react'
import {Link} from 'react-router-dom';
import {AuthService} from '../../common/auth/AuthService';
import {Roles} from '../../common/enums/Roles';
import {VocabularyEnum} from '../../common/enums/VocabularyEnum';
import {Client} from '../../common/models/Client'
import Layout from '../../components/layout/Layout'
import DataTable from '../../components/tables/DataTable';
import SectionTitle from '../../components/titles/SectionTitle';
import {clientsContent} from '../../content/clients/ClientsContent';
import AppContext from '../../context/AppContext';
import {useTableList} from '../../customHooks/useTableList';
import {useVocabulary} from '../../customHooks/vocabulary/useVocabulary';
import {matchOptionWithName} from '../../utils/MatchOptionWithName';

const ActiveClientsList = () => {
    const {currentUser} = useContext(AppContext)

    const {tableData, isLoading, handleColClick} = useTableList<Client>('client', 0)
    const {vocabulary: provinces} = useVocabulary(VocabularyEnum.province, true)

    return (
        <Layout
            breadcrumbs={{currentPageTitle: 'Clients'}}
        >
            <section>
                <div className="row">
                    <div className="col-12 pb-4 d-flex flex-row flex-wrap justify-content-between align-items-center">
                        <SectionTitle title="Active Clients" className="mt-2 mt-md-0"/>
                        <div className="d-flex mt-2 mt-md-0">
                            {
                                AuthService.checkIfUserHasPermission([Roles.Admin, Roles.ASP], currentUser.role) &&
                                <Link to="/clients/add" className="btn btn-aqua-blue">Add a Client</Link>
                            }
                            {
                                AuthService.checkIfUserHasPermission([Roles.Admin, Roles.ASP], currentUser.role) &&
                                <Link to="/clients/deactivate" className="btn ml-2 btn-outline-aqua-blue">View
                                    requests</Link>
                            }
                        </div>
                    </div>
                </div>

                {
                    React.useMemo(() => (
                        <div className="row">
                            {
                                tableData &&
                                <div className="col-12">
                                    <DataTable
                                        thead={clientsContent.thead}
                                        tbody={tableData.map(item => (
                                            {
                                                id: item.id,
                                                fullNameLink: `<a href="#/clients/${item.id}/details">${item.organizationName}</a>`,
                                                contactName: item.primaryContactName ? item.primaryContactName : '-',
                                                address: `${item.firstAddress} ${item.secondAddress || ""}, ${item.city}, ${matchOptionWithName(item.province, provinces)}, ${item.postalCode.toUpperCase()}`,
                                                clientEmail: item.email,
                                                clientPhone: item.phoneNumber,
                                            }
                                        ))}
                                        isLoading={isLoading}
                                        ignoreCols={[0]}
                                        // onColClick={(key, trIndx) => handleColClick(key, trIndx, 'fullNameLink', 'clients')}
                                        tableName={"Active Clients"}
                                    />
                                </div>
                            }
                        </div>
                    ), [tableData, isLoading, provinces])
                }
            </section>
        </Layout>
    )
}

export default ActiveClientsList
