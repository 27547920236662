import React, {useContext, useEffect, useState} from 'react'
import {Tabs, Tab} from 'react-bootstrap'
import {Link, useHistory} from 'react-router-dom'
import { SVGMail } from '../../../assets/icons/SvgIcons'
import {ActivationDeactivation} from '../../../common/enums/Actions'
import {Roles} from '../../../common/enums/Roles'
import {VocabularyEnum} from '../../../common/enums/VocabularyEnum'
import {IClientDropdown} from '../../../common/interfaces/dropdowns/IClientDropDown'
import {NotificationTypes} from '../../../common/interfaces/INotification'
import {IAdministrationUser, IAdministrationUsers} from '../../../common/interfaces/manage-users/IManageUser'
import {IVocabulary} from '../../../common/interfaces/vocabulary/IVocabulary'
import {DataService} from '../../../common/services/DataService'
import Layout from '../../../components/layout/Layout'
import BaseModal from '../../../components/modal/BaseModal'
import AddEditUserModal from '../../../components/modal/users/AddEditUserModal'
import DeactivateModal from '../../../components/modal/users/DeactivateModal'
import DataTable from '../../../components/tables/DataTable'
import SectionTitle from '../../../components/titles/SectionTitle'
import {aspUsersContent, clientUsersContent} from '../../../content/manage-users/ManageUsersTables'
import AppContext from '../../../context/AppContext'
import useActivateDeactivate from '../../../customHooks/useActivateDeactivate'
import {useReason} from '../../../customHooks/useReason'
import {useVocabulary} from '../../../customHooks/vocabulary/useVocabulary'
import {IClient} from "../../../common/interfaces/clients/IClient";
import useGetModel from '../../../customHooks/useGetModel'
import ActionDropdown from '../../../components/administration/ActionDropdown'
import {useRole} from "../../../customHooks/useRole";

enum UserTabKeys {
    clientUsers = 'clientUsers',
    aspUsers = 'aspUsers',
    aspAdmins = 'aspAdmins',
    supervisorUsers = 'supervisorUsers'
}

type ModalsState = { [index:string]: boolean, resetPassword: boolean, success: boolean, newUser: boolean, editUser: boolean}

const ManageUsers = () => {
    const appContext = useContext(AppContext)

    const [modals, setModals] = useState<ModalsState>({
        resetPassword: false, success: false, newUser: false, editUser: false
    })
    const [modalTitle, setModalTitle] = useState<string>('');
    const clientDeactVocabulary = useVocabulary(VocabularyEnum.clientContactDeactivationReason)
    const aspDeactVocabulary = useVocabulary(VocabularyEnum.aspUserDeactivationReason)
    const adminDeactVocabulary = useVocabulary(VocabularyEnum.aspAdminDeactivationReason)
    const replacementReasonHook = useReason()
    const {onRequestHandler, onRequestSubmitHandler, modalText, itemInFocus, requestModals, setRequestModals, onCloseRequestModals} = useActivateDeactivate<IAdministrationUser>('user');

    const [users, setUsers] = useState<IAdministrationUsers>({} as IAdministrationUsers);
    const [clients, setClients] = useState<IClientDropdown[]>([] as IClientDropdown[])
    const [selectedUser, setSelectedUser] = useState<{ id: string, key: string, user: IAdministrationUser }>({
        id: '',
        key: '',
        user: {} as IAdministrationUser
    })
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const { getModelData: getUsers, } = useGetModel<IAdministrationUsers>('user')
    const { getModelData: getClients, } = useGetModel<IClient[]>('client')
    const userSvc = new DataService<IAdministrationUser>({url: 'user'})
    const passwordSvc = new DataService<{ email: string }>({url: 'account/forgot-password'})
    const history = useHistory()
    const {isAdminRole} = useRole()

    useEffect(() => {
        getUsers(data => { 
            setUsers(data)
            setIsLoading(false)
        }, () => setIsLoading(false))
        
        getClients(responseData => {
            let activeClients: any[] = [];
            if (responseData) {
                let data = responseData;
                data.forEach(item => {
                    let obj = {id: 0, name: ''}
                    obj.id = item.id;
                    obj.name = item.organizationName
                    activeClients.push(obj)
                })
            }
            setClients(activeClients)
        })
    }, [])

    const onAddNewUserClick = (actionType: UserTabKeys) => {
        handleModalTitle(actionType, 'new')
        setSelectedUser({
            ...selectedUser,
            key: actionType
        })
        setModals(toggleModals('newUser', modals))
    }

    const handleModalTitle = (modalType: UserTabKeys, type: 'edit' | 'new' , userName?: string) => {
        const condition = type === 'edit' ? true : false
        switch (modalType) {
            case UserTabKeys.clientUsers:
                setModalTitle(condition ? `Edit Profile Details for <span class="font-weight-bold">${userName}</span>` : 'Add a Client Contact User')
                return;
            case UserTabKeys.aspUsers:
                setModalTitle(condition ? `Edit Profile Details for <span class="font-weight-bold">${userName}</span>` : 'Add an ASP User')
                return;
            case UserTabKeys.aspAdmins:
                setModalTitle(condition ? `Edit Profile Details for <span class="font-weight-bold">${userName}</span>` : 'Add an ASP Admin')
                return;
            default:
                setModalTitle('');
                return;
        }
    }

    const handleRole = () => {
        switch (selectedUser.key) {
            case UserTabKeys.clientUsers:
                return {
                    role: Roles.Client,
                    arrKey: UserTabKeys.clientUsers
                }
            case UserTabKeys.aspUsers:
                return {
                    role: Roles.ASP,
                    arrKey: UserTabKeys.aspUsers
                }
            case UserTabKeys.aspAdmins:
                return {
                    role: Roles.Admin,
                    arrKey: UserTabKeys.aspAdmins
                }
            case UserTabKeys.supervisorUsers:
                return {
                    role: Roles.Supervisor,
                    arrKey: UserTabKeys.supervisorUsers
                }
            default:
                break;
        }
    }

    const onResetPasswordClick = async (selectedUserId: string, userArrKey: UserTabKeys) => {
        setSelectedUser({ ...selectedUser, id: selectedUserId, key: userArrKey })
        setModals(toggleModals('resetPassword', modals))
    }

    const onPasswordChangeConfirmClick = async () => {
        appContext.showLoader(true)
        setModalTitle('Password has been successfully reset');
        try {
            await passwordSvc.create({email: users[selectedUser.key].find(user => user.id === selectedUser.id)?.email || ''})
            setModals(toggleModals('success', modals))
            appContext.showLoader(false)
        } catch (error: any) {
            appContext.showLoader(false)
            appContext.showNotification(NotificationTypes.danger, error.message)
        }
    }

    const onNewUserSaveClick = async (filledForm: IAdministrationUser) => {
        const role = handleRole()?.role as Roles;
        filledForm.clientName = clients.find(client => client.id === Number(filledForm.client))?.name
        appContext.showLoader(true)
        try {
            if (handleRole() === undefined) return;
            
            const body = {
                ...filledForm,
                role,
            }
            
            await userSvc.create(body);

            getUsers(data => {
                setUsers(data)
                setIsLoading(false)
                switch(role) {
                  case "Client":
                    setModalTitle(`
                      <span class="font-weight-bold">${filledForm.firstName} ${filledForm.lastName}</span> has been 
                      added as a client contact for <span class="font-weight-bold">${filledForm.clientName}</span>`
                    );
                    break;
                  
                  case "ASP":
                    setModalTitle(`
                      <span class="font-weight-bold">${filledForm.firstName} ${filledForm.lastName}</span> has been added.`
                    );
                    break;

                  case "Supervisor":
                    setModalTitle(`
                      <span class="font-weight-bold">${filledForm.firstName} ${filledForm.lastName}</span> has been added as ASP Supervisor`
                    );
                    break;

                  case "Admin":
                    setModalTitle(`
                      <span class="font-weight-bold">${filledForm.firstName} ${filledForm.lastName}</span> has been added as ASP Admin`
                    );
                    break;

                  default:
                    return "";
                }
                setModals(toggleModals('success', modals))
                appContext.showLoader(false)
            }, (error) => {
                appContext.showLoader(false)
                appContext.showNotification(NotificationTypes.danger, error.message)
            })
           
        } catch (error: any) {
            appContext.showNotification(NotificationTypes.danger, error.message)
            appContext.showLoader(false)
        }
    }

    const getDeactivationReasons = async (key: string): Promise<IVocabulary[] | undefined> => {
        switch (key) {
            case UserTabKeys.clientUsers:
                return clientDeactVocabulary.getVocabulary()
            case UserTabKeys.aspUsers:
                return aspDeactVocabulary.getVocabulary()
            case UserTabKeys.aspAdmins:
                return adminDeactVocabulary.getVocabulary()
            default:
                return undefined
        }
    }

    const toggleModals = (modalKey: string, modals: ModalsState) => {
        for (const [key, ] of Object.entries({...modals})) {
            key === modalKey ? (modals[key] = true) : (modals[key] = false)
        }
        return {...modals}
    }

    const onDeactivateUserClick = async (rowItem: IAdministrationUser, userArrKey: string) => {
        onRequestHandler({
            title: `Are you sure you want to deactivate
              <span class="font-weight-bold">${rowItem.fullName}</span> from
              <span class="font-weight-bold">${rowItem.clientName}</span>?`,
            itemInFocus: rowItem,
            type: ActivationDeactivation.deactivate
        })
        const reasons = await getDeactivationReasons(userArrKey)

        reasons !== undefined && replacementReasonHook.setReasons(reasons)
    }

    const onDeactivateUserSubmitClick = async (userId: string) => {
        setUsers(
            {
                ...Object.fromEntries(Object.entries(users).map(([key, value]) => {
                    return [
                        key, value.filter((user) => user.id !== userId)
                    ]
                })) as IAdministrationUsers
            }
        )

        replacementReasonHook.setReasons([])
        replacementReasonHook.setReason({} as IVocabulary)
    }

    const onUserEditClick = async (rowItem: IAdministrationUser, userArrKey: UserTabKeys) => {
        handleModalTitle(userArrKey, 'edit', `${rowItem.fullName}`)
        setSelectedUser({ user: users[userArrKey].find(user => user.id === rowItem.id) || {} as IAdministrationUser, id: rowItem.id as string, key: userArrKey })
        setModals(toggleModals('editUser', modals))
    }

    const onUserEditSubmit = async (filledForm: IAdministrationUser) => {
        appContext.showLoader(true)
        if (filledForm.client) {
            filledForm.clientName = clients.find(client => client.id === Number(filledForm.client))?.name
        }
        try {
            if (handleRole() === undefined) return;
            await userSvc.update(filledForm, selectedUser.id)

            getUsers(data => { 
                setUsers(data)
                setModalTitle(
                  `Profile details for <span class="font-weight-bold">${filledForm.firstName} ${filledForm.lastName}</span>
                  have been updated as a client contact for <span class="font-weight-bold">${filledForm.clientName}</span>`
                );
                setModals(toggleModals('success', modals))
            })
            
            // setUsers({
            //     ...users,
            //     [handleRole()?.arrKey as string]: users[handleRole()?.arrKey as string].map(user => {
            //         if (filledForm.setAsPrimary) (user.id !== selectedUser.id) && (user.setAsPrimary = false);

            //         if (user.id === selectedUser.id) {
            //             return filledForm
            //         } else return user;
            //     })
            // })
            // setModalTitle(`<span class="font-weight-bold">${filledForm.firstName} ${filledForm.middleName || ""} ${filledForm.lastName}</span> has been updated.`);
            // setModals(toggleModals('success', modals))
            // appContext.showLoader(false)
        } catch (error: any) {
            appContext.showNotification(NotificationTypes.danger, error.message)
            appContext.showLoader(false)
        }
    }

    return (
        <Layout
            breadcrumbs={{
                links: [{
                    title: 'Administration',
                    link: 'administration'
                }],
                currentPageTitle: 'Manage Users'
            }}
        >
            <div className="row">
                <div className="col-12 mb-4 d-flex flex-row align-items-center justify-content-between">
                    <SectionTitle title="Manage Users"/>
                    { isAdminRole() &&
                        <Link to="/administration/manage-users/pending"
                          className="btn btn-outline-aqua-blue ml-2 ml-sm-3 mt-2 mt-sm-0">User Deactivation Requests</Link>
                    }
                </div>

                <div className="col-12 details-tabs details-tabs--manage-users">
                    <Tabs defaultActiveKey="client-users" id="uncontrolled-tab-example" className="d-flex tabs-details">
                        <Tab eventKey="client-users" title="Client Contact Users">
                            <div className="d-flex flex-row align-items-center justify-content-between mb-4">
                                <h5 className="mb-0 text-center">Client Contact Users</h5>
                                {
                                    isAdminRole() &&
                                    <button className="btn btn-aqua-blue pt-0 pb-0"
                                            onClick={() => onAddNewUserClick(UserTabKeys.clientUsers)}>Add a Client Contact</button>
                                }
                            </div>
                            {
                                React.useMemo(() => (
                                    <DataTable
                                        thead={clientUsersContent.thead}
                                        tbody={users.clientUsers ? users.clientUsers.map(user => ({
                                                id: user.id,
                                                fullName: `${user.firstName} ${user.middleName ? user.middleName : ""} ${user.lastName}`,
                                                clientName: user.clientName || '-',
                                                position: user.position ? user.position : '-',
                                                email: user.email ? user.email : '-',
                                                phone: user.phoneNumber ? user.phoneNumber : '-',
                                                mobile: user.mobileNumber ? user.mobileNumber : '-'
                                            })).sort((a, b) => a.fullName.localeCompare(b.fullName))
                                            : []}
                                        ignoreCols={[0]}
                                        isLoading={isLoading}
                                        tableClass={'table-info--notFixed'}
                                    >
                                        {
                                            (id, rowItem: IAdministrationUser) => (
                                                <>
                                                    <td className="aling-middle">
                                                        <div>
                                                            <ActionDropdown
                                                                onEdit={() => onUserEditClick(rowItem, UserTabKeys.clientUsers)}
                                                                onDeactivate={() => onDeactivateUserClick(rowItem, 'clientUsers')}
                                                                key={UserTabKeys.clientUsers}
                                                            />
                                                        </div>
                                                    </td>

                                                    <td className="aling-middle">
                                                        <div>
                                                            <button
                                                                className={`btn btn-aqua-blue`}
                                                                onClick={() => onResetPasswordClick(id, UserTabKeys.clientUsers)}
                                                            >
                                                                <SVGMail/>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </>
                                            )
                                        }
                                    </DataTable>
                                ), [users, isLoading])
                            }
                        </Tab>
                        <Tab eventKey="asp-users" title="ASP Users">
                            <div className="d-flex flex-row align-items-center justify-content-between mb-4">
                                <h5 className="mb-0 ">ASP Users</h5>
                                {
                                    isAdminRole() &&
                                    <button className="btn btn-aqua-blue "
                                            onClick={() => onAddNewUserClick(UserTabKeys.aspUsers)}>Add an ASP User</button>
                                }
                            </div>
                            {
                                React.useMemo(() => (
                                    <DataTable
                                        thead={aspUsersContent.thead}
                                        tbody={users.aspUsers ? users.aspUsers.map(user => ({
                                                id: user.id,
                                                fullName: `${user.firstName} ${user.middleName ? user.middleName : ""} ${user.lastName}`,
                                                position: user.position ? user.position : '-',
                                                email: user.email ? user.email : '-',
                                                phone: user.phoneNumber ? user.phoneNumber : '-',
                                                mobile: user.mobileNumber ? user.mobileNumber : '-',
                                            })).sort((a, b) => a.fullName.localeCompare(b.fullName))
                                            : []}
                                        ignoreCols={[0]}
                                        isLoading={isLoading}
                                        tableClass={'table-info--notFixed'}
                                    >
                                        {
                                            (id, rowItem: IAdministrationUser) => (
                                                <>
                                                    <td className="aling-middle">
                                                        <div>
                                                            <ActionDropdown
                                                                onEdit={() => onUserEditClick(rowItem, UserTabKeys.aspUsers)}
                                                                onDeactivate={() => onDeactivateUserClick(rowItem, 'aspUsers')}
                                                                key={UserTabKeys.aspUsers}
                                                            />
                                                        </div>
                                                    </td>

                                                    <td className="aling-middle">
                                                        <div>
                                                            <button
                                                                className={`btn ${isAdminRole() ? 'btn-aqua-blue' : 'btn-disabled'}`}
                                                                onClick={() => isAdminRole() && onResetPasswordClick(id, UserTabKeys.aspUsers)}
                                                            >
                                                                <SVGMail/>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </>
                                            )
                                        }
                                    </DataTable>
                                ), [users, isLoading])
                            }
                        </Tab>
                        <Tab eventKey="asp-supervisors" title="ASP Supervisors">
                            <div className="d-flex flex-row align-items-center justify-content-between mb-4">
                                <h5 className="mb-0">ASP Supervisors</h5>
                                {
                                    isAdminRole() &&
                                    <button className="btn btn-aqua-blue"
                                            onClick={() => history.push(`/guards/add`)}>Add an ASP Supervisor</button>
                                }
                            </div>
                            {
                                React.useMemo(() => (
                                    <DataTable
                                        thead={aspUsersContent.thead}
                                        tbody={users.supervisorUsers ? users.supervisorUsers.map(user => ({
                                                id: user.id,
                                                fullName: `${user.firstName} ${user.middleName ? user.middleName : ""} ${user.lastName}`,
                                                position: user.position ? user.position : '-',
                                                email: user.email ? user.email : '-',
                                                phone: user.phoneNumber ? user.phoneNumber : '-',
                                                mobile: user.mobileNumber ? user.mobileNumber : '-',
                                            })).sort((a, b) => a.fullName.localeCompare(b.fullName))
                                            : []}
                                        ignoreCols={[0]}
                                        isLoading={isLoading}
                                        tableClass={'table-info--notFixed'}
                                    >
                                        {
                                            (id, rowItem: IAdministrationUser) => (
                                                <>
                                                    <td className="aling-middle">
                                                        <div>
                                                            <ActionDropdown
                                                                onEdit={() => history.push(`/guards/${id}/edit`)}
                                                                key={UserTabKeys.supervisorUsers}
                                                            />
                                                        </div>
                                                    </td>

                                                    <td className="aling-middle">
                                                        <div>
                                                            <button
                                                                className={`btn ${isAdminRole() ? 'btn-aqua-blue' : 'btn-disabled'}`}
                                                                onClick={() => isAdminRole() && onResetPasswordClick(id, UserTabKeys.supervisorUsers)}
                                                            >
                                                                <SVGMail/>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </>
                                            )
                                        }
                                    </DataTable>
                                ), [users, isLoading])
                            }
                        </Tab>
                        <Tab eventKey="asp-admin" title="ASP Admin">
                            <div className="d-flex flex-row align-items-center justify-content-between mb-4">
                                <h5 className="mb-0">ASP Admin</h5>
                                {
                                    isAdminRole() &&
                                    <button className="btn btn-aqua-blue"
                                            onClick={() => onAddNewUserClick(UserTabKeys.aspAdmins)}>Add an ASP Admin</button>
                                }
                            </div>
                            {
                                React.useMemo(() => (
                                    <DataTable
                                        thead={aspUsersContent.thead}
                                        tbody={users.aspAdmins ? users.aspAdmins.map(user => ({
                                                id: user.id,
                                                fullName: `${user.firstName} ${user.middleName ? user.middleName : ""} ${user.lastName}`,
                                                position: user.position ? user.position : '-',
                                                email: user.email ? user.email : '-',
                                                phone: user.phoneNumber ? user.phoneNumber : '-',
                                                mobile: user.mobileNumber ? user.mobileNumber : '-',
                                            })).sort((a, b) => a.fullName.localeCompare(b.fullName))
                                            : []}
                                        ignoreCols={[0]}
                                        isLoading={isLoading}
                                        tableClass={'table-info--notFixed'}
                                    >
                                        {
                                            (id, rowItem: IAdministrationUser) => (
                                                <>
                                                    <td className="aling-middle">
                                                        <div>
                                                            <ActionDropdown
                                                                onEdit={() => onUserEditClick(rowItem, UserTabKeys.aspAdmins)}
                                                                onDeactivate={() => onDeactivateUserClick(rowItem, 'aspAdmins')}
                                                                key={UserTabKeys.aspAdmins}
                                                            />
                                                        </div>
                                                    </td>

                                                    <td className="aling-middle">
                                                        <div>
                                                            <button
                                                                className={`btn ${isAdminRole() ? 'btn-aqua-blue' : 'btn-disabled'}`}
                                                                onClick={() => isAdminRole() && onResetPasswordClick(id, UserTabKeys.aspAdmins)}
                                                            >
                                                                <SVGMail/>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </>
                                            )
                                        }
                                    </DataTable>
                                ), [users, isLoading])
                            }
                        </Tab>
                    </Tabs>
                </div>
            </div>

            {isAdminRole() &&
            <>
                {
                    (modals.newUser || modals.editUser) &&  <AddEditUserModal
                        onCancel={() => setModals({...modals, newUser: false, editUser: false})}
                        onSubmit={data => modals.newUser ? onNewUserSaveClick(data) : onUserEditSubmit(data)}
                        title={modalTitle}
                        isClient={selectedUser.key === UserTabKeys.clientUsers}
                        listOfClients={clients}
                        submitBtnText={modals.newUser ? 'Save and Send Invitation to Set Password' : 'Save Updates'}
                        user={modals.editUser ? selectedUser.user : undefined}
                    />
                }

                {
                    modals.success && <BaseModal
                        show={true}
                        bodyClassName="text-center"
                        onCancel={() => setModals({...modals, success: false})}
                        cancelBtnText={'Close'}
                    >
                        <h4 dangerouslySetInnerHTML={{__html: modalTitle }}/>
                        <p className="mt-5 mb-0">
                          An email had been sent to the email address entered for this contact to set up their password.
                        </p>
                    </BaseModal>
                }

                {
                    modals.resetPassword && <BaseModal
                        show={true}
                        onCancel={() => setModals({...modals, resetPassword: false})}
                        onSubmit={() => onPasswordChangeConfirmClick()}
                        cancelBtnText={'Cancel'}
                        submitBtnText={'Confirm'}
                    >
                        <h4>Are you sure you want to reset password ? </h4>
                    </BaseModal>
                }          

            </>
            }

                {
                    requestModals.showRequest &&
                    <DeactivateModal
                        onCancel={() => setRequestModals({...requestModals, showRequest: false })}
                        onSubmit={(reason: string | IVocabulary) => onRequestSubmitHandler(ActivationDeactivation.deactivate,
                            {itemData: null, successCallback: (id) => onDeactivateUserSubmitClick(id!.toString())},
                            `${isAdminRole() ? `<span class="font-weight-bold">${itemInFocus.fullName}</span> has been deactivated.` : `Your request to deactivate <span class="font-weight-bold">${itemInFocus.fullName}</span> has been sent to the Administrator.`} `,
                            reason
                        )}
                        title={modalText.title}
                        vocabulary={replacementReasonHook.reasons}
                        bodyClassName="text-center"
                    />
                }

                {
                    requestModals.showSuccess && <BaseModal
                        show={requestModals.showSuccess}
                        onCancel={() => onCloseRequestModals()}
                        cancelBtnText={'Close'}
                    >
                        <h4 className="text-left" dangerouslySetInnerHTML={{ __html: modalText.title }} />
                    </BaseModal>
                } 
        </Layout>
    )
}

export default ManageUsers
