import React, { FC, useContext, useState } from 'react'
import DatePicker from "react-datepicker";
import { SVGCalendar } from '../../assets/icons/SvgIcons'
import InputMask from 'react-input-mask';

import Layout from '../../components/layout/Layout'
import SectionTitle from '../../components/titles/SectionTitle'

import { IClient, IClientContact } from '../../common/interfaces/clients/IClient'
import { Client } from '../../common/models/Client'

import { useOnIputChange } from '../../customHooks/useOnInputChange'
import { datePickerFormat, setHoursAndMinutes } from '../../utils/DateFormatting'
import { highlightError, removeHighlightError, validateEmail, validateFiled, validatePhoneNumber } from '../../utils/Validation';
import CustomSelectControl from '../../components/custom-select/CustomSelectControl';
import { Link, RouteComponentProps } from 'react-router-dom';
import AddEditUserModal from '../../components/modal/users/AddEditUserModal';
import { VocabularyEnum } from '../../common/enums/VocabularyEnum';
import { useVocabulary } from '../../customHooks/vocabulary/useVocabulary';
import { useEffect } from 'react';
import { IValidationBody, ValidationRules } from '../../common/interfaces/IValidation';
import { NotificationTypes } from '../../common/interfaces/INotification';
import AppContext from '../../context/AppContext';
import { DataService } from '../../common/services/DataService';
import useSaveClientContact from '../../customHooks/useSaveClientContact'

const AddClient: FC<RouteComponentProps> = (props) => {
    const [modals, setModals] = useState<{ add: boolean }>({ add: false })
    const [clientId, setClientId] = useState<number | null>(null)
    const { hookState, onChange } = useOnIputChange<IClient>(new Client())
    const { vocabulary, getVocabulary } = useVocabulary(VocabularyEnum.province)
    const { saveClientContact } = useSaveClientContact()
    const appContext = useContext(AppContext)

    const formData = hookState
    const clientSvc = new DataService<IClient>({ url: 'client' })

    useEffect(() => {;
        getVocabulary();
    }, [])

    const validateClientFields = (callback: () => Promise<void>) => {
        let errors: IValidationBody[] = [];

        for (let [field, value] of Object.entries(formData)) {
            const skipFields = ["secondAddress", "id", "primaryContactName"]
            if (!skipFields.includes(field)) {
                errors = [...errors, ...validateFiled(value as string, field, ValidationRules.required)]
            }
        }

        errors = [...errors, ...validateFiled(formData.phoneNumber as string, "phoneNumber", ValidationRules.isValidPhone)]
        errors = [...errors, ...validateFiled(formData.email as string, "email", ValidationRules.isValidEmail)]
        errors = [...errors, ...validateFiled(formData.postalCode as string, "postalCode", ValidationRules.isValidPostalCode)]

        if (errors.length !== 0) {
            appContext.showNotification(NotificationTypes.warning, "Please fill in all fields correctly")
            highlightError(errors)
        } else {
            removeHighlightError()
            callback()
        }
    }

    const saveClient = async (redirectOnSuccess = true, callback?: () =>void) => {
        appContext.showLoader(true)
        try {
            const response = await clientSvc.create(formData)

            setClientId(response.data)

            appContext.showNotification(NotificationTypes.success, `${formData.organizationName} has been created`)
            redirectOnSuccess && props.history.push('/clients');
            (!redirectOnSuccess && callback) && callback();

            appContext.showLoader(false)
        } catch (error: any) {
            appContext.showNotification(NotificationTypes.danger, error.message)
            appContext.showLoader(false)
        }
    }

    const saveContact = async (redirectOnSuccess = true, clientContact: IClientContact, client: number | null) => {
        saveClientContact(clientContact, client, () => {
            redirectOnSuccess && props.history.push('/clients')
        })
    }

    const onSaveAndAddClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        validateClientFields(async () => {
            await saveClient(false, () => setModals({...modals, add: true}))
        })
    }

    return (
        <Layout
            breadcrumbs={{
                links: [{
                    title: 'Clients',
                    link: 'clients'
                }],
                currentPageTitle: 'Add a Client'
            }}
        >

        <div className="row mb-3">
            <div className="col-12">
                <SectionTitle title={'Add a Client'}/>
            </div>
        </div>

        <section className="row">
            <div className="col-lg-11 col-xl-9 mx-auto">
                <form autoComplete="new-password">
                    <div className="row">
                        <div className="form-group col-md-6">
                            <label className="required" htmlFor="organizationName">Organization Name</label>
                            <input type="text" name="organizationName" className="form-control" id="organizationName" placeholder="Enter organization name" autoComplete="new-password"
                                spellCheck="false"
                                autoCapitalize="none"
                                autoCorrect="off"
                                value={formData.organizationName}
                                onChange={e => onChange(e)}
                            />
                        </div>
                        <div className="form-group col-md-6 align-self-end">
                            <label className="required">ASP Start Date</label>
                            <div className="react-datepicker-custom-wrapper react-datepicker-custom-wrapper--full-width d-inline-block w-100">
                                <DatePicker
                                    selected={datePickerFormat(formData.aspStartDate)}
                                    onChange={date => date && onChange(setHoursAndMinutes(date as Date), 'aspStartDate')}
                                    dateFormat="dd/MM/yyyy"
                                />
                                <div onClick={() => console.log()}><SVGCalendar /></div>
                            </div>
                        </div>

                        <div className="form-group col-md-6">
                            <label className="required" htmlFor="email">Email address</label>
                            <input type="email" name="email" className="form-control" id="email" placeholder="Enter email address" autoComplete="new-password"
                                spellCheck="false"
                                autoCapitalize="none"
                                autoCorrect="off"
                                value={formData.email ? formData.email : ''}
                                onChange={e => onChange(e)}
                                onBlur={e => validateEmail(e)}
                            />
                        </div>
                        <div className="form-group col-md-6">
                            <label className="required" htmlFor="phoneNumber">Phone Number</label>
                            <InputMask
                                mask={"999-999-9999"}
                                type="text"
                                name="phoneNumber"
                                className="form-control"
                                id="phoneNumber"
                                placeholder="Enter phone number"
                                autoComplete="new-password"
                                spellCheck="false"
                                autoCapitalize="none"
                                autoCorrect="off"
                                value={formData.phoneNumber}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
                                onBlur={e => validatePhoneNumber(e)}
                            />
                        </div>
                        <div className="form-group col-md-6">
                            <label className="required" htmlFor="city">City</label>
                            <input type="text" name="city" className="form-control" id="city" placeholder="Enter a city"  autoComplete="new-password"
                                spellCheck="false"
                                autoCapitalize="none"
                                autoCorrect="off"
                                value={formData.city}
                                onChange={e => onChange(e)}
                            />
                        </div>
                        <div className="form-group col-md-6">
                            <label className="required" htmlFor="province">Select a Province</label>
                            <CustomSelectControl
                                options={vocabulary ? vocabulary : []}
                                onChange={value => onChange(value, "province")}
                                value={formData.province}
                                placeholder={"Select a Province"}
                                className={`custom-react-select--province`}
                            />
                        </div>
                        <div className="form-group col-md-6 align-self-end">
                            <label className="required" htmlFor="firstAddress">Address 1</label>
                            <input type="text" name="firstAddress" className="form-control" id="firstAddress" placeholder="Enter address line 1"  autoComplete="new-password"
                                spellCheck="false"
                                autoCapitalize="none"
                                autoCorrect="off"
                                value={formData.firstAddress}
                                onChange={e => onChange(e)}
                            />
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="secondAddress">Address 2</label>
                            <input type="text" name="secondAddress" className="form-control" id="secondAddress" placeholder="Enter address line 2" autoComplete="new-password"
                                spellCheck="false"
                                autoCapitalize="none"
                                autoCorrect="off"
                                value={formData.secondAddress}
                                onChange={e => onChange(e)}
                            />
                        </div>
                        <div className="form-group col-md-6">
                            <label className="required" htmlFor="postalCode">Postal Code</label>
                            <InputMask
                                mask={"a9a 9a9"}
                                type="text"
                                name="postalCode"
                                className="form-control"
                                id="postalCode"
                                placeholder="Enter postal code"
                                autoComplete="new-password"
                                value={formData.postalCode}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
                                onBlur={e => validatePhoneNumber(e)}
                            />
                        </div>
                    </div>

                </form>
            </div>
            <div className="col-lg-11 col-xl-9 mx-auto d-flex flex-wrap py-2 py-md-5 justify-content-between">
                <Link className="btn btn-outline-aqua-blue mt-2" to="/clients">Cancel</Link>
                <div>
                    <button type="submit" className="btn btn-outline-aqua-blue ml-auto mr-3 mt-2" onClick={e => onSaveAndAddClick(e)}>Save And Add a Contact</button>
                    <button type="submit" className="btn btn-aqua-blue mt-2" onClick={() => validateClientFields(() => saveClient())}>Save a Client</button>
                </div>

            </div>
        </section>

        {
            modals.add &&
            <AddEditUserModal
                onCancel={() => props.history.push('/clients')}
                onSubmit={data => saveContact(true, data as IClientContact, clientId)}
                title={`Add a Client Contact User for <span class="font-weight-bold">${formData.organizationName}</span>`}
                isClient={true}
                clientAlreadySet
                submitBtnText={'Save and Send Invitation to Set Password'}
            />
        }

        </Layout>
    )
}

export default AddClient
