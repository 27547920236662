import React, { useContext, useState } from 'react'

import { NotificationTypes } from 'common/interfaces/INotification';
import { IValidationBody, ValidationRules } from 'common/interfaces/IValidation';
import { DataService } from 'common/services/DataService';
import AppContext from 'context/AppContext';
import { highlightError, removeHighlightError, validateFiled } from 'utils/Validation';

type TProps = {
    defaultValue?: string | undefined,
	required?: boolean
}

const useClientComment = ({ defaultValue, required = true }: TProps) => {
    const [comment, setComment] = useState(defaultValue || '');
	const [showErrorMessage, setShowErrorMessage] = useState('');
	const {showLoader, showNotification} = useContext(AppContext);
    
    const svc = new DataService({ url: 'shift' });

    const handleAddEditClientComment = async (jobId: number, submitCallback?: () => void) => {
		if (validForm() && jobId) {
			showLoader(true)
			try {
				await svc.update({comment}, jobId, 'comment')
				submitCallback && submitCallback();
				showLoader(false);
			} catch (error: any) {
				showLoader(false);
				showNotification(NotificationTypes.danger, error.message)
			} finally {
				setShowErrorMessage('');
			}
		}
	};

    const handleRemoveClientComment = async (jobId: number, submitCallback?: () => void) => {
        showLoader(true)
        try {
            await svc.delete(`${jobId}/comment`)
            submitCallback && submitCallback();
            showLoader(false);
        } catch (error: any) {
            showLoader(false);
            showNotification(NotificationTypes.danger, error.message)
        }
    }

    const validForm = () => {
		let errors: IValidationBody[] = [];

		if (required) {
			errors.push(...validateFiled(comment as string, 'comments', ValidationRules.required))
		}
		if (comment.length > 200) {
			errors.push({
				errorMessage: 'The comment must contain less then 200 characters.',
				fieldName: 'comments',
			});
		}

		if (errors.length === 0) {
			removeHighlightError();
			setShowErrorMessage('');
			return true;
		} else {
			console.log(errors);
			setShowErrorMessage(errors.map((err) => err.errorMessage).join('\n'));
			highlightError([{ fieldName: 'comments', errorMessage: '' }]);
			return false;
		}
	};

	const onCommentChange =  (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		const value = event.target.value
		setComment(value.length > 200 ? value.slice(0, 200): value);
	}

    return {
        handleAddEditClientComment, showErrorMessage, onCommentChange, comment, handleRemoveClientComment, setComment, validForm, setShowErrorMessage
    }
}

export default useClientComment