import React, { FC } from 'react'
import { IGuardDevice } from '../../../common/interfaces/guards/IGuard'
import CustomSelectControl from '../../custom-select/CustomSelectControl'
import { IVocabulary } from '../../../common/interfaces/vocabulary/IVocabulary'
import InputMask from 'react-input-mask';
import { validatePhoneNumber } from '../../../utils/Validation';

type Props = {
    device: IGuardDevice | undefined;
    onChange: (e: React.ChangeEvent<HTMLInputElement> | string, fieldName?: string) => void
    vocabulary: IVocabulary[]
}

const AssignDeviceForm: FC<Props> = ({ device, onChange, vocabulary }) => {
    return (
        <div className="col-lg-11 col-xl-9 mx-auto pt-4">
            <h5>Assign Device</h5>
            <div className="row pt-4">
                <div className="form-group col-md-6">
                    <label htmlFor="brand">Device Brand</label>
                    <CustomSelectControl 
                        options={vocabulary} 
                        onChange={e => onChange(e, "brand")}
                        placeholder="Select device brand"
                        value={device?.brand || ""}
                        className="custom-react-select--brand"
                        isClearable={true}
                    />
                </div>
                <div className="form-group col-md-6">
                    <label htmlFor="model">Device Model</label>
                    <input type="text" className="form-control" name="model" id="model" placeholder="Enter device model" autoComplete="nope"
                        value={device?.model || ""}
                        onChange={e => onChange(e)}
                    />
                </div>
                <div className="form-group col-md-6">
                    <label htmlFor="serialNumber">Serial Number</label>
                    <input type="text" className="form-control" name="serialNumber" id="serialNumber" placeholder="Enter serial number" autoComplete="nope"
                        value={device?.serialNumber || ""}
                        onChange={e => onChange(e)}
                    />
                </div>
                <div className="form-group col-md-6">
                    <label htmlFor="aspMobile">ASP Mobile</label>
                    <InputMask
                        mask={"999-999-9999"}
                        type="text"
                        name="aspMobile"
                        className="form-control"
                        id="aspMobile"
                        placeholder="Enter ASP Mobile number"
                        autoComplete="nope"
                        value={device?.aspMobile}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
                        onBlur={e => validatePhoneNumber(e)}
                    />
                </div>
            </div>
        </div>
    )
}

export default AssignDeviceForm
