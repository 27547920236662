import React, { FC } from 'react';
import Switch from 'react-switch';
type Props = {
	onChange: () => void;
	checked: boolean;
};
const CustomSwitch: FC<Props> = ({ onChange, checked }) => {
	return (
		<div className="custom-switch pl-0">
			<Switch
				onChange={onChange}
				checked={checked}
				borderRadius={3}
				onColor={'#78BC61'}
				offColor={'#9C9C9C'}
                height={20}
                width={50}
				checkedIcon={
					<p className="custom-switch__checked-mode">on</p>
				}
				uncheckedIcon={
					<p className="custom-switch__unchecked-mode">off</p>
				}
                handleDiameter={13}
			/>
		</div>
	);
};

export default CustomSwitch;
