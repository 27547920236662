import { FC, useContext, useEffect, useState } from 'react'
import { IBaseModalProps } from '../../../common/interfaces/IModal'
import {IActiveJobSchedules, IJob, IJobSchedule} from '../../../common/interfaces/jobs/IJob'
import JobDetailsTabsContext from '../../../context/jobs/JobDetailsTabsContext'
import { momentDateFormat, momentHoursFormat, timeMinusMinutes } from '../../../utils/DateFormatting'
import { matchOptionWithName } from '../../../utils/MatchOptionWithName'
import { matchWeekDays } from '../../../utils/MatchWeekDays'
import BaseModal from '../BaseModal'
import {IVocabulary} from "../../../common/interfaces/vocabulary/IVocabulary";
import { IAvailableGuard } from '../../../common/interfaces/guards/IGuard'
import { generateFullName } from '../../../utils/GenerateFullName'
import ResourceDataService from 'common/services/ResourceDataService'
import { ISettings } from 'common/interfaces/administration/ISettings'

interface IProps extends IBaseModalProps {
    originalSchedule: IJobSchedule |  IActiveJobSchedules
    editedSchedule: IJobSchedule |  IActiveJobSchedules
    activeJob?: IJob;
    vocabularyShift?: IVocabulary[];
    assignedGuard?: IAvailableGuard
}

const EditScheduleConfirmationModal: FC<IProps> = ({ onCancel, onSubmit, originalSchedule, editedSchedule,
    activeJob, vocabularyShift, assignedGuard
    }) => {
    const { vocabulary, job } = useContext(JobDetailsTabsContext);
    const [bufferTime, setBufferTime] = useState(30);
    const settingSvc = new ResourceDataService<ISettings>({url: 'setting'})
    useEffect(() => {
        const getSettings = async () => {
            const {data: settings} = await settingSvc.getAll()
            settings.bufferTime && setBufferTime(settings.bufferTime);
        }
        getSettings()
    }, [])
    return (
        <BaseModal
            show={true}
            onCancel={() => onCancel()}
            onSubmit={() => onSubmit && onSubmit()}
            submitBtnText={"Confirm"}
            cancelBtnText={'Back'}
            className={'edit-schedule-modal edit-shedule-confirmation-modal'}
        >
            <h4>Confirmation Required</h4>
            <p className="mb-3">
                You have { assignedGuard ? "added" : "requested" } the following job schedule update for <span className="font-weight-bold">{ job?.jobName || activeJob!.jobName }</span>:
            </p>
            <div className="row align-items-center">
                { !assignedGuard &&
                    <>
                        <div className="col-sm-4 details-information__title mb-3 "></div>
                        <div className="col-sm-4 details-information__title mb-3 ">
                            <h6 className="mb-0">From:</h6>
                        </div>
                        <div className="col-sm-4 details-information__title mb-3 ">
                            <h6 className="mb-0">To:</h6>
                        </div>
                    </>
                }
                {/* Gyard */}
                <div className="col-sm-4 details-information__title mb-3 ">
                    <h6 className="mb-0">Start Date:</h6>
                </div>
                { !assignedGuard &&
                    <div className="col-sm-4 details-information__value d-flex flex-row align-items-center mb-3 ">
                        <p className="mb-0">
                            {momentDateFormat(originalSchedule.startDate)}
                        </p>
                    </div>
                }
                <div className={`${!assignedGuard ? 'col-sm-4' : 'col-sm-8'} details-information__value d-flex flex-row align-items-center mb-3`}>
                    <p className="mb-0">
                        {momentDateFormat(editedSchedule.startDate)}
                    </p>
                </div>

                {/* Date */}
                <div className="col-sm-4 details-information__title mb-3 ">
                    <h6 className="mb-0">End Date:</h6>
                </div>
                { !assignedGuard && 
                <div className="col-sm-4 details-information__value d-flex flex-row align-items-center mb-3 ">
                    <p className="mb-0">
                        {momentDateFormat(originalSchedule.endsOn)}
                    </p>
                </div>
                }
                <div className={`${!assignedGuard ? 'col-sm-4' : 'col-sm-8'} details-information__value d-flex flex-row align-items-center mb-3`}>
                    <p className="mb-0">
                        {momentDateFormat(editedSchedule.endsOn)}
                    </p>
                </div>

                {/* ASP Start Time */}
                <div className="col-sm-4 details-information__title mb-3 ">
                    <h6 className="mb-0">ASP Start Time:</h6>
                </div>
                { !assignedGuard && 
                <div className="col-sm-4 details-information__value d-flex flex-row align-items-center mb-3 ">
                    <p className="mb-0">
                        {
                            originalSchedule.aspStartDate ? 
                                momentHoursFormat(originalSchedule.aspStartDate || '') : 
                                originalSchedule.timeFrom ? 
                                momentHoursFormat(timeMinusMinutes(originalSchedule.timeFrom || '', bufferTime))
                            : '-'
                        }
                    </p>
                </div>
                }
                <div className={`${!assignedGuard ? 'col-sm-4' : 'col-sm-8'} details-information__value d-flex flex-row align-items-center mb-3`}>

                    <p className="mb-0">
                        {
                            editedSchedule.timeFrom ? 
                                momentHoursFormat(timeMinusMinutes(editedSchedule.timeFrom || '', bufferTime))
                            : '-'
                        }
                    </p>
                </div>

                {/* Start Time */}
                <div className="col-sm-4 details-information__title mb-3 ">
                    <h6 className="mb-0">Start Time:</h6>
                </div>
                { !assignedGuard && 
                <div className="col-sm-4 details-information__value d-flex flex-row align-items-center mb-3 ">
                    <p className="mb-0">
                        {momentHoursFormat(originalSchedule.timeFrom)}
                    </p>
                </div>
                }
                <div className={`${!assignedGuard ? 'col-sm-4' : 'col-sm-8'} details-information__value d-flex flex-row align-items-center mb-3`}>
                    <p className="mb-0">
                        {momentHoursFormat(editedSchedule.timeFrom)}
                    </p>
                </div>

                {/* End Time */}
                <div className="col-sm-4 details-information__title mb-3 ">
                    <h6 className="mb-0">End Time:</h6>
                </div>
                { !assignedGuard && 
                <div className="col-sm-4 details-information__value d-flex flex-row align-items-center mb-3 ">
                    <p className="mb-0">
                        {momentHoursFormat(originalSchedule.timeTo)}
                    </p>
                </div>
                }
                <div className={`${!assignedGuard ? 'col-sm-4' : 'col-sm-8'} details-information__value d-flex flex-row align-items-center mb-3`}>
                    <p className="mb-0">
                        {momentHoursFormat(editedSchedule.timeTo)}
                    </p>
                </div>

                {/* Repeat */}
                <div className="col-sm-4 details-information__title mb-3 ">
                    <h6 className="mb-0">Shift Period:</h6>
                </div>
                { !assignedGuard && 
                    <div className="col-sm-4 details-information__value d-flex flex-row align-items-center mb-3 ">
                        <p className="mb-0">
                            { vocabularyShift ? matchOptionWithName(originalSchedule.shiftPeriod, vocabularyShift): matchOptionWithName(originalSchedule.shiftPeriod, vocabulary)}
                        </p>
                    </div>
                }
                <div className={`${!assignedGuard ? 'col-sm-4' : 'col-sm-8'} details-information__value d-flex flex-row align-items-center mb-3`}>
                    <p className="mb-0">
                        { vocabularyShift ? matchOptionWithName(editedSchedule.shiftPeriod, vocabularyShift): matchOptionWithName(editedSchedule.shiftPeriod, vocabulary)}
                    </p>
                </div>

                <div className="col-sm-4 details-information__title mb-3 ">
                    <h6 className="mb-0">Repeat:</h6>
                </div>
                { !assignedGuard && 
                    <div className="col-sm-4 details-information__value d-flex flex-row align-items-center mb-3 ">
                        <p className="mb-0">
                            {matchWeekDays(originalSchedule.repeatOn)}
                        </p>
                    </div>
                }
                <div className={`${!assignedGuard ? 'col-sm-4' : 'col-sm-8'} details-information__value d-flex flex-row align-items-center mb-3`}>
                    <p className="mb-0">
                        {matchWeekDays(editedSchedule.repeatOn)}
                    </p>
                </div>

                {
                    assignedGuard && 
                    <>
                        <div className="col-sm-4 details-information__title mb-3 ">
                            <h6 className="mb-0">Assigned Guard:</h6>
                        </div>
                    
                        <div className="col-sm-8 details-information__value d-flex flex-row align-items-center mb-3 ">
                            <p className="mb-0">
                                { generateFullName(assignedGuard) }
                            </p>
                        </div>
                        <div className="col-sm-12">
                            <small className="text-danger">A notification has been sent to the guard with the details of this update.</small>
                        </div>
                    </>
                }

            </div>
        </BaseModal>
    )
}

export default EditScheduleConfirmationModal
