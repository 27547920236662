import React, {FC, useEffect, useRef, useState} from 'react'
import {SVGEraser, SVGPencil, SVGTrash} from '../../../assets/icons/SvgIcons'
import {VocabularyEnum} from '../../../common/enums/VocabularyEnum'
import {IClientDropdown} from '../../../common/interfaces/dropdowns/IClientDropDown'
import {IJob, IJobSchedule} from '../../../common/interfaces/jobs/IJob'
import {DataService} from '../../../common/services/DataService'
import CustomSelectControl from '../../../components/custom-select/CustomSelectControl'
import DataTable from '../../../components/tables/DataTable'
import SectionTitle from '../../../components/titles/SectionTitle'
import {useTableList} from '../../../customHooks/useTableList'
import {useVocabulary} from '../../../customHooks/vocabulary/useVocabulary'
import {QueryParamsState} from '../EditJobs'
import {matchOptionWithName} from '../../../utils/MatchOptionWithName'
import {useListFilter} from '../../../customHooks/useListFilter'
import {momentDateFormat, momentHoursFormat} from '../../../utils/DateFormatting'
import {AuthService} from '../../../common/auth/AuthService'
import {Roles} from '../../../common/enums/Roles'
import AppContext from '../../../context/AppContext'
import {useContext} from 'react'
import {NotificationTypes} from '../../../common/interfaces/INotification'
import {matchWeekDays} from "../../../utils/MatchWeekDays";
import AutocompleteField from '../../../components/autosuggestField/AutocompleteField'
import AddEditScheduleModal from "../../../components/modal/jobs/AddEditScheduleModal";
import ResourceDataService from "../../../common/services/ResourceDataService";
import {useHolidays} from "../../../customHooks/useHolidays";
import {ActivationDeactivation, ModelStatus} from "../../../common/enums/Actions";
import useActivateDeactivate from "../../../customHooks/useActivateDeactivate";
import DeactivateModal from "../../../components/modal/users/DeactivateModal";
import {IVocabulary} from "../../../common/interfaces/vocabulary/IVocabulary";
import DeactivateScheduleModalBody from "../../../components/modal/administration/DeactivateScheduleModalBody";
import BaseModal from "../../../components/modal/BaseModal";
import { useRole } from 'customHooks/useRole'
import {editJobSchedulesTable} from "../../../content/jobs/JobsContent";
import {useVerifyRolesTHead} from "../../../customHooks/useVerifyRolesTHead";

type Props = {
    queryParams: QueryParamsState
    clients: IClientDropdown[]

}
const EditJobSchedules: FC<Props> = ({queryParams, clients}) => {
    const baseUrl = 'schedule';
    const queryParamsRef = useRef(queryParams);
    const appContext = useContext(AppContext)
    const {vocabulary: shiftVocabulary} = useVocabulary(VocabularyEnum.shift, true)
    const {tableData, isLoading, fetchData, perPage, allRecords, onPagination, onPerPage, currentPage, onSortCall, onClearFilterQuery} = useTableList<IJobSchedule>(`${baseUrl}?ClientId=${queryParams.client}&SchoolYearId=${queryParams.schoolYear}`, '24')
    const reasonsVocabulary = useVocabulary(VocabularyEnum.shiftReason)
    const shiftsVocabulary = useVocabulary(VocabularyEnum.shift)
    const {vocabulary: scheduleDeactivation} = useVocabulary(VocabularyEnum.scheduleDeactivation, true)
    const [searchIsActive, setSearchIsActive] = useState<boolean>(false)
    const resourceSvc = new ResourceDataService<IJob>('job')
    const dataSvc = new DataService<IJobSchedule>({url: "schedule"})
    const {onRequestHandler, onRequestSubmitHandler, requestModals, modalText, onCloseRequestModals, itemInFocus} = useActivateDeactivate('schedule');
    const [activeJob, setActiveJob] = useState<IJob>()
    const {allHolidays, getHolidaysSchoolBoardById} = useHolidays();
    const [scheduleModals, setScheduleModals] = useState<{ showEdit: boolean, showEditSuccess: boolean, itemInFocus: IJobSchedule }>({
        showEdit: false,
        showEditSuccess: false,
        itemInFocus: {} as IJobSchedule
    });
    const { isClientRole, isASPAndAdminRole } = useRole()
    const {theadTable, addVerifyRoles} = useVerifyRolesTHead()
    const {
        generateFilterQuery, filter, onFilterDropdownChange, onFilterClearClick, schoolBoards, getSchoolBoard,
        onFilterSearchClearClick, cities, getCities, filterIsClear
    } = useListFilter(baseUrl, +queryParams.client, +queryParams.schoolYear)
    const checkSvc = new ResourceDataService<any>({ url: "job/assigned-guard-check" });
    const [hasAssignedGuards, setHasAssignedGuards] = useState(false);

    useEffect(() => {
        shiftsVocabulary.getVocabulary()
        reasonsVocabulary.getVocabulary()

        getSchoolBoard()
        getCities()
    }, [])

    useEffect((() => {
        if ((queryParamsRef.current !== queryParams) && !isLoading) {
            onFilterClearClick()
            fetchData(0, 0)
        }
    }), [queryParams])

    useEffect(() =>{
        addVerifyRoles(editJobSchedulesTable.thead)
    }, [editJobSchedulesTable])

    const getSchoolYear = () => {
        const client = clients.find(client => client.id === +queryParams.client)?.schoolYears.find(year => year.id === +queryParams.schoolYear);
        if (client) {
            return `${momentDateFormat(client.startDate)} - ${momentDateFormat(client.endDate)}`
        }
    }

    const onEditScheduleClick = async (schedule: IJobSchedule) => {
        await getUser(schedule)
        const result = tableData.find(item => item.id === schedule.id)

        setScheduleModals({
            ...scheduleModals,
            showEdit: true,
            itemInFocus: result || {} as IJobSchedule,
        })

    }

    const getUser = async (schedule: IJobSchedule) => {
        const res = await resourceSvc.select(`${schedule.jobId}?&include=JobSchedules`)
        await getHolidaysSchoolBoardById(res.data.schools)
        setActiveJob(res.data)
    }

    const onAddEditScheduleSubmit = async (updatedSchedule: IJobSchedule) => {
        appContext.showLoader(true)
        try {
            await dataSvc.update(updatedSchedule, updatedSchedule.id as number)
            setScheduleModals({showEdit: false, showEditSuccess: false, itemInFocus: {} as IJobSchedule})
            appContext.showLoader(false)
            fetchData(1, 24, generateFilterQuery())
        } catch (error: any) {
            appContext.showNotification(NotificationTypes.danger, error.message)
            appContext.showLoader(false)
        }
    }

    const onSuccessDeactivation = (id: number) => {
        fetchData(1, 24, generateFilterQuery())
    }

    const checkJobHasAssignedGuard = async (jobScheduleId: number | undefined) => {
      const response = await checkSvc.queryString(`JobScheduleId=${jobScheduleId}`);

      setHasAssignedGuards(response.data.hasGuards);
    }

    return (
        <>
            <div className="row mb-4">
                <div className="col-12">
                    <SectionTitle
                        title={`${isASPAndAdminRole() ? 'Edit Job Schedules' : 'Job Schedules'}: ${matchOptionWithName(queryParams.client, clients)}, ${getSchoolYear()}`}/>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="d-flex flex-row justify-content-start justify-content-md-end w-100-md-0">
                        <div className="form-group ml-0 w-100-md-0">
                            <AutocompleteField
                                includes={'Job,SchoolBoard,School'}
                                isAsync={true}
                                placeholder={'Enter keyword'}
                                onClear={() => {
                                    setSearchIsActive(false);
                                    onFilterSearchClearClick(() => fetchData(1, 24, generateFilterQuery(true)))
                                }}
                                onSearch={async (value) => {
                                    setSearchIsActive(true);
                                    onFilterDropdownChange(value, 'keyWord');
                                    await fetchData(1, 24, generateFilterQuery(false, true, value, `&ClientId=${queryParams.client}&SchoolYearId=${queryParams.schoolYear}`), false, true);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <form className="filters-form-group mb-4" onKeyDown={(event) => {
                if (event.key === 'Enter') fetchData(1, 24, generateFilterQuery(true, false))
            }}>
                <div className="row">
                    <div className="col-12">
                        <h5>Filter Schedules List</h5>
                    </div>
                    <div className="d-flex w-100 flex-wrap flex-xl-row ml-1  ">
                        {/* { TODO: ADD Zone } */}
                        <CustomSelectControl
                            isClearable={true}
                            options={cities || []}
                            value={filter.zone}
                            placeholder={"Select Zone"}
                            onChange={e => onFilterDropdownChange(e, "zone")}
                            className="flex-grow-0 ml-2 mb-2"
                        />

                        <CustomSelectControl
                            isClearable={true}
                            options={schoolBoards}
                            value={filter.schoolBoardId}
                            placeholder={"Select School Board"}
                            onChange={e => onFilterDropdownChange(e, "schoolBoardId")}
                            className="flex-grow-0 ml-2 mb-2"
                        />

                        <button type="button" className="btn btn-aqua-blue ml-2"
                                onClick={() => fetchData(1, 24, generateFilterQuery())}>
                            Filter Shifts
                        </button>
                        {
                            filter.hasValue &&
                            <button type="button" className="btn btn-aqua-blue ml-2"
                                    onClick={() => {
                                        setSearchIsActive(false);
                                        onClearFilterQuery()
                                        onFilterClearClick()
                                    }}>
                                <SVGEraser/>
                            </button>
                        }

                    </div>
                </div>
            </form>
            <section>
                <div className="row">
                    <div className="col-12">
                        {
                            React.useMemo(
                                () => (
                                    <DataTable
                                        tableClass="mt-4"
                                        thead={theadTable}
                                        tbody={
                                            tableData ? tableData
                                                .map(schedule => (
                                                    {
                                                        id: schedule.id,
                                                        jobName: schedule.jobName,
                                                        dates: `${momentDateFormat(schedule.startDate)} - ${momentDateFormat(schedule.endsOn)}`,
                                                        times: `${momentHoursFormat(schedule.timeFrom)} - ${momentHoursFormat(schedule.timeTo)}`,
                                                        shiftPeriod: matchOptionWithName(schedule.shiftPeriod, shiftVocabulary),
                                                        repeatOn: `Every week: ${matchWeekDays(schedule.repeatOn)}`,
                                                        jobId: schedule.jobId,
                                                        isActive: schedule.isActive
                                                    }
                                                )) : []}
                                        ignoreCols={[0, 6, 7]}
                                        isLoading={isLoading}
                                        pagination={true}
                                        currentPage={currentPage}
                                        itemsCount={allRecords}
                                        itemsPerPage={+perPage}
                                        onPager={page => onPagination(page)}
                                        onPerPage={value => onPerPage(value)}
                                        tableName={"Job Schedules"}
                                        onSort={(orderBy, desc) => onSortCall(orderBy, desc, filter.keyWord)}
                                        showTableLengthData={true}
                                        sortOnBackend={true}
                                        searchIsActive={searchIsActive}
                                        filtersIsNotClear={filterIsClear ? false : true}
                                        defaultSortedColumn={2}
                                    >
                                        {
                                            (id, rowItem: IJobSchedule, rowIndex: number) => (
                                                !isClientRole() &&
                                                <>
                                                    <td className="align-middle">
                                                        <button
                                                            type="button"
                                                            className={`btn btn-aqua-blue`}
                                                            onClick={() => onEditScheduleClick(rowItem)}>
                                                            <SVGPencil/>
                                                        </button>
                                                    </td>

                                                    <td className="aling-middle">
                                                        <div>
                                                            {
                                                                rowItem.isActive === ModelStatus.active &&
                                                                <button
                                                                    className={`btn btn-aqua-blue`}
                                                                    onClick={() => {
                                                                        checkJobHasAssignedGuard(rowItem?.id)
                                                                        getUser(rowItem)
                                                                        onRequestHandler({
                                                                            title: `Are you sure you want to deactivate selected schedule for <span class="font-weight-bold">${rowItem?.jobName}</span>?`,
                                                                            itemInFocus: tableData[rowIndex],
                                                                            type: ActivationDeactivation.deactivate
                                                                        })
                                                                    }}
                                                                >
                                                                    <SVGTrash/>
                                                                </button>
                                                            }
                                                            {(rowItem.isActive === ModelStatus.pending || rowItem.isActive === ModelStatus.inactive) &&
                                                            <span className="font-weight-bold text-uppercase">
                                                                {rowItem.isActive === ModelStatus.pending ? ModelStatus.pending : ModelStatus.inactive}
                                                            </span>
                                                            }
                                                        </div>
                                                    </td>
                                                </>
                                            )
                                        }
                                    </DataTable>
                                ), [tableData, isLoading, shiftsVocabulary.vocabulary, theadTable]
                            )
                        }
                    </div>
                </div>
            </section>
            {

                requestModals.showRequest && <DeactivateModal
                    onCancel={() => onCloseRequestModals()}
                    onSubmit={(reason: string | IVocabulary) => onRequestSubmitHandler(
                        ActivationDeactivation.deactivate,
                        {itemData: null, successCallback: id => onSuccessDeactivation(id)},
                        `${AuthService.checkIfUserHasPermission([Roles.Admin], appContext.currentUser.role) ? `<span class="font-weight-bold">${activeJob?.jobName}</span> schedule has been deactivated.`
                        : `Your request to deactivate <span class="font-weight-bold">${activeJob?.jobName}</span> schedule has been sent to the Administrator.`} `,
                        reason
                    )}
                    title={modalText.title}
                    vocabulary={scheduleDeactivation}
                    htmlBody={<DeactivateScheduleModalBody selectedItem={itemInFocus as IJobSchedule}/>}
                    htmlFooter={
                      hasAssignedGuards ? (
                        <div className="text-center mt-4 text-danger">
                          This job has assigned guards. Are you sure you want to request deactivation?
                        </div>
                      ) : ""
                    }
                />
            }

            {
                requestModals.showSuccess &&
                <BaseModal
                    show={requestModals.showSuccess}
                    onCancel={() => onCloseRequestModals()}
                    cancelBtnText={"Close"}
                    title={modalText.title}
                >
                </BaseModal>
            }

            {
                scheduleModals.showEdit && <AddEditScheduleModal
                    onCancel={() => setScheduleModals({...scheduleModals, showEdit: false})}
                    onSubmit={(schedule) => onAddEditScheduleSubmit(schedule as IJobSchedule)}
                    schedule={scheduleModals.itemInFocus}
                    activeJob={activeJob}
                    getallHolidays={allHolidays}
                    vocabulary={shiftsVocabulary.vocabulary}
                    descriptionNote={'When a schedule is edited, the affected future shifts will be removed, and new ones will be created. Removed shifts will be stored in the archive records. The assigned guard will be unassigned.'}
                />
            }

        </>
    )
}

export default EditJobSchedules
