import { UTCTimeNow } from 'utils/DateFormatting';
import { IGuardBookLeave } from './../interfaces/guards/IGuard';

export class GuardBookLeave implements IGuardBookLeave {
    guardId = 0
    id = 0;
    startDate = '';
    endDate = '';
    reason = ""
    shifts = null
    approvedDate = null;
    isRequested = false;
    jobsAffected = "";
    requestedOn = UTCTimeNow(true, true) as string;;
    
    constructor(id: number) {
        this.id = id;
        this.guardId = id;
    }
}