import { IVocabulary } from 'common/interfaces/vocabulary/IVocabulary'
import React, { FC } from 'react'

type TProps = {
    optionType: string
    option: IVocabulary
    handleCheckBox: (optionKey: any, id: string | number) => void
    optionKey: string
    children?: React.ReactNode
}

const ReportOptCheckBox: FC<TProps> = ({ optionType, option,
    handleCheckBox, children, optionKey }) => (
    <div
        className="custom-control custom-checkbox d-flex flex-wrap align-items-center mt-2">
        <div className="mr-2">
        <input
            type="checkbox"
            checked={(optionType && optionType!.indexOf(option.id.toString()) >= 0) ? true : false}
            onChange={() => handleCheckBox(optionKey, option.id)}
            className="custom-control-input"
            name={optionKey}
            id={`${optionKey}-${option.name}`}
            />
        <label
            className="custom-control-label mb-0"
            htmlFor={`${optionKey}-${option.name}`}>
            { option.name }
        </label>
        </div>
        { children ? children : null }
    </div>
)

export default ReportOptCheckBox
