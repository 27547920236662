import moment from 'moment';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom';
import { VocabularyEnum } from '../../../../common/enums/VocabularyEnum';
import { IGuardBookLeave } from '../../../../common/interfaces/guards/IGuard';
import { IGuardShift } from '../../../../common/interfaces/guards/IShift';
import { IValidationBody, ValidationRules } from '../../../../common/interfaces/IValidation';
import { GuardBookLeave } from '../../../../common/models/GuardBookLeave';
import { bookedLeaveContent, leaveRequestsPendingContent } from '../../../../content/dashboard/TablesContent';
import BookLeaveContext, { BookLeaveModalContext } from '../../../../context/guards/BookLeaveContext';
import GuardDetailsModalsContext from '../../../../context/guards/GuardDetailsModalsContext';
import { useFromToFilter } from '../../../../customHooks/useFromToFilter';
import { useVocabulary } from '../../../../customHooks/vocabulary/useVocabulary';
import { checkIfIsOtherReason } from '../../../../utils/CheckOtherReason';
import { datesAreTheSame, momentDateFormat } from '../../../../utils/DateFormatting';
import { getHolidayField } from '../../../../utils/GetFieldFromTable';
import { inputDataChecker } from '../../../../utils/InputDataChecker';
import { matchOptionWithName } from '../../../../utils/MatchOptionWithName';
import { highlightError, removeHighlightError, validateFiled } from '../../../../utils/Validation';
import BaseModal from '../../../modal/BaseModal';
import ConfirmationModal from '../../../modal/ConfirmationModal';
import BookLeaveModal, { LeaveTypeState } from '../../../modal/guards/BookLeaveModal';
import DataTable from '../../../tables/DataTable';
import FromToFilter from './../FromToFilter';
import ReportActions from "../../../reports/ReportActions";
import {GuardIncludesEnum} from "../../../../common/enums/GuardEnums";
import { useRole } from 'customHooks/useRole';
import { isSameDates } from 'utils/CheckDate';
import { ActivationDeactivation } from 'common/enums/Actions';
import useActivateDeactivate from 'customHooks/useActivateDeactivate';
import { jobFromStrToLink } from 'utils/Converting';
import LeaveRequestActions from 'components/reports/LeaveRequestActions';
import { LeaveRequestReportModals } from 'common/enums/Modals';
import { IGuardAppLeaveRequestReport } from 'common/interfaces/reports/xguard-reports/iGuardAppLeave';
import useLeaveReportActions from 'customHooks/reports/useLeaveReportActions';
import usePagination from 'customHooks/usePagination';
import { SortDataTypesEnum } from 'customHooks/useSortableData';

const BookedLeaveTab: FC = () => {
    const { modals, setModals, userId, userName, isLoading } = useContext(GuardDetailsModalsContext)
    const { onSubmit, bookLeaves, onDelete, assignedShifts } = useContext(BookLeaveContext)
    const [bookLeave, setBookLeave] = useState<IGuardBookLeave>(new GuardBookLeave(+userId))
    const { onFilter, formEmpty, onClear, filteredData, filterQuery } = useFromToFilter<IGuardBookLeave>(bookLeaves)
    const [createMode, setCreateMode] = useState<boolean>(true)
    const [otherReason, setOtherReason] = useState<string>("")
    const [selectedId, setSelectedId] = useState<number | null>(null)
    const [guardAssignedDuringLeave, setGuardAssignedDuringLeave] = useState<boolean>(false)
    const [bookLeaveType, setBookLeaveType] = useState<LeaveTypeState>({ multipleDays: true, singleDay: false, singleShift: false })
    const bookLeaveVocabulary = useVocabulary(VocabularyEnum.bookLeave, true)
    const shiftVocabulary = useVocabulary(VocabularyEnum.shift, true)
    const { isClientRole, isSupervisorRole } = useRole()
    const { onRequestHandler, onRequestSubmitHandler, requestModals, modalText, onCloseRequestModals, setItemInFocus } =
    useActivateDeactivate<IGuardBookLeave>('guardbookedleave');
    const { approveOrDisapproveGuardBookedLeave } = useLeaveReportActions();
    const [addEditSuccessModal, setAddEditSuccessModal] = useState({show: false, title: ''})
    const { currentPage, paginate, setItemsPerPage, itemsPerPage } = usePagination({
        items: filteredData,
      });
    useEffect(() => {
        modals.bookLeaveEdit && createMode && setBookLeave(new GuardBookLeave(+userId))
        modals.bookLeaveEditSuccess && setOtherReason("")
    }, [modals.bookLeaveEdit])

    const handleChanges = (value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string | Date | boolean, fieldName?: string) => {
        const res = inputDataChecker(value, fieldName)
        setBookLeave({ ...bookLeave, [res.field]: res.value })
        if (['startDate', 'endDate', 'shifts'].includes(res.field)) checkShiftsForBookLeave({ ...bookLeave, [res.field]: res.value });
    }

    useEffect(() => {
        modals.bookLeaveEdit && checkShiftsForBookLeave(bookLeave);
    }, [modals.bookLeaveEdit])

    const checkShiftsForBookLeave = (bookLeaveInFocus: IGuardBookLeave) => {
        let from = bookLeaveInFocus.startDate;
        let to = bookLeaveInFocus.endDate;
        let result: IGuardShift[] = [];

        if (bookLeaveType.singleDay || bookLeaveType.singleShift) {
            from = bookLeaveInFocus.startDate
            result = [...assignedShifts].filter(item => moment(moment.utc(item.startDate).format("MM-DD-YYYY")).isSame(moment.utc(from).format("MM-DD-YYYY")))
            .filter(shift => bookLeaveInFocus.shifts?.toString().split(';').some(item => shift.shiftPeriod.includes(item)));
        } else {
            result = [...assignedShifts].filter(item => {
                return moment(moment.utc(item.startDate).format("MM-DD-YYYY")).isBetween(moment.utc(from).format("MM-DD-YYYY"), moment.utc(to).format("MM-DD-YYYY")) ||
                moment(moment.utc(item.startDate).format("MM-DD-YYYY")).isSame(moment.utc(from).format("MM-DD-YYYY")) ||
                moment(moment.utc(item.startDate).format("MM-DD-YYYY")).isSame(moment.utc(to).format("MM-DD-YYYY"))
            });
        }
        result.length === 0 ? setGuardAssignedDuringLeave(false) : setGuardAssignedDuringLeave(true)
    }

    const onBookLeaveSubmit = () => {
        let errors: IValidationBody[] = [];
        removeHighlightError()
        if (bookLeaveType.multipleDays) {
            errors = [...errors, ...validateFiled(bookLeave.reason as string, 'reason', ValidationRules.required)]
        } else {
            errors = [...errors, ...validateFiled(bookLeave.reason as string, 'reason', ValidationRules.required)]
            errors = [...errors, ...validateFiled(bookLeave.shifts as string, 'shifts', ValidationRules.required)]
            errors = [...errors, ...validateFiled(bookLeave.shifts as string, 'shifts', ValidationRules.notZero)]
        }

        const finalReason = otherReason !== "" ? otherReason : bookLeave.reason

        if (finalReason === -1) {
            errors = [...errors, ...[{ fieldName: 'bookLeaveReason--other', errorMessage: '' }]]
        }
        if (errors.length === 0) {
            setBookLeave({ ...bookLeave, reason: finalReason })
            if (bookLeave.status === 'Pending') {
                approveOrDisapproveGuardBookedLeave(`${bookLeave.id}`, 'approve', () => {
                    setModals(false, 'bookLeaveEdit')
                    setAddEditSuccessModal({show: true, title: createMode ? 'This Leave Request has been created.' : 'This Leave Request has been updated.'})
                    onSubmit({ ...bookLeave, reason: finalReason }, createMode ? true : false)
                })
               
            } else {
                onSubmit({ ...bookLeave, reason: finalReason }, createMode ? true : false)
            }
           
            setCreateMode(true);
        } else {
            highlightError(errors)
        }
    }

    const onEdit = useMemo(() =>
        (id: number) => {
            const matchedBookedLeave = filteredData.find(leave => leave.id === id);
            const isSingleDay = datesAreTheSame(matchedBookedLeave?.startDate, matchedBookedLeave?.endDate);
            
            setBookLeaveType({
                ...bookLeaveType,
                multipleDays: !isSingleDay ? true : false,
                singleDay: isSingleDay ? true : false,
            })
            if (matchedBookedLeave) {
                setBookLeave(matchedBookedLeave)
                setModals(true, 'bookLeaveEdit')
            }
        }
    , [filteredData, setModals])

    const onBookLeaveDelete =  useMemo(() =>
        (id: number) => {
            const matchedBookedLeave = filteredData.find(leave => leave.id === id);
            if (matchedBookedLeave) {
                setBookLeave(matchedBookedLeave)
            }
            setSelectedId(id)
            setModals(true, 'bookLeaveRemove')
        }
    , [filteredData, setModals])

    const onBookLeaveEdit = useMemo(() =>
        (id: number) => {
            setCreateMode(false);
            onEdit(id)
        }
    , [onEdit])

    // Temporary
    const onBookLeaveTypeChanged = (value: LeaveTypeState) => {
        setBookLeaveType(value)
        setGuardAssignedDuringLeave(false)
        createMode && setBookLeave(new GuardBookLeave(+userId))
    }


    
	const onActionItemPressed = (type: LeaveRequestReportModals, id: string, rowItem: IGuardAppLeaveRequestReport | IGuardBookLeave) => {
		if (type === LeaveRequestReportModals.deny) {
            onRequestHandler(
                {
                    title: `Do you want to decline the Leave Request
                    for <span class="font-weight-bold">${userName}</span>?`,
                    itemInFocus: rowItem,
                    type: ActivationDeactivation.disapprove
                }
                )
		}

		if (type === LeaveRequestReportModals.approve) {
            onRequestHandler(
                {
                    title: `Do you want to approve the Leave Request
                    for <span class="font-weight-bold">${userName}</span>?
                    ${rowItem.jobsAffected && `<br /> <small class="text-danger mt-3 d-block">There are jobs affected by this leave</small>`}
                    `,
                    itemInFocus: rowItem
                }
                )
		}

		if (type === LeaveRequestReportModals.editDates) {
            onBookLeaveEdit(+id)
            setItemInFocus(rowItem as IGuardBookLeave);
		}
	};

    const generateAddEditModalTitle = (createMode: boolean, userName: string) => {
        if (createMode) {
            return `Book a Leave for <span class="font-weight-bold">${userName}</span`
        }
        
        return bookLeave.status === 'Pending' ? `Edit and Approve a Leave Request for ${userName}` : `Edit a Booked Leave for ${userName}`
    }

    const convertToTbody = (items: IGuardBookLeave[]) => items.map(item => ({
            id: item.id,
            startDate: momentDateFormat(item.startDate),
            endDate: isSameDates(item.startDate, item.endDate) ? '' : momentDateFormat(item.endDate),
            shiftPeriod: isSameDates(item.startDate, item.endDate) ?
            matchOptionWithName(item.shifts as string, shiftVocabulary.vocabulary) : "",
            reason: matchOptionWithName(item.reason as number, bookLeaveVocabulary.vocabulary),
            requestedOn: momentDateFormat(item.requestedOn) || "",
            jobsAffected: jobFromStrToLink(item.jobsAffected),
            status: item.status || '',
            dateOfLastStatusUpdate: item.dateOfLastStatusUpdate ? momentDateFormat(item.dateOfLastStatusUpdate) : '',
    }))
    // const tbody = convertToTbody(currentItems)
    const tbody = convertToTbody(filteredData);
    return (
        <>
            <div className="d-flex align-items-center flex-wrap justify-content-between">
                <div className="d-flex align-items-center">
                    <h5 className="mb-0 mr-5 mr-md-0">Leave Report</h5>
                    { !isClientRole() && !isSupervisorRole() &&
                    <button className="btn btn-aqua-blue ml-0 ml-md-2 mr-5 mr-md-0 mt-2 mt-md-0" onClick={() => setModals(true, 'bookLeaveEdit')}>Book a Leave</button>
                    }
                </div>
                <ReportActions
                    className="mt-2"
                    title="Leave Report:"
                    id={userId}
                    tabName={GuardIncludesEnum.bookedLeaves}
                    page="guard"
                    query={filterQuery}
                />
            </div>
            { !isClientRole() &&
                <FromToFilter
                    fromLabel='Leave From'
                    isEmpty={formEmpty}
                    onSubmit= {(from, to) => onFilter(from, to)}
                    onClear={() => {
                        const setToAll = itemsPerPage === filteredData.length 
                        onClear()
                        setToAll && setItemsPerPage(bookLeaves.length)
                    }}
                    tooltipMessage='Please note that the results will display any booked leaves that overlap with the selected date range. This means that if any of the booked leaves have dates that fall within the selected range, they will be included in the results.'
                />
            }
            {
                    !isClientRole() &&
                    <DataTable
                        tableClass={"mt-4 table-info--notFixed"}
                        ignoreCols={[0]}
                        ignoreTheadCols={[...(isClientRole() || isSupervisorRole() ? [5] : [])]}
                        thead={bookedLeaveContent.thead}
                        tbody={tbody}
                        pagination
                        onPerPage={(value) => setItemsPerPage(value !== '0' ? +value : filteredData.length)}
                        currentPage={currentPage}
						itemsCount={filteredData.length}
						itemsPerPage={itemsPerPage === filteredData.length || bookLeaves.length ===  itemsPerPage ? 0 : itemsPerPage}
						onPager={(page) => paginate(page)}
                        defaultSortedColumn={0}
                        defaultSortConfig={{direction: 'down', colName: 'startDate', key: 0, dataType: SortDataTypesEnum.date}}
                        isLoading={isLoading}
                        syncPagination
                        responsive="xl"
                        showTableLengthData
                        tableName='Leave Report'
                    >
                        {
                            (id, rowItem) => (
                                <>
                                    { !isClientRole() && !isSupervisorRole() &&
                                        <td className="align-middle">
                                            <div>
                                                <LeaveRequestActions
                                                    openModal={(type, id) => onActionItemPressed(type, `${id}`, (filteredData.find(v => v.id === id) || {}) as IGuardAppLeaveRequestReport)}
                                                    id={id}
                                                    startDate={rowItem.startDate}
                                                    status={rowItem.status}
                                                />
                                            </div>
                                        </td>
                                    }
                                    {/* <td className="align-middle">
                                        { getHolidayField(id, filteredData) }
                                    </td> */}
                                </>
                            )
                        }
                    </DataTable>
                
            }

            {/* { !isClientRole() && <h5 className="mb-0 mt-5">Leave Requests Pending</h5> } */}
            {/* {
                React.useMemo(() => (
                    !isClientRole() &&
                    <DataTable
                        tableClass={"mt-2 table-info--notFixed"}
                        thead={leaveRequestsPendingContent.thead}
                        tbody={
                            filteredData
                                .map(item => ({
                                id: item.id,
                                requestedOn: momentDateFormat(item.requestedOn) || "",
                                // guardName: `<a href="/#/guards/${item.guardId}/details" target="_blank">${item.guardName}</a>`,
								// employeeId: `<a href="/#/guards/${item.guardId}/details" target="_blank">${item.employeeId}</a>`,
                                startDate: momentDateFormat(item.startDate),
                                endDate: isSameDates(item.startDate, item.endDate) ? '' : momentDateFormat(item.endDate),
                                shiftPeriod: isSameDates(item.startDate, item.endDate) ?
                                matchOptionWithName(item.shifts as string, shiftVocabulary.vocabulary) : "--",
                                reason: matchOptionWithName(item.reason as number, bookLeaveVocabulary.vocabulary),
                                jobsAffected: jobFromStrToLink(item.jobsAffected),
                                // status: item.status || '',
                                // dateOfLastStatusUpdate: item.dateOfLastStatusUpdate ? momentDateFormat(item.dateOfLastStatusUpdate) : ''
                        }))}
                        ignoreCols={[0]}
                        ignoreTheadCols={[...(isClientRole() || isSupervisorRole() ? [6] : [])]}
                        isLoading={isLoading}
                    >
                        {
                            (_id, rowItem: IGuardBookLeave) => (
                                !isSupervisorRole() && !isClientRole() &&
                                <td className="aling-middle">
                                    <div>
                                        <button
                                            className={`btn btn-aqua-blue`}
                                            onClick={() => onRequestHandler(
                                            {
                                                title: `Do you want to approve the Leave Request
                                                for <span class="font-weight-bold">${userName}</span>?
                                                ${rowItem.jobsAffected && `<br /> <small class="text-danger mt-3 d-block">There are jobs affected by this leave</small>`}
                                                `,
                                                itemInFocus: rowItem
                                            }
                                            )}
                                        >
                                            <SVGApprove />
                                        </button>
                                        <button
                                            className={`btn btn-aqua-blue ml-2`}
                                            onClick={() => onRequestHandler(
                                            {
                                                title: `Do you want to decline the Leave Request
                                                for <span class="font-weight-bold">${userName}</span>?`,
                                                itemInFocus: rowItem,
                                                type: ActivationDeactivation.disapprove
                                            }
                                            )}
                                        >
                                            <SVGCancel/>
                                        </button>
                                    </div>
                                </td>
                            )
                            }
                    </DataTable>
                ), [filteredData, bookLeaveVocabulary.vocabulary, isLoading,
                    onRequestHandler, shiftVocabulary.vocabulary, userName, isClientRole
                ])
            } */}

            { !isClientRole() &&
            <>
            {
                modals.bookLeaveEdit &&
                <BaseModal
                        show={modals.bookLeaveEdit}
                        onCancel={() => { setCreateMode(true); setModals(false, 'bookLeaveEdit')}}
                        onSubmit={() => onBookLeaveSubmit()}
                        submitBtnText={"Book"}
                        title={generateAddEditModalTitle(createMode, userName)}
                    >
                        <BookLeaveModalContext.Provider
                            value={{
                                reasons: [ ...bookLeaveVocabulary.vocabulary, ...[{id: -1, name: "Other"}] ],
                                shifts: shiftVocabulary.vocabulary,
                                bookLeave,
                                guardAssignedDuringLeave,
                                onChange: (e, name) => handleChanges(e, name),
                                createMode,
                                otherReason,
                                setOtherReason,
                                onBookLeaveTypeChanged,
                                bookLeaveType
                            }}
                        >
                            <BookLeaveModal />
                        </BookLeaveModalContext.Provider>
                </BaseModal>
            }

            {
                modals.bookLeaveEditSuccess && <BaseModal
                    show={modals.bookLeaveEditSuccess}
                    onCancel={() => setModals(false, 'bookLeaveEditSuccess')}
                    cancelBtnText={"Close"}
                >
                    <div className="row">
                        <div className="col-12">
                            <h4 className="mb-4">
                                {
                                    createMode ?
                                    <>
                                        <span className="font-weight-bold">{ userName }</span> has been booked leave:
                                    </> :
                                    <>
                                        <span className="font-weight-bold">{ userName }</span> booked leave has been updated:
                                    </>
                                }
                            </h4>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-sm-4 details-information__title mb-0">
                            <h6 className="mb-0">Date(s):</h6>
                        </div>
                        <div className="col-sm-8 details-information__value">
                            <p className="mb-0">{ momentDateFormat(bookLeave.startDate) } { !bookLeave.shifts && `- ${momentDateFormat(bookLeave.endDate)}`}</p>
                        </div>
                    </div>
                    {
                        bookLeave.shifts &&
                        <div className="row mb-3">
                            <div className="col-sm-4 details-information__title mb-0">
                                <h6 className="mb-0">Shift Period(s):</h6>
                            </div>
                            <div className="col-sm-8 details-information__value">
                                <p className="mb-0">
                                    { bookLeave.shifts && matchOptionWithName(bookLeave.shifts, shiftVocabulary.vocabulary) }
                                </p>
                            </div>
                        </div>
                    }

                    <div className="row mb-3">
                        <div className="col-sm-4 details-information__title mb-0">
                            <h6 className="mb-0">Reason(s):</h6>
                        </div>
                        <div className="col-sm-8 details-information__value">
                            <p className="mb-0">
                                { checkIfIsOtherReason(bookLeave.reason) ?  bookLeave.reason :
                                matchOptionWithName(+bookLeave.reason as number, bookLeaveVocabulary.vocabulary) }
                            </p>
                        </div>
                    </div>

                    {
                        guardAssignedDuringLeave &&
                        <div className="row">
                            <div className="col-12 d-flex justify-content-center">
                                <Link className="btn btn-aqua-blue mt-4" to="/jobs/unassigned">Go To Unassigned Job Shifts</Link>
                            </div>
                        </div>
                    }

                </BaseModal>
            }
            {
                modals.bookLeaveRemove && <BaseModal
                show={modals.bookLeaveRemove}
                onCancel={() => setModals(false, 'bookLeaveRemove')}
                onSubmit={() => onDelete(selectedId as number)}
                cancelBtnText={"Cancel"}
                submitBtnText={"Confirm removal"}
                >
                    <h4 className="mb-4">
                        Remove a Booked Leave for <span className="font-weight-bold">{userName}</span>
                    </h4>
                    <div className="row mb-3">
                        <div className="col-sm-3 details-information__title mb-0">
                            <h6 className="mb-0">Date(s):</h6>
                        </div>
                        <div className="col-sm-9 details-information__value">
                            <p className="mb-0">
                                { momentDateFormat(bookLeave.startDate)} - { momentDateFormat(bookLeave.endDate ? bookLeave.endDate : bookLeave.startDate)}
                            </p>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-sm-3 details-information__title mb-0">
                            <h6 className="mb-0">Reason:</h6>
                        </div>
                        <div className="col-sm-9 details-information__value">
                            <p className="mb-0">
                                { matchOptionWithName(bookLeave.reason as number, bookLeaveVocabulary.vocabulary) }
                            </p>
                        </div>
                    </div>
                </BaseModal>
            }

            {
                modals.bookLeaveRemoveSuccess &&
                <BaseModal
                    show={modals.bookLeaveRemoveSuccess}
                    onCancel={() => setModals(false, 'bookLeaveRemoveSuccess')}
                    cancelBtnText={'Close'}
                >
                    <ConfirmationModal text={`You have successfully removed <span class="font-weight-bold">${userName}</span> from the book leave`}/>
                </BaseModal>
            }
            </>
            }

            {
                (requestModals.showRequest || requestModals.showDissaprove) && <BaseModal
                    show={requestModals.showRequest || requestModals.showDissaprove}
                    onCancel={() => onCloseRequestModals()}
                    onSubmit={() => onRequestSubmitHandler(requestModals.showRequest ? ActivationDeactivation.approve : ActivationDeactivation.disapprove,
                        { itemData: null, successCallback: () => onSubmit()},
                        requestModals.showRequest ?
                        `The Leave Request for <span class="font-weight-bold">${userName}</span> has been approved.<br />
                        <p class="mt-4">An automatic message has been sent to the guard and Resource Planning</p>
                        <p>Affected shifts have been added to the list of <a class="text-aqua-blue" href="/#/jobs/unassigned">Shifts in Need of Guards</a></p>` :
                        `The Leave Request for <span class="font-weight-bold">${userName}</span> has been denied.
                        <p class="mt-4">An automatic message has been sent to the guard</p>`
                    )}
                    submitBtnText={"Confirm"}
                    >
                    <h4 className="text-center mb-4" dangerouslySetInnerHTML={{ __html: modalText.title }} />
                </BaseModal>
            }
            {
                (requestModals.showSuccess || addEditSuccessModal.show) && <BaseModal
                show={requestModals.showSuccess || addEditSuccessModal.show}
                onCancel={() => {onCloseRequestModals(); setAddEditSuccessModal({show: false, title: ''})}}
                cancelBtnText={"Close"}
                >
                <h4 className="text-left mb-4" dangerouslySetInnerHTML={{ __html: modalText.title || addEditSuccessModal.title }} />
                </BaseModal>
            }

        </>
    )
}

export default BookedLeaveTab
