import React, {FC, useEffect, useRef, useState} from 'react'
import {SVGCalendar, SVGInfo, SVGTrash} from '../../assets/icons/SvgIcons'
import DatePicker from "react-datepicker";
import {IGuardAvailability} from '../../common/interfaces/guards/IGuard';
import CustomSelectControl from '../custom-select/CustomSelectControl';
import {useVocabulary} from '../../customHooks/vocabulary/useVocabulary';
import {VocabularyEnum} from '../../common/enums/VocabularyEnum';
import {IJobSchedule} from '../../common/interfaces/jobs/IJob';
import {weekDays} from '../../utils/MatchWeekDays';
import CustomTooltip from '../custom-tooltip/CustomTooltip';
import ScheduleHolidays from '../holidays/ScheduleHolidays';
import {
    timeWithoutSecondsFormat,
    setHoursAndMinutes,
    datePickerFormat,
    UTCtimeToLocal,
} from '../../utils/DateFormatting';
import {useDatapickerSvg} from "../../customHooks/useDatapickerSvg";
import {useLocation} from 'react-router';
import CustomTimePicker from "../custom-time-picker/CustomTimePicker";

type Props = {
    onChange: (value: React.ChangeEvent<HTMLInputElement> | string | Date | boolean, fieldName?: string,) => void;
    data: IGuardAvailability | IJobSchedule
    showShiftPeriod?: boolean
    hideEndDate?: boolean
    subtitle?: string
    trackHolidays?: (holidayId: number, yesNoValue: boolean) => void
    onRemoveItemFromCalendar: (scheduleId: number) => void
    holidaysTitle?: string
    schoolYear?: string
    tooltipDates?: string
    tooltipHours?: string
    tooltipRepeatOn?: string
    hideHolidays?: boolean
    className?: string,
}

const ScheduleForm: FC<Props> = React.memo(({
        onChange, data, showShiftPeriod, subtitle, trackHolidays, onRemoveItemFromCalendar, holidaysTitle, schoolYear,
        tooltipDates, tooltipHours, tooltipRepeatOn, hideHolidays, className
    }) => {

    const [maxEndsOn, setMaxEndsOn] = useState<string | null>(null)
    const {vocabulary, getVocabulary} = useVocabulary(VocabularyEnum.shift)
    const {showDatapicker, changeDatapicker} = useDatapickerSvg()
    const location = useLocation()
    const scheduleRef = useRef<null | HTMLDivElement>(null);

    const scrollToSchedule = () => {
        if (location.state) {
            const {lastJobId} = (location.state as any)
            if (lastJobId && lastJobId === data.id) {
                scheduleRef.current!.scrollIntoView()
            }
        }
    }

    useEffect(() => {
        showShiftPeriod && getVocabulary()
    }, [showShiftPeriod])

    useEffect(() => {
        if (schoolYear && schoolYear !== '') {
            setMaxEndsOn(setHoursAndMinutes(schoolYear))
            setTimeout(() => {
                scrollToSchedule()
            }, 400);
            if (data.id !== undefined) return;
            if (data.endsOnSelected && (new Date(data.endsOn) < new Date(schoolYear))) return;
            onChange(setHoursAndMinutes(schoolYear), "endsOn");
        } else setMaxEndsOn(null);
    }, [schoolYear])

    const daySelected = (selectedDays: string, day: number) => selectedDays.includes(day.toString()) ? true : false;

    useEffect(() => {
        onChange(true, 'scheduleHasBeenChanged')
    }, [data.startDate, data.endsOn, data.timeFrom, data.timeTo, data.shiftPeriod, data.repeatOn])

    return (

        <div className={`schedule-form ${className}`}>
            <div className="d-flex align-items-center mb-2 schedule-form__subtitle" ref={scheduleRef}>
                {subtitle && <h6 className="mb-0">{subtitle}</h6>}
                {
                    data.edited &&
                    <button
                        type="button"
                        className="btn btn-transparent p-0 ml-3"
                        onClick={() => onRemoveItemFromCalendar(data.id ? data.id : data.uid)}>
                        <SVGTrash/>
                    </button>
                }
            </div>

            <div className="row mt-md-3 mb-4">
                <div className="col-lg-12 mb-lg-0 d-flex flex-row align-items-end flex-wrap">

                    {
                        React.useMemo(() => (
                            <div className="schedule-form-startDate">
                                <div className="form-group mb-0 d-flex align-items-center">
                                    <label>From Date: </label>
                                </div>
                                <div className="react-datepicker-custom-wrapper d-inline-block mr-2 mr-md-0">
                                    <DatePicker
                                        selected={datePickerFormat(data.startDate)}
                                        onChange={date => {
                                            date && onChange(setHoursAndMinutes(date as Date), 'startDate');
                                            changeDatapicker(1)
                                            
                                        }}
                                        dateFormat="dd/MM/yyyy"
                                        minDate={new Date()}
                                        maxDate={datePickerFormat(maxEndsOn as string)}
                                        onInputClick={() => changeDatapicker(1)}
                                        open={showDatapicker[1]}
                                        onClickOutside={() => changeDatapicker(1)}
                                    />
                                    <div onClick={() => changeDatapicker(1)}><SVGCalendar/></div>
                                </div>
                            </div>
                        ), [changeDatapicker, data.startDate, maxEndsOn, onChange, showDatapicker])
                    }

                   {
                       React.useMemo(() => (
                        <div>
                            <div className="form-group mb-0 d-flex align-items-center">
                                <label>To Date: </label>
                            </div>
                            <div className="react-datepicker-custom-wrapper d-inline-block ">
                                <DatePicker
                                    selected={datePickerFormat(data.endsOn)}
                                    onChange={date => {
                                        date && onChange(setHoursAndMinutes(date as Date), "endsOn")
                                        // date && onChange(setHoursAndMinutes(date as Date), "endsOnSelected")
                                        changeDatapicker(2)
                                        
                                    }}
                                    dateFormat="dd/MM/yyyy"
                                    minDate={new Date()}
                                    maxDate={datePickerFormat(maxEndsOn as string)}
                                    onInputClick={() => changeDatapicker(2)}
                                    open={showDatapicker[2]}
                                    onClickOutside={() => changeDatapicker(2)}
                                />
                                <div onClick={() => changeDatapicker(2)}><SVGCalendar/></div>
                            </div>
                        </div>
                       ), [changeDatapicker, data.endsOn, maxEndsOn, onChange, showDatapicker])
                   }

                    <CustomTooltip
                        bodyText={tooltipDates ? tooltipDates : "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, debitis."}>
                        <button
                            type="button"
                            className="btn btn-transparent px-0 ml-3"
                        >
                            <SVGInfo/>
                        </button>
                    </CustomTooltip>
                </div>
            </div>
            <div className="row my-md-3">
                <div className="col-11 col-md-12 form-group d-flex flex-row align-items-end flex-wrap">
                    {
                        React.useMemo(() => (
                            <div className="schedule-form-startTime form-group mb-md-0 d-flex flex-column mr-2 mr-md-0">
                                <label>From Time: </label>
                                <CustomTimePicker
                                    value={data.timeFrom ? UTCtimeToLocal(data.timeFrom) : UTCtimeToLocal()}
                                    onChange={time => {
                                        onChange(timeWithoutSecondsFormat(time), 'timeFrom')
                                        
                                    }}
                                    index={`from_${data.uid ? data.uid : data.id}`}
                                    defaultSet={false}
                                />
                            </div>
                        ), [data.id, data.timeFrom, data.uid, onChange])
                    }

                    {
                        React.useMemo(() => (
                            <div className="form-group mb-md-0 d-flex flex-column">
                                <label>To Time: </label>

                                <CustomTimePicker
                                    value={data.timeTo ? UTCtimeToLocal(data.timeTo) : UTCtimeToLocal()}
                                    onChange={time => {
                                        onChange(timeWithoutSecondsFormat(time), 'timeTo')
                                        
                                    }}
                                    index={`to_${data.uid ? data.uid : data.id}`}
                                    defaultSet={false}
                                />
                            </div>
                        ), [data.id, data.timeTo, data.uid, onChange])
                    }

                    <CustomTooltip
                        bodyText={tooltipHours ? tooltipHours : "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, debitis."}>
                        <button
                            type="button"
                            className="btn btn-transparent px-0 ml-3 mb-3 mb-md-0"
                        >
                            <SVGInfo/>
                        </button>
                    </CustomTooltip>
                </div>
            </div>

            <div className="row mb-md-3">
                <div className="col-12 d-flex flex-row align-items-center flex-wrap flex-md-nowrap">
                    {
                        showShiftPeriod &&
                        <div className="schedule-form-shiftPeriod mb-2 mb-md-0 flex-wrap form-group mb-0 d-flex flex-column">
                            <label className="mb-md-0">Shift Period: </label>
                            <CustomSelectControl
                                options={vocabulary}
                                onChange={value => {
                                    onChange(value, 'shiftPeriod')
                                    
                                }}
                                value={data.shiftPeriod}
                                className={"flex-grow-1"}
                                placeholder={"Select Shift"}
                            />
                        </div>
                    }
                    <div className="form-group mb-0 d-flex flex-wrap align-items-center align-self-end">
                        <label className={`mb-md-0 validate mr-3 required repeatOn-${data.uid ? data.uid : data.id}`}>
                            Repeat Every Week On:
                        </label>
                        <div className="d-flex flex-row flex-wrap">
                            <div className="d-flex">
                                {
                                    React.useMemo(() =>
                                        weekDays.map(day =>
                                            <div key={`repeatOn-${day.index}`} className="form-group__custom-checkbox">
                                                <input
                                                    type="checkbox"
                                                    name={`repeatOn-${data.uid ? data.uid : data.id}`}
                                                    id={`repeat-${day.index}-${data.uid ? data.uid : data.id}`}
                                                    onChange={() => {
                                                        onChange(String(day.index), "repeatOn")
                                                        // 
                                                    }}
                                                    checked={daySelected(data.repeatOn, day.index)}
                                                />
                                                <label className="d-flex align-items-center justify-content-center "
                                                    htmlFor={`repeat-${day.index}-${data.uid ? data.uid : data.id}`}>{day.label}</label>
                                            </div>
                                        )
                                    , [data.id, data.repeatOn, data.uid, onChange])
                                }
                            </div>
                            <CustomTooltip
                                bodyText={tooltipRepeatOn ? tooltipRepeatOn : "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, debitis."}>
                                <button
                                    type="button"
                                    className="btn btn-transparent px-0 ml-3"
                                >
                                    <SVGInfo/>
                                </button>
                            </CustomTooltip>
                        </div>
                    </div>

                </div>
            </div>

            {/* Start Holidays */}
            {
                !hideHolidays && data.holidays && data.holidays.length !== 0 &&
                <>
                    <div className="py-4">
                        <ScheduleHolidays
                            holidays={data.holidays}
                            onChange={(value, holidayId) => trackHolidays && trackHolidays(holidayId, value)}
                            parentId={data.id ? data.id : data.uid}
                            titleKeyWord={holidaysTitle}
                        />
                    </div>
                </>
            }
            {/* End Holidays */}
        </div>
    )
})

export default ScheduleForm
