import DatePicker from "react-datepicker";
import { locationTrackingFilter } from 'common/models/XGuardReportsFilters'
import Layout from 'components/layout/Layout'
import { useDatapickerSvg } from 'customHooks/useDatapickerSvg'
import useXGuardReportsQuery from 'customHooks/xguard-reports/useXGuardReportsQuery'
import { datePickerFormat, setHoursAndMinutes } from 'utils/DateFormatting'
import { SVGCalendar } from "assets/icons/SvgIcons";
import AutocompleteField from "components/autosuggestField/AutocompleteField";
import CustomSelectControl from "components/custom-select/CustomSelectControl";
import { useVocabulary } from "customHooks/vocabulary/useVocabulary";
import { VocabularyEnum } from "common/enums/VocabularyEnum";
import SectionTitle from "components/titles/SectionTitle";
import { useContext, useEffect, useState } from "react";
import useGetModel from "customHooks/useGetModel";
import { NotificationTypes } from "common/interfaces/INotification";
import HeatmapReportModal from "components/modal/reports/HeatmapReportModal";
import { IGuardLocationTrackingReport } from "common/interfaces/reports/xguard-reports/IGuardLocationTracking";
import AppContext from "context/AppContext";

const GuardLocationTrackingReports = () => {
    const { showNotification } = useContext(AppContext)
    const { vocabulary: shiftVocabulary } = useVocabulary(VocabularyEnum.shift, true)
    const [showModal, setShowModal] = useState<boolean>();
    const {generateQueryParams, onQueryParamChanged,
        queryParams} = useXGuardReportsQuery({
          baseUrl: "reports/location-tracking",
          defaultParams: locationTrackingFilter
    })
    const { getModelData, data: modalData } = useGetModel<IGuardLocationTrackingReport[]>("")
    const {showDatapicker, changeDatapicker} = useDatapickerSvg()
    const [formIsValid, setFormIsValid] = useState<boolean>(false)

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (formIsValid) {
            getModelData((data) => {
                data.length !== 0 ?
                setShowModal(true) :
                showNotification(NotificationTypes.warning, "No Results. Please change search params.")
            }, undefined, generateQueryParams())
        }
    }

    useEffect(() => {
        const isValid = queryParams.keyWord && queryParams.shiftDate && queryParams.shiftPeriod ? true : false
        setFormIsValid(isValid)
    }, [queryParams.keyWord, queryParams.shiftDate, queryParams.shiftPeriod])

    return (
        <Layout
            breadcrumbs={{
                links: [{
                    title: 'Reports',
                    link: 'reports'
                }],
                currentPageTitle: 'Guard Location Tracking'
            }}
        >
            <div className="row mb-3">
                <div className="col-12">
                    <SectionTitle title={'Guard Location Tracking'}/>
                </div>
            </div>

            <form className="row" onSubmit={handleSubmit}>
                {/* Start Guard Name Search */}
                <div className="col-md-4 form-group shfit-autosegest">
                    <label className="required" htmlFor="keyWord">Guard Name or Employee ID:</label>
                    <AutocompleteField
                        showButton={false}
                        defaultValue={!queryParams.keyWord ? true : false}
                        includes={'Guard,Job,JobId'}
                        placeholder='Guard Name or Employee ID'
                        onSearch={value => onQueryParamChanged(value, 'keyWord')}
                        isAsync
                        showClearButton={queryParams.keyWord ? true : false}
                        onChange={(value) => onQueryParamChanged(value, 'keyWord')}
                        onClear={() => onQueryParamChanged('', 'keyWord')}
                    />
                </div>
                {/* End Guard Name Search */}
                <div className="col-md-4 mb-3 form-group">
                    <label className="required">From: </label>
                    <div
                        className="react-datepicker-custom-wrapper react-datepicker-custom-wrapper--full-width d-inline-block w-100">
                        <DatePicker
                            selected={datePickerFormat(queryParams.shiftDate)}
                            onChange={date => {
                            onQueryParamChanged(date ? setHoursAndMinutes(date as Date) : '', 'shiftDate')
                            changeDatapicker(1);
                            }}
                            dateFormat="dd/MM/yyyy"
                            onInputClick={() => changeDatapicker(1)}
                            open={showDatapicker[1]}
                            onClickOutside={() => changeDatapicker(1)}
                            placeholderText="dd/mm/yyyy"
                        />
                        <div onClick={() => changeDatapicker(1)}><SVGCalendar/></div>
                    </div>
                </div>
                {/* Start Shift Period Dropdown */}
                <div className="col-md-4 mb-3 form-group">
                    <label className="required">Shift Period: </label>
                    <CustomSelectControl
                        options={shiftVocabulary || []}
                        value={queryParams.shiftPeriod}
                        placeholder={"Select Shift"}
                        onChange={e => onQueryParamChanged(e, "shiftPeriod")}
                        className="flex-grow-0 mx-1 mb-2 mb-xl-0 custom-react-select--small"
                    />
                    <small className="d-block pt-2 pl-1">
                        Shows 15 minutes before ASP Start Time
                        to 15 minutes after Shift End Time.
                    </small>
                </div>
                {/* End Shift Period Dropdown */}

                {/* Download Button */}
                <div className="col-12 text-right">
                    <button
                    type="submit"
                    className={`btn ${formIsValid ? 'btn-aqua-blue' : 'btn-disabled'}`}
                    >
                        Show On Map
                    </button>
                </div>
            </form>
            {
                showModal && formIsValid && modalData &&
                <HeatmapReportModal
                    onCancel={() => setShowModal(false)}
                    reports={modalData}
                />
            }
        </Layout>
    )
}

export default GuardLocationTrackingReports
