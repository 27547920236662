import { useContext } from "react"
import { NotificationTypes } from "../common/interfaces/INotification"
import { DataService } from "../common/services/DataService"
import { IVocabulary } from '../common/interfaces/vocabulary/IVocabulary'

import AppContext from "../context/AppContext"
import { checkIfIsOtherReason } from '../utils/CheckOtherReason'

const useUnassignGuard = (url: string = "shift") => {
    const asssignSvc = new DataService<{ reason: number | string }>({ url })
    const { showLoader, showNotification } = useContext(AppContext)

    const onUnassignGuardSubmit = async (reason: string | IVocabulary, itemId: number, callBack: () => void, entityUrl = 'unassign',
    stopLoaderAfterSuccess = false) => {
        showLoader(true)
        try {
            const body = {
                reason: checkIfIsOtherReason(reason) ? reason as string : (reason as IVocabulary).id
            }
            await asssignSvc.update(body, itemId, entityUrl)

            callBack()
            !stopLoaderAfterSuccess && showLoader(false)
        } catch (e: any) {
            showLoader(false)
            showNotification(NotificationTypes.danger, e.message)
        }
    }

    const onBulkUnassingGuardSubmitted = async (reasonHook: any, shiftIds: string, callBack?: () => void) => {
        const svc = new DataService<{ reason: number | string }>({ url: 'shift/bulk-unassign' })
        const reason = reasonHook.reason
        const emptyReason = reasonHook.reasonIsEmpty('reasonForReplacement', 'reasonForReplacement--other')
        if (emptyReason) {
            showNotification(NotificationTypes.warning, `Please Select A Reason To Continue.`)
            return
        }
        showLoader(true)
        try {
            const body = {
                reason: checkIfIsOtherReason(reason) ? reason as string : (reason as IVocabulary).id,
                shiftIds: shiftIds,
            }
            await svc.update(body)
            callBack && callBack()
            showLoader(false)
        } catch (e: any) {
            showLoader(false)
            showNotification(NotificationTypes.danger, e.message)
        }
    } 

    return {
        onUnassignGuardSubmit, onBulkUnassingGuardSubmitted
    }
}

export default useUnassignGuard