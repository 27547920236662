import React, { useState } from 'react'
import { useContext } from 'react'
import BaseModal from '../../components/modal/BaseModal'
import { AuthService } from '../../common/auth/AuthService'
import { Roles } from '../../common/enums/Roles'
import Layout from '../../components/layout/Layout'
import SectionTitle from '../../components/titles/SectionTitle'
import AppContext from '../../context/AppContext'
import ClienInfo from '../../components/clients/ClientInfo'
import DataTable from '../../components/tables/DataTable'
import { clientContactsContent } from '../../content/manage-users/ManageUsersTables'
import { ClientIncludesEnum } from '../../common/enums/ClientEnum'
import { IClient, IClientContact } from '../../common/interfaces/clients/IClient'
import { Client } from '../../common/models/Client'
import { useModelDetails } from '../../customHooks/useModelDetails'
import { VocabularyEnum } from '../../common/enums/VocabularyEnum'
import { useVocabulary } from '../../customHooks/vocabulary/useVocabulary'
import { DataService } from '../../common/services/DataService'
import { NotificationTypes } from '../../common/interfaces/INotification'
import { ModelStatus, ActivationDeactivation } from '../../common/enums/Actions'
import useActivateDeactivate from '../../customHooks/useActivateDeactivate'
import DeactivateModal from '../../components/modal/users/DeactivateModal'
import ModelStatuses from '../../components/jobs/ModelStatuses'
import { IVocabulary } from '../../common/interfaces/vocabulary/IVocabulary'
import { useRole } from 'customHooks/useRole'

const ClientDetails = () => {
    const {isLoading, detailsModel: clientData, setDetailsModel} = useModelDetails<IClient>("client", new Client(), {include: [ClientIncludesEnum.contacts]})
    const [modals, setModals] = useState<{ show: boolean, success: boolean, title: string, reset: boolean}>({
        show: false, success: false, title: '', reset: false
    })
    const [itemInFocus, setItemInFocus] = useState<IClientContact>({} as IClientContact)
    const provinceVocabulary = useVocabulary(VocabularyEnum.province, true)
    const appContext = useContext(AppContext)

    const passwordSvc = new DataService<{ email: string }>({ url: 'account/forgot-password' })
    const invitationSvc = new DataService<{ email: string }>({ url: 'user' })
    const { onRequestHandler, onRequestSubmitHandler, requestModals, modalText, onCloseRequestModals, itemInFocus: clientInFocus } = useActivateDeactivate<IClient>('client');

    const { vocabulary: deactivationVocabulary } = useVocabulary(VocabularyEnum.clientDeactivationReason, true)
    const { isClientRole } = useRole()

    const onAccessClick = (clientContact: IClientContact, type: "reset" | "send") => {
        setItemInFocus(clientContact)
        if (type === "reset") {
            setModals({
                ...modals,
                title: `Are you sure you want to reset password for <span class="font-weight-bold">${clientContact.fullName}</span>?`,
                show: true,
                reset: true,
            })
        }

        if (type === "send") {
            setModals({
                ...modals,
                title: `Are you sure you want to send invitation to <span class="font-weight-bold">${clientContact.fullName}</span>?`,
                show: true,
                reset: false,
            })
        }
    }

    const onPasswordChangeConfirmClick = async () => {
        appContext.showLoader(true)
        try {
            await passwordSvc.create({ email: itemInFocus.email })
            setModals({
                ...modals,
                show: false,
                success: true,
                title: `Further instructions have been sent to <span class="font-weight-bold">${itemInFocus.fullName}</span>`
            })
            appContext.showLoader(false)
        } catch (error: any) {
            appContext.showLoader(false)
            appContext.showNotification(NotificationTypes.danger, error.message)
        }
    }

    const onSendInvitationConfirmClick = async () => {
        appContext.showLoader(true)
        try {
            await invitationSvc.update({} as any, itemInFocus.email, 'set')
            setModals({
                ...modals,
                show: false,
                success: true,
                title: `Further instructions have been sent to <span class="font-weight-bold">${itemInFocus.fullName}</span>`
            })
            appContext.showLoader(false)
        } catch (error: any) {
            appContext.showLoader(false)
            appContext.showNotification(NotificationTypes.danger, error.message)
        }
    }

    const onSuccessDeactivation = () => {
        setDetailsModel({
            ...clientData,
            isActive: ModelStatus.pending
        })
    }

    return (
        <Layout
            breadcrumbs={{
                links: [
                    {
                        title: `Clients`,
                        link: `clients`
                    }
                ],
                currentPageTitle: `${clientData.organizationName}`
            }}
        >

        <section className="guard-details">

            <div className="row">
                <div className="col-12">
                    <div className="d-flex flex-row flex-wrap align-items-center justify-content-between">
                        <SectionTitle
                            className="mb-2"
                            title={`${clientData.organizationName}`}
                        />
                        <div className="d-flex flex-row align-items-center mb-2">
                            { AuthService.checkIfUserHasPermission([Roles.Admin], appContext.currentUser.role) && clientData.isActive === ModelStatus.active &&
                            <button className="btn btn-outline-aqua-blue" onClick={() => {
                                onRequestHandler({
                                title: `Are you sure you want to deactivate <span class="font-weight-bold">${clientData.organizationName}</span>?`,
                                itemInFocus: clientData,
                                type: ActivationDeactivation.deactivate
                            })}}>Deactivate Client</button>
                            }
                            <ModelStatuses item={clientData}/>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <ClienInfo
            client={clientData}
            vocabulary={provinceVocabulary.vocabulary}
        />

        <section className="mt-5">
            <div className="row">
                <div className="col-12">
                    <SectionTitle title="Client Contacts" className="mb-4"/>
                </div>
            </div>
        </section>

        <section>
            <div className="row">
                <div className="col-12">
                {
                    React.useMemo(() => (
                        <DataTable
                            thead={clientContactsContent.thead}
                            tbody={clientData.clientContacts ? clientData.clientContacts.map(clientContact => ({
                                id: clientContact.id,
                                fullName: clientContact.setAsPrimary ? `<svg class="mr-2" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.5 3C0.5 2.72386 0.723858 2.5 1 2.5H16.4793C16.6425 2.5 16.7954 2.57961 16.889 2.71327L23.3897 12L16.889 21.2867C16.7954 21.4204 16.6425 21.5 16.4793 21.5H1C0.723858 21.5 0.5 21.2761 0.5 21V3Z" stroke="#283044"/>
                                <circle cx="10" cy="9" r="3" stroke="#283044"/><path d="M16 18C16 14.6863 13.3137 12 10 12C6.68629 12 4 14.6863 4 18" stroke="#283044" stroke-linecap="round"/>
                                </svg>${clientContact.fullName}` : clientContact.fullName || "-",
                                position: clientContact.position ? clientContact.position : '-',
                                email: clientContact.email ? clientContact.email : '-',
                                phone: clientContact.phoneNumber ? clientContact.phoneNumber : '-',
                                mobile: clientContact.mobileNumber ? clientContact.mobileNumber : '-',
                                isActive: clientContact.isActive
                            })) : []}
                            ignoreCols={[0, 6]}
                            ignoreTheadCols={[isClientRole() ? 5 : -1]}
                            isLoading={isLoading}
                            tableClass={'table-info--notFixed'}
                        >
                            {
                                (_id, rowItem: IClientContact) => (
                                    !isClientRole() &&
                                    <>
                                        <td className="aling-middle">
                                            <div>
                                                { rowItem.isActive ?
                                                    <button
                                                        className={`btn btn-aqua-blue btn--lg btn-outline-dark-lighter--2`}
                                                        onClick={() => onAccessClick(rowItem, 'reset')}
                                                    >
                                                        Reset Password
                                                    </button>
                                                    :
                                                    <button
                                                        className={`btn btn--lg btn-outline-aqua-blue'`}
                                                        onClick={() => onAccessClick(rowItem, 'send')}
                                                    >
                                                        Send Invitation
                                                    </button>
                                                }
                                            </div>
                                        </td>
                                    </>
                                )
                            }
                        </DataTable>
                    ), [clientData, isLoading, isClientRole])
                }
                </div>
            </div>
        </section>

        {
            modals.show && <BaseModal
                show={modals.show}
                onCancel={() => setModals({...modals, show: false })}
                onSubmit={() => modals.reset ? onPasswordChangeConfirmClick() : onSendInvitationConfirmClick()}
                cancelBtnText={'Close'}
                submitBtnText={'Confirm'}
            >
                <h4 className="mb-4" dangerouslySetInnerHTML={{ __html: modals.title }}/>
            </BaseModal>
        }

        {
            (modals.success || requestModals.showSuccess) && <BaseModal
                show={(modals.success || requestModals.showSuccess)}
                onCancel={() => { setModals({...modals, success: false }); onCloseRequestModals() }}
                cancelBtnText={'Close'}
            >
                <h4 className="mb-4" dangerouslySetInnerHTML={{ __html: requestModals.showSuccess ? modalText.title : modals.title }}/>
            </BaseModal>
        }

        { (requestModals.showRequest) &&
            <DeactivateModal
                onCancel={() => onCloseRequestModals()}
                onSubmit={(reason: string | IVocabulary) => onRequestSubmitHandler(ActivationDeactivation.deactivate,
                    { itemData: null, successCallback: () => onSuccessDeactivation() },
                    `Your request to deactivate <span class="font-weight-bold">${clientInFocus.organizationName}</span> has been sent.`,
                    reason
                )}
                title={modalText.title}
                // subtitle={`
                //     <p class="text-danger text-center">If you deactivate a client, all jobs, schedules, shifts and guard assignments will be deactivated.</p>
                //     <p class="text-danger mb-0 text-center">Your request will need to be approved before it takes effect.</p>
                // `}
                vocabulary={deactivationVocabulary}
            />
        }

        </Layout>
    )
}

export default ClientDetails
