import {useContext, useEffect, useState} from 'react'
import {FC} from 'react';
import {useHistory} from 'react-router-dom';
import {IClientDropdown} from '../../../common/interfaces/dropdowns/IClientDropDown';
import {ISchoolYearDropdown} from '../../../common/interfaces/dropdowns/ISchoolYearDropdown';
import CustomSelectControl from '../../../components/custom-select/CustomSelectControl';
import SectionTitle from '../../../components/titles/SectionTitle';
import {momentDateFormat} from '../../../utils/DateFormatting';
import {IValidationBody, ValidationRules} from "../../../common/interfaces/IValidation";
import {highlightError, removeHighlightError, validateFiled} from "../../../utils/Validation";
import {NotificationTypes} from "../../../common/interfaces/INotification";
import AppContext from "../../../context/AppContext";
import { useSchoolYear } from 'customHooks/useSchoolYear';

type Props = {
    onNext: () => void
    clients: IClientDropdown[]
}

const EditJobChoose: FC<Props> = ({onNext, clients}) => {
    const [selectedOption, setSelectedOption] = useState<{ cliendId: number, schoolYearId: number | null }>({
        cliendId: 1, schoolYearId: 1
    })
    const {showNotification} = useContext(AppContext)
    const [schoolYears, setSchoolYears] = useState<ISchoolYearDropdown[]>([] as ISchoolYearDropdown[])
    const history = useHistory()
    const { getCurrentYear } = useSchoolYear()

    useEffect(() => {
        if (clients.length !== 0)
            setSchoolYears(clients.find(client => client.id === selectedOption.cliendId)?.schoolYears || []);
    }, [schoolYears, clients, selectedOption.cliendId])

    useEffect(() => {
        setSchoolYears(clients.find(client => client.id === selectedOption.cliendId)?.schoolYears || [])
    }, [selectedOption.cliendId])

    useEffect(() => {
      setSelectedOption({
        ...selectedOption,
        schoolYearId: getCurrentYear(schoolYears)?.id || null})
    }, [schoolYears])

    const onClientSelect = (value: string) => {
        setSelectedOption({...selectedOption, cliendId: +value, schoolYearId: null})
    }

    const validateHolidayFields = () => {
        let errors: IValidationBody[] = [];
        for (let [field, value] of Object.entries(selectedOption)) {
            const skipFields = ['']
            if (!skipFields.includes(field)) {
                errors = [...errors, ...validateFiled(value, field, ValidationRules.required)]
            }
        }
        if (errors.length !== 0) {
            showNotification(NotificationTypes.warning, "Please fill in all fields correctly")
            highlightError(errors)
            return false
        } else {
            removeHighlightError()
            return true
        }
    }

    const onEditJobClick = () => {
        validateHolidayFields() && history.push({
            pathname: '/jobs/edit',
            search: `?client=${selectedOption.cliendId}&schoolYear=${selectedOption.schoolYearId}&schedules=false`
        })
        onNext()
    }

    const onEditJobSchedule = () => {
        validateHolidayFields() && history.push({
            pathname: '/jobs/edit',
            search: `?client=${selectedOption.cliendId}&schoolYear=${selectedOption.schoolYearId}&schedules=true`
        })
        onNext()
    }

    return (
        <section>
            <div className="row pb-4">
                <div className="col-12 d-flex flex-row justify-content-between">
                    <SectionTitle title="Edit job"/>
                </div>
                <div className="col-12 pt-3">
                    <p>To edit job schedules and job shifts in bulk, first select the Client and the School Year.</p>
                </div>
                <form className="col-lg-6 mx-auto">
                    <div className="form-group">
                        <label>Clients</label>
                        <CustomSelectControl
                            options={clients.map(client => (
                                {
                                    id: client.id,
                                    name: client.name
                                }
                            ))}
                            onChange={val => onClientSelect(val)}
                            value={selectedOption.cliendId}
                            placeholder={"Select a client"}
                            className={`custom-react-select--clientId`}
                        />
                    </div>

                    <div className="form-group">
                        <label>School Years</label>
                        <CustomSelectControl
                            options={schoolYears.map(year => ({
                                id: year.id,
                                name: `${momentDateFormat(year.startDate)} - ${momentDateFormat(year.endDate)}`
                            }))}
                            onChange={value => setSelectedOption({...selectedOption, schoolYearId: +value})}
                            value={selectedOption.schoolYearId}
                            placeholder={"Select a school year"}
                            className={`custom-react-select--schoolYearId`}
                        />
                    </div>
                    {
                        selectedOption.cliendId !== 0 &&
                        <div className="d-flex justify-content-between flex-wrap">
                            <button
                                type="button"
                                className="btn btn-aqua-blue d-block mx-auto text-center mb-2"
                                onClick={() => onEditJobSchedule()}
                            >Edit Job Schedules
                            </button>
                            <button
                                type="button"
                                className="btn btn-aqua-blue d-block mx-auto text-center mb-2"
                                onClick={() => onEditJobClick()}
                            >Edit Job Shifts
                            </button>
                        </div>

                    }
                </form>
            </div>
        </section>

    )
}

export default EditJobChoose
