import React, {FC, useContext, useEffect, useState} from 'react'
import {Tabs, Tab} from 'react-bootstrap'
import {Link, RouteComponentProps} from 'react-router-dom'
import { SVGPencil} from '../../assets/icons/SvgIcons'

import { VocabularyEnum } from '../../common/enums/VocabularyEnum'
import {Guard} from '../../common/models/Guard'
import {NotificationTypes} from '../../common/interfaces/INotification'
import {DataService} from '../../common/services/DataService'
import {GuardIncludesEnum} from '../../common/enums/GuardEnums'
import {IGuard, IGuardBookLeave, IGuardDevice} from '../../common/interfaces/guards/IGuard'
import {IVocabulary} from '../../common/interfaces/vocabulary/IVocabulary'
import {IGuardShift} from '../../common/interfaces/guards/IShift'
import { ModelStatus, ActivationDeactivation } from '../../common/enums/Actions'

import AssignedDeviceTab from '../../components/guards/details/tabs/AssignedDeviceTab'
import AssignedSchedulesTab from '../../components/guards/details/tabs/AssignedSchedulesTab'
import BookedLeaveTab from '../../components/guards/details/tabs/BookedLeaveTab'
import GuardInfo from '../../components/guards/details/GuardInfo'
import Layout from '../../components/layout/Layout'
import AssignedShiftsTab from '../../components/guards/details/tabs/AssignedShiftsTab'
import GuardReportTab from '../../components/guards/details/tabs/GuardReportTab'
import SectionTitle from '../../components/titles/SectionTitle'
import ModelStatuses from '../../components/jobs/ModelStatuses'
import AvailabilityScheduleTab from '../../components/guards/details/tabs/AvailabilityScheduleTab'

import GuardDetailsModalsContext, {IGuardDetailsModals} from '../../context/guards/GuardDetailsModalsContext'
import AppContext from '../../context/AppContext'
import AssignedDeviceContext from '../../context/guards/AssignedDeviceContext'
import {inputDataChecker} from '../../utils/InputDataChecker'
import BookLeaveContext from '../../context/guards/BookLeaveContext'
import {useModelDetails} from '../../customHooks/useModelDetails'
import UpdateAssignments from './UpdateAssignments'
import AssignedShiftsContext from '../../context/guards/AssignedShiftsContext'
import useActivateDeactivate from '../../customHooks/useActivateDeactivate'
import {useHolidays} from '../../customHooks/useHolidays'
import {useSort} from '../../customHooks/useSort'
import { useVocabulary } from '../../customHooks/vocabulary/useVocabulary'
import BaseModal from '../../components/modal/BaseModal'
import DeactivateModal from '../../components/modal/users/DeactivateModal'
import useUnassignGuard from '../../customHooks/useUnassignGuard'
import { generateFullName } from '../../utils/GenerateFullName'
import { useTableList } from '../../customHooks/useTableList'
import { useRole } from 'customHooks/useRole'

const GuardDetails: FC<RouteComponentProps> = (props) => {
    const { isLoading, detailsModel, userId, onChildArrDelete, setDetailsModel, fetchData } = useModelDetails<IGuard>("guard", new Guard(),
    {include: [GuardIncludesEnum.bookedLeaves, GuardIncludesEnum.device, GuardIncludesEnum.shift,
        GuardIncludesEnum.availabilities, GuardIncludesEnum.schedules, GuardIncludesEnum.report]})
    const { showLoader, showNotification } = useContext(AppContext)
    const shiftReportList = useTableList(`reports/guardshifts?GuardId=${userId}`, 24, true, {desc: 'up', orderBy: 'startDate', isSorted: false, keyword: ''})
    const provinceVocabulary = useVocabulary(VocabularyEnum.province, true)
    const shiftVocabulary = useVocabulary(VocabularyEnum.shift, true)
    const { vocabulary: removeGuardVocabulary } = useVocabulary(VocabularyEnum.guardRemoveJobScheduleReason, true)
    const { vocabulary: deactivationReasons } = useVocabulary(VocabularyEnum.guardDeactivation, true)
    const [guard, setGuard] = useState<IGuard>(detailsModel);
    const [showAssignments, setShowAssignments] = useState<boolean>(false)

    const [modals, setModals] = useState<IGuardDetailsModals>({
        device: false, deviceSuccess: false, bookLeaveEdit: false, bookLeaveEditSuccess: false,
        unassignGuard: false, unassignGuardSuccess: false, bookLeaveRemove: false, bookLeaveRemoveSuccess: false,
        assign: false, assignSuccess: false
    })

    const deviceSvc = new DataService<IGuardDevice>({url: "device"})
    const bookLeaveSvc = new DataService<IGuardBookLeave>({url: "guardbookedleave"})

    const {getAllHolidays} = useHolidays()
    const { onRequestHandler, onRequestSubmitHandler, requestModals, modalText, onCloseRequestModals } = useActivateDeactivate<IGuard>('guard');
    const sortShiftsHook = useSort<IGuardShift>();
    const { onUnassignGuardSubmit } = useUnassignGuard()
    const { isASPAndAdminRole, isAdminRole } = useRole();

    useEffect(() => {
        setGuard(detailsModel)
    }, [detailsModel])

    useEffect(() => { getAllHolidays() }, [])

    useEffect(() => {
        props.location.pathname.includes('assignments') ? setShowAssignments(true) : setShowAssignments(false)
    }, [props.location.pathname])

    // Handle Assigned device tab
    const handleAssignedDevice = (value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string | Date | boolean, fieldName?: string) => {
        const res = inputDataChecker(value, fieldName)

        guard.device &&
        setGuard({
            ...guard,
            device: {
                ...guard.device,
                [res.field]: res.value
            }
        });
    }

    const handleAssignedDeviceSubmit = async (obj: IGuardDevice, create = false) => {
        showLoader(true)
        try {
            if (create) {
                obj.guardId = guard.id
                const response = await deviceSvc.create(obj)
                obj.id = response.data as number
            } else {
                await deviceSvc.update(obj, guard?.device?.id as number)
            }
            setGuard({...guard, device: obj})
            setModals({
                ...modals,
                device: false,
                deviceSuccess: true
            })
            showLoader(false)
        } catch (e: any) {
            showLoader(false)
            showNotification(NotificationTypes.danger, e.message)
        }
    }

    const handleBookLeaveSubmit = async (obj?: IGuardBookLeave, create = false) => {
        showLoader(true)
        try {

            if (create && obj) {
                const res = await bookLeaveSvc.create(obj);
                obj.id = res.data as number
                obj.guardId && delete obj.guardId;
            } else if (obj) {
                await bookLeaveSvc.update(obj, obj.id as number);
            }
            await fetchData(+userId)
            setModals({
                ...modals,
                bookLeaveEdit: false,
                bookLeaveEditSuccess: false
            })
            showLoader(false)
        } catch (e: any) {
            showLoader(false)
            showNotification(NotificationTypes.danger, e.message)
        }
    }

    const onUnassignGuardConfirmed = async (reason: IVocabulary | string, shiftId: number) => {
        onUnassignGuardSubmit(reason, shiftId, () => {
            setGuard({...guard, jobScheduleShifts: guard.jobScheduleShifts.filter(shift => +shift.id !== +shiftId)})
            setModals({
                ...modals,
                unassignGuard: false,
                unassignGuardSuccess: true,
            })
        })
    }

    const onAssignGuardToJobShift = (jobShift: IGuardShift) => {
        setGuard({
            ...guard,
            jobScheduleShifts: sortShiftsHook.dateTimeSort([...guard.jobScheduleShifts, jobShift], 'startDate', 'timeFrom')
        })
    }

    const onAssignGuardToJobSchedule = () => fetchData(+userId)

    const onSuccessAvailabilityRequest = () => fetchData(+userId)

    const onBookLeaveRemoveConfirm = async (id: number) => {
        showLoader(true)
        await onChildArrDelete("guardBookedLeaves", id, "guardbookedleave")
        setModals({
            ...modals,
            bookLeaveRemove: false,
            bookLeaveRemoveSuccess: false
        })
        showLoader(false)
    }

    const handleModalWindows = (state: boolean, keyName: string) => {
        const modalsCopy = {...modals}
        for (const [key,] of Object.entries(modalsCopy)) {
            if (keyName === key) {
                modalsCopy[key] = state
            } else {
                modalsCopy[key] = false
            }
        }

        setModals(modalsCopy)
    }

    const breadcrumbsContent = (assignments: boolean) => {
        const baseLinks = [{
            title: 'Guards',
            link: 'guards'
        }];

        if (assignments) {
            return {
                links: [...baseLinks, ...[
                    {
                        title: `${guard.firstName} ${guard.middleName} ${guard.lastName}`,
                        link: `guards/${guard.id}/details`
                    }
                ]],
                currentPageTitle: 'Assign Jobs'
            }
        } else {
            return {
                links: baseLinks,
                currentPageTitle: `${guard.firstName} ${guard.middleName} ${guard.lastName}`
            }
        }
    }

    const onSuccessGuardDeactivation = () => {
        setDetailsModel({
            ...guard,
            isActive: isAdminRole() ? ModelStatus.inactive : ModelStatus.pending
        })
    }

    const onSuccessGuardRemove = () => fetchData(+userId)

    const onSendHealthCheck = async () => {
        showLoader(true)
        const svc = new DataService({ url: 'notification/trigger-health-check' })
        try {
            await svc.update({ guardId: +userId })
            showLoader(false)
            showNotification(NotificationTypes.success, 'Health & Safety Check Notification has been sent to guard.')
        } catch (error: any) {
            showLoader(false)
            showNotification(NotificationTypes.danger, error.message)
        }
    }

    return (

        <Layout
            breadcrumbs={breadcrumbsContent(showAssignments)}
        >

            {!showAssignments &&
            <>
                <section className={`guard-details ${isASPAndAdminRole() ? 'mb-0' : 'mb-5'}`}>
            
                    <div className="row">
                        <div className="col-12 d-flex justify-content-end align-items-center mb-3 mb-md-0 ">
                            <h6 className="mb-0">Employee ID: <span className='text-uppercase'>{guard.employeeId}</span></h6>
                            <ModelStatuses item={guard}/>
                        </div>
                        <div className="col-12 d-flex flex-row flex-wrap mb-3 upload-image align-items-center justify-content-between">
                            <div className="d-flex flex-column flex-sm-row flex-fill align-items-start align-items-md-center">
                                {
                                    guard.profileImage ?
                                        <img src={guard.profileImage} alt="Guard"/> :
                                        <div className="upload-image__blank ml-auto mr-auto mr-md-0 ml-md-0"></div>
                                }
                                <div>
                                    <SectionTitle className="ml-md-4 mt-3 mt-md-0" title={`${guard.firstName} ${guard.middleName} ${guard.lastName}`}/>
                                    <div className='ml-md-4 mt-3 font-weight-bold'>{guard.isSupervisor && 'Supervisor'}</div>
                                </div>


                            </div>
                            <div className="d-flex flex-row mt-3 mt-lg-0">
                                <div className="d-flex flex-column flex-sm-row">
                                    { isASPAndAdminRole() && guard.isActive === ModelStatus.active &&
                                    <button className="btn btn-outline-aqua-blue ml-sm-auto mr-2 text-center" onClick={() => {
                                        onRequestHandler({
                                        title: `Are you sure you want to deactivate <span class="font-weight-bold">${guard.firstName} ${guard.middleName} ${guard.lastName}</span>?`,
                                        itemInFocus: guard,
                                        type: ActivationDeactivation.deactivate
                                    })}}>Deactivate Guard</button>
                                    }
                                    { isASPAndAdminRole() && <button
                                        className={`btn ${guard.guardDeviceToken ? 'btn-aqua-blue': 'btn-disabled'} d-flex mt-2 mt-sm-0`}
                                        onClick={() => guard.guardDeviceToken && onSendHealthCheck()}
                                        >Send Health & Safety Check</button>
                                    }
                                </div>
                                {/* Start Health Btn */}
                                <div>
                                    {
                                        isASPAndAdminRole() &&
                                        <Link
                                            className="btn btn-aqua-blue ml-2"
                                            to={`/guards/${userId}/edit`}
                                        >
                                            <SVGPencil/>
                                        </Link>
                                    }
                                </div>
                                {/* End Health Btn */}
                            </div>
                        </div>
                    </div>
                </section>

                {
                    guard && isASPAndAdminRole() &&
                    <GuardInfo
                        guard={guard}
                        vocabulary={provinceVocabulary.vocabulary}
                    />
                }
            </>
            }

            <GuardDetailsModalsContext.Provider
                value={{
                    isLoading,
                    userId,
                    userName: `${guard.firstName} ${guard.middleName || ""} ${guard.lastName}`,
                    modals,
                    setModals: (state, keyName) => handleModalWindows(state, keyName)
                }}
            >
                {
                    showAssignments ? <UpdateAssignments
                            onAssignGuardToJobShift={(_jobId, jobShift) => onAssignGuardToJobShift(jobShift)}
                            onAssignGuardToJobSchedule={() => onAssignGuardToJobSchedule()}
                            vocabulary={shiftVocabulary.vocabulary}
                            /> :
                        <section className="details-tabs">
                            <div className="row">
                                <div className="col-12">
                                    <div className="mb-4">
                                        <SectionTitle title="Guard Details"/>
                                    </div>
                                    <Tabs defaultActiveKey="assigned-schedules" id="uncontrolled-tab-example"
                                          className="justify-content-stretch tabs-details">
                                        <Tab eventKey="assigned-schedules" title="Assigned Schedules">
                                            <AssignedSchedulesTab
                                                onSuccessGuardRemove={() => onSuccessGuardRemove()}
                                                assignedSchedules={guard.jobSchedules}
                                                removeGuardVocabulary={removeGuardVocabulary}
                                            />
                                        </Tab>
                                        <Tab eventKey="assigned-shifts" title="Assigned Shifts">
                                            <AssignedShiftsContext.Provider
                                                value={{
                                                    assignedShifts: guard.jobScheduleShifts,
                                                    onSubmit: (obj, id) => onUnassignGuardConfirmed(obj, id),
                                                }}
                                            >
                                                <AssignedShiftsTab/>
                                            </AssignedShiftsContext.Provider>
                                        </Tab>
                                        <Tab eventKey="guard-report" title="Guard Shift Report">
                                            <GuardReportTab
                                                lateOrAbsences={guard.latesOrAbsences}
                                                vocabulary={shiftVocabulary.vocabulary}
                                                shiftReportList={shiftReportList}
                                            />
                                        </Tab>
                                        {isASPAndAdminRole() &&
                                        <Tab eventKey="booked-leave" title="Booked Leave">
                                            <BookLeaveContext.Provider
                                                value={{
                                                    bookLeaves: guard.guardBookedLeaves,
                                                    assignedShifts: guard.jobScheduleShifts,
                                                    onChange: (e, fieldName) => handleAssignedDevice(e, fieldName),
                                                    onSubmit: (obj, create) => handleBookLeaveSubmit(obj, create),
                                                    onDelete: id => onBookLeaveRemoveConfirm(id),
                                                }}
                                            >
                                                <BookedLeaveTab/>
                                            </BookLeaveContext.Provider>
                                        </Tab>
                                        }
                                        <Tab eventKey="availability-schedule" title="Availability Schedule">
                                            <AvailabilityScheduleTab
                                              guardDetails={guard}
                                              onSuccessAvailabilityRequest={onSuccessAvailabilityRequest}
                                            />
                                        </Tab>
                                        {isASPAndAdminRole() &&
                                        <Tab eventKey="assigned-device" title="Device and OS">
                                            {/* Device and OS */}
                                            <AssignedDeviceContext.Provider
                                                value={{
                                                    device: guard?.device,
                                                    onChange: (e, fieldName) => handleAssignedDevice(e, fieldName),
                                                    onSubmit: (obj, create) => handleAssignedDeviceSubmit(obj, create)
                                                }}
                                            >
                                                <AssignedDeviceTab/>
                                            </AssignedDeviceContext.Provider>
                                        </Tab>
                                        }
                                    </Tabs>
                                </div>
                            </div>
                        </section>
                }
            </GuardDetailsModalsContext.Provider>
            { (requestModals.showRequest) &&
                <DeactivateModal
                    onCancel={() => onCloseRequestModals()}
                    onSubmit={(reason: string | IVocabulary) => onRequestSubmitHandler(ActivationDeactivation.deactivate,
                        { itemData: null, successCallback: () => onSuccessGuardDeactivation() },
                        `${isAdminRole()
                        ? `<span class="font-weight-bold">${generateFullName(guard)}</span> has been deactivated.
                        An automatic message has been sent to the guard and affected shifts have been added to the list of shifts.<br>
                        <a class="text-aqua-blue text-center d-block pt-2" href="#/jobs/unassigned">Shifts in Need of Guards</a>`
                        : `Your request to deactivate <span class="font-weight-bold">${ generateFullName(guard) }</span> has been sent to the Administrator.`} `,

                        reason
                    )}
                    title={modalText.title}
                    vocabulary={deactivationReasons}
                />
            }

            {
                requestModals.showSuccess && <BaseModal
                    show={requestModals.showSuccess}
                    onCancel={() => onCloseRequestModals()}
                    cancelBtnText={'Close'}
                >
                    <h4 className="text-center" dangerouslySetInnerHTML={{ __html: modalText.title }} />
                </BaseModal>
            }
        </Layout>
    )
}

export default GuardDetails
