export enum CheckInOutTypeEnum {
    Manual = '1',
    Auto = '2',
    Admin = '3',
    Temp = '4',
    Offline = '4',
}

export enum CheckInTypeLabelEnum {
    Manual = 'Guard Check-In',
    Auto = 'Auto Check-In',
    Admin = 'Admin Check-In',
    Temp = 'Admin Temp Check-In'
}

export enum CheckOutTypeLabelEnum {
    Manual = 'Guard Check-Out',
    Auto = 'Auto Check-Out',
    Admin = 'Admin Check-Out',
    Offline = 'Offline Check-Out',
}