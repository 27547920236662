import moment from "moment";
import {useState, useContext, useMemo} from "react";
import {IGuard} from "../common/interfaces/guards/IGuard";
import {IHoliday} from "../common/interfaces/IHoliday";
import {NotificationTypes} from "../common/interfaces/INotification";
import {IJob, IJobSchools} from "../common/interfaces/jobs/IJob";
import ResourceDataService from "../common/services/ResourceDataService";
import AppContext from "../context/AppContext";

export const useHolidays = () => {
    const [allHolidays, setAllHolidays] = useState<IHoliday[]>([] as IHoliday[]);
    const svc = new ResourceDataService<IHoliday[]>({url: `holiday`})
    const context = useContext(AppContext)

    const getWeekDayNum = (value: string) => value.split(';')

    const getAllHolidays = useMemo(() =>
            async () => {
                if (allHolidays.length !== 0) return;
                try {
                    const res = await svc.getAll();
                    setAllHolidays(filterSameDayHoliday(res.data))
                } catch (e: any) {
                    context.showNotification(NotificationTypes.danger, e.message)
                    return
                }
            }
        , [])

    const getHolidaysSchoolBoardById = async (schoolArray: Array<IJobSchools>) => {
        setAllHolidays([])
        if (schoolArray) {
            try {
                const res = await svc.queryString(`schoolBoardsId=${schoolArray.map(board => board.schoolBoardId).join(';')}`);
                setAllHolidays(filterSameDayHoliday(res.data))
                return res.data;
            } catch (e: any) {
                context.showNotification(NotificationTypes.danger, e.message)
                return
            }
        }
    }

    const filterSameDayHoliday = (originalHolidays: IHoliday[]): IHoliday[] => {
        const commonDateFormat = (date: string | Date) => moment.utc(date).format('YYYY-MM-DD');
        const filteredHolidays: IHoliday[] = []
        originalHolidays.forEach(holiday => {
            const index = filteredHolidays.findIndex((item: any) => commonDateFormat(item.startDate) === commonDateFormat(holiday.startDate) && commonDateFormat(item.endDate) === commonDateFormat(holiday.endDate))
            if (index > -1) {
                filteredHolidays[index].name = filteredHolidays[index].name + `, ${holiday.name}`;
            } else {
                filteredHolidays.push(holiday)
            }
        })
        return filteredHolidays;
    }

    const checkIfDayHasHoliday = <T extends { [index: string]: any }>(holidays: IHoliday[], dataToCheck: T): IHoliday[] => {
        const holidaysCopy = [...holidays]
        const dateFormat = (date: string | Date) => moment(date).format('YYYY-MM-DD');
        const endOfSchedule = dataToCheck.endsOn ? dataToCheck.endsOn : dataToCheck.endDate

        return holidaysCopy.filter(holiday => {
            if (holiday.endDate !== null) {
                return moment(dateFormat(holiday.endDate)).isSameOrAfter(dateFormat(dataToCheck.startDate)) && 
                !moment(dateFormat(holiday.startDate)).isAfter(dateFormat(endOfSchedule))
            } else {
                return moment(dateFormat(holiday.startDate)).isBetween(dateFormat(dataToCheck.startDate), dateFormat(endOfSchedule), undefined, `[]`)
            }
        }).sort((dayPrev, dayNext) => new Date(dayNext.startDate) < new Date(dayPrev.startDate) ? 1 : -1)
    }

    const prepopulateHolidays = (originalData: IGuard | IJob, holidays: IHoliday[] = [], fieldKey: string, callback: (schedule: any, scheduleId: number) => IHoliday[]) => {
        const commonDateFormat = (date: string | Date) => moment.utc(date).format('YYYY-MM-DD');
        return {
            ...originalData,
            [fieldKey]: (originalData[fieldKey] as any).map((schedule: any) => {
                schedule.holidays.forEach((holiday: IHoliday) => {
                    !holiday.hasOwnProperty('remove') && (holiday.remove = true)
                })
                return schedule
            })
                .map((schedule: any) => {
                    schedule.holidays = [
                        ...schedule.holidays,
                        ...callback(schedule, schedule.id as number)
                            .filter(holiday => {
                                const statement = schedule.holidays.some((item: IHoliday) =>
                                    commonDateFormat(item.startDate) === commonDateFormat(holiday.startDate) && commonDateFormat(item.endDate) === commonDateFormat(holiday.endDate))
                                if (!statement) {
                                    holiday.remove = false
                                    return holiday
                                }
                                return false;
                            })
                    ].sort((dayPrev, dayNext) => new Date(dayNext.startDate) < new Date(dayPrev.startDate) ? 1 : -1)
                    return schedule
                }).map((sch: any) => ({...sch, holidays: filterSameDayHoliday(sch.holidays)}))
        }
    }

    return {allHolidays, getAllHolidays, getWeekDayNum, checkIfDayHasHoliday, prepopulateHolidays, getHolidaysSchoolBoardById}
}
