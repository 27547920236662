import { matchOptionWithName, matchOptionWithoutOther } from '../utils/MatchOptionWithName'
import { checkIfIsOtherReason } from '../utils/CheckOtherReason'
import { IVocabulary } from '../common/interfaces/vocabulary/IVocabulary'

export const useMatchArrayOfReasons = () => {
    const matchShiftsReasonIdWithName = (reason: string | null | number, bookLeaveVoc: IVocabulary[], reasonVoc: IVocabulary[], guardDeact: IVocabulary[]) => {
        let matchedReason = ""
        if (reason === null) {
            matchedReason =  "New Shift";
        } else if (checkIfIsOtherReason(reason)) {
            matchedReason =  reason as string
        } else if (matchOptionWithoutOther(reason as string, bookLeaveVoc)) {
            matchedReason =  matchOptionWithName(reason as string, bookLeaveVoc) as string
        } else if (matchOptionWithoutOther(reason as string, reasonVoc)) {
            matchedReason =  matchOptionWithName(reason as string, reasonVoc) as string
        } else if (matchOptionWithoutOther(reason as string, guardDeact)) {
            matchedReason =  matchOptionWithName(reason as string, guardDeact) as string
        } else {
            matchedReason = reason as string;
        }
        return matchedReason
    }

    const matchUsersReasonIdWithName = (reason: string | null | number, clientDeactVoc: IVocabulary[], aspDeactVoc: IVocabulary[], aspAdminDeactVoc: IVocabulary[], clientContactVocabulary: IVocabulary[]) => {
        let matchedReason = ""
        if (reason === null) {
            matchedReason =  "No Reason";
        } else if (checkIfIsOtherReason(reason)) {
            matchedReason =  reason as string
        } else if (matchOptionWithoutOther(reason as string, clientDeactVoc)) {
            matchedReason =  matchOptionWithName(reason as string, clientDeactVoc) as string
        } else if (matchOptionWithoutOther(reason as string, aspDeactVoc)) {
            matchedReason =  matchOptionWithName(reason as string, aspDeactVoc) as string
        } else if (matchOptionWithoutOther(reason as string, aspAdminDeactVoc)) {
            matchedReason =  matchOptionWithName(reason as string, aspAdminDeactVoc) as string
        } else if (matchOptionWithoutOther(reason as string, clientContactVocabulary)) {
            matchedReason =  matchOptionWithName(reason as string, clientContactVocabulary) as string
        } else {
            matchedReason = reason as string;
        }
        return matchedReason
    }

    const matchNameWithMultipleReasons = (reason: string | null | number, ...vocabularies: Array<IVocabulary[]>) => {
        let matchedReason = ""
        if (reason === null) {
            matchedReason =  "-";
        } else if (checkIfIsOtherReason(reason)) {
            matchedReason = reason as string
        } else {
            vocabularies.forEach(vocabulary => {
                if (matchOptionWithoutOther(reason as string, vocabulary)) {
                    matchedReason = matchOptionWithName(reason as string, vocabulary) as string
                }
            })
            matchedReason = matchedReason ? matchedReason : reason as string;
        }
        return matchedReason
    }

    return {
        matchShiftsReasonIdWithName, matchUsersReasonIdWithName, matchNameWithMultipleReasons
    }
}