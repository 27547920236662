import React, { FC } from 'react';
import SimpleModal from '../SimpleModal';
type Props = {
	showModal: {[key: string]: boolean};
	onCancelModal: () => void;
	handleBulkAction: (
		body: { [key: string]: any },
		subUrl: string,
		callBack: () => Promise<void>
	) => Promise<void>;
	bulkActionCallback: () => Promise<void>;
	selectedIds: string;
};
const BulkActionsModal: FC<Props> = ({
	showModal,
	onCancelModal,
	handleBulkAction,
	bulkActionCallback,
	selectedIds,
}) => {
	return (
		<>
			{showModal.bulkTempCheckIn && (
				<SimpleModal
					onCancel={onCancelModal}
					onSubmit={() =>
						handleBulkAction(
							{ isTentant: true, shiftIds: selectedIds },
							'bulk-check-in',
							bulkActionCallback
						)
					}
					show={showModal.bulkTempCheckIn}
					submitBtnText='Temp Check In'
					title={'Temporarily Check In Guards in Bulk'}
					description={
						'All selected shifts will be marked "temp checked in" at ASP Start Time. This will only affect shifts that do not have any check-in status yet.'
					}
				/>
			)}
			{showModal.bulkMarkAbsent && (
				<SimpleModal
					onCancel={onCancelModal}
					onSubmit={() =>
						handleBulkAction(
							{ reason: '-', shiftIds: selectedIds },
							'bulk-mark-absent',
							bulkActionCallback
						)
					}
					show={showModal.bulkMarkAbsent}
					submitBtnText='Mark Absent'
					title={'Mark Guards as Absent in Bulk'}
					description={
						'All selected shifts will have guards marked as "absent". This will only affect shifts that have guards assigned, and all shifts whether they have guards checked in or temp checked-in'
					}
				/>
			)}
			{showModal.bulkConfirmCheckIn && (
				<SimpleModal
					onCancel={onCancelModal}
					onSubmit={() =>
						handleBulkAction(
							{ isTentant: false, shiftIds: selectedIds },
							'bulk-check-in',
							bulkActionCallback
						)
					}
					show={showModal.bulkConfirmCheckIn}
					submitBtnText='Confirm Check In'
					title={'Confirm Guard Check-In in Bulk'}
					description={
						'All selected shifts that are currently marked as "temp check-in" will be updated to "checked in" on ASP Start Time.'
					}
				/>
			)}
		</>
	);
};

export default BulkActionsModal;
