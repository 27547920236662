import React from 'react'
import { Link } from 'react-router-dom'

import Layout from '../../../components/layout/Layout'
import SectionTitle from '../../../components/titles/SectionTitle'
import RequestsToDeactivateSchedules from '../../../components/jobs/schedule-requests/RequestsToDeactivateSchedules'

const DeactivateSchedules = () => {
    return (
        <Layout
            breadcrumbs={{
                links: [
                    {
                        title: 'Jobs',
                        link: 'jobs'
                    },
                    {
                        title: 'Job Schedules',
                        link: 'jobs'
                    },
                ],
                currentPageTitle: 'Deactivate Schedules'
            }}
        >
            <div className="row">
                <div className="col-12 mb-4 d-flex flex-row flex-wrap align-items-center justify-content-between">
                    <SectionTitle title="Deactivation Requests for Job Schedules"/>
                    <Link to="/jobs/job-schedules/deactivated" className="btn btn-outline-aqua-blue ml-0 ml-md-3 mt-2 mt-md-0">View inactive schedules</Link>
                </div>
                <div className="col-12">
                    <RequestsToDeactivateSchedules />
                </div>
            </div>

        </Layout>
    )
}

export default DeactivateSchedules
