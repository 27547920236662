import { FC, ReactNode, useEffect, useState } from 'react'
import { HashRouter as Router } from 'react-router-dom';
import { AuthService } from '../../common/auth/AuthService';
import { INotification, NotificationTypes } from '../../common/interfaces/INotification';
import { ILoggedInUser } from '../../common/interfaces/IUser';
import AppContext from '../../context/AppContext';
import PageLoader from '../loading/PageLoader';
import Alert from '../notification/Notification';
import createHistory from "history/createBrowserHistory"
import { usePushNotifications } from 'customHooks/fb/usePushNotifications';
import FBNotificationsWrapper from './FBNotificationsWrapper';

type Props = {
    children: ReactNode
}

export const history = createHistory()

history.listen(() => {
    window.scrollTo(0, 0)
})

const AppWrapper: FC<Props> = ({ children }) => {
    const [intialized, setIntialized] = useState(false)
    const [currentUser, setCurrentUser] = useState<ILoggedInUser>({} as ILoggedInUser)
    const [loading, setLoading] = useState(true)
    const [loggedIn, setLoggedIn] = useState(false)
    const [notification, setNotification] = useState<INotification>({} as INotification)
    const { fbNotifications, initCloudMsg, setFbNotifications } = usePushNotifications()

    useEffect(() => {
        isCurrentUserValid()
        .then(() => {
            setIntialized(true)
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        !loading && intialized && currentUser && initCloudMsg(currentUser)
    }, [loading, intialized, currentUser])

    const isCurrentUserValid = async () => {
        const user = await AuthService.getAuthenticatedUser(currentUser);
        setCurrentUser(user ? user : {} as ILoggedInUser)
        setLoggedIn(user ? true : false)
        if (user === undefined) return false;

        setLoggedIn(true)
        return true
    }

    const handleNotification = (type: NotificationTypes, msgs: Array<string> | string) => {
        let hideTimeout;
        clearTimeout(hideTimeout)
        if (type === NotificationTypes.danger) {
            setNotification({...notification,  show: false })
            hideTimeout = setTimeout(() => setNotification({ type, msgs, show: true }), 500)
        } else {
            setNotification({...notification,  show: false })
            setTimeout(() => setNotification({ type, msgs, show: true }), 100)
            hideTimeout = setTimeout(() => setNotification({...notification,  show: false }), 5000)
        }
    }

    return (
        <AppContext.Provider 
        value={{ 
            authenticated: loggedIn,
            currentUser,
            loading,
            showLoader: show => setLoading(show),
            setCurrentUser,
            setLoggedIn,
            showNotification: handleNotification
        }}>
            <Router>
               <>
                    { loading ? <PageLoader /> : null }
                    { intialized ? children : null }
                    
                    { notification.show && <Alert
                        msgs={notification.msgs}
                        type={notification.type}
                        onClick={() => setNotification({ type: NotificationTypes.warning, msgs: '', show: false })}
                    /> }
                    <FBNotificationsWrapper
                        notifications={fbNotifications}
                        setFbNotifications={setFbNotifications}
                    />
               </>
            </Router>
        </AppContext.Provider>
    )
}

export default AppWrapper
