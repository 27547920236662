import { VocabularyEnum } from "common/enums/VocabularyEnum";
import { arrivalReportFilter } from "common/models/XGuardReportsFilters";
import { IGuardArrivalReport } from "common/interfaces/reports/xguard-reports/IGuardArrival";
import DataTable from "components/tables/DataTable";
import { guardArrivalReportsThead } from "content/xguard-reports/tableContent";
import { useTableList } from "customHooks/useTableList";
import { useVocabulary } from "customHooks/vocabulary/useVocabulary";
import useXGuardReportsQuery from "customHooks/xguard-reports/useXGuardReportsQuery";
import { datePickerFormat, momentDateFormat, momentHoursFormat, setHoursAndMinutes } from "utils/DateFormatting";
import { matchOptionWithName } from "utils/MatchOptionWithName";
import Layout from "../../../components/layout/Layout";
import SectionTitle from "../../../components/titles/SectionTitle";
import DatePicker from "react-datepicker";
import { SVGCalendar, SVGDownload } from "assets/icons/SvgIcons";
import { useDatapickerSvg } from "customHooks/useDatapickerSvg";
import AutocompleteField from "components/autosuggestField/AutocompleteField";
import useReportActions from "customHooks/reports/useReportActions";
import { useContext, useMemo, useState } from "react";
import AppContext from "context/AppContext";
import { useDowloandFile } from "customHooks/useDownloadFile";
import { Reports } from "common/enums/Reports";
import ReportsDetailsModal from "components/modal/reports/ReportsDetailsModal";
import ReportOptCheckBox from "components/reports/ReportOptCheckBox";

const ArrivalReports = () => {
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false)
  const [reportDetails, setReportDetails] = useState<IGuardArrivalReport | undefined>(undefined)

  const { showLoader } = useContext(AppContext)
  const { fetchData: fetchReports, tableData: arrivalReports,
    isLoading, currentPage, perPage, allRecords, onPagination, onSortCall, 
    onPerPage } = useTableList<IGuardArrivalReport>("reports/arrival", 24)
  const { vocabulary: shiftPeriods } = useVocabulary(VocabularyEnum.shift, true)
  const { vocabulary: reportedOptions } = useVocabulary(VocabularyEnum.arrivalTypesOnGuardMobileApp, true)
  const {generateQueryParams, onQueryParamChanged,
    queryParams} = useXGuardReportsQuery({
      baseUrl: "reports/arrival",
      defaultParams: arrivalReportFilter
    })
  const {showDatapicker, changeDatapicker} = useDatapickerSvg()
  const { onChangeMultiSelectString } = useReportActions(queryParams);
  const { dowloandFile } = useDowloandFile()

  const handleCheckBox = (type: keyof IGuardArrivalReport, index: number | string) => {
    onChangeMultiSelectString(type, index,
      (result, type) => {
        onQueryParamChanged(result, type as any)
      }
    )
  }

  const onDownload = async () => {
    // TODO: Check functionality after back-end updates
    showLoader(true)
    await dowloandFile('report/guard-arrival',
    Reports.excel, Reports.download, '', '',
    `&${generateQueryParams().split("?")[1]}`, true)
    showLoader(false)  
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    fetchReports(1, 24, generateQueryParams())
    event.preventDefault();
  }

  const onTableRowClick = (rowItem: IGuardArrivalReport) => {
    rowItem && setReportDetails(rowItem)
    setShowDetailsModal(true)
  }

  const onModalCancel = () => {
    setReportDetails(undefined)
    setShowDetailsModal(false)
  }

  return (
    <Layout
      breadcrumbs={{
        links: [{
            title: 'Reports',
            link: 'reports'
        }],
        currentPageTitle: 'Guard Arrival Reports'
      }}
      className={'reports-screen'}
    >
      <div className="row mb-3">
        <div className="col-12">
          <SectionTitle title={'Guard Arrival Reports'}/>
        </div>
      </div>

      <form
        onSubmit={handleSubmit}
      >
        <div className="row">
          {/* Start CheckBoxes */}
          <div className="col-md-4 form-group mb-md-4">
            <label htmlFor="firstStreet">Reported:</label>
            <div>
            {
              reportedOptions ? reportedOptions.map(option => (
                !option.isDisabled &&
                <ReportOptCheckBox
                  optionType={queryParams.type}
                  option={option}
                  handleCheckBox={handleCheckBox}
                  optionKey={"type"}
                  key={option.id}
                />
              )) : null
            }
          </div>
        </div>
        {/* End CheckBoxes */}


          {/* Start Date Filters */}
          <div
            className="col-md-4 form-group mb-md-4">
              <div className="row d-flex flex-column">

                <div className="col-12 mb-3">
                  <label>From: </label>
                  <div
                      className="react-datepicker-custom-wrapper react-datepicker-custom-wrapper--full-width d-inline-block w-100">
                      <DatePicker
                          selected={datePickerFormat(queryParams.startDate)}
                          onChange={date => {
                            onQueryParamChanged(date ? setHoursAndMinutes(date as Date) : '', 'startDate')
                            changeDatapicker(1);
                          }}
                          dateFormat="dd/MM/yyyy"
                          onInputClick={() => changeDatapicker(1)}
                          open={showDatapicker[1]}
                          onClickOutside={() => changeDatapicker(1)}
                          placeholderText="dd/mm/yyyy"
                      />
                      <div onClick={() => changeDatapicker(1)}><SVGCalendar/></div>
                  </div>
                </div>
                <div className="col-12">
                  <label>To: </label>
                  <div
                    className="react-datepicker-custom-wrapper react-datepicker-custom-wrapper--full-width d-inline-block w-100">
                    <DatePicker
                      selected={datePickerFormat(queryParams.endDate)}
                      onChange={date => {
                        onQueryParamChanged(date ? setHoursAndMinutes(date as Date) : '', 'endDate')
                        changeDatapicker(2);
                      }}
                      dateFormat="dd/MM/yyyy"
                      onInputClick={() => changeDatapicker(2)}
                      open={showDatapicker[2]}
                      onClickOutside={() => changeDatapicker(2)}
                      placeholderText="dd/mm/yyyy"
                    />
                    <div onClick={() => changeDatapicker(2)}><SVGCalendar/></div>
                  </div>
                </div>

              </div>
          </div>
          {/* End Date Filters */}

          <div className="col-md-4 mb-md-4">

            <div className="row">
              {/* Start Job Name Search */}
              <div className="col-12 form-group shfit-autosegest mb-md-3">
                <label htmlFor="firstStreet">Job Name or ID:</label>
                  <AutocompleteField
                    showButton={false}
                    includes={'Job'}
                    isAsync={true}
                    defaultValue={!queryParams.jobNameOrId ? true : false}
                    placeholder='Enter Job Name or ID'
                    onSearch={async (value) => {
                      onQueryParamChanged(value, 'jobNameOrId')
                    }}
                    onChange={(value) => onQueryParamChanged(value, 'jobNameOrId')}
                    onClear={() => onQueryParamChanged('', 'jobNameOrId')}/>
                </div>
              {/* End Job Name Search */}

              {/* Start Guard Name Search */}
              <div className="col-12 form-group shfit-autosegest">
                <label htmlFor="firstStreet">Guard Name or Employee ID:</label>
                <AutocompleteField
                  showButton={false}
                  defaultValue={!queryParams.guardNameOrEmployeeNumber ? true : false}
                  includes={'Guard'}
                  isAsync={true}
                  placeholder='Guard Name or Employee ID'
                  onSearch={async (value) => {
                      onQueryParamChanged(value, 'guardNameOrEmployeeNumber')
                  }}
                  onChange={(value) => onQueryParamChanged(value, 'guardNameOrEmployeeNumber')}
                  onClear={() => onQueryParamChanged('', 'guardNameOrEmployeeNumber')}/>
              </div>
              {/* End Guard Name Search */}
            </div>

          </div>

        </div>

        <div className="row mb-5">
          <div className="col-12 text-right">
            <button
              type="submit"
              className="btn btn-outline-aqua-blue mr-2"
              >
              Update Report
            </button>
            <button
              type="button"
              className="btn btn-aqua-blue"
              onClick={onDownload}
            ><SVGDownload /></button>
          </div>
        </div>

      </form>

      {
        useMemo(() => (
          <div className="row">
            <div className="col-12">
              <DataTable
                thead={guardArrivalReportsThead.thead}
                tbody={arrivalReports ? arrivalReports.map(report => ({
                  id: report.id,
                  date: momentDateFormat(report.date) || "",
                  time: momentHoursFormat(report.date) || "",
                  guardName: report.guardName,
                  employeeId: report.employeeId,
                  jobId: report.jobId,
                  shiftPeriod: matchOptionWithName(report.shiftPeriod, shiftPeriods),
                  type: matchOptionWithName(report.type, reportedOptions),
                  message: report.message,
                  location: report.location,
                  shiftStartDate: report.shiftStartDate
                })) : []}
                isLoading={isLoading}
                ignoreCols={[0, 9, 10]}
                currentPage={currentPage}
                itemsCount={allRecords}
                itemsPerPage={+perPage}
                onPager={page => onPagination(page)}
                onPerPage={value => onPerPage(value)}
                onColClick={(_key, _id, _item, _idIndx, trIndex) => trIndex !== undefined && onTableRowClick(arrivalReports[trIndex])}
                onSort={(orderBy, desc) => onSortCall(orderBy, desc, queryParams.keyWord)}
                defaultSortedColumn={0}
                tableName={"Arrival Reports"}
                tableClass={'table-info--notFixed table-arrival-reports table-info-hover'}
                pagination
                sortOnBackend
                showTableLengthData
              />
            </div>
          </div>
        ), [arrivalReports, isLoading, currentPage, allRecords, perPage,
          shiftPeriods, reportedOptions, onPagination, onPerPage, onSortCall, queryParams.keyWord])
      }

      {
        showDetailsModal && reportDetails && 
        <ReportsDetailsModal
          report={reportDetails}
          title={'Guard Arrival Report Details'}
          onCancel={onModalCancel}
          vocabulary={[shiftPeriods, reportedOptions]}
          typeLabel={'Arrival Report'}
        />
      }
    </Layout>
  )
}

export default ArrivalReports
