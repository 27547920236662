import React, {FC, useState, useContext, useEffect} from 'react'
import InputMask from 'react-input-mask';

import { IBaseModalProps } from '../../../common/interfaces/IModal'
import { NotificationTypes } from '../../../common/interfaces/INotification';
import { School } from '../../../common/models/Administration'
import { IValidationBody, ValidationRules } from '../../../common/interfaces/IValidation';
import { AddNewItemEnum } from '../../../common/enums/AddNewItemEnum';
import { IVocabulary } from '../../../common/interfaces/vocabulary/IVocabulary';
import AppContext from '../../../context/AppContext';

import BaseModal from '../BaseModal'
import CustomSelectControl from '../../custom-select/CustomSelectControl'
import { highlightError, removeHighlightError, validateFiled, validatePhoneNumber } from '../../../utils/Validation';
import AddNewItemModal from '../AddNewItemModal'

import { useOnIputChange } from '../../../customHooks/useOnInputChange'
import { useAddNewItem } from '../../../customHooks/useAddNewItem';
import {IJobSchools} from "../../../common/interfaces/jobs/IJob";
import { ISchool } from '../../../common/interfaces/administration/ISchool';

interface Props extends IBaseModalProps {
    vocabulary: IVocabulary[]
    schoolBoards: Array<{id: number, name: string}>
    boardsCallback: (option: any) => void
    selectBoard?: IJobSchools | undefined
    schoolToEdit?: ISchool
    isEditMode?: boolean
}

const AddSchoolModal: FC<Props> = ({ onCancel, onSubmit, vocabulary, schoolBoards, boardsCallback, selectBoard, isEditMode = false, schoolToEdit }) => {
    const {onChange , hookState: formData, setHookState} = useOnIputChange(schoolToEdit ? schoolToEdit : new School())
    const [showMainModal, setShowMainModal] = useState<boolean>(true)
    const appContext = useContext(AppContext)
    const { showAddNewItemModal, newItemModalTypo, onAddNewItemClick, onAddNewItemSubmit, onAddNewItemModalClose } = useAddNewItem();
    const [selectedBoardId, setSelectedBoardId] = useState(selectBoard?.schoolBoardId);
    
    useEffect(() => {
        if (selectBoard && selectBoard.schoolBoardId) {
            const board = `${selectBoard.schoolBoardId}`
            setHookState({
                ...formData,
                schoolBoardId: board
            })
        }
        if (schoolToEdit && schoolToEdit.schoolBoardId) {
            setHookState({
                ...formData,
                schoolBoardId: schoolToEdit.schoolBoardId
            })
        }
    }, [schoolToEdit, selectBoard])

    const validateClientFields = (callback: () => void) => {
        let errors: IValidationBody[] = [];

        for (let [field, value] of Object.entries(formData)) {
            const skipFields = ['id', "secondStreet"]
            if (!skipFields.includes(field)) {
                errors = [...errors, ...validateFiled(value as string, field, ValidationRules.required)]
            }
        }

        errors = [...errors, ...validateFiled(formData.postalCode as string, "postalCode", ValidationRules.isValidPostalCode)]

        if (errors.length !== 0) {
            appContext.showNotification(NotificationTypes.warning, "Please fill in all fields correctly")
            highlightError(errors)
        } else {
            removeHighlightError()
            callback()
        }
    }

    const onSaveDataClick = () => {
        onSubmit && validateClientFields(() => onSubmit(formData))
    }

    const newSchoolBoardAdded = (option: { id: number, boardName: string }) => {
        onChange([
            { value: option.id, name: "schoolBoardId" },
            {value: option.boardName, name: "boardName"},
        ])
        boardsCallback(option)
    }

    return (
        <>
        <BaseModal
            show={showMainModal}
            onCancel={() => onCancel()}
            onSubmit={() => onSaveDataClick()}
            submitBtnText={'Save'}
            title={isEditMode ? 'Edit School' : 'Add a School'}
        >

            <form autoComplete="off" onSubmit={e => e.preventDefault()}>
                <div className="row">
                    <div className="col-12 form-group">
                        <label className="required" htmlFor="schoolName">School Name</label>
                        <input value={formData.schoolName} onChange={e => onChange(e)} name="schoolName" type="text" id="schoolName" className="form-control" placeholder="Enter school name in full" autoComplete="new-password"/>
                    </div>


                    <div className="col-12 form-group">
                        <label className="required" htmlFor="firstStreet">Address 1: </label>
                        <input value={formData.firstStreet} onChange={e => onChange(e)} name="firstStreet" type="text" id="firstStreet" className="form-control" placeholder="Enter Address 1" autoComplete="new-password"/>
                    </div>

                    <div className="col-12 form-group">
                        <label htmlFor="secondStreet">Address 2: </label>
                        <input value={formData.secondStreet} onChange={e => onChange(e)} name="secondStreet" type="text" id="secondStreet" className="form-control" placeholder="Enter Address 2" autoComplete="new-password"/>
                    </div>

                    <div className="col-12 form-group">
                        <label className="required mb-0" htmlFor="city">City: </label>
                        <input value={formData.city} onChange={e => onChange(e)} name="city" type="text" id="city" className="form-control"  placeholder="Enter City" autoComplete="new-password"/>
                    </div>


                    <div className="col-md-12 form-group">
                        <label className="required mb-0">Province: </label>
                        <CustomSelectControl
                            options={vocabulary}
                            onChange={value => onChange(value, "province")}
                            value={formData.province}
                            placeholder={"Select a Province"}
                            className={`custom-react-select--province`}
                        />
                    </div>

                    <div className="col-12 form-group">
                        <label className="required mb-0">Postal Code: </label>
                        <InputMask
                            mask={"a9a 9a9"}
                            type="text"
                            name="postalCode"
                            className="form-control"
                            id="postalCode"
                            placeholder="Enter postal code"
                            autoComplete="new-password"
                            value={formData.postalCode}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
                            onBlur={e => validatePhoneNumber(e)}
                        />
                    </div>


                    <div className="col-md-12 form-group">
                        <div className="add-item-group d-flex flex-row align-items-center justify-content-between">
                            <label className="mb-0 required">School Board: </label>
                            <button
                                type="button"
                                className="btn btn-transparent p-0 text-primary ml-auto"
                                onClick={() => {
                                    setShowMainModal(false)
                                    onAddNewItemClick(AddNewItemEnum.board)
                                    setSelectedBoardId(0);
                                }}
                            >
                                Add School Board</button>
                        </div>
                        <CustomSelectControl
                            options={schoolBoards}
                            onChange={(value, label) => onChange([
                                {value: value, name: "schoolBoardId"},
                                {value: label, name: "boardName"},
                            ])}
                            value={selectedBoardId ? selectedBoardId : formData.schoolBoardId}
                            placeholder={"Select a School Board"}
                            className={`custom-react-select--schoolBoardId`}
                        />
                    </div>

                </div>
            </form>

        </BaseModal>

        { showAddNewItemModal && <AddNewItemModal
            showModal={showAddNewItemModal}
            titleBody={newItemModalTypo.title}
            placeholderText={newItemModalTypo.placeholder}
            onCancel={() => {
                onAddNewItemModalClose()
                setShowMainModal(true)
            }}
            onSubmit={newItem => {
                onAddNewItemSubmit({ itemName: newItem }, newSchoolBoardAdded)
                setShowMainModal(true)
            }}
        /> }

        </>
    )
}

export default AddSchoolModal
