import React, { FC, useContext, useState } from 'react'
import BaseModal from '../BaseModal'
import { momentHoursFormat, momentDateFormat } from '../../../utils/DateFormatting';
import { IJobReports, IJobsRightNow, IUpdateShiftBody } from 'common/interfaces/jobs/IJob';
import AutocompleteField from '../../autosuggestField/AutocompleteField';
import CustomSelectControl from '../../custom-select/CustomSelectControl';
import { checkIfIsOtherReason } from '../../../utils/CheckOtherReason';
import UpdateShiftStatusContext from '../../../context/dashboard/UpdateShiftStatusContext';
import { IValidationBody, ValidationRules } from 'common/interfaces/IValidation';
import AppContext from '../../../context/AppContext';
import { highlightError, removeHighlightError, validateFiled } from '../../../utils/Validation';
import { useReason } from '../../../customHooks/useReason';
import { NotificationTypes } from 'common/interfaces/INotification';
import { IBaseModalProps } from 'common/interfaces/IModal';
// import CustomTimePicker from '../../custom-time-picker/CustomTimePicker';
import { IVocabulary } from 'common/interfaces/vocabulary/IVocabulary';
import { IShiftJobReportModel } from 'common/interfaces/IDataFilter';

interface IProps extends IBaseModalProps { 
    shift: IJobsRightNow | IJobReports | IShiftJobReportModel
    body: IUpdateShiftBody
}

const UpdateShiftStatusReplaceModal: FC<IProps> = React.memo(({ onSubmit, onCancel, shift, body }) => {
    // const { hookState: fields, onChange } = useOnIputChange(body)
    const [fields, setFields] = useState<IUpdateShiftBody>(body)
    const { showNotification } = useContext(AppContext)

    const onChange = (value: string | number, key: string) => {
        setFields({ ...fields, [key]: value })
    }
    const { vocabulary } = useContext(UpdateShiftStatusContext)
    const hook = useReason(vocabulary)

    const onFormSubmit = () => {
        let errors: IValidationBody[] = [];
        errors = [...errors, ...validateFiled(fields.guardId, 'guardId', ValidationRules.notZero)]
        hook.reasonIsEmpty('reasonForReplacement', 'reasonForReplacement--other', false, (error: IValidationBody[]) => {
            errors = [...errors, ...error]
        })
        if (errors.length !== 0) {
            highlightError(errors)
            showNotification(NotificationTypes.warning, 'Please fill in all required fields')
        } else {
            removeHighlightError()
            onSubmit && onSubmit({ guardId: fields.guardId, reason: (hook.reason as any).id || hook.reason });
        }
    }

    return (
        <BaseModal
            show={true}
            title={`${shift.guardName ? 'Replace ' : 'Add Guard '}<span class="font-weight-bold">${shift.guardName}</span> for <span class="font-weight-bold">${shift.jobName ? shift.jobName : `${shift.firstStreet} ${shift.secondStreet}`}</span>
            <br /> <h5 class="font-weight-bold">${momentDateFormat(shift.startDate)} - Shift ${momentHoursFormat(shift.timeFrom)} - ${momentHoursFormat(shift.timeTo)}</h5>`}
            onSubmit={onFormSubmit}
            onCancel={onCancel}
            submitBtnText={"Confirm"}
            className={"available-guards-modal"}
        >
           <form className="row">
                {
                    <div className="col-md-6 form-group">
                        <label className="required">Search by Name:</label>
                        <AutocompleteField
                            includes={'Guard'}
                            isAsync={true}
                            placeholder='Guard Name'
                            onSearch={(_value, id) => id && onChange(id, 'guardId')}
                            onClear={() => onChange('', 'guardId')}
                            className={'form-control--searchByName w-100 react-autosuggest-wrapper--guardId'}
                            showButton={false}
                        />
                    </div>
                }

                {
                    React.useMemo(() => (
                        <div className="col-md-6">
                            <div className="form-group mb-0">
                                <label className="required">Reason for replacement:</label>
                                <CustomSelectControl
                                    value={hook.isOtherReason ? 0 : (hook.reason as IVocabulary).id}
                                    placeholder={"Select a reason"}
                                    options={vocabulary || []}
                                    onChange={(e) => hook.onReasonSelect(e)}
                                    className={`custom-react-select--reasonForReplacement`}
                                />
                            </div>
                            {
                                hook.isOtherReason &&
                                <div className="form-group mt-3">
                                    <textarea
                                    className="form-control"
                                    rows={5}
                                    name={'reasonForReplacement--other'}
                                    placeholder="Type other reason"
                                    value={checkIfIsOtherReason(hook.reason) ? hook.reason as string: ""}
                                    onChange={e => hook.setReason(e.target.value)}
                                    ref={hook.textareaRef}
                                    ></textarea>
                                </div>
                            }
                        </div>
                    ), [hook, vocabulary])
                }
                
                {/* { (shift.shiftStatus.checkIn || shift.shiftStatus.absent) && 
                    <div className="col-md-6 form-group d-flex flex-column align-items-start">
                        <label>Check In Time: </label>
                        <CustomTimePicker
                            onChange={time => onChange(timeWithoutSecondsFormat(time), 'checkInDateTime')}
                            value={fields.checkInDateTime ? UTCtimeToLocal(fields.checkInDateTime) : UTCtimeToLocal()}
                            index={1}
                        />
                    </div>
                }

                { (shift.shiftStatus.checkOut || shift.shiftStatus.absent) && 
                    <div className="col-md-6 form-group d-flex flex-column align-items-start">
                        <label>Check Out Time:</label>
                        <CustomTimePicker
                            onChange={time => onChange(timeWithoutSecondsFormat(time), 'checkOutDateTime')}
                            value={fields.checkOutDateTime ? UTCtimeToLocal(fields.checkOutDateTime) : UTCtimeToLocal()}
                            index={1}
                        />
                    </div>
                } */}

            </form>

        </BaseModal>
    )
})

export default UpdateShiftStatusReplaceModal
