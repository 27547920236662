import React, {FC, useContext, useState} from 'react'
import {useEffect} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import { SVGInfo, SVGTrash } from '../../../assets/icons/SvgIcons'
import {ICityDropdown} from '../../../common/interfaces/dropdowns/ICityDropdown'
import {IClientContactsDropdown} from '../../../common/interfaces/dropdowns/IClientContactsDropdown'
import {IClientDropdown} from '../../../common/interfaces/dropdowns/IClientDropDown'
import {ISchoolYearDropdown} from '../../../common/interfaces/dropdowns/ISchoolYearDropdown'
import {IZoneDropdown} from '../../../common/interfaces/dropdowns/IZoneDropdown'
import JobContext from '../../../context/jobs/JobContext'
import WizardFormContext from '../../../context/jobs/WizardFormContext'
import {momentDateFormat} from '../../../utils/DateFormatting'
import { useAddNewItem } from '../../../customHooks/useAddNewItem'
import useGoogleApi from '../../../customHooks/useGoogleApi'
import CustomSelectControl from '../../custom-select/CustomSelectControl'
import CustomTooltip from '../../custom-tooltip/CustomTooltip'
import GeofenceMap from "../GeofenceMap";
import AddNewItemModal from "../../modal/AddNewItemModal";
import { AddNewItemEnum } from '../../../common/enums/AddNewItemEnum'
import { AuthService } from '../../../common/auth/AuthService'
import { Roles } from '../../../common/enums/Roles'
import AppContext from '../../../context/AppContext'
import AddEditUserModal from '../../modal/users/AddEditUserModal'
import useSaveClientContact from '../../../customHooks/useSaveClientContact'
import {ISchoolBoardsDropdown} from "../../../common/interfaces/dropdowns/ISchoolDropdown";
import {SchoolDropdownsState} from "../../../pages/jobs/AddEditJob";
import {generateFullName} from "../../../utils/GenerateFullName";
import {IClientContact} from '../../../common/interfaces/clients/IClient';
import AddSchoolModal from "../../modal/administration/AddSchoolModal";
import {ISchool} from "../../../common/interfaces/administration/ISchool";
import {IManageModalsState} from "../../../common/interfaces/administration/IAdministrationStates";
import {NotificationTypes} from "../../../common/interfaces/INotification";
import {useVocabulary} from "../../../customHooks/vocabulary/useVocabulary";
import {VocabularyEnum} from "../../../common/enums/VocabularyEnum";
import {DataService} from "../../../common/services/DataService";
import {useTableList} from "../../../customHooks/useTableList";
import ResourceDataService from "../../../common/services/ResourceDataService";
import { LoadScript } from '@react-google-maps/api'

type Props = {
    clients: IClientDropdown[]
    cities: ICityDropdown[]
    addNewItemToDropdown: (option: any, type: AddNewItemEnum) => void
    schoolBoards: ISchoolBoardsDropdown[]
    schoolDropdown: any
    setSchoolDropdown: (school: SchoolDropdownsState) => void
    onChangeSchool: (event: React.ChangeEvent<HTMLInputElement> | string | null, fieldName?: string | null, id?: number) => void;
    onSubmitSchool: () => void
    onRemoveItemFromCalendar: (schoolId: number) => void
    updateSchools: () => void
    getOptionsForDropdowns: () => void,
}

const CreateJobTab: FC<Props> = ({clients, cities, addNewItemToDropdown, schoolBoards, schoolDropdown, setSchoolDropdown, onChangeSchool, onSubmitSchool, onRemoveItemFromCalendar, updateSchools, getOptionsForDropdowns}) => {
    const [clientContactOpts, setClientContactOpts] = useState<IClientContactsDropdown[]>([] as IClientContactsDropdown[]);
    const [addContactModal, setAddContactModal] = useState<boolean>(false)
    const [schoolYeartOpts, setSchoolYeartOpts] = useState<ISchoolYearDropdown[]>([] as ISchoolYearDropdown[]);
    const [zoneOpts, setZoneOpts] = useState<IZoneDropdown[]>([] as IZoneDropdown[]);
    const [locataionFirst, setLocationFirst] = useState();
    const history = useHistory();
    const {stepHandler, saveJob} = useContext(WizardFormContext)
    const { showAddNewItemModal, newItemModalTypo, onAddNewItemClick, onAddNewItemSubmit, onAddNewItemModalClose, currentItemType } = useAddNewItem();
    const { getLocationGeocoding, setCount, count } = useGoogleApi()
    const appContext = useContext(AppContext)
    const [modal, setModal] = useState<IManageModalsState>({ add: false, confirm: false, upload: false, deactivate: false, deactivateSuccess: false, index: 0 })
    const { vocabulary: provinces } = useVocabulary(VocabularyEnum.province, true)
    const schoolSvc = new DataService<ISchool>({ url: 'school' })
    const [schoolBoardLoc, setSchoolBoardsLoc] = useState<Array<{id: number, name: string}>>([{id: 0, name: ''}])
    const {job, onChange } = useContext(JobContext)
    const { saveClientContact } = useSaveClientContact()
    const params = useParams<{ id: string }>();
    const dropSvc = new ResourceDataService<ISchoolBoardsDropdown[]>({url: "school/dropdown"})
    const { tableData, setTableData } = useTableList<ISchool>('school')
    const [ libraries ] = useState<("places" | "drawing" | "geometry" | "localContext" | "visualization")[]>(['places']);
    useEffect(() => {
        if (clients.length === 0) return;
        setCount(count + 1)
        getLocationGeocoding(true, job, cities, setLocationFirst)
    }, [job.firstStreet, job.cityId, clients, cities])

    useEffect(() => {
        if (clients.length === 0) return;
        getLocationGeocoding(false, job, cities, setLocationFirst)
    }, [job.secondStreet, job.cityId, clients, cities])

    useEffect(() => {
        if (locataionFirst && job.firstStreet.length >= 2) {
            const {lng, lat} = locataionFirst!;
            onChange([
                {value: lat, name: "latitude"},
                {value: lng, name: "longititude"},
            ])
        }
        if (locataionFirst && job.firstStreet === '') {
            onChange([
                {value: '', name: "latitude"},
                {value: '', name: "longititude"},
            ])
        }

    }, [job.firstStreet, locataionFirst])

    useEffect(() => {
        if (clients.length === 0) return;
        const client = clients.find(client => client.id === job.clientId)
        if (client && (client.schoolYears.length !== 0)) {
            setSchoolYeartOpts(client.schoolYears)
            setClientContactOpts(client.clientContacts)

            // TODO: Refactor logic
            const hasYearInDropdown =  client.schoolYears.find(year => year.id === Number(job.schoolYearId)) ? true : false;
            const schoolYearIsNull = job.schoolYearId === null ? true : false;
            const schoolYearToSet = schoolYearIsNull ? client.schoolYears[0].id : (hasYearInDropdown ? job.schoolYearId : client.schoolYears[0].id)

            const hasContactInDropdown = client.clientContacts.find(client => client.id === Number(job.clientContactId)) ? true : false;
            const contactIsNull = job.clientContactId === null ? true : false;
            let getPrimaryContact = contactIsNull ? client.clientContacts.find(client => client.setAsPrimary) : undefined;
            getPrimaryContact = getPrimaryContact ? getPrimaryContact : client.clientContacts[0];  
            const clientContactToSet = contactIsNull ? getPrimaryContact.id : (hasContactInDropdown ? job.clientContactId : client.clientContacts[0].id)

            onChange([
                {value: schoolYearToSet, name: "schoolYearId"},
                {value: clientContactToSet, name: "clientContactId"},
            ])
        } else {
            setSchoolYeartOpts([])
            setClientContactOpts([])
            onChange([
                {value: null, name: "schoolYearId"},
                {value: null, name: "clientContactId"},
            ])
        }
    }, [job.clientId, clients])

    useEffect(() => {
        if (schoolYeartOpts.length === 0) return;
        const currentOpt = schoolYeartOpts.find(opt => opt.id === job.schoolYearId)
        currentOpt && onChange([{value: currentOpt.endDate, name: "schoolYearDate"}])
    }, [job.schoolYearId, schoolYeartOpts])

    useEffect(() => {
        if (cities.length === 0) return;
        const city = cities.find(city => city.id === job.cityId)

        if (city && city.zoneViewModels.length !== 0) {
            setZoneOpts(city.zoneViewModels)
        } else {
            setZoneOpts([])
            onChange('', "zoneId")
        }
    }, [job.cityId, cities])

    const updateSelectSchool = async (type?: string) => {
        if (job.schools.length !== 0 || schoolDropdown.schoolNumEditing !== undefined) {
            const school = job.schools.find(school => school.uid === schoolDropdown.schoolNumEditing);
            if (school) {
                let board: any;
                if (type) {
                    const res = await dropSvc.getAll();
                    board = res.data.find(board => board.id === school.schoolBoardId);
                } else {
                    board = schoolBoards.find(board => board.id === school.schoolBoardId);
                }

                if (board) {
                    let newSchoolOpts = []
                    let schoolDropdownCopy = [...schoolDropdown.schoolOpts || []];
                    if (schoolDropdown.schoolOpts === undefined) {
                        newSchoolOpts.push({
                            schoolNum: schoolDropdown.schoolNumEditing,
                            options: board.schools
                        })
                    } else {
                        schoolDropdownCopy = schoolDropdownCopy.map(item => {
                            if (item.schoolNum === schoolDropdown.schoolNumEditing) {
                                return {
                                    schoolNum: item.schoolNum,
                                    options: board.schools
                                }
                            }
                            return item
                        })
                    }
                    setSchoolDropdown({ ...schoolDropdown, schoolOpts: [...schoolDropdownCopy, ...newSchoolOpts]})
                }
            }
        }
    }

    useEffect(() => {
        updateSelectSchool()
    }, [JSON.stringify(job.schools)])


    const getSchoolOptions = (index?: number) => {
        // TODO: Refactor
        if (params.id !== undefined) {
            return (schoolDropdown.schoolOpts && index !== undefined) ? schoolDropdown.schoolOpts[index].options : []
        } else {
            return (schoolDropdown.schoolOpts && index !== undefined) ? schoolDropdown.schoolOpts[index].options : []
        }
    }

    const onSchoolBoardSelect = (value: string, id: number) => {
        setSchoolDropdown({ ...schoolDropdown, schoolNumEditing: id})
        onChangeSchool(value, "schoolBoardId", id)
    }
    const addAnotherSchool = () => {
        // if (schoolDropdown.schoolOpts && job.schools[job.schools.length - 1].schoolId) {
            if (schoolDropdown.schoolOpts) {
            let schoolOptionsCopy = Array.from([...schoolDropdown.schoolOpts]);
            if (schoolOptionsCopy) {
                const lastItem = {...schoolOptionsCopy[schoolDropdown.schoolOpts.length - 1]}
                lastItem.schoolNum = lastItem.schoolNum + 1;
                setSchoolDropdown({ ...schoolDropdown, schoolOpts: [...schoolDropdown.schoolOpts, ...[lastItem]]})
                onSubmitSchool()
            }
        }
    }

    const draggableMarker = (lat: any, lng: any) => {
        onChange([
            {value: lat, name: "latitude"},
            {value: lng, name: "longititude"},
        ])
    }

    const saveContact = async (clientContact: IClientContact, clientId: number | null) => {
        saveClientContact(clientContact, clientId, (recordId) => {
            const client = clients.find(client => client.id === job.clientId)
            client && setClientContactOpts([
                ...client.clientContacts,
                ...[{ id: recordId, name: generateFullName(clientContact), clientId: (clientId as number), email: clientContact.email, phone: '' }]
            ])
            onChange([{value: recordId, name: "clientContactId"}])
            setAddContactModal(false)
        })
    }

    const saveNewSchool = async (newSchool: ISchool) => {
        appContext.showLoader(true)
        try {
            const response = await schoolSvc.create(newSchool)
            setTableData([
                ...tableData,
                ...[{
                    id: response.data,
                    schoolName: newSchool.schoolName,
                    firstStreet: newSchool.firstStreet,
                    secondStreet: newSchool.secondStreet,
                    city: newSchool.city,
                    province: newSchool.province,
                    postalCode: newSchool.postalCode,
                    boardName: newSchool.boardName,
                    schoolHasJobs: false
                }]
            ].sort((a, b) => a.schoolName.toLowerCase().localeCompare(b.schoolName.toLowerCase())))
            await updateSelectSchool('action')
            appContext.showLoader(false)
            appContext.showNotification(NotificationTypes.success, `${newSchool.schoolName} has been added`)
            setModal({...modal, add: false})

        } catch (error: any) {
            appContext.showLoader(false)
            appContext.showNotification(NotificationTypes.danger, error.message)
        }
    }

    const newSchoolBoardAdded = async (option: any) => {
        setSchoolBoardsLoc([
            ...schoolBoardLoc,
            ...[{
                id: option.id,
                name: option.boardName
            }]
        ].sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())))
        await updateSchools()
    }

    const onGeofenceBlur = (ev: React.FocusEvent<HTMLInputElement>) => {
        if (+ev.target.value < 25) {
            appContext.showNotification(NotificationTypes.warning, "Geofence can't be less than 25m")
            onChange("25", "geoFence")
        } else if (+ev.target.value > 150) {
            appContext.showNotification(NotificationTypes.warning, "Geofence can't be greater than 150m")
            onChange("150", "geoFence")
        } else {
            onChange(ev.target.value)
        }
    }

    return (
        <LoadScript libraries={libraries} googleMapsApiKey={process.env.REACT_APP_MAP_KEY as string}>
            <div className="row">
                <div className="col-12 col-xl-9 mx-auto">
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label className="required">Client</label>
                            <CustomSelectControl
                                options={clients.map(client => (
                                    {
                                        id: client.id,
                                        name: client.name
                                    }
                                ))}
                                onChange={val => onChange(val, "clientId")}
                                value={job.clientId}
                                placeholder={"Select a Client"}
                                className={`custom-react-select--clientId`}
                            />
                        </div>
                        <div className="col-md-6 form-group">
                            <div className="add-item-group d-flex flex-row align-items-center justify-content-between">
                                <label className="required">Client Contact</label>
                                {/* {AuthService.checkIfUserHasPermission([Roles.Admin], appContext.currentUser.role) && */}
                                <button className="btn btn-transparent p-0 text-primary" onClick={() => setAddContactModal(true)}>Add a Contact</button>
                                {/* } */}
                            </div>
                            <CustomSelectControl
                                options={clientContactOpts}
                                onChange={val => onChange(val, "clientContactId")}
                                value={job.clientContactId}
                                placeholder={"Select a Client Contact"}
                                className={`custom-react-select--clientContactId`}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 form-group">
                            <div className="add-item-group d-flex flex-row align-items-center justify-content-between">
                                <label className="required" htmlFor="cityName">Select a City</label>
                                {AuthService.checkIfUserHasPermission([Roles.Admin], appContext.currentUser.role) &&
                                <button className="btn btn-transparent p-0 text-primary" onClick={() => onAddNewItemClick(AddNewItemEnum.city)}>Add a City</button>
                                }
                            </div>
                            <CustomSelectControl
                                options={cities}
                                onChange={value => onChange(value, "cityId")}
                                value={job.cityId}
                                placeholder={"Select a City"}
                                className={`custom-react-select--cityId`}
                            />
                        </div>
                        <div className="col-md-6 form-group">
                            <div className="add-item-group d-flex flex-row align-items-center justify-content-between">
                                <label className="required">Select a Zone ID</label>
                                {AuthService.checkIfUserHasPermission([Roles.Admin], appContext.currentUser.role) &&
                                <button className="btn btn-transparent p-0 text-primary" onClick={() => onAddNewItemClick(AddNewItemEnum.zone)}>Add a Zone</button>
                                }
                            </div>
                            <CustomSelectControl
                                options={zoneOpts}
                                onChange={val => onChange(val, "zoneId")}
                                value={job.zoneId}
                                placeholder={"Select a Zone"}
                                className={`custom-react-select--zoneId`}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label className="required" htmlFor="firstStreet">Street 1 Name</label>
                            <input type="text" name="firstStreet" className="form-control" id="firstStreet"
                                   placeholder="Enter primary street name" autoComplete="nope"
                                   value={job.firstStreet}
                                   onChange={e => onChange(e)}
                            />
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="secondStreet">Street 2 Name</label>
                            <input type="text" name="secondStreet" className="form-control" id="secondStreet"
                                   placeholder="Enter secondary street name" autoComplete="nope"
                                   value={job.secondStreet}
                                   onChange={e => onChange(e)}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 form-group d-flex flex-column align-items-start">
                            <label htmlFor="jobName">Location Name</label>
                            <div className="d-flex align-self-stretch">
                                <input type="text" name="jobName" className="form-control" id="jobName" placeholder="Enter location name"
                                        autoComplete="nope"
                                        value={job.jobName}
                                        onChange={e => onChange(e)}
                                />
                            </div>
                        </div>
                    </div>

                    {job.latitude && job.longititude ? <div className="col-md-12 pb-3">
                        <GeofenceMap
                            lat={job.latitude !== null ? +job.latitude : 0}
                            lng={job.longititude !== null ? +job.longititude : 0}
                            radius={0}
                            draggable={true}
                            draggableMarker={draggableMarker}
                            removeLoadScript
                        />
                    </div> : null}

                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label className="required" htmlFor="latitude">Location Latitude</label>
                            <input type="text" name="latitude" className="form-control" id="latitude"
                                   placeholder="Enter Location Latitude" autoComplete="nope"
                                   value={job.latitude || ""}
                                   onChange={e => onChange(e)}
                            />
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="required" htmlFor="longititude">Location Longitude</label>
                            <input type="text" name="longititude" className="form-control" id="longititude"
                                   placeholder="Enter Location Longitude" autoComplete="nope"
                                   value={job.longititude || ""}
                                   onChange={e => onChange(e)}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 form-group d-flex flex-column align-items-start">
                            <label className="required" htmlFor="jobId">Job ID</label>
                            <div className="d-flex align-self-stretch">

                                <input type="text" name="jobId" className="form-control" id="jobId" placeholder="XXXXXXX"
                                        autoComplete="nope"
                                        value={job.jobId}
                                        onChange={e => onChange(e)}
                                    />
                                    <CustomTooltip
                                        bodyText={"Job ID is from the client"}>
                                        <button
                                            type="button"
                                            className="btn btn-transparent px-0 ml-3"
                                        >
                                            <SVGInfo />
                                        </button>
                                    </CustomTooltip>
                            </div>
                        </div>
                        {
                            job.clientId &&
                            <div className="col-md-6 form-group">
                                <label className="required">School year</label>
                                <CustomSelectControl
                                    options={schoolYeartOpts.map(year => ({
                                        id: year.id,
                                        name: `${momentDateFormat(year.startDate)} - ${momentDateFormat(year.endDate)}`
                                    }))}
                                    onChange={(value) => onChange(value, "schoolYearId")}
                                    value={job.schoolYearId}
                                    placeholder={"Select a School Year"}
                                    className={`custom-react-select--schoolYearId`}
                                />
                            </div>
                        }
                    </div>


                    <div className="row mt-4 ">
                        <div className="col-12 mx-auto ">
                            <h5 className="mb-4">Related Schools</h5>
                            <section>
                                { job.schools.length !== 0 && job.schools.map((school, indx) =>
                                    <div className="row" key={school.id ? school.id : school.uid}>
                                        {/* <div className="col-12 mb-3 font-weight-bold">School # {indx + 1}</div> */}
                                        <div className="col-12 mb-3 d-flex align-items-center mb-2 schedule-form__subtitle">
                                            <h6 className="mb-0">School # {indx + 1}</h6>
                                            {
                                                school.edited && 
                                                // indx !== 0 &&
                                                <button
                                                    type="button"
                                                    className="btn btn-transparent p-0 ml-3"
                                                    onClick={() => onRemoveItemFromCalendar(school.id ? school.id : school.uid)}>
                                                    <SVGTrash/>
                                                </button>
                                            }
                                        </div>
                                        <div className="col-md-6 form-group">
                                            <label className="required">Select School Board</label>
                                            <CustomSelectControl
                                                placeholder="Select School Board"
                                                value={school.schoolBoardId}
                                                options={schoolBoards}
                                                onChange={value => onSchoolBoardSelect(value, school.id ? school.id : school.uid)}
                                                className="custom-react-select--schoolBoardId"
                                            />
                                        </div>
                                        <div className="col-md-6 form-group">
                                            <div className="add-item-group d-flex flex-row align-items-center justify-content-between">
                                                <label className="required">Select School</label>
                                                {AuthService.checkIfUserHasPermission([Roles.Admin], appContext.currentUser.role) &&
                                                <button className="btn btn-transparent p-0 text-primary" onClick={() => setModal({ ...modal, add: true, index: indx })}>Add a School</button>
                                                }
                                            </div>

                                            <CustomSelectControl
                                                placeholder="Select School"
                                                value={school.schoolId}
                                                options={getSchoolOptions(indx)}
                                                onChange={value => onChangeSchool(value, "schoolId", school.id ? school.id : school.uid)}
                                                className="custom-react-select--schoolId"
                                            />
                                        </div>
                                    </div>
                                )}
                                <div className="row">
                                    <div className="col-12">
                                        <button type="button" className={`btn ${(job.schools.length !==0 && job.schools[0].schoolId && job.schools[0].schoolBoardId) ? 'btn-outline-aqua-blue' : 'btn-disabled'} text-dark-lighter`} onClick={addAnotherSchool}>Add another related school</button>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>

                    {/* Geofence Section */}
                    <div className="row mt-5">
                        <div className="col-12">
                            <h5 className="mb-4">Job Locations</h5>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-md-5">
                                    <div className="form-group d-flex flex-column align-items-start">
                                        <label className="required" htmlFor="geoFence">Geo Fence</label>
                                        <div className="d-flex align-self-stretch">
                                            <input type="number" min={5} name="geoFence" className="form-control" id="geoFence"
                                                placeholder="Enter radius in meters" autoComplete="nope"
                                                value={job.geoFence as number}
                                                onChange={e => onChange(e)}
                                                onBlur={ev => onGeofenceBlur(ev)}
                                            />
                                            <CustomTooltip
                                                bodyText={"This is the area around the location which will be used to detect the Guard's presence. Min 25m to Max 150m."}>
                                                <button
                                                    type="button"
                                                    className="btn btn-transparent px-0 ml-3"
                                                >
                                                    <SVGInfo/>
                                                </button>
                                            </CustomTooltip>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="geoFence">Comments (optional)</label>
                                        <textarea name="comments" className="form-control" id="comments"
                                                autoComplete="nope" rows={5}
                                                value={job.comments}
                                                onChange={e => onChange(e)}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-7">
                                    <GeofenceMap
                                        lat={job.latitude !== null ? +job.latitude : 0}
                                        lng={job.longititude !== null ? +job.longititude : 0}
                                        radius={job.geoFence ? +job.geoFence : 0 as number}
                                        removeLoadScript
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Geofence Section */}

                    <div className="row">
                        <div className="col-12 mx-auto d-flex justify-content-lg-between mt-3 mt-md-5 bottom-nav-card">
                            <button type="button" className="btn btn-outline-aqua-blue px-4"
                                    onClick={() => history.push('/jobs')}>
                                Cancel
                            </button>
                            {
                                params.id !== undefined && 
                                <button
                                    type="button"
                                    className="btn btn-outline-aqua-blue px-4 ml-auto"
                                    onClick={() => saveJob()}
                                >
                                    Save Job
                                </button>
                            }
                          
                            <button type="button" className="btn btn-aqua-blue px-4 ml-2"
                                    onClick={() => stepHandler(true)}>
                                Next
                            </button>
                        </div>
                    </div>

                </div>
            </div>

            { showAddNewItemModal && AuthService.checkIfUserHasPermission([Roles.Admin], appContext.currentUser.role) &&
                <AddNewItemModal
                    titleBody = {newItemModalTypo.title}
                    placeholderText = {newItemModalTypo.placeholder}
                    showModal = {showAddNewItemModal}
                    onCancel = { () => onAddNewItemModalClose() }
                    onSubmit = { newItem => onAddNewItemSubmit({ itemName: newItem, cityId: job.cityId }, addNewItemToDropdown) }
                    allowClearForm={currentItemType === AddNewItemEnum.city ? true : false}
                    allowPrediction={currentItemType === AddNewItemEnum.city ? true : false}
                />
            }

            {
                addContactModal &&
                <AddEditUserModal
                    onCancel={() => setAddContactModal(false)}
                    onSubmit={data => saveContact(data as IClientContact, job.clientId)}
                    title={`Add a Client Contact User for <span class="font-weight-bold">${
                        clients.find(client => client.id === job.clientId)?.name
                    }</span>`}
                    isClient={true}
                    clientAlreadySet
                    submitBtnText={'Save and Send Invitation to Set Password'}
                />
            }

            { modal.add &&
            <AddSchoolModal
                onCancel={() => setModal({ ...modal, add: false })}
                onSubmit={(newSchool: ISchool) => {
                  saveNewSchool(newSchool)
                  getOptionsForDropdowns();
                }}
                vocabulary={provinces}
                schoolBoards={schoolBoards}
                boardsCallback={option => newSchoolBoardAdded(option)}
                selectBoard={job.schools[modal.index || 0]}
            />
            }
        </LoadScript>
    )
}

export default CreateJobTab