import React, {FC, useEffect} from 'react'
import { SVGInfo } from '../../../assets/icons/SvgIcons'
import {IAdministrationUser} from '../../../common/interfaces/manage-users/IManageUser'
import { IVocabulary } from '../../../common/interfaces/vocabulary/IVocabulary'
import { IBaseModalProps } from '../../../common/interfaces/IModal'
import {AdministrationUser} from '../../../common/models/ManageUser'
import {useAddEditUser} from '../../../customHooks/useAddEditUser'
import CustomSelectControl from '../../custom-select/CustomSelectControl'
import CustomTooltip from '../../custom-tooltip/CustomTooltip'
import BaseModal from '../BaseModal'
import InputMask from 'react-input-mask';
import { validateEmail, validatePhoneNumber } from '../../../utils/Validation'

interface Props extends IBaseModalProps {
    user?: IAdministrationUser
    isClient?: boolean
    listOfClients?: IVocabulary[]
    submitBtnText?: string
    clientAlreadySet?: boolean
}

const AddEditUserModal: FC<Props> = ({ user, onCancel, onSubmit, title, listOfClients, isClient, submitBtnText, clientAlreadySet }) => {
    const {formData, onInputChange, formIsValid, setFormData} = useAddEditUser<IAdministrationUser>(new AdministrationUser())
    const onSaveDataClick = () => {
        if (formIsValid(['middleName', 'phoneNumber', 'setAsPrimary', (!isClient || clientAlreadySet) ? 'client' : ''])) onSubmit && onSubmit(formData);
    }
    useEffect(() => {
        if (user) setFormData(user);
    }, [])

    return (
        <BaseModal
            show={true}
            onCancel={() => onCancel()}
            onSubmit={() => onSaveDataClick()}
            submitBtnText={submitBtnText ? submitBtnText : 'Save'}
            className={'edit-user-info'}
        >
            { title && <h4 className="mb-4" dangerouslySetInnerHTML={{__html: title }}/> }
            <form className="row">
                <div className="form-group col-md-6 align-self-end">
                    <label className="required" htmlFor="firstName">First Name</label>
                    <input type="text" name="firstName" className="form-control" id="firstName"
                           placeholder="Enter First Name" autoComplete="nope"
                           value={formData.firstName}
                           onChange={e => onInputChange(e)}
                    />
                </div>
                <div className="form-group col-md-6">
                    <label htmlFor="middleName">Middle Name</label>
                    <input type="text" name="middleName" className="form-control" id="middleName"
                           placeholder="Enter middle Name" autoComplete="nope"
                           value={formData.middleName}
                           onChange={e => onInputChange(e)}
                    />
                </div>
                <div className="form-group col-md-6 align-self-end">
                    <label className="required" htmlFor="lastName">Last Name</label>
                    <input type="text" name="lastName" className="form-control" id="lastName"
                           placeholder="Enter Last Name" autoComplete="nope"
                           value={formData.lastName}
                           onChange={e => onInputChange(e)}
                    />
                </div>
                <div className="form-group col-md-6 align-self-end">
                    <label className="required" htmlFor="position">Position</label>
                    <input type="text" name="position" className="form-control" id="position"
                           placeholder="Enter Position" autoComplete="nope"
                           value={formData.position}
                           onChange={e => onInputChange(e)}
                    />
                </div>
                <div className="form-group col-md-6 align-self-end">
                    <label className="required" htmlFor="email">Email Address</label>
                    <input type="email" name="email" className="form-control" id="email"
                           placeholder="Enter Email Address" autoComplete="nope"
                           value={formData.email}
                           onChange={e => onInputChange(e)}
                           onBlur={e => validateEmail(e)}
                    />
                </div>
                <div className="form-group col-md-6 align-self-end">
                    <label className="required" htmlFor="mobileNumber">Mobile Number</label>
                    <InputMask
                        mask={"999-999-9999"}
                        type="text"
                        name="mobileNumber"
                        className="form-control"
                        id="mobileNumber"
                        placeholder="Enter Mobile Number"
                        autoComplete="nope"
                        value={formData.mobileNumber}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e)}
                        onBlur={e => validatePhoneNumber(e)}
                    />
                </div>
                <div className="form-group col-md-6">
                    { isClient && listOfClients &&
                    <>
                        <label className="required" htmlFor="phoneNumber">Select a Client</label>
                        <CustomSelectControl
                            value={formData.client}
                            onChange={(value, label) => {
                                onInputChange(value, "client")
                            }}
                            options={listOfClients}
                            className="custom-react-select--client"
                        />
                    </>
                    }
                </div>
                <div className="form-group col-md-6 align-self-end">
                    <label htmlFor="phoneNumber">Phone Number</label>
                    <InputMask
                        mask={"999-999-9999"}
                        type="text"
                        name="phoneNumber"
                        className="form-control"
                        id="phoneNumber"
                        placeholder="Enter Phone Number"
                        autoComplete="nope"
                        value={formData.phoneNumber}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e)}
                        onBlur={e => validatePhoneNumber(e)}
                    />
                </div>
                { isClient &&
                <div className="form-group col-md-6 ">
                    <div className="custom-control custom-checkbox d-flex align-items-center">
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id="setAsPrimary"
                            name='setAsPrimary'
                            checked={!!formData.setAsPrimary}
                            // checked={true}
                            onChange={e => onInputChange(formData.setAsPrimary ? false : true, 'setAsPrimary')}
                        />
                        <label className="custom-control-label mb-0" htmlFor="setAsPrimary">Set as primary contact for this client</label>
                        <CustomTooltip
                            bodyText={"If you already have primary contact it will automatically replace with current"}>
                            <button
                                type="button"
                                className="btn btn-transparent px-0 ml-3"
                            >
                                <SVGInfo />
                            </button>
                        </CustomTooltip>
                    </div>
                </div>
                }
            </form>
        </BaseModal>
    )
}
export default AddEditUserModal
