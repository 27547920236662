export enum DashboardModals {
    in = "checkIn",
    out = "checkOut",
    absent = "absent",
    update = 'updateStatusModal',
    addComment = 'addComment',
    editComment = 'editComment',
    removeComment = 'removeComment',
}

export enum LeaveRequestReportModals {
    approve = 'approve',
    deny = 'deny',
    editDates = 'updateDates'
}