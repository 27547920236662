import { EditorState } from 'draft-js'
import React, { FC } from 'react'
import { ICustomNotificationClient } from '../../../../common/interfaces/administration/INotificaion'
import { IClient } from '../../../../common/interfaces/clients/IClient'
import { IBaseModalProps } from '../../../../common/interfaces/IModal'
import { convertToHtml } from '../../../../utils/Converting'
import { matchOptionWithName } from '../../../../utils/MatchOptionWithName'
import BaseModal from '../../BaseModal'

interface Props extends IBaseModalProps {
    data: ICustomNotificationClient
    clients: IClient[] | undefined
    contacts: Array<{ id: number, fullName: string }>
}

const ConfirmClientNotificationModal: FC<Props> = ({ onCancel, onSubmit, data, clients, contacts }) => {
    return (
        <BaseModal
            show={true}
            onCancel={() => onCancel()}
            onSubmit={() => onSubmit && onSubmit()}
            cancelBtnText={'Back'}
            submitBtnText={'Confirm and Send'}
            title={"Confirm Your Message to Client Contacts"}
            className="custom-message"
        >
        <form>
            <div className="row mb-3">
                <div className="col-md-3 form-group mb-0 d-flex align-items-center">
                    <label className="mb-0">Sending message by:</label>
                </div>
                <div className="col-md-9 form-group mb-0">
                    <p className="mb-0">Email to All</p>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-md-3 form-group mb-0 d-flex align-items-center">
                    <label className="mb-0">Client Recipients:</label>
                </div>
                <div className="col-md-9 form-group mb-0">
                    { clients && <p className="mb-0">{ matchOptionWithName(data.clients, clients.map(client => ({
                        id: client.id,
                        name: client.organizationName
                    }))) }</p> }
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-md-3 form-group mb-0 d-flex align-items-center">
                    <label className="mb-0">Contact Recipients:</label>
                </div>
                <div className="col-md-9 form-group mb-0">
                { contacts && <p className="mb-0">{ matchOptionWithName(data.clientContacts as string, contacts.map(contact => ({
                        id: contact.id,
                        name: contact.fullName
                    }))) }</p> }
                </div>
            </div>

            <div className="row mb-3 d-flex align-item-start">
                <div className="col-md-3 form-group mb-0 d-flex align-items-center">
                    <label className="mb-0">Message:</label>
                </div>
                <div className="col-md-9 form-group mb-0" dangerouslySetInnerHTML={{ __html: convertToHtml(data.editor as EditorState) }}/>
            </div>
        </form>    
        </BaseModal>
    )
}

export default ConfirmClientNotificationModal
