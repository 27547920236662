import { useEffect, useState } from 'react';
import ResourceDataService, { IResourceData } from '../common/services/ResourceDataService';

const useTextMessage = <T extends { [key: string]: string }>(url: string) => {
    const [message, setMessage] = useState<string>('');

    const getMessage = async () => {
        const tableSvc = new ResourceDataService<T>({ url });

        try {
            let result: IResourceData<T>;
            result = await tableSvc.getAll();
            setMessage(result.data?.questionToGuard || '');
        } catch (e: any) {
            console.log(e);
        }
    };
    useEffect(() => {
        getMessage();
    }, []);
    return { message };
};

export default useTextMessage;
