import MulTipleCheckbox from 'components/miltiple-checkbox/miltiple-checkbox'
import { useMultipleCheckbox } from 'customHooks/useMultipleCheckbox'
import { FC } from 'react'
import { IHoliday } from '../../../common/interfaces/administration/IHoliday'
import { momentDateFormat } from '../../../utils/DateFormatting'

type Props = {
    selectedItem?: IHoliday
    checkboxChanged?: (value: boolean) => void
}

const RemoveHolidayModalBody: FC<Props> = ({ selectedItem, checkboxChanged }) => {
    const {onChangeCheckbox, onRemoveCheckbox, allCheckbox} = useMultipleCheckbox();
    
    return (
        <>
            { selectedItem?.boardName &&
                <div className="row mb-3">
                    <div className="col-5 details-information__title mb-0">
                        <h6 className="mb-0">School Board:</h6>
                    </div>
                    <div className="col-7 details-information__value">
                        <p className="mb-0">
                        { selectedItem?.boardName }
                        </p>
                    </div>
                </div>
            }

            <div className="row mb-3">
                <div className="col-5 details-information__title mb-0">
                    <h6 className="mb-0">Holiday Type:</h6>
                </div>
                <div className="col-7 details-information__value">
                    <p className="mb-0">
                    { selectedItem?.holidayType }
                    </p>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-5 details-information__title mb-0">
                    <h6 className="mb-0">Holiday Name:</h6>
                </div>
                <div className="col-7 details-information__value">
                    <p className="mb-0">
                    { selectedItem?.holidayName }
                    </p>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-5 details-information__title mb-0">
                    <h6 className="mb-0">Holiday Start Date(s):</h6>
                </div>
                <div className="col-7 details-information__value">
                    <p className="mb-0">
                        { momentDateFormat(selectedItem!.startDate) } - { momentDateFormat(selectedItem!.endDate as string) }
                    </p>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-12 form-group d-flex align-items-center cursor-pointer"> 
                    <div className="custom-control custom-checkbox mr-2">
                        <MulTipleCheckbox
                            id={'applyHolidayToAllShifts'}
                            onChange={(_, value) => {
                                onChangeCheckbox('applyHolidayToAllShifts')
                                checkboxChanged && checkboxChanged(value!)
                            }}
                            onRemove={onRemoveCheckbox}
                            allCheckboxes={allCheckbox}
                            /> 
                    </div>
                    <label htmlFor="applyHolidayToAllShifts" className={'mb-0 f20'}>Reactivate All Shifts For { selectedItem?.boardName } School Board</label>
                </div>
            </div>
        </>
    )
}

export default RemoveHolidayModalBody
