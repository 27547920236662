import {SVGGuardSubmission} from 'assets/icons/SvgIcons'
import { IPushNotificaion } from 'common/interfaces/firebase/IPushNotificaion'
import { IBaseModalProps } from 'common/interfaces/IModal'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import BaseModal from '../BaseModal'

interface IProps extends IBaseModalProps {
    message: IPushNotificaion
    topMargin: number
}

const GuardHealthAndSafetyModal: FC<IProps> = ({ onCancel, message: { data }, topMargin }) => {
    const classes = data.response === 'True' ? "guard-safety__border custom_body" : "guard-safety__border guard-safety__bg-warn custom_body"
    return (
        <BaseModal
            show
            cancelBtnText={"Close"}
            onCancel={onCancel}
            showCancelBtn={false}
            showExitBtn={true}
            bodyClassName={classes}
            modalStyle={{ margin: `${topMargin}px` }}
        >
            <div className="guard-safety">
                <button type="button" className="close btn-close-modal" aria-label="Close" onClick={() => onCancel()}>
                    <span aria-hidden="true">&times;</span>
                </button>
                <div className="row flex-nowrap">
                    <div className="col-auto pr-0">
                        <SVGGuardSubmission className='mr-3' />
                    </div>
                    <div className="col-11 p-0">
                        <h2 className="guard-safety__title mb-4 font-weight-bold text-danger">Guard Health & Safety Check Alert</h2>
                        <h5 className="d-flex align-items-center guard-safety__sub mb-3">
                            <Link className="font-weight-bold pr-2 text-nowrap" to={`/guards/${data.guardId}/details`} onClick={() => onCancel()}><u>{ data.guardName }</u></Link>
                            {
                                data.response === "True" ?
                                    <>
                                        <span className="font-weight-bold">responded with <q>NO</q></span>
                                    </> :
                                    <>
                                        <span className="font-weight-bold text-nowrap">did not respond</span>
                                    </>
                            }

                        </h5>
                        <div className="row">
                            <div className="col-4 details-information__title">
                                <h6>Phone:</h6>
                            </div>
                            <div className="col-8 details-information__value">
                                <p className="mb-0">{ data.phone }</p>
                            </div>
                        </div>
                        <div className="row flex-nowrap">
                            <div className="col-4 details-information__title">
                                <h6 className="text-nowrap">Employee ID:</h6>
                            </div>
                            <div className="col-8 details-information__value">
                                <p className="mb-0">{ data.employeeId }</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BaseModal>
    )
}

export default GuardHealthAndSafetyModal
