import { useState } from "react"
import { IValidationBody, ValidationRules } from "../common/interfaces/IValidation"
import { validateFiled, highlightError, removeHighlightError } from "../utils/Validation"

export function useAddEditUser<T extends {[index: string]: any}>(initialData: T){
    const [formData, setFormData] = useState<T >(initialData)

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement> | string | boolean, fieldName?: string) => {
        setFormData({
            ...formData,
            [(typeof e === 'string' || typeof e === 'boolean') ? fieldName as string : e.target.name]: fieldName ? e : (e as React.ChangeEvent<HTMLInputElement>).target.value
        })
    }

    const formIsValid = (skipFields: string[], length?: string) => {
        let errors: IValidationBody[] = [];

        for (let [field, value] of Object.entries(formData)) {
            if (!skipFields.includes(field)) {
                errors = [...errors, ...validateFiled(value as string, field, ValidationRules.required)]
            }
        }
        if (length) {
            for (let [field, value] of Object.entries(formData)) {
                if (!skipFields.includes(field)) {
                    errors = [...errors, ...validateFiled(value as string, field, ValidationRules.required, [],'length')]
                }
            }
        }
        formData['mobileNumber'] && (errors = [...errors, ...validateFiled(formData['mobileNumber'] as string, "mobileNumber", ValidationRules.isValidPhone)])
        formData['email'] && (errors = [...errors, ...validateFiled(formData['email'] as string, "email", ValidationRules.isValidEmail)])
        formData['phoneNumber'] && (errors = [...errors, ...validateFiled(formData['phoneNumber'] as string, "phoneNumber", ValidationRules.isValidPhone)])
        if (errors.length !== 0) {
            highlightError(errors)
            return false
        }
        else {
            removeHighlightError()
            return true
        }
    }

    return {
        formData, onInputChange, formIsValid, setFormData
    }
}
