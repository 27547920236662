import React, { FC, useContext, useEffect } from 'react'
import { IVocabulary } from '../../../../common/interfaces/vocabulary/IVocabulary'
import { IJobReports } from '../../../../common/interfaces/jobs/IJob'
import DatePicker from "react-datepicker";
import { useLateOrAbsent } from '../../../../customHooks/useLateOrAbsent'
import { filterDateFormat, isTodayUpToMinus72hours, momentDateFormat, momentHoursFormat } from '../../../../utils/DateFormatting'
import { getHolidayField } from '../../../../utils/GetFieldFromTable'
import { matchOptionWithName } from '../../../../utils/MatchOptionWithName'
import DataTable from '../../../tables/DataTable'
import ReportActions from "../../../reports/ReportActions";
import { JobIncludesEnum } from "../../../../common/enums/JobEnums";
import JobDetailsTabsContext from "../../../../context/jobs/JobDetailsTabsContext";
import { jobDetailsShiftReport } from '../../../../content/dashboard/TablesContent'
import { useListFilter } from '../../../../customHooks/useListFilter'
import { SVGCalendar, SVGEraser, SVGPencil, SVGSearch } from '../../../../assets/icons/SvgIcons'
import { useDatapickerSvg } from '../../../../customHooks/useDatapickerSvg';
import { useRole } from 'customHooks/useRole';
import UpdateShiftStatusModal from 'components/modal/ dashboard/UpdateShiftStatusModal';
import { useModals } from 'customHooks/modals/useModals';
import { EditPastJobShiftsModals } from 'common/models/Modals';
import BaseModal from 'components/modal/BaseModal';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import UpdateShiftStatusContext from 'context/dashboard/UpdateShiftStatusContext';
import { VocabularyEnum } from 'common/enums/VocabularyEnum';
import { useVocabulary } from 'customHooks/vocabulary/useVocabulary';
import CheckInOutModal from 'components/modal/CheckInOutModal';
import { useCheckGuard } from 'customHooks/modals/useCheckGuard';
import AppContext from 'context/AppContext';
import useClientComment from 'customHooks/useClientComment';

type Props = {
    guardsAssigned: number
    vocabulary: IVocabulary[]
    // TODO: Check How we can avoid any type
    shiftReportList: any
}

const ShiftReportTab: FC<Props> = ({ guardsAssigned, vocabulary, shiftReportList }) => {
    const { job } = useContext(JobDetailsTabsContext)
    const { showLoader } = useContext(AppContext)
    const { generateFilterQuery, filter, onFilterDropdownChange, onFilterClearClick, setFilter } = useListFilter(`reports/jobshifts`)
    const { setLateOrAbsentValue } = useLateOrAbsent()
    const { showDatapicker, changeDatapicker } = useDatapickerSvg()
    const { currentPage, allRecords, perPage, onPagination, onPerPage, isLoading, fetchData, onClearFilterQuery, onSortCall, tableData } = shiftReportList
    const { isClientRole, isSupervisorRole, isASPAndAdminRole } = useRole()
    const { modals, setModals, itemInFocus: selectedShift,
        setItemInFocus: setSelectedShift } = useModals<EditPastJobShiftsModals, IJobReports>({
            defaultState: new EditPastJobShiftsModals({})
        });
    const { vocabulary: replaceGuardVoc } = useVocabulary(VocabularyEnum.replacement, true)
    const { handleCheckActions } = useCheckGuard()
    const { handleAddEditClientComment, showErrorMessage, onCommentChange, comment, setComment } = useClientComment({required: false})
    useEffect(() => {
        job.id !== undefined &&
            setFilter({
                ...filter,
                jobId: job.id
            })
    }, [job.id])

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement | HTMLFormElement | HTMLDivElement>) => {
        if (event.key === 'Enter') fetchData(1, 24, generateFilterQuery())
    }

    useEffect(() => {
        const listener = (event: any) => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                handleKeyPress(event);
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [filter]);

    const onEditShiftClick = (shift: IJobReports) => {
        setSelectedShift({
            ...shift,
            firstStreet: job.firstStreet,
            secondStreet: job.secondStreet,
        })
        setModals(new EditPastJobShiftsModals({
            showEditModal: shift.shiftStatus.tentantCheckIn ? false : true,
            showTempCheckInModal: shift.shiftStatus.tentantCheckIn ? true : false
        }))
    }

    const onEditShiftSubmit = async () => {
        handleAddEditClientComment(selectedShift.id ? +selectedShift.id : +selectedShift.shiftId!, async () => {
            setComment('')
            await fetchData(1, 24, generateFilterQuery())
            setModals(new EditPastJobShiftsModals({ showEditModalSuccess: true }))
        });
    }

    const onAbsentSuccess = async () => {
        await fetchData(1, 24, generateFilterQuery())
        setModals(new EditPastJobShiftsModals({ showAbsentGuardSuccess: true }))
        showLoader(false)
    }

    const onConfirmCheckInSubmit = async () => {
        handleCheckActions({
            checkType: 'checkIn',
            checkTime: selectedShift.aspStartDate,
            shiftId: selectedShift.id
        }, async () => {
            await fetchData(1, 24, generateFilterQuery())
            setModals(new EditPastJobShiftsModals({ showTempCheckInSuccessModal: true }))
            showLoader(false)
        })
       
    }

    return (
        <div>
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-3">
                <div>
                    <h5>Shift Report</h5>
                    <h6># Guards Assigned: {guardsAssigned}</h6>
                </div>
                <ReportActions
                    title="Shift Report:"
                    id={job.id}
                    tabName={JobIncludesEnum.report}
                    page="job"
                    query={(filter.startDate && filter.endDate)
                        ? `&StartDate=${filter.startDate}&EndDate=${filter.endDate}`
                        : ''}
                />
            </div>
            <form className="filters-form-group filters-form-group--dashboard border-0"
                onKeyDown={(event) => {
                    if (event.key === 'Enter') fetchData(1, 24, generateFilterQuery(true, false))
                }}>
                <div className="row">
                    <div className="d-flex w-100 flex-wrap ml-1  ">
                        <div className="react-datepicker-custom-wrapper datepicker-width d-inline-block ml-2 mb-2 form-group">
                            <label className="mr-2" htmlFor="#">View From: </label>
                            <DatePicker
                                selected={filter.startDate ? new Date(filter.startDate) : null}
                                onChange={date => {
                                    date && onFilterDropdownChange(filterDateFormat(date as Date), 'startDate')
                                    changeDatapicker(1)
                                }}
                                dateFormat="dd/MM/yyyy"
                                placeholderText="dd/mm/yyyy"
                                onInputClick={() => changeDatapicker(1)}
                                open={showDatapicker[1]}
                                onClickOutside={() => changeDatapicker(1)}
                                maxDate={new Date()}
                            />
                            <div onClick={() => changeDatapicker(1)}><SVGCalendar /></div>
                        </div>

                        <div className="react-datepicker-custom-wrapper datepicker-width d-inline-block ml-2 mb-2 ts form-group">
                            <label className="mr-2" htmlFor="#">To: </label>
                            <DatePicker
                                selected={filter.endDate ? new Date(filter.endDate) : null}
                                onChange={date => {
                                    date && onFilterDropdownChange(filterDateFormat(date as Date), 'endDate')
                                    changeDatapicker(2)
                                }}
                                dateFormat="dd/MM/yyyy"
                                className="flex-basis-0"
                                placeholderText="dd/mm/yyyy"
                                onInputClick={() => changeDatapicker(2)}
                                open={showDatapicker[2]}
                                onClickOutside={() => changeDatapicker(2)}
                                maxDate={new Date()}
                            />
                            <div onClick={() => changeDatapicker(2)}><SVGCalendar /></div>
                        </div>

                        <button type="button" className="btn btn-aqua-blue ml-2"
                            onClick={() => { fetchData(1, 24, generateFilterQuery()) }
                            }>
                            <SVGSearch />
                        </button>
                        {
                            filter.hasValue &&
                            <button type="button" className="btn btn-aqua-blue ml-2"
                                onClick={() => {
                                    onClearFilterQuery()
                                    onFilterClearClick(undefined, { jobId: job.id })
                                }}>
                                <SVGEraser />
                            </button>
                        }
                    </div>
                </div>
            </form>
            <div className="row">
                <div className="col-12">
                    {
                        React.useMemo(() => (
                            <DataTable
                                tableClass={"mt-4 table-info--lastColHolidays table-info--notFixed"}
                                responsive="none"
                                thead={jobDetailsShiftReport.thead}
                                tbody={tableData ? tableData.map((report: IJobReports) => ({
                                    id: report.id ? report.id : null,
                                    guardNameLink: report.guardName ? `<a href="#/guards/${report.guardId}/details">${report.guardName}</a>` : '-',
                                    startDate: momentDateFormat(report.startDate),
                                    aspStartDate: momentHoursFormat(report.aspStartDate),
                                    timeFrom: momentHoursFormat(report.timeFrom),
                                    timeTo: momentHoursFormat(report.timeTo),
                                    shiftPeriod: matchOptionWithName(report.shiftPeriod, vocabulary),
                                    checkInDateTime: report.checkInDateTime,
                                    checkOutDateTime: report.checkOutDateTime,
                                    updateComments: report.updateComments || '--',
                                })) : []}
                                ignoreCols={[...(isClientRole() || isSupervisorRole() ? [0, 3, 7, 8, 9] : [0, 7, 8, 9])]}
                                ignoreTheadCols={[...(isClientRole() || isSupervisorRole() ? [0, 3 , 10] : [])]}
                                defaultSortedColumn={2}
                                pagination
                                sortOnBackend
                                showTableLengthData
                                currentPage={currentPage}
                                itemsCount={allRecords}
                                itemsPerPage={+perPage}
                                onPager={page => onPagination(page)}
                                onPerPage={value => onPerPage(value)}
                                tableName={"Past Job Shifts"}
                                isLoading={isLoading}
                                onSort={(orderBy, desc) => onSortCall(orderBy, desc, filter.keyWord)}
                                customMarkupAtStart={(_id, rowItem: IJobReports, rowIndex: number) => (
                                    isASPAndAdminRole() &&
                                    <td className='align-middle'>
                                        {isTodayUpToMinus72hours(rowItem.startDate) &&
                                            <div>
                                                <button type="button" className="btn btn-aqua-blue"
                                                    onClick={() => onEditShiftClick(tableData[rowIndex])}
                                                >
                                                    <SVGPencil />
                                                </button>
                                            </div>
                                        }
                                    </td>
                                )}
                            >
                                {
                                    (reportId, rowItem: IJobReports) => (
                                        <>
                                            {setLateOrAbsentValue(reportId, shiftReportList.tableData)}
                                            <td className="align-middle">{rowItem.checkInDateTime === null ? '-' : momentHoursFormat(rowItem.checkInDateTime)}</td>
                                            <td className="align-middle">{rowItem.checkOutDateTime === null ? '-' : momentHoursFormat(rowItem.checkOutDateTime)}</td>
                                            {isASPAndAdminRole() &&
                                                <td className='align-middle'>
                                                    {rowItem.updateComments}
                                                </td>
                                            }
                                            <td className="align-middle">
                                                {getHolidayField(reportId, shiftReportList.tableData)}
                                            </td>
                                        </>
                                    )
                                }
                            </DataTable>
                        ), [shiftReportList.tableData, setLateOrAbsentValue, vocabulary])
                    }
                </div>
            </div>

            <UpdateShiftStatusContext.Provider
                value={{
                    vocabulary: [...replaceGuardVoc, ...[{ id: 0, name: "Other" }]],
                }}
            >
                {modals.showEditModal &&
                    <UpdateShiftStatusModal
                        shift={selectedShift}
                        onCancel={() => {
                            setModals(new EditPastJobShiftsModals({}));
                            setComment('');
                        }}
                        onSubmit={onEditShiftSubmit}
                        showReasonField
                        onMarkAbsent={onAbsentSuccess}
                        comment={comment}
                        setComment={setComment}
                        onCommentChange={onCommentChange}
                        showErrorMessage={showErrorMessage}
                        handleAddEditClientComment={handleAddEditClientComment}
                        refreshTableData={async () => await fetchData(1, 24, generateFilterQuery())}
                        setShift={setSelectedShift}
                    />
                }
            </UpdateShiftStatusContext.Provider>

            {
                modals.showTempCheckInModal &&
                <BaseModal
                    show={modals.showTempCheckInModal}
                    title={'Confirm Temp Check In'}
                    onSubmit={() => onConfirmCheckInSubmit()}
                    onCancel={() => setModals(new EditPastJobShiftsModals({}))}
                    submitBtnText={'Confirm'}
                >
                    <CheckInOutModal
                        type={"checkin"}
                        absenceReasons={[]}
                        checkTime={selectedShift.aspStartDate}
                        onTimeChange={time => setSelectedShift({
                            ...selectedShift,
                            aspStartDate: time
                        })}
                        selectedItem={selectedShift}
                    />
                </BaseModal>
            }

            {(modals.showEditModalSuccess || modals.showTempCheckInSuccessModal) &&
                <BaseModal
                    show={(modals.showEditModalSuccess || modals.showTempCheckInSuccessModal)}
                    onCancel={() => setModals(new EditPastJobShiftsModals({}))}
                    cancelBtnText={'Close'}
                >
                    <ConfirmationModal text={
                        modals.showEditModalSuccess
                        ? `Shift details for <span class="font-weight-bold">${job.jobName}</span> has been updated.`
                        : `<span class="font-weight-bold">${selectedShift.guardName} </span>
                        has been checked in for <span class="font-weight-bold">${job.jobName}</span>`
                    }
                    />
                </BaseModal>
            }

            {modals.showAbsentGuardSuccess &&
                <BaseModal
                    show
                    onCancel={() => setModals(new EditPastJobShiftsModals({}))}
                    cancelBtnText='Close'
                >
                    <h4><span className="font-weight-bold">{selectedShift.guardName}</span> has been marked as absent for
                    <span className="font-weight-bold"> {job.jobName}</span></h4>
                </BaseModal>
            }
        </div>
    )
}

export default ShiftReportTab
