import React, { FC, useContext, useEffect, useRef, useState } from 'react'
import { IGuard } from '../../../common/interfaces/guards/IGuard'
import ImageUpload from '../../upload/ImageUpload'
import DatePicker from "react-datepicker";
import { SVGCalendar } from '../../../assets/icons/SvgIcons'
import { IVocabulary } from '../../../common/interfaces/vocabulary/IVocabulary';
import CustomSelectControl from '../../custom-select/CustomSelectControl';
import {useDatapickerSvg} from "../../../customHooks/useDatapickerSvg";
import InputMask from 'react-input-mask';
import { datePickerFormat, setHoursAndMinutes } from '../../../utils/DateFormatting';
import { validateEmail, validatePhoneNumber } from '../../../utils/Validation';
import GeofenceMap from '../../jobs/GeofenceMap';
import useGoogleApi from '../../../customHooks/useGoogleApi';
import { ICityDropdown } from '../../../common/interfaces/dropdowns/ICityDropdown';
import { AuthService } from '../../../common/auth/AuthService';
import { AddNewItemEnum } from '../../../common/enums/AddNewItemEnum';
import { Roles } from '../../../common/enums/Roles';
import AppContext from '../../../context/AppContext';
import { useAddNewItem } from '../../../customHooks/useAddNewItem';
import AddNewItemModal from '../../modal/AddNewItemModal';
import { useHistory } from 'react-router';
import { LoadScript } from '@react-google-maps/api';

type Props = {
    guard: IGuard
    onChange: (value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | any> | string | Date | boolean | Array<any>, fieldName?: string) => void
    vocabulary: IVocabulary[]
    cities: ICityDropdown[]
    addNewItemToDropdown: (option: any, type: AddNewItemEnum) => void
}

const GuardInfoForm: FC<Props> = ({ guard, onChange, vocabulary, cities, addNewItemToDropdown }) => {
    const {showDatapicker, changeDatapicker} = useDatapickerSvg()
    const { getLocationGeocoding, setCount, count } = useGoogleApi()
    const [locataionFirst, setLocationFirst] = useState();
    const [coordinatesOnLoad, setCoordinatesOnLoad] = useState<boolean>(false)
    const appContext = useContext(AppContext)
    const { location: { pathname } } = useHistory()
    const { showAddNewItemModal, newItemModalTypo, onAddNewItemClick, onAddNewItemSubmit, onAddNewItemModalClose, currentItemType } = useAddNewItem();
    const [cursorEmployeeId, setCursorEmployeeId] = useState<number | null>(null);
    const employeeIdRef = useRef<any>();

    const draggableMarker = (lat: string, lng: string) => {
        onChange([
            {value: lat, name: "latitude"},
            {value: lng, name: "longitude"},
        ])
    }

    useEffect(() => {
        const input = employeeIdRef.current;
        if (input) input.setSelectionRange(cursorEmployeeId, cursorEmployeeId);
     }, [employeeIdRef, cursorEmployeeId, guard.employeeId]);

    const getLocationOnBlur = () => {
        if (pathname.includes('edit') && !coordinatesOnLoad) {
            if (guard.firstStreet !== '' && guard.city !== '' && cities.length !== 0 ) {
                setCount(3)
                getLocationGeocoding(true, guard, cities, setLocationFirst, true)
                .then(() => setCoordinatesOnLoad(true))
            }
        }
    }

    useEffect(() => {
        if (cities.length === 0) return;
        setCount(count + 1)
        getLocationGeocoding(true, guard, cities, setLocationFirst)
    }, [guard.firstStreet, guard.city, cities])

    useEffect(() => {
        if (cities.length === 0) return;
        setCount(count + 1)
        getLocationGeocoding(false, guard, cities, setLocationFirst)
    }, [guard.secondStreet, guard.city, cities])

    useEffect(() => {
        if (locataionFirst && guard.firstStreet.length >= 2) {
            const {lng, lat} = locataionFirst!;
            onChange([
                {value: lat, name: "latitude"},
                {value: lng, name: "longitude"},
            ])
        }
        if (locataionFirst && guard.firstStreet === '') {
            onChange([
                {value: '', name: "latitude"},
                {value: '', name: "longitude"},
            ])
        }
    }, [guard.firstStreet, locataionFirst])

    return (
        <>
        <LoadScript libraries={["places"]} googleMapsApiKey={process.env.REACT_APP_MAP_KEY as string}>

            <div className="row">
                <div className="col-md-4 mb-4 mb-md-0">
                    <ImageUpload image={guard.profileImage}  onChange={value => onChange(value, "profileImage")}/>
                </div>
                <div className="form-group col-md-4 align-self-end">
                    <label className="required" htmlFor="employeeId">Employee ID</label>
                    <input
                        type="text"
                        name="employeeId"
                        className="form-control"
                        id="employeeId"
                        placeholder="Enter employee id"
                        autoComplete="nope"
                        value={guard.employeeId}
                        onChange={(ev) => { 
                            setCursorEmployeeId(ev.target.selectionStart);
                            onChange(ev.target.value.toUpperCase(), 'employeeId')}
                        }
                        ref={employeeIdRef}
                    />
                </div>
                <div className="form-group col-md-4 align-self-end">
                    <label className="required">ASP Start Date</label>
                    <div className="react-datepicker-custom-wrapper react-datepicker-custom-wrapper--full-width d-inline-block w-100">
                        <DatePicker
                            selected={datePickerFormat(guard.aspStartDate)}
                            onChange={date => date && onChange(setHoursAndMinutes(date as Date), 'aspStartDate')}
                            dateFormat="dd/MM/yyyy"
                            onInputClick={() => changeDatapicker(1)}
                            open={showDatapicker[1]}

                            onClickOutside={() => changeDatapicker(1)}
                        />
                        <div onClick={() => changeDatapicker(1)}><SVGCalendar /></div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="form-group col-md-4">
                    <label className="required" htmlFor="firstName">First Name</label>
                    <input type="text" name="firstName" className="form-control" id="firstName" placeholder="Enter first name" autoComplete="nope"
                        value={guard.firstName}
                        onChange={e => onChange(e)}
                    />
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="middleName">Middle Name or Initial</label>
                    <input type="text" name="middleName" className="form-control" id="middleName" placeholder="Enter middle name or initial" autoComplete="nope"
                        value={guard.middleName}
                        onChange={e => onChange(e)}
                    />
                </div>
                <div className="form-group col-md-4">
                    <label className="required" htmlFor="lastName">Last Name</label>
                    <input type="text" name="lastName" className="form-control" id="lastName" placeholder="Enter last name" autoComplete="nope"
                        value={guard.lastName}
                        onChange={e => onChange(e)}
                    />
                </div>
            </div>

            <div className="row">
                <div className="form-group col-md-4">
                    <label htmlFor="email">Email address</label>
                    <input type="email" name="email" className="form-control" id="email" placeholder="Enter email address" autoComplete="nope"
                        value={guard.email ? guard.email : ''}
                        onChange={e => onChange(e)}
                        onBlur={e => validateEmail(e)}
                    />
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="homeMobile">Home Phone</label>
                    <InputMask
                        mask={"999-999-9999"}
                        type="text"
                        name="homeMobile"
                        className="form-control"
                        id="homeMobile"
                        placeholder="Enter home phone number"
                        autoComplete="nope"
                        value={guard.homeMobile}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
                        onBlur={e => validatePhoneNumber(e)}
                    />
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="personalMobile">Personal Mobile</label>
                    <InputMask
                        mask={"999-999-9999"}
                        type="text"
                        name="personalMobile"
                        className="form-control"
                        id="personalMobile"
                        placeholder="Enter personal mobile number"
                        autoComplete="nope"
                        value={guard.personalMobile}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
                        onBlur={e => validatePhoneNumber(e)}
                    />
                </div>
            </div>

            <div className="row">
                <div className="form-group col-md-4">
                    <div className="add-item-group d-flex flex-row align-items-center justify-content-between">
                        <label className="required" htmlFor="cityName">Select a City</label>
                        {AuthService.checkIfUserHasPermission([Roles.Admin], appContext.currentUser.role) &&
                        <button type="button" className="btn btn-transparent p-0 text-primary" onClick={() => onAddNewItemClick(AddNewItemEnum.city)}>Add a City</button>
                        }
                    </div>
                    <CustomSelectControl
                        options={cities}
                        onChange={(id, label) => {
                            onChange([
                                {value: label, name: "city"},
                                {value: id, name: "cityId"},
                            ])
                            // onChange(label as string, "city")
                        }}
                        value={guard.city}
                        valueIsLabel
                        placeholder={"Select a City"}
                        className={`custom-react-select--cityId`}
                    />
                </div>
                <div className="form-group col-md-4 align-self-end">
                    <label className="required" htmlFor="firstStreet">Address 1</label>
                    <input type="text" name="firstStreet" className="form-control" id="firstStreet" placeholder="Enter address line 1"  autoComplete="nope"
                        value={guard.firstStreet}
                        onChange={e => onChange(e)}
                        onBlur={() => getLocationOnBlur()}
                    />
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="secondStreet">Address 2</label>
                    <input type="text" name="secondStreet" className="form-control" id="secondStreet" placeholder="Enter address line 2" autoComplete="nope"
                        value={guard.secondStreet}
                        onChange={e => onChange(e)}
                    />
                </div>
            </div>

            <div className="row">
                <div className="form-group col-md-4">
                    <label className="required" htmlFor="province">Select a Province</label>
                    <CustomSelectControl
                        options={vocabulary}
                        onChange={value => onChange(value, "province")}
                        value={guard.province}
                        placeholder={"Select a Province"}
                        className={`custom-react-select--province`}
                    />
                </div>
                <div className="form-group col-md-4">
                    <label className="required" htmlFor="postalCode">Postal Code</label>
                    <InputMask
                        mask={"a9a 9a9"}
                        type="text"
                        name="postalCode"
                        className="form-control"
                        id="postalCode"
                        placeholder="Enter postal code"
                        autoComplete="nope"
                        value={guard.postalCode}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
                        onBlur={e => validatePhoneNumber(e)}
                    />
                </div>
            </div>
            {
                React.useMemo(() => (
                (guard.latitude && guard.longitude) ?
                    <div className="row">
                        <div className="col-md-12 pb-3">
                            <GeofenceMap
                                lat={guard.latitude !== null ? +guard.latitude : 0}
                                lng={guard.longitude !== null ? +guard.longitude : 0}
                                radius={0}
                                draggable={true}
                                draggableMarker={draggableMarker}
                                removeLoadScript
                            />
                        </div>
                    </div>
                    : null
                ), [guard.latitude, guard.longitude])
            }
            { (guard.latitude && guard.longitude) ?
                <div className="row">
                    <div className="col-md-4 form-group">
                        <label className="required" htmlFor="latitude">Address Latitude</label>
                        <input type="text" name="latitude" className="form-control" id="latitude"
                            placeholder="Address Latitude" autoComplete="nope"
                            value={guard.latitude || ""}
                            onChange={e => onChange(e)}
                        />
                    </div>

                    <div className="col-md-4 form-group">
                        <label className="required" htmlFor="longitude">Address Longitude</label>
                        <input type="text" name="longitude" className="form-control" id="longitude"
                            placeholder="Address Longitude" autoComplete="nope"
                            value={guard.longitude || ""}
                            onChange={e => onChange(e)}
                        />
                    </div>
                </div>
            : null
            }
            </LoadScript>
            { showAddNewItemModal && AuthService.checkIfUserHasPermission([Roles.Admin], appContext.currentUser.role) &&
                <AddNewItemModal
                    titleBody = {newItemModalTypo.title}
                    placeholderText = {newItemModalTypo.placeholder}
                    showModal = {showAddNewItemModal}
                    onCancel = { () => onAddNewItemModalClose() }
                    onSubmit = { newItem => onAddNewItemSubmit({ itemName: newItem, cityId: guard.cityId }, addNewItemToDropdown) }
                    allowClearForm={currentItemType === AddNewItemEnum.city ? true : false}
                    allowPrediction={currentItemType === AddNewItemEnum.city ? true : false}
                />
            }
        </>
    )
}

export default GuardInfoForm
