import { IBaseShift } from 'common/interfaces/jobs/IShifts'
import React, {FC} from 'react'
import { IJobModalProps } from '../../../common/interfaces/jobs/IJobModal'
import { IVocabulary } from '../../../common/interfaces/vocabulary/IVocabulary'
import { useReason } from '../../../customHooks/useReason'
import { momentDateFormat, momentHoursFormat } from '../../../utils/DateFormatting'
import { matchOptionWithName } from '../../../utils/MatchOptionWithName'
import { matchWeekDays } from '../../../utils/MatchWeekDays'
import CustomSelectControl from '../../custom-select/CustomSelectControl'
import BaseModal from '../BaseModal'

interface IProps extends IJobModalProps<IBaseShift> {
    reasons: IVocabulary[]
    titleBody: string
    warning?: string
    shifts?: IVocabulary[]
}

const RemoveShiftModal: FC<IProps> = ({ show, onClose, onSubmit, shift, reasons: vocabulary, titleBody, jobName, warning, shifts }) => {
    const { reason, reasonIsEmpty, reasons, onReasonSelect, textareaRef, setReason, isOtherReason } = useReason(vocabulary)

    const onSubmitClick = () => {
        if (!reasonIsEmpty('reasonForUnassign', 'reasonForUnassign--other')) {
            onSubmit && onSubmit(reason)
        }
    }

    return (
        <BaseModal
            show={show}
            onCancel={() => onClose()}
            onSubmit={ () => onSubmitClick()}
            cancelBtnText={"Cancel"}
            submitBtnText={"Yes"}
        >
            <h4 className="mb-4" dangerouslySetInnerHTML={{ __html: titleBody }} />

            <div className="row mb-3">
                <div className="col-sm-4 details-information__title mb-0">
                    <h6 className="mb-0">Job Name:</h6>
                </div>
                <div className="col-sm-8 details-information__value">
                    <p className="mb-0">
                        { jobName ? jobName : shift?.jobName }
                    </p>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-sm-4 details-information__title mb-0">
                    <h6 className="mb-0">Date(s):</h6>
                </div>
                <div className="col-sm-8 details-information__value">
                    <p className="mb-0">
                        { momentDateFormat(shift?.startDate || '') }
                        { shift?.endDate ?  ` - ${momentDateFormat(shift.endDate)}` : ''}
                    </p>
                </div>
            </div> 

            <div className="row mb-3">
                <div className="col-sm-4 details-information__title mb-0">
                    <h6 className="mb-0">ASP Start Time:</h6>
                </div>
                <div className="col-sm-8 details-information__value">
                    <p className="mb-0">
                        { momentHoursFormat(shift?.aspStartDate || '') } 
                    </p>
                </div>
            </div>


            <div className="row mb-3">
                <div className="col-sm-4 details-information__title mb-0">
                    <h6 className="mb-0">Shift Times:</h6>
                </div>
                <div className="col-sm-8 details-information__value">
                    <p className="mb-0">
                        { momentHoursFormat(shift?.timeFrom || '') } - { momentHoursFormat(shift?.timeTo || '') }
                    </p>
                </div>
            </div>

            { shift?.shiftPeriod && 
                <div className="row mb-3">
                    <div className="col-sm-4 details-information__title mb-0">
                        <h6 className="mb-0">Shift Period:</h6>
                    </div>
                    <div className="col-sm-8 details-information__value">
                        <p className="mb-0">
                            {  matchOptionWithName(shift.shiftPeriod, shifts) }
                        </p>
                    </div>
                </div> 
            }

            { shift?.repeatOn &&
            <div className="row mb-3">
                <div className="col-sm-4 details-information__title mb-0">
                    <h6 className="mb-0">Repeat:</h6>
                </div>
                <div className="col-sm-8 details-information__value">
                    <p className="mb-0">
                        Every week: { matchWeekDays(shift.repeatOn) }
                    </p>
                </div>
            </div>
            }
                
            <div className="row">
                {
                reasons.length !== 0 &&
                    <form className="col-12">
                        <div className="form-group">
                            <CustomSelectControl
                                // value={checkIfIsOtherReason(reasonsHook.reason) ? 0 : reasonsHook.reason.id}
                                value={isOtherReason ? 0 : (reason as IVocabulary).id}
                                placeholder={"Select a reason"}
                                options={reasons ? reasons : []}
                                onChange={(e) => onReasonSelect(e)}
                                className={`custom-react-select--reasonForUnassign`}
                            />
                        </div>
                        {
                            isOtherReason && 
                            <div className="form-group">
                                <div className="form-group mt-3">
                                    <textarea
                                    className="form-control"
                                    rows={5}
                                    name={'reasonForUnassign--other'}
                                    placeholder="Type other reason"
                                    value={reason as string}
                                    onChange={e => setReason(e.target.value)}
                                    ref={textareaRef}
                                    ></textarea>
                                </div>
                            </div>
                        }
                    </form>
                }
            </div>   

            {
                warning && 
                <div className="row mt-3">
                    <div className="col-12">
                        <small className="text-danger">{ warning }</small>
                    </div>
                </div>
            }
                
        </BaseModal>
    )
}

export default RemoveShiftModal
