import { useCallback, useEffect, useState } from 'react';

type Model = {
	[index: string]: any;
};

export const useTimeFilter = <T extends Model>(data: T[]) => {
	const [originalData, setOriginalData] = useState<T[]>(data);
	const [filteredData, setFilteredData] = useState<T[]>(data);
	const [formEmpty, setFormEmpty] = useState<boolean>(true);

	useEffect(() => {
		setOriginalData(data);
		setFilteredData(data);
	}, [data]);

	const onFilterItem = (item: Model, timeFrom: string | Date, timeTo: string | Date) => {
		const dateFormat = (date: string | Date) => new Date(date).getTime();
		return (
			dateFormat(timeFrom) <= dateFormat(item.time) &&
			dateFormat(timeTo) >= dateFormat(item.time)
		);
	};
	const onFilter = useCallback(
		(from: Date | string, to: Date | string) => {
			let result: T[] = [];
			result = [...data].filter((item) => onFilterItem(item, from, to));
			setFormEmpty(false);
			setFilteredData(result);
		},
		[data]
	);
	const onFilterMultiTable = useCallback(
		(from: Date | string, to: Date | string, key: keyof T) => {
			let result: T[] = [];
			result = [...data].map((element) => ({
				...element,
				[key]: element[key].filter((item: Model) => onFilterItem(item, from, to)),
			}));
			setFormEmpty(false);
			setFilteredData(result);
		},
		[data]
	);
	const onClear = () => {
		setFormEmpty(true);
		setFilteredData(originalData);
	};
	return {
		onFilter,
		filteredData,
		formEmpty,
		onClear,
		setOriginalData,
		setFilteredData,
		onFilterMultiTable,
	};
};
