import React, { useEffect, useState } from 'react'
import DatePicker from "react-datepicker";
import { SVGCalendar, SVGSearch, SVGEraser, SVGInfo } from '../../../assets/icons/SvgIcons';
import {useDatapickerSvg} from "../../../customHooks/useDatapickerSvg";
import { datePickerFormat, resetUTCTime, setHoursAndMinutes } from '../../../utils/DateFormatting';
import { searchInArray } from '../../../utils/Search';
import AutocompleteField from '../../autosuggestField/AutocompleteField';
import CustomTooltip from 'components/custom-tooltip/CustomTooltip';

type Props<T> = {
    isEmpty: boolean
    onSubmit: (from?: Date, to?: Date, keyWord?: string) => void
    onClear: () => void
    showKeyword?: boolean
    dataFiltered?: Array<T>
    isMinDate?: boolean
    schoolYear?: string;
    fromLabel?: string;
    tooltipMessage?: string;
}

type DateRangeState = {
    from?: Date | null | string
    to?: Date | null | string
    keyWord?: string
}

const FromToFilter = <T extends object>({ isEmpty, onSubmit, onClear, showKeyword, dataFiltered, isMinDate, schoolYear, fromLabel, tooltipMessage }: Props<T>) => {
    const [dateRange, setDateRange] = useState<DateRangeState>({
        from: null,
        to: null,
        keyWord: ''
    });
    const {showDatapicker, changeDatapicker} = useDatapickerSvg()
    const [maxEndsOn, setMaxEndsOn] = useState<string | null>(null)

    useEffect(() => {
        if (schoolYear && schoolYear !== '') {
            setMaxEndsOn(setHoursAndMinutes(schoolYear.split('-')[1]))
        } else setMaxEndsOn(null);
    }, [schoolYear])

    const onFilterClick = (e?: React.MouseEvent<HTMLButtonElement> | null, keyWord?: string) => {
        if (dateRange.from || dateRange.to || keyWord) {
            showKeyword ? onSubmit(dateRange.from as Date, dateRange.to as Date, keyWord) : onSubmit(dateRange.from as Date, dateRange.to as Date)
        }
    }

    const getSuggestionsSearched = (value: string) => {
        if (value.trim().length < 2) {
            return [];
        }

        return Array.from(new Set(searchInArray<T>(
            dataFiltered && dataFiltered.length ? dataFiltered : [],
            ['assignedGuard'],
            value.trim(),
            2
        ).map((v: any) => v.assignedGuard)))
    };

    const getSuggestionsSearchedValue = (assignedGuard: any) => {
        return `${assignedGuard}`;
    };

    const renderSuggestions = (assignedGuard: any) => (
        <span>{`${assignedGuard}`}</span>
    );
    
    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement | HTMLFormElement | HTMLDivElement>) => {
        if (event.key === 'Enter') {
            onFilterClick(null)
        }
    }

    useEffect(() => {
        const listener = (event: any) => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                handleKeyPress(event);
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [dateRange]);

    return (
        <form className="row mt-4 justify-content-start"
              >
            <div className="form-group ml-3">
                <label className="pr-2 mb-0">{(fromLabel || 'View From') + ':'}</label>
                <div className="react-datepicker-custom-wrapper d-inline-block view-from">
                    <DatePicker
                        onKeyDown={(event) => handleKeyPress(event)}
                        placeholderText="dd/mm/yyyy"
                        dateFormat="dd/MM/yyyy"
                        selected={dateRange.from ? datePickerFormat(dateRange.from as string) : dateRange.from as null}
                        onChange={date => {
                            setDateRange({...dateRange, from: resetUTCTime(date as Date).format()})
                            changeDatapicker(1)
                        }}
                        onInputClick={() => changeDatapicker(1)}
                        open={showDatapicker[1]}
                        onClickOutside={() => changeDatapicker(1)}
                        minDate={isMinDate ? new Date() : null}
                        maxDate={schoolYear ? datePickerFormat(maxEndsOn as string) : null}
                    />
                    <div onClick={() => changeDatapicker(1)}><SVGCalendar /></div>
                </div>
            </div>
            <div className="form-group ml-3">
                <label className="pr-2 mb-0">To:</label>
                <div className="react-datepicker-custom-wrapper d-inline-block view-to">
                    <DatePicker
                        onKeyDown={(event) => handleKeyPress(event)}
                        placeholderText="dd/mm/yyyy"
                        dateFormat="dd/MM/yyyy"
                        selected={dateRange.to ? datePickerFormat(dateRange.to as string) : dateRange.to as null}
                        onChange={date => {
                            setDateRange({...dateRange, to: resetUTCTime(date as Date).format()})
                            changeDatapicker(2)
                        }}
                        onInputClick={() => changeDatapicker(2)}
                        open={showDatapicker[2]}
                        onClickOutside={() => changeDatapicker(2)}
                        minDate={isMinDate ? new Date() : null}
                        maxDate={schoolYear ? datePickerFormat(maxEndsOn as string) : null}
                    />
                    <div onClick={() => changeDatapicker(2)}><SVGCalendar /></div>
                </div>
                {tooltipMessage && (
                    <CustomTooltip
                            bodyText={
                                tooltipMessage
                            }
                        >
                            <button type="button" className="btn btn-transparent px-0 ml-3">
                                <SVGInfo />
                            </button>
                    </CustomTooltip>
                )}
            </div>
            {
                showKeyword &&
                <div className="form-group ml-3" >
                    <AutocompleteField
                        getSuggestions={getSuggestionsSearched}
                        getSuggestionValue={getSuggestionsSearchedValue}
                        renderSuggestion={renderSuggestions}
                        onClear={() => {
                            onClear();
                            setDateRange({
                                from: null,
                                to: null,
                                keyWord: ''
                            })
                        }}
                        onSearch={v => {
                            onFilterClick(null, v)
                        }}
                        placeholder={"Enter guard name"}
                        showClearButton={!isEmpty}
                    />
                </div>
            }
            {  !showKeyword &&
                <div className="form-group ml-1 ml-md-3">
                    <button className={'btn btn-aqua-blue'} type="button" onClick={e => onFilterClick(e)}>
                        <SVGSearch />
                    </button>
                {
                    !isEmpty && <button className="btn btn-aqua-blue ml-2" type="button" onClick={() => {
                        onClear()
                        setDateRange({
                            from: null,
                            to: null,
                            keyWord: ''
                        })
                    }}><SVGEraser /></button>
                }
                </div>
            }
        </form>
    )
}

export default FromToFilter