export enum ValidationRules {
    required,
    notZero,
    passwordMatch,
    notNull,
    lengthFields,
    isValidPhone,
    isValidEmail,
    isValidPostalCode
}

export interface IValidationBody {
    fieldName: string
    errorMessage: string
}

export interface IStepValidation {
    [index: string]: any
    mainForm: string[]
    subForm?: {
        key: string
        fields: string[]
    }
}
