import moment from 'moment'
import React, { FC, useContext, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { IJob, IJobSchedule } from '../../../common/interfaces/jobs/IJob'
import WizardFormContext from '../../../context/jobs/WizardFormContext'
import { DropdownsState } from '../../../pages/jobs/AddEditJob'
import DataTable from '../../tables/DataTable'
import { matchOptionWithName } from '../../../utils/MatchOptionWithName'
import JobContext from '../../../context/jobs/JobContext'
import { matchWeekDays } from '../../../utils/MatchWeekDays'
import { useState } from 'react'
import MainJobData from '../MainJobData'
import { useParams } from 'react-router-dom';
import { SVGPlus } from '../../../assets/icons/SvgIcons'
import { momentDateFormat, momentHoursFormat } from '../../../utils/DateFormatting'
import { useShiftPeriod } from '../../../customHooks/useShiftPeriod'

type Props = {
    job: IJob
    dropdownOpts: DropdownsState
}

const Confirmation: FC<Props> = ({ job, dropdownOpts }) => {
    const { stepHandler, saveJob } = useContext(WizardFormContext)
    const { vocabulary } = useContext(JobContext)
    const history = useHistory();
    const params = useParams<{ id: string }>();
    const [guardSchedules, setGuardSchedules] = useState<IJobSchedule[]>([]);
    const [schedulesNeedGuards, setSchedulesNeedGuards] = useState<IJobSchedule[]>([]);
    const { format, currentHoursTime } = useShiftPeriod()

    useEffect(() => {
        if (params?.id !== undefined) {
            getAssignedSchedules()
        } else {
            setGuardSchedules(job.jobSchedules.filter(schedule => schedule.assigned))
            setSchedulesNeedGuards(job.jobSchedules.filter(schedule => schedule.assigned === false))
        }
    }, [])

    const getAssignedSchedules = useMemo(() =>
        () => {
            setSchedulesNeedGuards([...job.jobSchedules.filter(schedule => (!schedule.guardId && !schedule.assigned) || schedule.guardId === null)])

            setGuardSchedules([
                ...job.jobSchedules
                .filter(schedule => schedule.assigned === true || schedule.guardId)
                .map(schedule => {
                    if (schedule.assignedGuard === undefined) {
                        const assignedSchedule = job.assignedSchedues.find(assignedSchedule =>
                            assignedSchedule.id === schedule.id
                        );
                        if (assignedSchedule) {
                            schedule.assignedGuard = assignedSchedule.assignedGuard
                        }
                    }
                    return schedule
                })
            ])
        }
    , [job.jobSchedules, job.assignedSchedues])

    const checkIfCanAddGuard = (scheduleId: number): boolean => {
        const schedule = schedulesNeedGuards.find(schedule => schedule.id === scheduleId || schedule.uid === scheduleId)
        if (schedule && schedule.guardId) {
            return false
        } else if (schedule && moment(moment().format("YYYY-MM-DD")).isSame(moment.utc(schedule.endsOn).format("YYYY-MM-DD"))) {
            if (moment(momentHoursFormat(schedule.timeTo), format).isBefore(moment(currentHoursTime, format))) {
                return false
            } else return true;
        }
        else { return true }
    }

    return (
        <>
            <div className="row">
                <div className="col-md-9 mx-auto d-flex justify-content-end mb-3">
                    <div className="d-flex">
                        <h6>Job ID: { job.jobId }</h6>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-9 mx-auto form-group d-flex">
                    <h4> {job.jobName ? job.jobName : `${ job.firstStreet } ${job.secondStreet}`}</h4>
                </div>
            </div>

           <div className="row">
               <div className="col-md-9 mx-auto">
                  <MainJobData
                    job={job}
                    dropdownOpts={dropdownOpts}
                    jobDetailsPage={false}
                  />
               </div>
           </div>

            <div className="row">
                <div className="col-md-9 mx-auto">
                    <div className="row">
                        <div className="col-12 mt-4 mb-3">
                            <h5>Job Schedule</h5>
                        </div>
                        <div className="col-12">
                            <DataTable
                                thead={[ {label: "Start and End Dates"}, {label: "Start and End Times"}, {label: "Shift Period"}, {label: "Repeat"} ]}
                                tbody={job.jobSchedules.map(schedule => (
                                    {
                                        dates: `${momentDateFormat(schedule.startDate)} - ${momentDateFormat(schedule.endsOn)}`,
                                        times: `${momentHoursFormat(schedule.timeFrom)} - ${momentHoursFormat(schedule.timeTo)}`,
                                        shiftPeriod: matchOptionWithName(schedule.shiftPeriod, vocabulary),
                                        repeat: schedule.repeatOn ? `Every week: ${matchWeekDays(schedule.repeatOn)}` : ""
                                    }
                                ))}
                                tableClass={'table-info-job-schedule'}
                            />
                        </div>
                    </div>
                </div>


                <div className="col-md-9 mx-auto">
                    <div className="row">
                        <div className="col-12 mt-4 mb-3">
                            <h5>Job Guard Schedule</h5>
                        </div>

                        <div className="col-12">
                            <DataTable
                                thead={[ {label: "Assigned Guard"}, {label: "Dates"}, {label: "Times"}, {label: "Shift"}, {label: "Repeat"} ]}
                                tbody={guardSchedules
                                    .map(schedule => (
                                    {
                                        assignedGuard: schedule.assignedGuard,
                                        dates: `${momentDateFormat(schedule.startDate)} - ${momentDateFormat(schedule.endsOn)}`,
                                        times: `${momentHoursFormat(schedule.timeFrom)} - ${momentHoursFormat(schedule.timeTo)}`,
                                        shiftPeriod: matchOptionWithName(schedule.shiftPeriod, vocabulary),
                                        repeat: schedule.repeatOn ? `Every week: ${matchWeekDays(schedule.repeatOn)}` : ""
                                    }
                                ))}
                                tableClass={'table-info-job-schedule guard'}
                            />
                        </div>
                    </div>
                </div>

            </div>

            <div className="row">
                <div className="col-md-9 mx-auto">
                    <div className="row">
                    {
                        schedulesNeedGuards.length !== 0 &&
                        <>
                        <div className="col-12 mt-4 mb-3">
                            <h4>Schedules for this Job Still in Need of Guards</h4>
                        </div>
                        <div className="col-12">
                            <DataTable
                                thead={[ {label: "Dates"}, {label: "Times"}, {label: "Shift"}, {label: "Repeat"}, {label: "Add Guard"} ]}
                                tbody={schedulesNeedGuards
                                    .map(schedule => (
                                    {
                                        id: schedule.id ? schedule.id : schedule.uid,
                                        dates: `${momentDateFormat(schedule.startDate)} - ${momentDateFormat(schedule.endsOn)}`,
                                        times: `${momentHoursFormat(schedule.timeFrom)} - ${momentHoursFormat(schedule.timeTo)}`,
                                        shiftPeriod: matchOptionWithName(schedule.shiftPeriod, vocabulary),
                                        repeat: schedule.repeatOn ? `Every week: ${matchWeekDays(schedule.repeatOn)}` : ""
                                    }
                                ))}
                                ignoreCols={[0]}
                                tableClass={'table-info--notFixed'}
                            >
                                {
                                    id => (
                                        <td className="align-middle">
                                            <div>
                                                {
                                                    <button type="button" className={`btn btn-aqua-blue ${checkIfCanAddGuard(id) ? '' : "btn-disabled"}`} onClick={() => checkIfCanAddGuard(id) && stepHandler()}>
                                                        <SVGPlus/>
                                                    </button>
                                                }
                                            </div>
                                        </td>
                                    )
                                }
                            </DataTable>
                        </div>
                        </>
                    }
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-9 mx-auto d-flex justify-content-lg-between mt-4 bottom-nav-card">
                    <div className="d-flex flex-row">
                        <button type="button" className="btn btn-outline-aqua-blue px-4 mr-2" onClick={() => stepHandler()}>
                            Back
                        </button>
                        <button type="button" className="btn btn-outline-aqua-blue px-4 mr-1" onClick={() => history.push('/jobs')}>
                            Cancel
                        </button>
                    </div>
                    <button type="button" className="btn btn-aqua-blue px-4 ml-auto" onClick={() => saveJob()}>
                        Save Job
                    </button>
                </div>
            </div>
        </>
    )
}

export default Confirmation
