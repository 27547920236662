import React, {FC, useContext, useEffect, useState} from 'react'
import {IBaseModalProps} from '../../../common/interfaces/IModal'
import BaseModal from '../BaseModal'
import CustomSelectControl from '../../custom-select/CustomSelectControl'
import {IClientDropdown} from "../../../common/interfaces/dropdowns/IClientDropDown";
import DatePicker from "react-datepicker";
import {datePickerFormat, filterDateFormat, momentDateFormat, setHoursAndMinutes} from "../../../utils/DateFormatting";
import {SVGCalendar, SVGWarning} from "../../../assets/icons/SvgIcons";
import {useDatapickerSvg} from "../../../customHooks/useDatapickerSvg";
import {useOnIputChange} from "../../../customHooks/useOnInputChange";
import {SchoolYear} from "../../../common/models/Administration";
import {IValidationBody, ValidationRules} from "../../../common/interfaces/IValidation";
import {highlightError, removeHighlightError, validateFiled} from "../../../utils/Validation";
import {NotificationTypes} from "../../../common/interfaces/INotification";
import AppContext from "../../../context/AppContext";
import {ISchoolYear} from "../../../common/interfaces/administration/ISchoolYear";

interface Props extends IBaseModalProps {
    titleBody: string;
    selectedSchoolYear?: ISchoolYear,
    isEditMode: boolean
    clients: IClientDropdown[]
}

const AddEditSchoolYearModal: FC<Props> = ({onCancel, onSubmit, titleBody, selectedSchoolYear, clients, isEditMode}) => {
    const {showNotification} = useContext(AppContext)
    const [textError, setTextError] = useState({
        equality: '',
        searchRange: ''
    })

    const {showDatapicker, changeDatapicker} = useDatapickerSvg()
    const {onChange, hookState: formData, setHookState} = useOnIputChange(new SchoolYear())
    useEffect(() => {
        if (selectedSchoolYear && isEditMode) setHookState(selectedSchoolYear);
    }, [])


    const validateHolidayFields = (callback: () => void) => {
        let errors: IValidationBody[] = [];
        for (let [field, value] of Object.entries(formData)) {
            const skipFields = ['id', 'clientId', 'clientName']
            if (!skipFields.includes(field)) {
                errors = [...errors, ...validateFiled(value as string, field, ValidationRules.required)]
            }
        }
        if (errors.length !== 0) {
            showNotification(NotificationTypes.warning, "Please fill in all fields correctly")
            highlightError(errors)
        } else {
            removeHighlightError()
            callback()
        }
    }
    const filterDateSchoolYear = () => {
        const searchRange = clients.filter((client: IClientDropdown) => client.id === formData.clientId)

        if (searchRange.length) {
            let result: any;
            if (isEditMode) {
                result = searchRange[0].schoolYears
                    .filter(item => item.id !== +selectedSchoolYear!.id)
                    .filter(year => (year.startDate <= formData.startDate && formData.startDate <= year.endDate) || (year.startDate <= formData.endDate && formData.endDate <= year.endDate) || (`${momentDateFormat(year.startDate)}` === (`${momentDateFormat(formData.startDate)}`) || (`${momentDateFormat(formData.startDate)}` === `${momentDateFormat(year.endDate)}`) || (`${momentDateFormat(year.startDate)}` === `${momentDateFormat(formData.endDate)}`) || (`${momentDateFormat(formData.endDate)}` === `${momentDateFormat(year.endDate)}`)))
            } else {
                result = searchRange[0].schoolYears
                    .filter(year => (year.startDate <= formData.startDate && formData.startDate <= year.endDate) || (year.startDate <= formData.endDate && formData.endDate <= year.endDate)
                        || (`${momentDateFormat(year.startDate)}` === (`${momentDateFormat(formData.startDate)}`) || (`${momentDateFormat(formData.startDate)}` === `${momentDateFormat(year.endDate)}`) || (`${momentDateFormat(year.startDate)}` === `${momentDateFormat(formData.endDate)}`) || (`${momentDateFormat(formData.endDate)}` === `${momentDateFormat(year.endDate)}`))
                    )
            }
            if (result && result.length) {
                return false
            } else {
                return true
            }
        }

    }

    const onSaveDataClick = () => {
        if (formData.startDate < formData.endDate) {
            if (filterDateSchoolYear()) {
                setTextError(prevState => ({
                    ...prevState,
                    equality: '',
                    searchRange: ''
                }))
                onSubmit && validateHolidayFields(() => onSubmit(formData))
            } else {
                setTextError(prevState => ({
                    ...prevState,
                    equality: '',
                    searchRange: 'This school year intersects with already existing school year'
                }))
            }

        } else {
            if (filterDateSchoolYear()) {
                setTextError(prevState => ({
                    ...prevState,
                    equality: 'Date "To" must be more than Date "From"'
                }))
            } else {
                setTextError(prevState => ({
                    ...prevState,
                    equality: 'Date "To" must be more than Date "From"',
                    searchRange: 'This school year intersects with already existing school year'

                }))
            }
        }
    }

    return (
        <>
            <BaseModal
                show={true}
                onCancel={() => onCancel()}
                onSubmit={() => onSaveDataClick()}
                submitBtnText={'Save'}
                title={titleBody}
            >

                <form onSubmit={e => e.preventDefault()}>
                    <div className="row">
                        {!isEditMode && <div className="col-md-12">
                            <div className="form-group">
                                <label className="required">Choose a Client:</label>
                                <CustomSelectControl
                                    options={clients.map(client => (
                                        {
                                            id: client.id,
                                            name: client.name
                                        }
                                    ))}
                                    onChange={val => onChange(val, 'clientId')}
                                    value={formData.clientId}
                                    placeholder={"Select a client"}
                                    className={`custom-react-select--boardId`}
                                />
                            </div>
                        </div>
                        }


                        <div className="col-md-6 form-group">
                            <label className="required mr-3">From: </label>
                            <div className="react-datepicker-custom-wrapper d-inline-block">
                                <DatePicker
                                    selected={datePickerFormat(formData.startDate)}
                                    onChange={date => {
                                        date && onChange(setHoursAndMinutes(date as Date), "startDate")
                                        changeDatapicker(1)
                                    }}
                                    dateFormat="dd/MM/yyyy"
                                    onInputClick={() => changeDatapicker(1)}
                                    open={showDatapicker[1]}
                                    onClickOutside={() => changeDatapicker(1)}
                                />
                                <div onClick={() => changeDatapicker(1)}><SVGCalendar/></div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <div className="ml-0 ml-lg-3">
                                <label className="required mr-4">To: </label>
                                <div className="react-datepicker-custom-wrapper d-inline-block ml-auto">
                                    <DatePicker
                                        selected={datePickerFormat(formData.endDate)}
                                        onChange={date => {
                                            date && onChange(setHoursAndMinutes(date as Date), "endDate")
                                            changeDatapicker(2)
                                        }}
                                        dateFormat="dd/MM/yyyy"
                                        onInputClick={() => changeDatapicker(2)}
                                        open={showDatapicker[2]}
                                        onClickOutside={() => changeDatapicker(2)}
                                    />
                                    <div onClick={() => changeDatapicker(2)}><SVGCalendar/></div>
                                </div>
                            </div>
                        </div>
                        {
                            (textError.searchRange || textError.equality) && <div className="col-12">
                                <div className="holidays-warning d-flex flex-row align-items-center mb-3">
                                    <div className="flex-grow-1 flex-md-grow-0">
                                        <SVGWarning className="mr-3"/>
                                    </div>
                                    <div>
                                        <small className="d-inline-block">{textError.equality}</small>
                                        <small className="d-inline-block">{textError.searchRange}</small>
                                    </div>
                                </div>

                            </div>
                        }
                    </div>
                </form>
            </BaseModal>
        </>
    )
}

export default AddEditSchoolYearModal
