import { healthAndCheckFilter } from "common/models/XGuardReportsFilters";
import DataTable from "components/tables/DataTable";
import { guardAppProfileRequestsThead } from "content/xguard-reports/tableContent";
import { useTableList } from "customHooks/useTableList";
import useXGuardReportsQuery from "customHooks/xguard-reports/useXGuardReportsQuery";
import { datePickerFormat, momentDateFormat, setHoursAndMinutes } from "utils/DateFormatting";
import Layout from "../../../components/layout/Layout";
import SectionTitle from "../../../components/titles/SectionTitle";
import DatePicker from "react-datepicker";
import { SVGApprove, SVGCalendar, SVGCancel, SVGDownload } from "assets/icons/SvgIcons";
import { useDatapickerSvg } from "customHooks/useDatapickerSvg";
import AutocompleteField from "components/autosuggestField/AutocompleteField";
import { useContext, useMemo } from "react";
import AppContext from "context/AppContext";
import { useDowloandFile } from "customHooks/useDownloadFile";
import { Reports } from "common/enums/Reports";
import { IGuardAppProfileUpdate } from "common/interfaces/reports/xguard-reports/IGuardAppProfileUpdate";
import { ActivationDeactivation } from 'common/enums/Actions';
import useActivateDeactivate from 'customHooks/useActivateDeactivate';
import BaseModal from "components/modal/BaseModal";
import ProfileUpdateBodyModal from "components/modal/reports/ProfileUpdateBodyModal";
import { useVocabulary } from "customHooks/vocabulary/useVocabulary";
import { VocabularyEnum } from "common/enums/VocabularyEnum";
import { matchOptionWithName } from "utils/MatchOptionWithName";

const GuardAppProfileUpdateReports = () => {
  const { showLoader } = useContext(AppContext)
  const { fetchData: fetchReports, tableData: reports,
    isLoading, currentPage, perPage, allRecords, onPagination, onSortCall,
    onPerPage } = useTableList<IGuardAppProfileUpdate>("reports/update-requests", 24)
  const {generateQueryParams, onQueryParamChanged,
    queryParams} = useXGuardReportsQuery({
      baseUrl: "reports/update-requests",
      defaultParams: healthAndCheckFilter
    })
  const {showDatapicker, changeDatapicker} = useDatapickerSvg()
  const { dowloandFile } = useDowloandFile()
  const { onRequestHandler, onRequestSubmitHandler, requestModals, modalText, onCloseRequestModals, itemInFocus } =
  useActivateDeactivate<IGuardAppProfileUpdate>('guard/updaterequest');
  const { vocabulary: provinceVoc } = useVocabulary(VocabularyEnum.province, true)

  const onDownload = async () => {
    // TODO: Check functionality after back-end updates
    showLoader(true)

    await dowloandFile('report/update-requests',
    Reports.excel, Reports.download, '', '',
    `&${generateQueryParams().split("?")[1]}`, true)

    showLoader(false)
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    fetchReports(1, 24, generateQueryParams())
    event.preventDefault();
  }

  const getSingleReport = useMemo(() =>
    (reportId: number) => {
      const matchedReport = reports.find(report => report.id === reportId);
      if (matchedReport) return {
        currentData: matchedReport.updateRequestData.currentData,
        requestedData: matchedReport.updateRequestData.requestedData,
      }
    }
  ,[reports])

  const updateRequestedField = useMemo(() => (
    (report: IGuardAppProfileUpdate): string => {
      const { province } = report.updateRequestData.requestedData
      if ((!report.updateRequestData || Object.keys(report.updateRequestData.requestedData).length === 0)) return ""
      province && (report.updateRequestData.requestedData.province = matchOptionWithName(province, provinceVoc))
      return Object.values(report.updateRequestData.requestedData).join(', ')
    }
  ), [provinceVoc])

  return (
    <Layout
      breadcrumbs={{
        links: [{
            title: 'Reports',
            link: 'reports'
        }],
        currentPageTitle: 'Guard App Profile Update Requests'
      }}
      className={'reports-screen'}
    >
      <div className="row mb-3">
        <div className="col-12">
          <SectionTitle title={'Guard App Profile Update Requests Report'}/>
        </div>
      </div>

      <form
        onSubmit={handleSubmit}
      >
        <div className="row">

          <div className="col-md-4 mb-md-4">
            <div className="row">
              {/* Start Guard Name Search */}
              <div className="col-12 form-group shfit-autosegest">
                <label htmlFor="guardNameOrEmployeeNumber">Guard Name or Employee ID:</label>
                <AutocompleteField
                  showButton={false}
                  defaultValue={!queryParams.guardNameOrEmployeeNumber ? true : false}
                  includes={'Guard'}
                  isAsync={true}
                  placeholder='Guard Name or Employee ID'
                  onSearch={async (value) => {
                      onQueryParamChanged(value, 'guardNameOrEmployeeNumber')
                  }}
                  onChange={(value) => onQueryParamChanged(value, 'guardNameOrEmployeeNumber')}
                  onClear={() => onQueryParamChanged('', 'guardNameOrEmployeeNumber')}/>
              </div>
              {/* End Guard Name Search */}
            </div>
          </div>

          {/* Start Date Filters */}
          <div
            className="col-md-4 form-group mb-md-4">
              <div className="row d-flex flex-column">

                <div className="col-12 mb-3">
                  <label>Update Request Received From: </label>
                  <div
                      className="react-datepicker-custom-wrapper react-datepicker-custom-wrapper--full-width d-inline-block w-100">
                      <DatePicker
                          selected={datePickerFormat(queryParams.startDate)}
                          onChange={date => {
                            onQueryParamChanged(date ? setHoursAndMinutes(date as Date) : '', 'startDate')
                            changeDatapicker(1);
                          }}
                          dateFormat="dd/MM/yyyy"
                          onInputClick={() => changeDatapicker(1)}
                          open={showDatapicker[1]}
                          onClickOutside={() => changeDatapicker(1)}
                          placeholderText="dd/mm/yyyy"
                      />
                      <div onClick={() => changeDatapicker(1)}><SVGCalendar/></div>
                  </div>
                </div>
                <div className="col-12">
                  <label>To: </label>
                  <div
                    className="react-datepicker-custom-wrapper react-datepicker-custom-wrapper--full-width d-inline-block w-100">
                    <DatePicker
                      selected={datePickerFormat(queryParams.endDate)}
                      onChange={date => {
                        onQueryParamChanged(date ? setHoursAndMinutes(date as Date) : '', 'endDate')
                        changeDatapicker(2);
                      }}
                      dateFormat="dd/MM/yyyy"
                      onInputClick={() => changeDatapicker(2)}
                      open={showDatapicker[2]}
                      onClickOutside={() => changeDatapicker(2)}
                      placeholderText="dd/mm/yyyy"
                    />
                    <div onClick={() => changeDatapicker(2)}><SVGCalendar/></div>
                  </div>
                </div>

              </div>
          </div>
          {/* End Date Filters */}
        </div>

        <div className="row mb-5">
          <div className="col-12 text-right">
            <button
              type="submit"
              className="btn btn-outline-aqua-blue mr-2"
              >
              Update Report
            </button>
            <button
              type="button"
              className="btn btn-aqua-blue"
              onClick={onDownload}
            ><SVGDownload /></button>
          </div>
        </div>

      </form>

      {
        useMemo(() => (
          <div className="row">
            <div className="col-lg-12 mx-lg-auto">
              <DataTable
                thead={guardAppProfileRequestsThead.thead}
                tbody={reports ? reports.map(report => ({
                  id: report.id,
                  date: momentDateFormat(report.dateRequestReceived) || "",
                  guardName: `<a href="/#/guards/${report.guardId}/details" target="_blank">${report.guardName}</a>`,
                  employeeId: `<a href="/#/guards/${report.guardId}/details" target="_blank">${report.employeeId}</a>`,
                  updateRequestData: updateRequestedField(report),
                })) : []}
                isLoading={isLoading}
                ignoreCols={[0]}
                currentPage={currentPage}
                itemsCount={allRecords}
                itemsPerPage={+perPage}
                onPager={page => onPagination(page)}
                onPerPage={value => onPerPage(value)}
                onSort={(orderBy, desc) => onSortCall(orderBy, desc, queryParams.keyWord)}
                defaultSortedColumn={0}
                tableName={"Guard App Profile Update Requests"}
                tableClass={'table-info--notFixed'}
                pagination
                sortOnBackend
                showTableLengthData
              >
                {
                  (id, rowItem: IGuardAppProfileUpdate) => (
                    <td className="aling-middle">
                      <div>
                        <button
                            className={`btn btn-aqua-blue`}
                            onClick={() => onRequestHandler(
                              // {}
                              {
                                title: `<h4>Do you want to approve this profile update
                                for <span class="font-weight-bold">${rowItem.guardName}</span>?</h4>
                                ${ProfileUpdateBodyModal({
                                  currentData: getSingleReport(id)?.currentData,
                                  requestedData: getSingleReport(id)?.requestedData,
                                  vocabulary: [provinceVoc]
                                })}`,
                                itemInFocus: rowItem
                              }
                            )}
                        >
                          <SVGApprove />
                        </button>
                        <button
                          className={`btn btn-aqua-blue ml-2`}
                          onClick={() => onRequestHandler(
                            {
                              title: `<h4>Do you want to decline the Profile Update Request
                              for <span class="font-weight-bold">${rowItem.guardName}</span>?</h4>`,
                              itemInFocus: rowItem,
                              type: ActivationDeactivation.disapprove
                            }
                          )}
                        >
                          <SVGCancel/>
                        </button>
                      </div>
                    </td>
                  )
                }
              </DataTable>
            </div>
          </div>
        ), [reports, isLoading, currentPage, allRecords, perPage, onRequestHandler,
          onPagination, onPerPage, onSortCall, queryParams.keyWord, getSingleReport])
      }

      {
        (requestModals.showRequest || requestModals.showDissaprove) && <BaseModal
          show={requestModals.showRequest || requestModals.showDissaprove}
          onCancel={onCloseRequestModals}
          onSubmit={() => onRequestSubmitHandler(requestModals.showRequest ? ActivationDeactivation.approve : ActivationDeactivation.disapprove,
            { itemData: null, successCallback: () => fetchReports(1, 24) },
            requestModals.showRequest ?
            `The Profile Update for <span class="font-weight-bold">${itemInFocus.guardName}</span> has been confirmed.<br />
            <p class="mt-4">An automatic message has been sent to:</p>
            <ul>
              <li>${itemInFocus.guardName}</li>
              <li>Resource Planning</li>
              <li>Human Resources</li>
            </ul>
            ` :
            `The Profile Update Request from <span class="font-weight-bold">${itemInFocus.guardName}</span> has been denied.
            <p class="mt-4">An automatic message has been sent to the guard</p>`
          )}
          submitBtnText={"Confirm"}
          className={"edit-schedule-modal"}
        >
          <div dangerouslySetInnerHTML={{ __html: modalText.title }} />
        </BaseModal>
      }

      {
        requestModals.showSuccess && <BaseModal
          show={requestModals.showSuccess}
          onCancel={() => onCloseRequestModals()}
          cancelBtnText={"Close"}
        >
          <h4 className="text-left mb-4" dangerouslySetInnerHTML={{ __html: modalText.title }} />
        </BaseModal>
      }

    </Layout>
  )
}

export default GuardAppProfileUpdateReports
