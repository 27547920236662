import React, {useEffect, useState, useMemo} from 'react'
import DatePicker from "react-datepicker";
import {datePickerFormat, setCustomTimeInUTC, setHoursAndMinutes, timeWithoutSecondsFormat, UTCtimeToLocal} from "../../../utils/DateFormatting";
import {SVGCalendar, SVGEraser} from "../../../assets/icons/SvgIcons";
import {useDatapickerSvg} from "../../../customHooks/useDatapickerSvg";
import CustomSelectControl from "../../../components/custom-select/CustomSelectControl";
import CustomTimePicker from "../../../components/custom-time-picker/CustomTimePicker";
import {IShiftJobReportModel} from "../../../common/interfaces/IDataFilter";
import {useListFilterJobShiftReport} from "../../../customHooks/useListFilterJobShiftReport";
import SectionTitle from "../../../components/titles/SectionTitle";
import ListJobShiftReport from "./ListJobShiftsReport";
import Layout from "../../../components/layout/Layout";
import AutocompleteField from "../../../components/autosuggestField/AutocompleteField";
import useReportActions from '../../../customHooks/reports/useReportActions';
import { CheckInOutTypeEnum, CheckInTypeLabelEnum, CheckOutTypeLabelEnum } from 'common/enums/CheckInOutTypeEnum';

const JobShiftReport = () => {
    const {generateFilterQuery, onFilterDropdownChange, filter, onFilterClearClick, onFilterDropdownChangeAsync} = useListFilterJobShiftReport('reports/shifts')
    const { holidays, schoolBoards, onChangeMultiSelectString, fetchHolidays, fetchAdditionalData,
        fetchHolidaysBySchoolBoard, showTable, setShowTable } = useReportActions(filter);
    const {showDatapicker, changeDatapicker} = useDatapickerSvg()
    const [generateQuery, setGenerateQuery] = useState('')

    useEffect(() => {
        filter.schoolBoard && fetchHolidaysBySchoolBoard()
    }, [filter.schoolBoard])

    useEffect(() => {
        fetchAdditionalData()
        onFilterClearClick()
    }, [])

    useEffect(() => {
        !filter.schoolBoard && fetchHolidays()
    }, [filter.schoolBoard])

    const clearTime = async () => {
        await onFilterDropdownChangeAsync(setCustomTimeInUTC(6).format(), 'timeFrom');
        setTimeout(() => {
            onFilterDropdownChangeAsync(setCustomTimeInUTC(18).format(), 'timeTo');
        }, 150)
    }

    const handleCheckBox = (type: keyof IShiftJobReportModel, index: number) => {
        onChangeMultiSelectString(type, index, (result, type) => onFilterDropdownChange(result, type))
    }

    const onClickCreateReport = (): void => {
        setShowTable(true)
        setGenerateQuery(generateFilterQuery())
    }
    return (

        <Layout
            breadcrumbs={{
                links: [{
                    title: 'Reports',
                    link: 'reports'
                }],
                currentPageTitle: 'Job Shifts Report'
            }}
            className={'reports-screen'}
        >
            <div className="row mb-3">
                <div className="col-12">
                    <SectionTitle
                        title={'Job Shifts Report'}
                        className="mb-3"    
                    />
                    <p className='fs-14'>This report can be created based on any of the criteria below.
                        The results will show you past, present, or future shifts, depending on the dates you select.
                        You will only be able to Edit a shift, if it is within the last 72 hours.
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="d-flex justify-content-between align-items-center">
                        <h5 className="mb-0">Report Preferences</h5>
                        <button type="button" className="btn btn-aqua-blue" onClick={() => {
                            onFilterClearClick()
                            setShowTable(false)
                            clearTime()
                        }}><SVGEraser />
                        </button>
                    </div>
                </div>
            </div>
            <form onSubmit={e => {
                e.preventDefault()
            }}  onKeyDown={(event) => {
                if (event.key === 'Enter') onClickCreateReport()
            }}>
                {
                    React.useMemo(
                        () => (
                            <>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-md-6 form-group shfit-autosegest mb-md-4">
                                                <label htmlFor="firstStreet">Job Name or ID</label>
                                                <AutocompleteField
                                                    showButton={false}
                                                    includes={'Job'}
                                                    isAsync={true}
                                                    defaultValue={!filter.jobNameOrId ? true : false}
                                                    placeholder='Enter Job Name or ID'
                                                    onSearch={async (value) => {
                                                        onFilterDropdownChange(value, 'jobNameOrId')
                                                    }}
                                                    onChange={(value) => onFilterDropdownChange(value, 'jobNameOrId')}
                                                    onClear={() => onFilterDropdownChange('', 'jobNameOrId')}/>
                                            </div>
                                            <div
                                                className="col-md-6 form-group d-flex flex-row align-items-end flex-wrap mb-md-4">
                                                <div className="col-6 pl-0">
                                                    <div className="mb-0 d-flex align-items-center">
                                                        <label>From: </label>
                                                    </div>
                                                    <div
                                                        className="react-datepicker-custom-wrapper react-datepicker-custom-wrapper--full-width d-inline-block w-100">
                                                        <DatePicker
                                                            selected={datePickerFormat(filter.startDate!)}
                                                            onChange={date => {
                                                                date && onFilterDropdownChange(setHoursAndMinutes(date as Date), 'startDate')
                                                                changeDatapicker(1);
                                                            }}
                                                            dateFormat="dd/MM/yyyy"
                                                            onInputClick={() => changeDatapicker(1)}
                                                            open={showDatapicker[1]}
                                                            onClickOutside={() => changeDatapicker(1)}
                                                        />
                                                        <div onClick={() => changeDatapicker(1)}><SVGCalendar/></div>
                                                    </div>
                                                </div>
                                                <div className="col-6 pr-0">
                                                    <div className="mb-0 d-flex align-items-center">
                                                        <label>To: </label>
                                                    </div>
                                                    <div
                                                        className="react-datepicker-custom-wrapper react-datepicker-custom-wrapper--full-width d-inline-block w-100">
                                                        <DatePicker
                                                            selected={datePickerFormat(filter.endDate!)}
                                                            onChange={date => {
                                                                date && onFilterDropdownChange(setHoursAndMinutes(date as Date), 'endDate')
                                                                changeDatapicker(2);
                                                            }}
                                                            dateFormat="dd/MM/yyyy"
                                                            onInputClick={() => changeDatapicker(2)}
                                                            open={showDatapicker[2]}
                                                            onClickOutside={() => changeDatapicker(2)}
                                                        />
                                                        <div onClick={() => changeDatapicker(2)}><SVGCalendar/></div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6 form-group mb-md-4">
                                                <label htmlFor="firstStreet">Shift Period</label>
                                                <div
                                                    className="custom-control custom-checkbox d-flex flex-wrap align-items-center mt-2">
                                                    <div className="mr-5">
                                                        <input
                                                            type="checkbox"
                                                            checked={(filter.shiftPeriod!.indexOf('42') >= 0) ? true : false}
                                                            onChange={() => handleCheckBox('shiftPeriod', 42)}
                                                            className="custom-control-input" id="Morning"
                                                            name="Morning"/>
                                                        <label className="custom-control-label mb-0"
                                                               htmlFor="Morning">Morning</label>
                                                    </div>
                                                    <div className="mr-5 ml-2">
                                                        <input
                                                            type="checkbox"
                                                            checked={(filter.shiftPeriod!.indexOf('43') >= 0) ? true : false}
                                                            onChange={() => handleCheckBox('shiftPeriod', 43)}
                                                            className="custom-control-input"
                                                            id="Afternoon"
                                                            name="Afternoon"/>
                                                        <label className="custom-control-label mb-0"
                                                               htmlFor="Afternoon">Lunch</label>
                                                    </div>
                                                    <div className="ml-0 ml-sm-2">
                                                        <input
                                                            type="checkbox"
                                                            checked={(filter.shiftPeriod!.indexOf('44') >= 0) ? true : false}
                                                            onChange={() => handleCheckBox('shiftPeriod', 44)}
                                                            className="custom-control-input"
                                                            id="Dismissal"
                                                            name="Dismissal"/>
                                                        <label className="custom-control-label mb-0"
                                                               htmlFor="Dismissal">Dismissal</label>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div
                                                className="col-md-6 form-group d-flex flex-row align-items-end flex-wrap mb-md-4">
                                                <div className="col-12 col-xl-6 pl-0">
                                                    <div className="form-group mb-0 d-flex align-items-center">
                                                        <label>From: </label>
                                                    </div>
                                                    <CustomTimePicker
                                                        value={filter.timeFrom ? UTCtimeToLocal(filter.timeFrom) : undefined}
                                                        onChange={(time) => (
                                                            onFilterDropdownChange(timeWithoutSecondsFormat(time), 'timeFrom')
                                                        )}
                                                        index={1}
                                                    />

                                                </div>
                                                <div className="col-12 col-xl-6 pr-0 pl-0 pl-xl-3">
                                                    <div className="form-group mb-0 d-flex align-items-center">
                                                        <label>To: </label>
                                                    </div>
                                                    <CustomTimePicker
                                                        value={filter.timeTo ? UTCtimeToLocal(filter.timeTo) : undefined}
                                                        onChange={(time) => (
                                                            onFilterDropdownChange(timeWithoutSecondsFormat(time), 'timeTo')
                                                        )}
                                                        index={2}
                                                    />

                                                </div>
                                            </div>
                                            <div className="col-md-6 form-group mb-md-4">
                                                <label>School Board</label>
                                                <CustomSelectControl
                                                    options={schoolBoards}
                                                    onChange={e => onFilterDropdownChange(e, "schoolBoard")}
                                                    value={filter.schoolBoard}
                                                    placeholder={"Select a School Board"}
                                                    className={`custom-react-select--clientId`}
                                                    isMulti={true}
                                                />
                                            </div>
                                            <div className="col-md-6 form-group mb-md-4">
                                                <label>Holidays</label>
                                                <CustomSelectControl
                                                    options={holidays}
                                                    onChange={e => onFilterDropdownChange(e, "holidayName")}
                                                    value={filter.holidayName}
                                                    placeholder={"Select a Holidays"}
                                                    className={`custom-react-select--clientId`}
                                                    isMulti={true}
                                                />
                                            </div>
                                            <div className="col-md-6 form-group">
                                                <label htmlFor="firstStreet">Zone</label>
                                                <div
                                                    className="custom-control custom-checkbox d-flex align-items-center mt-2">
                                                    <div className="mr-5">
                                                        <input
                                                            type="checkbox"
                                                            checked={(filter.zone!.indexOf('2') >= 0) ? true : false}
                                                            onChange={() => handleCheckBox('zone', 2)}
                                                            className="custom-control-input" id="North"
                                                            name="North"/>
                                                        <label className="custom-control-label mb-0"
                                                               htmlFor="North">North</label>
                                                    </div>
                                                    <div className="mr-5 ml-2">

                                                        <input
                                                            type="checkbox"
                                                            checked={(filter.zone!.indexOf('1') >= 0) ? true : false}
                                                            onChange={() => handleCheckBox('zone', 1)}
                                                            className="custom-control-input" id="West"
                                                            name="West"/>

                                                        <label className="custom-control-label mb-0"
                                                               htmlFor="West">West</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Start Divider */}
                                <div className="row">
                                    <div className="col-12">
                                        <span className="divider"></span>
                                    </div>
                                </div>
                                {/* End Divider */}

                                <div className="row mb-3">
                                    <div className="col-12">
                                        <div className="d-flex align-items-center">
                                            <h5 className="mb-0">Guard Details for Desired Shift Report</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <div className="row mb-md-4">
                                            <div className="col-md-6 form-group shfit-autosegest">
                                                <label htmlFor="firstStreet">Guard Name or Employee Number</label>

                                                <AutocompleteField
                                                    showButton={false}
                                                    defaultValue={!filter.guardNameOrEmployeeNumber ? true : false}
                                                    includes={'Guard'}
                                                    isAsync={true}
                                                    placeholder='Guard Name or Employee Number'
                                                    onSearch={async (value) => {
                                                        onFilterDropdownChange(value, 'guardNameOrEmployeeNumber')
                                                    }}
                                                    onChange={(value) => onFilterDropdownChange(value, 'guardNameOrEmployeeNumber')}
                                                    onClear={() => onFilterDropdownChange('', 'guardNameOrEmployeeNumber')}
                                                />
                                            </div>
                                            <div className="col-md-6 form-group mt-3 mt-md-0">
                                                <label htmlFor="firstStreet">Guard Status on Shift</label>
                                                <div
                                                    className="custom-control custom-checkbox d-flex flex-wrap align-items-center mt-2">
                                                    <div className="mr-5">
                                                        <input
                                                            type="checkbox"
                                                            checked={(filter.guardStatusOnShift!.indexOf('1') >= 0) ? true : false}
                                                            onChange={() => handleCheckBox('guardStatusOnShift', 1)}
                                                            className="custom-control-input"
                                                            id="Assigned"
                                                            name="Assigned"/>
                                                        <label className="custom-control-label mb-0"
                                                               htmlFor="Assigned">Assigned</label>
                                                    </div>
                                                    <div className="mr-5 ">
                                                        <input
                                                            type="checkbox"
                                                            checked={(filter.guardStatusOnShift!.indexOf('2') >= 0) ? true : false}
                                                            onChange={() => handleCheckBox('guardStatusOnShift', 2)}
                                                            className="custom-control-input"
                                                            id="Unassigned"
                                                            name="Unassigned"/>
                                                        <label className="custom-control-label mb-0"
                                                               htmlFor="Unassigned">Unassigned</label>
                                                    </div>
                                                    <div className="mr-5">
                                                        <input
                                                            type="checkbox"
                                                            checked={(filter.guardStatusOnShift!.indexOf('3') >= 0) ? true : false}
                                                            onChange={() => handleCheckBox('guardStatusOnShift', 3)}
                                                            className="custom-control-input"
                                                            id="Guard Absent"
                                                            name="Guard Absent"/>
                                                        <label className="custom-control-label mb-0"
                                                               htmlFor="Guard Absent">Guard Absent</label>
                                                    </div>
                                                    <div className="">
                                                        <input
                                                            type="checkbox"
                                                            checked={(filter.guardStatusOnShift!.indexOf('4') >= 0) ? true : false}
                                                            onChange={() => handleCheckBox('guardStatusOnShift', 4)}
                                                            className="custom-control-input"
                                                            id="Guard Late"
                                                            name="Guard Late"/>
                                                        <label className="custom-control-label mb-0"
                                                               htmlFor="Guard Late">Guard Late</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-md-4">
                                            <div className="col-md-6 form-group mt-3 mt-md-0">
                                                <label htmlFor="firstStreet">Guard Check-In Status for Shift</label>
                                                <div
                                                    className="custom-control custom-checkbox d-flex flex-wrap align-items-center mt-2">
                                                    <div className="mr-5">
                                                        <input
                                                            type="checkbox"
                                                            checked={(filter.guardChecknStatusShift!.indexOf('1') >= 0) ? true : false}
                                                            onChange={() => handleCheckBox('guardChecknStatusShift', 1)}
                                                            className="custom-control-input"
                                                            id="AssignedguardChecknStatusShift"
                                                            name="AssignedguardChecknStatusShift"/>
                                                        <label className="custom-control-label mb-0"
                                                               htmlFor="AssignedguardChecknStatusShift">On Time for
                                                            ASP</label>
                                                    </div>
                                                    <div className="mr-0 mr-sm-5 ml-0">
                                                        <input
                                                            type="checkbox"
                                                            checked={(filter.guardChecknStatusShift!.indexOf('2') >= 0) ? true : false}
                                                            onChange={() => handleCheckBox('guardChecknStatusShift', 2)}
                                                            className="custom-control-input"
                                                            id="UnassignedguardChecknStatusShift"
                                                            name="UnassignedguardChecknStatusShift"/>
                                                        <label className="custom-control-label mb-0"
                                                               htmlFor="UnassignedguardChecknStatusShift">On Time for
                                                            Shift
                                                            Start</label>
                                                    </div>
                                                    <div className="ml-0">
                                                        <input
                                                            type="checkbox"
                                                            checked={(filter.guardChecknStatusShift!.indexOf('3') >= 0) ? true : false}
                                                            onChange={() => handleCheckBox('guardChecknStatusShift', 3)}
                                                            className="custom-control-input"
                                                            id="Guard AbsentguardChecknStatusShift"
                                                            name="Guard AbsentguardChecknStatusShift"/>
                                                        <label className="custom-control-label mb-0"
                                                               htmlFor="Guard AbsentguardChecknStatusShift">Late for
                                                            Shift Start</label>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-md-6 form-group mt-3 mt-md-0">
                                                <label htmlFor="firstStreet">Guard Check-In Methods</label>
                                                <div
                                                    className="custom-control custom-checkbox d-flex flex-wrap align-items-center mt-2">
                                                    <div className="mr-5">
                                                        <input
                                                            type="checkbox"
                                                            checked={(filter.guardCheckIn!.indexOf(CheckInOutTypeEnum.Manual) >= 0) ? true : false}
                                                            onChange={() => handleCheckBox('guardCheckIn', +CheckInOutTypeEnum.Manual)}
                                                            className="custom-control-input" id="manualCheckIn"
                                                            name="manualCheckIn"/>
                                                        <label className="custom-control-label mb-0"
                                                               htmlFor="manualCheckIn">{ CheckInTypeLabelEnum.Manual }</label>
                                                    </div>
                                                    <div className="mr-5">
                                                        <input
                                                            type="checkbox"
                                                            checked={(filter.guardCheckIn!.indexOf(CheckInOutTypeEnum.Auto) >= 0) ? true : false}
                                                            onChange={() => handleCheckBox('guardCheckIn', +CheckInOutTypeEnum.Auto)}
                                                            className="custom-control-input"
                                                            id="autoCheckIn"
                                                            name="autoCheckIn"/>
                                                        <label className="custom-control-label mb-0"
                                                               htmlFor="autoCheckIn">{ CheckInTypeLabelEnum.Auto }</label>
                                                    </div>
                                                    <div className="mr-5">
                                                        <input
                                                            type="checkbox"
                                                            checked={(filter.guardCheckIn!.indexOf(CheckInOutTypeEnum.Temp) >= 0) ? true : false}
                                                            onChange={() => handleCheckBox('guardCheckIn', +CheckInOutTypeEnum.Temp)}
                                                            className="custom-control-input" id="tempCheckIn"
                                                            name="tempCheckIn"/>
                                                        <label className="custom-control-label mb-0"
                                                               htmlFor="tempCheckIn">{ CheckInTypeLabelEnum.Temp }</label>
                                                    </div>
                                                    <div>
                                                        <input
                                                            type="checkbox"
                                                            checked={(filter.guardCheckIn!.indexOf(CheckInOutTypeEnum.Admin) >= 0) ? true : false}
                                                            onChange={() => handleCheckBox('guardCheckIn', +CheckInOutTypeEnum.Admin)}
                                                            className="custom-control-input" id="adminCheckIn"
                                                            name="adminCheckIn"/>
                                                        <label className="custom-control-label mb-0"
                                                               htmlFor="adminCheckIn">{ CheckInTypeLabelEnum.Admin }</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row row mb-md-4">
                                            <div className="col-md-6 form-group mt-3 mt-md-0">
                                                <label htmlFor="firstStreet">Guard Check-Out Status for Shift</label>
                                                <div
                                                    className="custom-control custom-checkbox d-flex flex-wrap align-items-center mt-2">
                                                    <div className="mr-5">
                                                        <input
                                                            type="checkbox"
                                                            checked={(filter.guardCheckOutStatusShift!.indexOf('1') >= 0) ? true : false}
                                                            onChange={() => handleCheckBox('guardCheckOutStatusShift', 1)}
                                                            className="custom-control-input"
                                                            id="AssignedguardCheckOutStatusShift"
                                                            name="AssignedguardCheckOutStatusShift"/>
                                                        <label className="custom-control-label mb-0"
                                                               htmlFor="AssignedguardCheckOutStatusShift">Checked out on
                                                            time</label>
                                                    </div>
                                                    <div className="">
                                                        <input
                                                            type="checkbox"
                                                            checked={(filter.guardCheckOutStatusShift!.indexOf('2') >= 0) ? true : false}
                                                            onChange={() => handleCheckBox('guardCheckOutStatusShift', 2)}
                                                            className="custom-control-input"
                                                            id="UnassignedguardCheckOutStatusShift"
                                                            name="UnassignedguardCheckOutStatusShift"/>
                                                        <label className="custom-control-label mb-0"
                                                               htmlFor="UnassignedguardCheckOutStatusShift">Checked out
                                                            early</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 form-group mt-3 mt-md-0">
                                                <label htmlFor="firstStreet">Guard Check-Out Methods</label>
                                                <div
                                                    className="custom-control custom-checkbox d-flex flex-wrap align-items-center mt-2">
                                                    <div className="mr-5">
                                                        <input
                                                            type="checkbox"
                                                            checked={(filter.guardCheckOut!.indexOf(CheckInOutTypeEnum.Manual) >= 0) ? true : false}
                                                            onChange={() => handleCheckBox('guardCheckOut', +CheckInOutTypeEnum.Manual)}
                                                            className="custom-control-input" id="manaulCheckOut"
                                                            name="manaulCheckOut"/>
                                                        <label className="custom-control-label mb-0"
                                                               htmlFor="manaulCheckOut">{ CheckOutTypeLabelEnum.Manual }</label>
                                                    </div>
                                                    <div className="mr-5">
                                                        <input
                                                            type="checkbox"
                                                            checked={(filter.guardCheckOut!.indexOf(CheckInOutTypeEnum.Auto) >= 0) ? true : false}
                                                            onChange={() => handleCheckBox('guardCheckOut', +CheckInOutTypeEnum.Auto)}
                                                            className="custom-control-input"
                                                            id="autoCheckOut"
                                                            name="autoCheckOut"/>
                                                        <label className="custom-control-label mb-0"
                                                               htmlFor="autoCheckOut">{ CheckOutTypeLabelEnum.Auto }</label>
                                                    </div>
                                                    <div className="mr-5">
                                                        <input
                                                            type="checkbox"
                                                            checked={(filter.guardCheckOut!.indexOf(CheckInOutTypeEnum.Admin) >= 0) ? true : false}
                                                            onChange={() => handleCheckBox('guardCheckOut', +CheckInOutTypeEnum.Admin)}
                                                            className="custom-control-input" id="adminCheckOut"
                                                            name="adminCheckOut"/>
                                                        <label className="custom-control-label mb-0"
                                                               htmlFor="adminCheckOut">{ CheckOutTypeLabelEnum.Admin }</label>
                                                    </div>
                                                    <div>
                                                        <input
                                                            type="checkbox"
                                                            checked={(filter.guardCheckOut!.indexOf(CheckInOutTypeEnum.Offline) >= 0) ? true : false}
                                                            onChange={() => handleCheckBox('guardCheckOut', +CheckInOutTypeEnum.Offline)}
                                                            className="custom-control-input" id="offlineCheckOut"
                                                            name="offlineCheckOut"/>
                                                        <label className="custom-control-label mb-0"
                                                               htmlFor="offlineCheckOut">{ CheckOutTypeLabelEnum.Offline }</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ), [filter, schoolBoards, holidays, showDatapicker]
                    )
                }
                <div className="row">
                    <div className="col-12">
                        <div className="d-flex justify-content-end">
                            <button type="button" className="btn btn-aqua-blue ml-0 ml-sm-3 mt-2 mt-lg-0"
                                    onClick={() => onClickCreateReport()}>
                                View Report
                            </button>
                        </div>
                    </div>
                </div>

                {useMemo(() => (
                    showTable && <div><ListJobShiftReport queryFilter={generateQuery}/></div>
                ), [generateQuery, showTable])
                }

            </form>
        </Layout>
    )
}

export default JobShiftReport;
