import { useContext } from 'react'
import { DataService } from '../common/services/DataService';
import { Roles } from '../common/enums/Roles';
import { IClientContact } from '../common/interfaces/clients/IClient'
import { NotificationTypes } from '../common/interfaces/INotification';
import AppContext from '../context/AppContext';

const useSaveClientContact = () => {
    const { showLoader, showNotification } = useContext(AppContext)
    const contactSvc = new DataService<IClientContact>({ url: 'user' })

    const saveClientContact = async (clientContact: IClientContact, client: number | null, successCallback?: (recordId: number) => void) => {
        showLoader(true)
        try {
            if (client === null) return;

            const body = {
                ...clientContact,
                client: client.toString(),
                role: Roles.Client
            }

            const response = await contactSvc.create(body)

            showNotification(NotificationTypes.success, `New client contact ${clientContact.firstName} ${clientContact.middleName || ""} ${clientContact.lastName} has been created.`)

            successCallback && successCallback(response.data)

            showLoader(false)
        } catch (error: any) {
            showNotification(NotificationTypes.danger, error.message)
            showLoader(false)
        }
    }

    return {
        saveClientContact
    }
}

export default useSaveClientContact