import React, {FC, useContext, useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import { SVGTrash } from '../../../../assets/icons/SvgIcons'
import { IVocabulary } from '../../../../common/interfaces/vocabulary/IVocabulary'
import { IJobSchedule } from '../../../../common/interfaces/jobs/IJob'
import { assignedSchedulesContent } from '../../../../content/dashboard/TablesContent'
import GuardDetailsModalsContext from '../../../../context/guards/GuardDetailsModalsContext'
import useUnassignGuard from '../../../../customHooks/useUnassignGuard'
import { momentDateFormat, momentHoursFormat } from '../../../../utils/DateFormatting'
import { matchWeekDays } from '../../../../utils/MatchWeekDays'
import BaseModal from '../../../modal/BaseModal'
import RemoveShiftModal from '../../../modal/jobs/RemoveShiftModal'
import DataTable from '../../../tables/DataTable'
import ReportActions from "../../../reports/ReportActions";
import {GuardIncludesEnum} from "../../../../common/enums/GuardEnums";
import { useRole } from 'customHooks/useRole'
import { useVerifyRolesTHead } from 'customHooks/useVerifyRolesTHead'


type Props = {
    assignedSchedules: IJobSchedule[]
    removeGuardVocabulary: IVocabulary[]
    onSuccessGuardRemove: (scheduleId: number) => void
}

const AssignedSchedulesTab: FC<Props> = ({ assignedSchedules, removeGuardVocabulary, onSuccessGuardRemove }) => {
    const { isLoading, userName, userId} = useContext(GuardDetailsModalsContext)
    const [itemInfocus, setItemInFocus] = useState<IJobSchedule>({} as IJobSchedule)
    const [modals, setModals] = useState<{ showRemove: boolean,  successRemove: boolean }>({ showRemove: false, successRemove: false })
    const { onUnassignGuardSubmit } = useUnassignGuard('schedule');
    const { isClientRole, isASPAndAdminRole, isSupervisorRole } = useRole()

    const {theadTable, addVerifyRoles} = useVerifyRolesTHead()

    useEffect(() =>{
        addVerifyRoles(assignedSchedulesContent.thead)
    }, [assignedSchedulesContent])

    // Remove Guard
    const onRemoveGuardClick = (rowItem: IJobSchedule) => {
        setItemInFocus(assignedSchedules.find(item => item.id === rowItem.id) || {} as IJobSchedule)
        setModals({
            ...modals,
            showRemove: true,
            successRemove: false
        })
    }

    const onRemoveGuardSubmitClick = (reason: string | IVocabulary) => {
        onUnassignGuardSubmit(reason, itemInfocus?.id as number, () => {
            onSuccessGuardRemove(itemInfocus?.id as number)
            setModals({
                ...modals,
                showRemove: false,
                successRemove: true
            })
        })
    }

    return (
        <>
            <div className="d-flex align-items-center flex-wrap justify-content-between">
                <div className="d-flex align-items-center flex-wrap">
                    <h5 className="mb-0 mr-5">Assigned Schedules</h5>
                    { isASPAndAdminRole() &&
                    <Link to={`/guards/${userId}/assignments`} className="btn btn-aqua-blue ml-0 ml-md-2 mr-5 mr-md-0 mt-2 mt-md-0">Assign a Job Schedule</Link>
                    }
                </div>
                <ReportActions className="mt-2" title="Schedules:" id={userId} tabName={GuardIncludesEnum.schedules} page="guard" />
            </div>
            {
                React.useMemo(() => (
                    <DataTable
                        tableClass="mt-4"
                        thead={theadTable}
                        ignoreCols={[0, ...(isClientRole() || isSupervisorRole() ? [3] : [])]}
                        ignoreTheadCols={[...(isClientRole() || isSupervisorRole() ? [2, 5] : [])]}
                        tbody={assignedSchedules ? assignedSchedules.map(schedule => (
                            {
                                id: schedule.id,
                                jobNameLink: `<a href="#/jobs/${schedule.jobId}/details">${schedule.jobName}</a>`,
                                startEndDate: `${momentDateFormat(schedule.startDate)} - ${momentDateFormat(schedule.endDate)}`,
                                aspStartDate: schedule.aspStartDate ? momentHoursFormat(schedule.aspStartDate) : "-",
                                startEndTime: `${momentHoursFormat(schedule.timeFrom)} - ${momentHoursFormat(schedule.timeTo)}`,
                                repeat: `Every week: ${matchWeekDays(schedule.repeatOn)}`
                            }
                        )) : []}
                        isLoading={isLoading}
                    >
                        {
                            (_id, rowItem) => (
                                !isClientRole() && !isSupervisorRole() &&
                                <td>
                                    <div>
                                        <button
                                            className={`btn btn-aqua-blue`}
                                            onClick={() => onRemoveGuardClick(rowItem)}
                                        >
                                            <SVGTrash />
                                        </button>
                                    </div>
                                </td>
                            )
                        }
                    </DataTable>
                ), [assignedSchedules])
            }

            {/* Remove Guard */}
            <>
                {

                    modals.showRemove &&
                    <RemoveShiftModal
                        titleBody={`Are you sure you want to unassign selected job schedule from <span class="font-weight-bold">${userName}</span>?`}
                        show={modals.showRemove}
                        onClose={() => setModals({
                            ...modals,
                            showRemove: false
                        }) }
                        onSubmit={(reason) => onRemoveGuardSubmitClick(reason as string | IVocabulary)}
                        reasons={removeGuardVocabulary}
                        jobName={itemInfocus.jobName as string}
                        shift={itemInfocus}
                        warning={"Reminder to replace this guard in the job schedule."}
                    />
                }
                {
                    modals.successRemove && <BaseModal
                        show={modals.successRemove}
                        onCancel={() => setModals({
                            ...modals,
                            successRemove: false
                        })}
                        cancelBtnText={'Close'}
                        title={`
                            <span class="font-weight-bold">${userName}</span> has been removed from 
                            <span class="font-weight-bold">${itemInfocus.jobName}</span> schedule 
                        `}
                    >
                        <div className="row">
                            <div className="col-12 d-flex justify-content-center">
                                <Link className="btn btn-aqua-blue mt-4" to="/jobs/unassigned">Go To Unassigned Job Shifts</Link>
                            </div>
                        </div>
                    </BaseModal>
                }
            </>

        </>
    )
}

export default AssignedSchedulesTab
