import moment from "moment";
import momentTimeZone from 'moment-timezone';
const utcFormat = 'YYYY-MM-DD[T]HH:mm:ssZ'

export const getTime = (date: string) => {
    const time = new Date(date).toLocaleTimeString('en-US', {hour: '2-digit', minute:'2-digit', second: "2-digit"});
    return time
}

export const customFormatFromUTC = (time: string | Date | moment.Moment, customFormat?: string) => moment.utc(time).local().format(customFormat ? customFormat : "h:mm A");

export const momentHoursFormat = (time: string | Date, customFormat?: string) => moment.utc(time).local().format(customFormat ? customFormat : "h:mm A");

export const momentDateFormat = (date: string | Date, toLocal?: boolean) => {
    const utcMoment = moment.utc(date);
    const format = 'MMM DD, Y';
    return toLocal ? utcMoment.local().format(format) : moment.utc(date).format(format);
}

export const filterDateFormat = (date: string | Date | moment.Moment) => moment(date).format('YYYY-MM-DD[T]HH:mm:ss');

export const todayDateFormat = () => moment().utc().local().toISOString();

export const todayTimeFormat = () => momentHoursFormat(moment().format());

export const tomorrowDateFormat = () => filterDateFormat(moment().add(1, 'days').format())

export const timeWithoutSecondsFormat = (time?: string | Date | moment.Moment) => moment(time ? time : moment()).seconds(0).milliseconds(0).toISOString()

export const resetUTCTime = (date: string | Date) =>  moment(date).set({ hours: 0, minutes: 0, seconds: 0 }).utc(true)

export const setHoursAndMinutes = (date: string | Date) => {
    if (date === "Invalid date") return moment().utc(true).format();
    const minutes  = moment().minutes();
    const hours  = moment().hours();
    const momentDate = momentTimeZone(date).tz(momentTimeZone.tz.guess());
    const finalTime = momentDate.set("hours", hours).set("minutes", minutes).set("seconds", 0).utc(true).format();
    return finalTime;
}

export const addCurrentHrsAndMins = (date: string | Date) => {
    const minutes  = moment().minutes();
    const hours  = moment().hours();
    return moment.utc(date).set("hours", hours).set("minutes", minutes).set("seconds", 0).format();
}

export const datePickerFormat = (date: string | Date | null) => (
    date ? moment.utc(date).local(true).toDate() : date as null
);

export const dateSendWithAddDay = ( date: string | Date) => moment.utc(date).add(1, 'year').format();

export const momentMinutesDiff = (a: string, b: string) => moment(a).diff(moment(b), 'minutes')

export const nextSchoolYear = () => {
    const currentYear = moment().year()
    const format =  "MM-D-Y";
    return  moment(`06-01-${currentYear}`, format).isBefore(moment()) ?
    setHoursAndMinutes(moment(`06-01-${moment().year()+1}`, format).format()) :
    setHoursAndMinutes(moment(`06-01-${moment().year()}`, format).format());
}

export const UTCtimeToLocal = (time?: string | Date) => moment.utc(time && time).local();

export const mergeDateAndTime = (date: string, time: string) => {
    let datePart = setHoursAndMinutes(date).split('T')[0];
    let timePart = time.split('T')[1];
    return UTCtimeToLocal(`${datePart}T${timePart}`)
}

export const UTCTimeNow = (formatToStr = true, keepLocalTime = false) => formatToStr ? moment().utc(keepLocalTime).format() : moment().utc(keepLocalTime);

export const attendanceDateFormat = (date: string | Date) => moment.utc(date).format("dddd MMMM DD, Y");

export const setCustomTimeInUTC = (hours = 0, minutes = 0, seconds = 0) => (
    moment().set({ hours }).set({ minutes }).set({ seconds }).set({ milliseconds: 0 }).utc()
)

export const isTodayUpToMinus72hours = (dateToCompare: string) => {
    const nowTime = UTCTimeNow(false) as moment.Moment
    const diff = nowTime.diff(moment.utc(dateToCompare), 'days')
    return diff <= 3 ? true : false 
    // const dayToCompareNumber = moment(dateToCompare).day()
    // const todayDayNumber = moment().day()
    // if (todayDayNumber === 1 || todayDayNumber === 0 || todayDayNumber === 6) {
    //     if (dayToCompareNumber === 5) return true;
    //     if (todayDayNumber === 1 && diff < 1) return true;
    //     return false;
    // } else return diff <= 1 ? true : false;
}

export const dateIsInPast = (dateToCheck: string) => moment.utc().isAfter(dateToCheck);

export const datesAreTheSame = (dateOne: string | undefined, dateTwo: string | undefined) => {
    if (!dateOne || !dateTwo) return false
    return moment.utc(dateOne).isSame(moment.utc(dateTwo), 'day')
};

export const momentNowCustomFormat = (format?: string) => moment().format(format || "MMM DD, Y");

export const momentAddDays = (date: string, day: number = 1) => moment.utc(date).add(day, 'day').format('YYYY-MM-DDTHH:mm:ss[Z]');

export const timeMinusMinutes = (date: string | Date, minutesToSubtract: number) =>  moment(date).subtract(minutesToSubtract, 'minutes').toDate();