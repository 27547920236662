import moment from "moment";
import { useState, useMemo, useEffect } from "react";
import { TheadData } from "../components/tables/DataTable";
import {clearHtmlWrapper} from '../helpers/ClearHtmlWrapper';
import {convertTime12to24} from '../helpers/ConvertTime12to24';
export enum SortDataTypesEnum {
  string = 'string',
  date = 'date',
  time = 'time',
  number = 'number',
  status = 'status'
}

export type SortConfig = {
  direction: string;
  key: number
  colName: string
  dataType: SortDataTypesEnum
}

type DataItem = {[index: string]: any}

export const useSortableData = (items: DataItem[], config: SortConfig | null = null, thead: TheadData[] | undefined = undefined, sortOnBackend = false, defaultSortedColumn?: number, defaultSortDirection?: 'up' | 'down', paginationConfig?: {currentPage: number, itemsPerPage: number}) => {
    const [sortConfig, setSortConfig] = useState<SortConfig | null>(config);
    const [theadContent, setTheadContent] = useState<TheadData[]>([] as TheadData[])

    useEffect(() => {
      defaultSortedColumn !== undefined ? sortThead(defaultSortDirection || 'up', defaultSortedColumn) : sortThead();
    }, [thead, defaultSortedColumn, defaultSortDirection])

    // TODO: try to find better solution
    const setCustomFieldName = (field: string) => {
      switch (field) {
        case 'Morning':
          return `A${field}`;
        case 'Afternoon':
          return `B${field}`;
        case 'Dismissal':
          return `C${field}`;
        default:
          return "";
      }
    }

    let sortedItems = useMemo(() => {
      if (sortOnBackend) return items;
      let sortableItems = [...items];
      if (sortConfig !== null) {
        sortableItems.sort((prev, next) => {
         

          if (sortConfig.direction === "down") {

            // Shift Period
            if (sortConfig.colName === "shiftPeriod") {
              // Morning, Afternoon, Dismissal
              // Dismissal, Afternoon, Morning
              return setCustomFieldName(next[sortConfig.colName]).localeCompare(setCustomFieldName(prev[sortConfig.colName]).trim().toLowerCase())
            }

            // Distance
            if (sortConfig.colName === "distance") {
              return +next[sortConfig.colName].replace(" km", "") - +prev[sortConfig.colName].replace(" km", "")
            }

            // AspStartTime
            if (sortConfig.colName === "AspStartTime") {
              if (moment(prev[sortConfig.colName], 'h:mm A') < moment(next[sortConfig.colName], 'h:mm A')) return 1
              if (moment(prev[sortConfig.colName], 'h:mm A') > moment(next[sortConfig.colName], 'h:mm A')) return -1
            }
 
            // Times
            if (sortConfig.colName === "times") {
              if (moment(prev[sortConfig.colName], 'h:mm A') < moment(next[sortConfig.colName], 'h:mm A')) return 1
              if (moment(prev[sortConfig.colName], 'h:mm A') > moment(next[sortConfig.colName], 'h:mm A')) return -1
            }

            // String
            if (sortConfig.dataType === SortDataTypesEnum.string) {
              return clearHtmlWrapper(next[sortConfig.colName])?.localeCompare(clearHtmlWrapper(prev[sortConfig.colName]).trim().toLowerCase());
            }
            // Status
            if (sortConfig.dataType === SortDataTypesEnum.status) {
              if (!sortConfig.colName) return - 1
              if (sortConfig.colName) {
                if (new Date(prev[sortConfig.colName]) < new Date(next[sortConfig.colName])) return 1
                if (new Date(prev[sortConfig.colName]) > new Date(next[sortConfig.colName])) return -1
              }
            }

            // Date
            if (sortConfig.dataType === SortDataTypesEnum.date) {
              if (new Date(prev[sortConfig.colName]) < new Date(next[sortConfig.colName])) return 1
              if (new Date(prev[sortConfig.colName]) > new Date(next[sortConfig.colName])) return -1
            }
            // Time
            if (sortConfig.dataType === SortDataTypesEnum.time) {
              if (moment(prev[sortConfig.colName], 'h:mm A') < moment(next[sortConfig.colName], 'h:mm A')) return 1
              if (moment(prev[sortConfig.colName], 'h:mm A') > moment(next[sortConfig.colName], 'h:mm A')) return -1
            }
            // Number
            if (sortConfig.dataType === SortDataTypesEnum.number) {
              return prev[sortConfig.colName] - next[sortConfig.colName];
            }
          }
          if (sortConfig.direction === "up") {
            // Shift Period
            if (sortConfig.colName === "shiftPeriod") {
              // Morning, Afternoon, Dismissal
              // Dismissal, Afternoon, Morning
              return setCustomFieldName(prev[sortConfig.colName]).localeCompare(setCustomFieldName(next[sortConfig.colName]).trim().toLowerCase())
            }

            // Distance
            if (sortConfig.colName === "distance") {
              return +prev[sortConfig.colName].replace(" km", "") - +next[sortConfig.colName].replace(" km", "") 
            }

            // AspStartTime
            if (sortConfig.colName === "AspStartTime") {
              if (moment(next[sortConfig.colName], 'h:mm A') < moment(prev[sortConfig.colName], 'h:mm A')) return 1
              if (moment(next[sortConfig.colName], 'h:mm A') > moment(prev[sortConfig.colName], 'h:mm A')) return -1
            }

            // Times
            if (sortConfig.colName === "times") {
              if (moment(next[sortConfig.colName], 'h:mm A') < moment(prev[sortConfig.colName], 'h:mm A')) return 1
              if (moment(next[sortConfig.colName], 'h:mm A') > moment(prev[sortConfig.colName], 'h:mm A')) return -1
            }

            // String
            if (sortConfig.dataType === SortDataTypesEnum.string) {
              return clearHtmlWrapper(prev[sortConfig.colName])?.localeCompare(clearHtmlWrapper(next[sortConfig.colName]) || '');
            }
            // Status
            if (sortConfig.dataType === SortDataTypesEnum.status) {
              if (!sortConfig.colName) return + 1
              if (sortConfig.colName) {
                if (new Date(next[sortConfig.colName]) < new Date(prev[sortConfig.colName])) return 1
                if (new Date(next[sortConfig.colName]) > new Date(prev[sortConfig.colName])) return -1
              }
            }
            // Date
            if (sortConfig.dataType === SortDataTypesEnum.date) {
              if (new Date(next[sortConfig.colName]) < new Date(prev[sortConfig.colName])) return 1
              if (new Date(next[sortConfig.colName]) > new Date(prev[sortConfig.colName])) return -1
            }
            // Time
            if (sortConfig.dataType === SortDataTypesEnum.time) {
              if (moment(next[sortConfig.colName], 'h:mm A') < moment(prev[sortConfig.colName], 'h:mm A')) return 1
              if (moment(next[sortConfig.colName], 'h:mm A') > moment(prev[sortConfig.colName], 'h:mm A')) return -1
            }
            // Number
            if (sortConfig.dataType === SortDataTypesEnum.number) {
              return next[sortConfig.colName] - prev[sortConfig.colName]
            }
            
          }
          return 0;
        });
      }
      return sortableItems;
    }, [items, sortConfig]);

    const sortThead = useMemo(() => ((direction: 'none' | 'up' | 'down' = 'none', thIndex?: number) => {
      if (thead === undefined) return;

      let sortableThead = [...thead];

      thead.forEach((th, indx) => {
        if (thIndex === undefined) {
          th.direction = direction
        } else {
          thIndex === indx ? (th.direction = direction) : (th.direction = "none")
        }
      });
      setTheadContent(sortableThead)
    }), [thead])

    const requestSort = (direction: 'none' | 'up' | 'down', colName: string, dataType: SortDataTypesEnum | undefined = SortDataTypesEnum.string, key: number) => {
      console.log(`
        direction: ${direction}
        colName: ${colName}
        dataType: ${dataType}
        key: ${key}
      `);
      sortThead(direction, key)
      setSortConfig({ key, direction, colName, dataType });
    }

    if (paginationConfig) {
        const indexOfLastItem = paginationConfig.currentPage * paginationConfig.itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - paginationConfig.itemsPerPage;
        if (indexOfFirstItem < sortedItems.length) {
          sortedItems = sortedItems.slice(indexOfFirstItem, indexOfLastItem);
        }
    }

  return { items: sortedItems, headItems: theadContent, requestSort, sortConfig, sortThead };
}
