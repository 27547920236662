import React, { FC } from 'react'
import { SVGMiniLogo } from '../../assets/icons/SvgIcons'

type Props = {
    title: string
    className?: string
}

const SectionTitle: FC<Props> = ({ title, className }) => {
    return (
        <h2 className={`section-title d-flex align-items-center align-items-start-md mb-0 mt-0 ${className ? className : ""}`}>
           <SVGMiniLogo />
           { title }
        </h2>
    )
}

export default SectionTitle
