import { useContext, useEffect, useState } from "react"
import AppContext from '../context/AppContext'
import ResourceDataService from '../common/services/ResourceDataService'
import { NotificationTypes } from "../common/interfaces/INotification"

const useGetModel = <T, >(requestUrl: string, getOnLoad = false) => {
    const [data, setData] = useState<T | undefined>(undefined)
    const { showNotification, showLoader } = useContext(AppContext)
    const resourceSvc = new ResourceDataService<T>({ url: requestUrl })
    
    useEffect(() => {
        getOnLoad && getModelData()
    }, [])
    
    const getModelData = async (successCallback?: (data: T) => void, errorCallback?: (error?: any) => void, queryUrl?: string) => {
        showLoader(true)
        try {
            const res = await resourceSvc.getAll(queryUrl)
            setData(res.data)
            successCallback && successCallback(res.data)
            showLoader(false)
        } catch (error: any) {
            errorCallback && errorCallback(error)
            showNotification(NotificationTypes.danger, error.message)
            showLoader(false)
        }
    }

    return {
        getModelData, data
    }
}

export default useGetModel