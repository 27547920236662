import { FC } from 'react'
import { SVGMoreDots } from 'assets/icons/SvgIcons'
import { Dropdown } from 'react-bootstrap'

type TProps = {
    onEditShift?: () => void
    onRemoveShift?: () => void
    onRemoveGuard?: () => void
    onReplaceGuard?: () => void
    hideGuardActions?: boolean
}

const ShiftActionsDropdown: FC<TProps> = ({ onEditShift, onRemoveShift, onRemoveGuard, onReplaceGuard, hideGuardActions }) => {
    return (
        <Dropdown>
            <Dropdown.Toggle
                id={`dropdown-action-${1}`}
                className={'btn-aqua-blue'}
            >
                <SVGMoreDots />
            </Dropdown.Toggle>
            <Dropdown.Menu rootCloseEvent="mousedown">
                {onEditShift &&
                    <Dropdown.Item
                        onClick={onEditShift}
                    >
                        Edit Shift
                    </Dropdown.Item>
                }
                {onRemoveShift &&
                    <Dropdown.Item
                        onClick={onRemoveShift}
                    >
                        Remove Shift
                    </Dropdown.Item>
                }

                {onRemoveGuard && !hideGuardActions &&
                    <Dropdown.Item
                        onClick={onRemoveGuard}
                    >
                        Remove Guard
                    </Dropdown.Item>
                }

                {onReplaceGuard && !hideGuardActions &&
                    <Dropdown.Item
                        onClick={onReplaceGuard}
                    >
                        Replace Guard
                    </Dropdown.Item>
                }
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default ShiftActionsDropdown
