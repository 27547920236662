import { IVocabulary } from 'common/interfaces/vocabulary/IVocabulary'
import { renderToString } from 'react-dom/server'
import { matchOptionWithName } from 'utils/MatchOptionWithName'

type TProps = {
    currentData: {[index: string]: any} | undefined
    requestedData: {[index: string]: any} | undefined
    vocabulary: Array<IVocabulary[]>
}

const ProfileUpdateBodyModal = ({ currentData, requestedData, vocabulary }: TProps): string => {

    const rowItem = (labelName: string, objKey: string, vocabulary?: IVocabulary[]) => (
        (currentData && requestedData) ?
        <>
            <div className="col-sm-3 details-information__title mb-3 ">
                <h6 className="mb-0">{labelName}: </h6>
            </div>
            <div className="col-sm-4 details-information__value d-flex flex-row align-items-center mb-3 ">
                <p className="mb-0">
                    { vocabulary ? matchOptionWithName(currentData[objKey], vocabulary) : currentData[objKey] }
                </p>
            </div>
            <div className="col-sm-4 details-information__value d-flex flex-row align-items-center mb-3">
                <p className="mb-0">
                { vocabulary ? matchOptionWithName(requestedData[objKey], vocabulary) : requestedData[objKey] }
                </p>
            </div>
        </> : null
    )

    return renderToString(
        <>
        { (currentData && requestedData) ?
        <div className="row mt-4">
            <div className="col-sm-3 details-information__title mb-3 "></div>
            <div className="col-sm-4 details-information__title mb-3 ">
                <h6 className="mb-0">From:</h6>
            </div>
            <div className="col-sm-4 details-information__title mb-3 ">
                <h6 className="mb-0">To:</h6>
            </div>
    
            {/* Start First Name */}
            { currentData.firstName && requestedData.firstName && rowItem('First Name', 'firstName')}
            {/* End First Name */}
    
            {/* Start Last Name */}
            { currentData.lastName && currentData.lastName && rowItem('Last Name', 'lastName')}
            {/* End Last Name */}
    
    
            {/* Start Address 1 */}
            { currentData.firstStreet && currentData.firstStreet && rowItem('Address 1', 'firstStreet')}
            {/* End Address 1 */}
    
            {/* Start Address 2 */}
            { currentData.secondStreet && currentData.secondStreet && rowItem('Address 2', 'secondStreet')}
            {/* End Address 2 */}
            
            {/* Start Email */}
            { currentData.email && currentData.email && rowItem('Email', 'email')}
            {/* End Email */}
    
            {/* Start Home Mobile */}
            { currentData.homeMobile && currentData.homeMobile && rowItem('Home Mobile', 'homeMobile')}
            {/* End Home Mobile */}
    
            {/* Start Personal Mobile */}
            { currentData.personal && currentData.personal && rowItem('Personal Mobile', 'personal')}
            {/* End Personal Mobile */}
    
            {/* Start ASP Mobile */}
            { currentData.aspMobile && currentData.aspMobile && rowItem('ASP Mobile', 'aspMobile')}
            {/* End ASP Mobile */}

            {/* Start City */}
            { currentData.city && currentData.city && rowItem('City', 'city')}
            {/* End City */}

            {/* Start Province */}
            { currentData.province && currentData.province && rowItem('Province', 'province', vocabulary[0])}
            {/* End Province */}
    
            {/* Start Postal Code */}
            { currentData.postalCode && currentData.postalCode && rowItem('Postal Code', 'postalCode')}
            {/* End Postal Code */}
        </div>
        : ''
        }
        </>
    )
}

export default ProfileUpdateBodyModal;