import React, { useEffect, useState } from "react";
import { inputDataChecker } from "../utils/InputDataChecker";

type Model = {
    [index: string]: any
}

export type MultipleDataChange = {
    name: string
    value: any;
}

export const useOnIputChangeV2 = <T extends Model> (mainObject: T,
    setCallBack?: React.Dispatch<React.SetStateAction<any>>) => {
    const [hookState, setHookState] = useState<T>(mainObject)

    const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string | Date | boolean | number | Array<MultipleDataChange>, fieldName?: string | null, subObject?: string) => {
        let state;
 
        if (Array.isArray(event)) {
            const fieldsToBeUpdated = {};
            event.forEach(field => {
                const cleanData = inputDataChecker(field.value, field.name)
                Object.assign(fieldsToBeUpdated, {[cleanData.field]: cleanData.value});
            })
            state = {...mainObject, ...fieldsToBeUpdated }
            !setCallBack && setHookState(state)
            setCallBack && setCallBack(state)
        } else {
            const cleanData = inputDataChecker(event, fieldName)
            if (subObject) {
                state = { ...mainObject, 
                    [subObject]: {
                        ...mainObject[subObject],
                        [cleanData.field]: cleanData.value
                    }
                }
                !setCallBack && setHookState(state)
                setCallBack && setCallBack(state)
            } else {
                state = {...mainObject, [cleanData.field]: cleanData.value };
                !setCallBack && setHookState(state)
                setCallBack && setCallBack(state)
            }
        }
    }

    return {
        hookState, onChange, setHookState
    }
}
// "react-error-overlay": "6.0.9"