import { useContext } from "react"
import { IAvailableGuard } from "../common/interfaces/guards/IGuard"
import { NotificationTypes } from "../common/interfaces/INotification"
import { DataService } from "../common/services/DataService"
import AppContext from "../context/AppContext"

const useAssignGuard = (url: string = "shift") => {
    const asssignSvc = new DataService<{ guardId: number }>({ url })
    const { showLoader, showNotification } = useContext(AppContext)

    const onAssignGuardSubmit = async (guard: IAvailableGuard | number, itemId: number, entityUrl = 'assign', callBack: () => void) => {
        showLoader(true)
        try {
            const body = { guardId: typeof guard === "number" ? guard : guard.id }
            await asssignSvc.update(body, itemId, entityUrl)

            callBack()
            showLoader(false)
        } catch (e: any) {
            showLoader(false)
            showNotification(NotificationTypes.danger, e.message)
        }
    }

    return {
        onAssignGuardSubmit
    }
}

export default useAssignGuard