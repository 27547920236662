import React, {FC, useEffect, useRef} from 'react'
import DataTable from "../../../components/tables/DataTable";
import {guardLocationReport} from "../../../content/dashboard/TablesContent";
import {useTableList} from "../../../customHooks/useTableList";
import {IShiftJobReportModel} from "../../../common/interfaces/IDataFilter";

import {momentDateFormat, momentHoursFormat} from "../../../utils/DateFormatting";
import {convertToM} from "../../../utils/ConvertToKm";

type Props = {
    queryFilter: string
}

const ListGuardLocationReport: FC<Props> = ({queryFilter}) => {
    const {tableData, isLoading, perPage, allRecords, onPagination, onPerPage, currentPage, onSortCall, fetchData} = useTableList<IShiftJobReportModel>(`${queryFilter}`, '24', false)
    const tableRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        queryFilter && fetchData(1,'24', `${queryFilter}`)
        .then(() => tableRef.current?.scrollIntoView())
    }, [queryFilter])

    return (
        <>

            <section>
                <div className="row ">
                    <div className="col-12" ref={tableRef}>
                        {
                            React.useMemo(
                                () => (
                                    <DataTable
                                        tableClass="mt-4"
                                        thead={guardLocationReport.thead}
                                        tbody={
                                            tableData ? tableData
                                                .map(guard => (
                                                    {
                                                        id: guard.id,
                                                        guardName: guard.guardName  ? guard.guardName : '-',
                                                        guardEmployeeNumber: guard.guardEmployeeNumber   ? guard.guardEmployeeNumber : '-',
                                                        jobName: guard.jobName ? guard.jobName : '-',
                                                        cityJobId: guard.cityJobId  ? guard.cityJobId : '-',
                                                        date: guard.startTime && guard.endTime && `${momentDateFormat(guard.startTime )} - ${momentDateFormat(guard.endTime )}`,
                                                        time: guard.startTime && guard.endTime && `${momentHoursFormat(guard.startTime )} - ${momentHoursFormat(guard.endTime )}`,
                                                        geoFence: guard.guardLocations?.length ?  convertToM(guard.guardLocations[0]?.distanceFromJob) : '-',
                                                        latitude : guard.guardLocations?.length ? guard.guardLocations[0]?.latitude : '-',
                                                        longitude : guard.guardLocations?.length ? guard.guardLocations[0]?.longitude : '-',
                                                    }
                                                )) : []}
                                        ignoreCols={[0]}
                                        isLoading={isLoading}
                                        pagination={true}
                                        currentPage={currentPage}
                                        itemsCount={allRecords}
                                        itemsPerPage={+perPage}
                                        onPager={page => onPagination(page)}
                                        onPerPage={value => onPerPage(value)}
                                        tableName={"Guard Location"}
                                        onSort={(orderBy, desc) => onSortCall(orderBy, desc)}
                                        showTableLengthData={true}
                                        sortOnBackend={true}
                                        defaultSortedColumn={2}
                                    />
                                ), [tableData, isLoading,]
                            )
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

export default ListGuardLocationReport;
