import React, { FC } from 'react'
import { IGuard } from '../../../common/interfaces/guards/IGuard'
import { IVocabulary } from '../../../common/interfaces/vocabulary/IVocabulary'
import { momentDateFormat } from '../../../utils/DateFormatting'
import { matchOptionWithName } from '../../../utils/MatchOptionWithName'

type Props = {
    guard: IGuard;
    vocabulary: IVocabulary[]
}

const GuardInfo: FC<Props> = ({ guard, vocabulary }) => {
    return (
        <section className="my-4 details-information">

            <div className="row">
                <div className="col-md-6">
                    <div className="row mb-3">
                        <div className="col-4 col-md-3 details-information__title mb-0">
                            <h6 className="mb-0">Guard Name:</h6>
                        </div>
                        <div className="col-8 col-md-9 details-information__value">
                            <p className="mb-0">{ guard.firstName } { guard.middleName } { guard.lastName }</p>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-4 col-md-3 details-information__title mb-0">
                            <h6 className="mb-0">Home Address:</h6>
                        </div>
                        <div className="col-8 col-md-9 details-information__value">
                            <p className="mb-0">{ guard.firstStreet } <br />{ guard.secondStreet } {guard.secondAddress && <br /> }
                                { guard.city } {  matchOptionWithName(guard.province, vocabulary) }, <span className="text-uppercase">{ guard.postalCode }</span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="row mb-3">
                        <div className="col-4 col-md-3 details-information__title mb-0">
                            <h6 className="mb-0">Personal Mobile:</h6>
                        </div>
                        <div className="col-8 col-md-9 details-information__value">
                            <p className="mb-0">{ guard.personalMobile }</p>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-4 col-md-3 details-information__title mb-0">
                            <h6 className="mb-0">Home Phone:</h6>
                        </div>
                        <div className="col-8 col-md-9 details-information__value">
                            <p className="mb-0">{ guard.homeMobile }</p>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-4 col-md-3 details-information__title mb-0">
                            <h6 className="mb-0">Email:</h6>
                        </div>
                        <div className="col-8 col-md-9 details-information__value">
                            <p className="mb-0">{ guard.email }</p>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-4 col-md-3 details-information__title mb-0">
                            <h6 className="mb-0">ASP Start Date:</h6>
                        </div>
                        <div className="col-8 col-md-9 details-information__value">
                            <p className="mb-0">{ guard.aspStartDate && momentDateFormat(guard.aspStartDate)}</p>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-4 col-md-3 details-information__title mb-0">
                            <h6 className="mb-0">ASP Mobile:</h6>
                        </div>
                        <div className="col-8 col-md-9 details-information__value">
                            <p className="mb-0">{  guard.device?.aspMobile }</p>
                        </div>
                    </div>
                </div>

            </div>

        </section>
    )
}

export default GuardInfo
