import { guardTestsFilter } from "common/models/XGuardReportsFilters";
import { IGuardTest, IGuardTestReport, IGuardTestReportTest } from "common/interfaces/reports/xguard-reports/IGuardTest";
import DataTable, { TheadData } from "components/tables/DataTable";
import { guardTestReportThead } from "content/xguard-reports/tableContent";
import { useTableList } from "customHooks/useTableList";
import useXGuardReportsQuery from "customHooks/xguard-reports/useXGuardReportsQuery";
import { datePickerFormat, momentDateFormat, setHoursAndMinutes } from "utils/DateFormatting";
import Layout from "../../../components/layout/Layout";
import SectionTitle from "../../../components/titles/SectionTitle";
import DatePicker from "react-datepicker";
import { SVGCalendar, SVGDownload, SVGInfo, SVGPencil } from "assets/icons/SvgIcons";
import { useDatapickerSvg } from "customHooks/useDatapickerSvg";
import AutocompleteField from "components/autosuggestField/AutocompleteField";
import useReportActions from "customHooks/reports/useReportActions";
import { memo, useContext, useEffect, useMemo, useState } from "react";
import AppContext from "context/AppContext";
import { useDowloandFile } from "customHooks/useDownloadFile";
import { Reports } from "common/enums/Reports";
import useGetModel from "customHooks/useGetModel";
import CustomTooltip from "components/custom-tooltip/CustomTooltip";
import ReportOptCheckBox from "components/reports/ReportOptCheckBox";
import UpdateGuardTestResponsesModal from "components/modal/reports/UpdateGuardTestResponsesModal";
import { DataService } from "common/services/DataService";
import { NotificationTypes } from "common/interfaces/INotification";
import BaseModal from "components/modal/BaseModal";

const GuardTestReports = () => {
  const testId = 14;
  const { data: covidTest } = useGetModel<IGuardTest>(`test?Id=${testId}`, true)
  const [tableThead, setTableThead] = useState<any>()
  const [modalData, setModalData] = useState<{
    body?: IGuardTestReportTest,
    showMain?: boolean,
    showSuccess?: boolean,
    guardName?: string
  } | undefined>(undefined)
  
  const { showLoader, showNotification } = useContext(AppContext)

  const { fetchData: fetchReports, tableData: arrivalReports,
    isLoading, currentPage, perPage, allRecords, onPagination, onSortCall, 
    onPerPage } = useTableList<IGuardTestReport>(`test/guard-test?TestId=${testId}`, 24)

  const {generateQueryParams, onQueryParamChanged,
    queryParams} = useXGuardReportsQuery({
      baseUrl: `test/guard-test?TestId=${testId}`,
      defaultParams: guardTestsFilter,
      urlAlreadyHasQuery: true
    })
  const {showDatapicker, changeDatapicker} = useDatapickerSvg()
  const { onChangeMultiSelectString } = useReportActions(queryParams);
  const { dowloandFile } = useDowloandFile()

  const handleCheckBox = (type: string, index: number | string) => {
    onChangeMultiSelectString(type, index,
      (result, type) => {
        onQueryParamChanged(result, type as any)
      }
    )
  }

  const onDownload = async () => {
    showLoader(true)
    await dowloandFile(`report/guard-test`,
    Reports.excel, Reports.download, '', '',
    `&${generateQueryParams().split('?')[1]}`, true)
    showLoader(false)

  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    fetchReports(1, 24, generateQueryParams())
  }

  const buildThead = useMemo(() => (
    () => {
      if (!covidTest?.questions) return undefined
      const baseThead = guardTestReportThead.thead;
      return {
        thead: [...baseThead, ...covidTest.questions.map(item => (
          {
            label: item.title,
            sortable: true
          })
        ), ...[{ label: "Update" }]
      ] as TheadData[],
      }
    }
  ), [covidTest])

  useEffect(() => {
    covidTest && setTableThead(buildThead()?.thead)
  }, [covidTest, buildThead])

  const onUpdateSubmit = useMemo(
    () => 
      async (data: IGuardTestReportTest) => {
        showLoader(true)
        const svc = new DataService({ url: 'reports/update-test-response' });
        try {
          const body = {
            response: data.response,
            testResponses: Object.entries(data.questions).map(([_key, value]) => ({
              questionId: value.questionId,
              answer: value.answer,
            }))
          }
          await svc.update(body, data.id)
          await fetchReports(currentPage, perPage)
          setModalData({
            showSuccess: true,
            guardName: data.guardName
          })
          showLoader(false)
        } catch (error: any) {
          showLoader(false)
          showNotification(NotificationTypes.danger, error.message)
        }
      },
    [],
  )

  const generateAnswers = useMemo(() => 
  (test: IGuardTestReportTest) => {
    // FIXED: table missing column after test questions were changed
    covidTest?.questions.map(v => v.title).forEach(key => {
      const element = test.questions[key];
      if (!element) {
        test.questions[key] = { questionId: 0, answer: '', updatedResponse: false};
      }
    })
    // TODO: "Highlight" - generic
    return Object.assign({}, ...Object.entries(test.questions).map(([key, value]) => ({ 
      [key]: `<span class="${value.answer?.toLowerCase() === "yes" ? 'table-info__counter--good p-2' : ''}">${value.answer}</span> ${value.updatedResponse ? 
        `
        <span class="ml-1 badge badge-pill badge-aqua-blue">
          <div class="badge-tooltip">updated response</div>
        </span>
        `
        : ''}
      `
    })))
  },[covidTest?.questions])

  return (
    <Layout
      breadcrumbs={{
        links: [{
            title: 'Reports',
            link: 'reports'
        }],
        currentPageTitle: 'Covid Screenings'
      }}
      className={'reports-screen'}
    >
      <div className="row mb-3">
        <div className="col-12">
          <SectionTitle title={'Guard Covid-19 Screening Report'}/>
        </div>
      </div>

      <form
        onSubmit={handleSubmit}
      >
        <div className="row">
        <div className="col-md-4 mb-md-4 mr-md-5">
          <div className="row">
              {/* Start Guard Name Search */}
              <div className="col-12 form-group shfit-autosegest">
                <label htmlFor="firstStreet">Guard Name or Employee ID:</label>
                <AutocompleteField
                  showButton={false}
                  defaultValue={!queryParams.guardNameOrEmployeeNumber ? true : false}
                  includes={'Guard'}
                  isAsync={true}
                  placeholder='Guard Name or Employee ID'
                  onSearch={async (value) => {
                      onQueryParamChanged(value, 'guardNameOrEmployeeNumber')
                  }}
                  onChange={(value) => onQueryParamChanged(value, 'guardNameOrEmployeeNumber')}
                  onClear={() => onQueryParamChanged('', 'guardNameOrEmployeeNumber')}/>
              </div>
              {/* End Guard Name Search */}
              </div>
          </div>

          {/* Start Date Filters */}
          <div
            className="col-md-3 form-group mb-md-4 mr-md-5">
              <div className="row d-flex flex-column">

                <div className="col-12 mb-3">
                  <label>From: </label>
                  <div
                      className="react-datepicker-custom-wrapper react-datepicker-custom-wrapper--full-width d-inline-block w-100">
                      <DatePicker
                          selected={datePickerFormat(queryParams.startDate)}
                          onChange={date => {
                            onQueryParamChanged(date ? setHoursAndMinutes(date as Date) : '', 'startDate')
                            changeDatapicker(1);
                          }}
                          dateFormat="dd/MM/yyyy"
                          onInputClick={() => changeDatapicker(1)}
                          open={showDatapicker[1]}
                          onClickOutside={() => changeDatapicker(1)}
                          placeholderText="dd/mm/yyyy"
                      />
                      <div onClick={() => changeDatapicker(1)}><SVGCalendar/></div>
                  </div>
                </div>

                <div className="col-12">
                  <label>To: </label>
                  <div
                    className="react-datepicker-custom-wrapper react-datepicker-custom-wrapper--full-width d-inline-block w-100">
                    <DatePicker
                      selected={datePickerFormat(queryParams.endDate)}
                      onChange={date => {
                        onQueryParamChanged(date ? setHoursAndMinutes(date as Date) : '', 'endDate')
                        changeDatapicker(2);
                      }}
                      dateFormat="dd/MM/yyyy"
                      onInputClick={() => changeDatapicker(2)}
                      open={showDatapicker[2]}
                      onClickOutside={() => changeDatapicker(2)}
                      placeholderText="dd/mm/yyyy"
                    />
                    <div onClick={() => changeDatapicker(2)}><SVGCalendar/></div>
                  </div>
                </div>

              </div>
          </div>
          {/* End Date Filters */}

          {/* Start CheckBoxes */}
          <div className="col-md-3 form-group mb-md-4 ml-md-4">
            <label htmlFor="firstStreet">Responded Yes to:</label>
            <div>
              {
                covidTest ? covidTest.questions.map(option => (
                  // !option.isDisabled &&
                  <ReportOptCheckBox
                    optionType={queryParams.question}
                    option={{ name: option.title, id: option.id }}
                    handleCheckBox={handleCheckBox}
                    optionKey={"question"}
                    key={option.id}
                  >
                    <CustomTooltip
                      bodyText={option.description} keyValue={option.id}>
                        <span className="ml-auto">
                          <SVGInfo />
                        </span>
                    </CustomTooltip>
                  </ReportOptCheckBox>
                )) : null
              }
            </div>
          </div>
        {/* End CheckBoxes */}

        </div>

        <div className="row mb-5">
          <div className="col-12 text-right">
            <button
              type="submit"
              className="btn btn-outline-aqua-blue mr-2"
              >
              Update Report
            </button>
            <button
              type="button"
              className="btn btn-aqua-blue"
              onClick={onDownload}
            ><SVGDownload /></button>
          </div>
        </div>

      </form>
      
      {
        useMemo(() => (
          <div className="row">
            <div className="col-12">
              <DataTable
                thead={tableThead || []}
                tbody={(arrivalReports as unknown as IGuardTestReport).tests
                  ? (arrivalReports as unknown as IGuardTestReport).tests.map(test => ({
                  ...{
                    id: test.id,
                    created: momentDateFormat(test.created) || "",
                    guardName: `<a href="/#/guards/${test.guardId}/details" target="_blank">${test.guardName}</a>`,
                    employeeId: `<a href="/#/guards/${test.guardId}/details" target="_blank">${test.employeeId}</a>`,
                  },
                  ...generateAnswers(test)
                })) : []}
                isLoading={isLoading}
                ignoreCols={[0]}
                currentPage={currentPage}
                itemsCount={allRecords}
                itemsPerPage={+perPage}
                onPager={page => onPagination(page)}
                onPerPage={value => onPerPage(value)}
                onSort={(orderBy, desc) => onSortCall(orderBy, desc, queryParams.keyWord)}
                defaultSortedColumn={0}
                tableName={"Covid Screenings"}
                tableClass={'table-info--notFixed'}
                pagination
                sortOnBackend
                showTableLengthData
              >
                {
                  (_id, _report: IGuardTestReportTest, reportIndex) => (
                    <td>
                      <div>
                        <button
                          type="button"
                          className={`btn btn-aqua-blue`}
                          onClick={() => setModalData({
                            body: (arrivalReports as unknown as IGuardTestReport).tests[reportIndex],
                            showMain: true
                          })}>
                          <SVGPencil/>
                        </button>
                      </div>
                    </td>
                  )
                }
              </DataTable>
              
            </div>
          </div>
        ), [arrivalReports, isLoading, currentPage, allRecords, perPage, tableThead,
          onPagination, onPerPage, onSortCall, queryParams.keyWord])
      }
    {
      modalData?.showMain &&
      <UpdateGuardTestResponsesModal
        onCancel={() => setModalData(undefined)}
        onSubmit={data => onUpdateSubmit(data)}
        modalData={modalData?.body}
        test={covidTest}
      />
    }

    {
      modalData?.showSuccess &&
      <BaseModal
        show
        onCancel={() => setModalData(undefined)}
        cancelBtnText={"Close"}
        title={`A response for <span class="font-weight-bold">${modalData?.guardName}</span> has been updated`}
      />
    }
    </Layout>
  )
}

export default memo(GuardTestReports)
