import React, { useEffect, useState } from 'react'
import AutocompleteField from 'components/autosuggestField/AutocompleteField'
import Layout from 'components/layout/Layout'
import SectionTitle from 'components/titles/SectionTitle'
import DatePicker from "react-datepicker";
import { useDatapickerSvg } from 'customHooks/useDatapickerSvg';
import { SVGCalendar, SVGEraser } from 'assets/icons/SvgIcons';
import CustomSelectControl from 'components/custom-select/CustomSelectControl';
import { useListFilterJobShiftReport } from 'customHooks/useListFilterJobShiftReport';
import { datePickerFormat, momentDateFormat, setHoursAndMinutes } from 'utils/DateFormatting';
import { IShiftJobReportModel } from 'common/interfaces/IDataFilter';
import { guardCoverageOpts } from 'content/dropdownOptionsConsts'
import useReportActions from 'customHooks/reports/useReportActions';
import AttendaceReportList from './AttendaceReportList';
import { IClientDropdown } from 'common/interfaces/dropdowns/IClientDropDown';
import ResourceDataService from 'common/services/ResourceDataService';
import { ISchoolYearDropdown } from 'common/interfaces/dropdowns/ISchoolYearDropdown';
import { useSchoolYear } from 'customHooks/useSchoolYear';

const AttendaceReportDashboard = () => {
    const { generateFilterQuery, onFilterDropdownChange, filter, onFilterClearClick, setFilter }
        = useListFilterJobShiftReport('reports/attendance')
    const { holidays, schoolBoards, onChangeMultiSelectString, showTable,
        setShowTable, fetchAdditionalData, shiftVocabulary, zones,
        fetchHolidaysBySchoolBoard, fetchHolidays } = useReportActions(filter);
    const clientSvc = new ResourceDataService<IClientDropdown[]>({ url: "client/dropdown" })
    const { showDatapicker, changeDatapicker } = useDatapickerSvg()
    const { getCurrentYear } = useSchoolYear()

    const [generatedQuery, setGeneratedQuery] = useState('')
    const [clientData, setClientData] = useState<IClientDropdown[]>([] as IClientDropdown[]);
    const [clientSchoolYears, setClientSchoolYears] = useState<ISchoolYearDropdown[]>([] as ISchoolYearDropdown[]);

    const handleCheckBox = (type: keyof IShiftJobReportModel, index: number) => {
        onChangeMultiSelectString(type, index, (result, type) => onFilterDropdownChange(result, type))
    }

    const onFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setGeneratedQuery(generateFilterQuery())
        setShowTable(true)
    }

    const fetchClientsData = async () => {
        try {
            const resClients = await clientSvc.getAll();
            const data = resClients.data;
            onFilterDropdownChange(data[0].id, "clientId");
            setClientData(data);
            filterClientSchoolYears(data[0].id, data, (matchedCurrentYear) => {
                setFilter({ 
                    ...filter,
                    clientId: data[0].id || 0,
                    schoolYearId: matchedCurrentYear?.id || 0
                })
            });
        } catch (error: any) {
            console.log('fetchClientsData', error?.message)
        }
    }

    const filterClientSchoolYears = (clientId: number | string, clientData: IClientDropdown[],
        returnCurrentYear?: (currentSchoolYear: ISchoolYearDropdown | undefined) => void) => {
        const matchedResult = clientData.find(client => client.id === +clientId)
        const matchedResultYears = matchedResult?.schoolYears;
        const matchedCurrentYear = getCurrentYear(matchedResultYears || []);
        setClientSchoolYears(matchedResultYears || [])
        returnCurrentYear && returnCurrentYear(matchedCurrentYear)
    }

    useEffect(() => {
        filter.clientId && filterClientSchoolYears(filter.clientId, clientData, (matchedCurrentYear) => {
            setFilter({ 
                ...filter,
                schoolYearId: matchedCurrentYear?.id || 0
            })
        });
    }, [filter.clientId])

    useEffect(() => {
        fetchAdditionalData();
        fetchClientsData();
    }, [])

    useEffect(() => {
        filter.schoolBoard && fetchHolidaysBySchoolBoard()
    }, [filter.schoolBoard])

    useEffect(() => {
        !filter.schoolBoard && fetchHolidays()
    }, [filter.schoolBoard])

    return (
        <Layout
            breadcrumbs={{
                links: [{
                    title: 'Reports',
                    link: 'reports'
                }],
                currentPageTitle: 'Attendance Report'
            }}
            className={showTable ? 'container-xl' : ''}
        >

            {!showTable &&
                <>
                    <div className="row mb-3">
                        <div className="col-12 d-flex justify-content-between">
                            <SectionTitle title={'Attendance Report'} />
                            <button
                                type="button"
                                className="btn btn-aqua-blue"
                                onClick={() => onFilterClearClick()}
                            >
                                <SVGEraser />
                            </button>
                        </div>
                    </div>


                    <form onSubmit={onFormSubmit} className="mb-5">

                        <div className="row">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-md-4 form-group">
                                        <label>Client Name</label>
                                        <CustomSelectControl
                                            options={clientData}
                                            onChange={e => onFilterDropdownChange(e, "clientId")}
                                            value={filter.clientId}
                                            placeholder={"Select Client"}
                                            className={`custom-react-select--clientId`}
                                        />
                                    </div>
                                    {/* {clientSchoolYears?.length !== 0 && */}
                                    <div className="col-md-4 form-group">
                                        <label>School Year</label>
                                        <CustomSelectControl
                                            options={clientSchoolYears.map(year => ({
                                                id: year.id,
                                                name: `${momentDateFormat(year.startDate)} - ${momentDateFormat(year.endDate)}`
                                            }))}
                                            onChange={(value) => onFilterDropdownChange(value, "schoolYearId")}
                                            value={filter.schoolYearId}
                                            placeholder={"Select a School Year"}
                                            className={`custom-react-select--schoolYearId`}
                                        />
                                    </div>
                                    {/* } */}
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-md-6 form-group shfit-autosegest">
                                        <label htmlFor="firstStreet">Job Name or ID</label>
                                        <AutocompleteField
                                            showButton={false}
                                            includes={'Job'}
                                            isAsync={true}
                                            defaultValue={!filter.jobNameOrId ? true : false}
                                            inputValue={filter.jobNameOrId || undefined}
                                            placeholder='Enter Job Name or ID'
                                            onSearch={value => onFilterDropdownChange(value, 'jobNameOrId')}
                                            onChange={(value) => onFilterDropdownChange(value, 'jobNameOrId')}
                                            onClear={() => onFilterDropdownChange('', 'jobNameOrId')} />
                                    </div>
                                    <div
                                        className="col-md-6 form-group d-flex flex-row align-items-end flex-wrap">
                                        <div className="col-6 pl-0">
                                            <div className="form-group mb-0 d-flex align-items-center">
                                                <label>From: </label>
                                            </div>
                                            <div
                                                className="react-datepicker-custom-wrapper react-datepicker-custom-wrapper--full-width d-inline-block w-100">
                                                <DatePicker
                                                    selected={datePickerFormat(filter.startDate!)}
                                                    onChange={date => {
                                                        date && onFilterDropdownChange(setHoursAndMinutes(date as Date), 'startDate')
                                                        changeDatapicker(1);
                                                    }}
                                                    dateFormat="dd/MM/yyyy"
                                                    onInputClick={() => changeDatapicker(1)}
                                                    open={showDatapicker[1]}
                                                    onClickOutside={() => changeDatapicker(1)}
                                                />
                                                <div onClick={() => changeDatapicker(1)}><SVGCalendar /></div>
                                            </div>
                                        </div>
                                        <div className="col-6 pr-0">
                                            <div className="form-group mb-0 d-flex align-items-center">
                                                <label>To: </label>
                                            </div>
                                            <div
                                                className="react-datepicker-custom-wrapper react-datepicker-custom-wrapper--full-width d-inline-block w-100">
                                                <DatePicker
                                                    selected={datePickerFormat(filter.endDate!)}
                                                    onChange={date => {
                                                        date && onFilterDropdownChange(setHoursAndMinutes(date as Date), 'endDate')
                                                        changeDatapicker(2);
                                                    }}
                                                    dateFormat="dd/MM/yyyy"
                                                    onInputClick={() => changeDatapicker(2)}
                                                    open={showDatapicker[2]}
                                                    onClickOutside={() => changeDatapicker(2)}
                                                />
                                                <div onClick={() => changeDatapicker(2)}><SVGCalendar /></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6 form-group">
                                        <label htmlFor="firstStreet">Shift Period</label>
                                        <div
                                            className="custom-control custom-checkbox d-flex flex-wrap align-items-center mt-2">
                                            <div className="mr-5">
                                                <input
                                                    type="checkbox"
                                                    checked={(filter.shiftPeriod!.indexOf('42') >= 0) ? true : false}
                                                    onChange={() => handleCheckBox('shiftPeriod', 42)}
                                                    className="custom-control-input" id="Morning"
                                                    name="Morning" />
                                                <label className="custom-control-label mb-0"
                                                    htmlFor="Morning">Morning</label>
                                            </div>
                                            <div className="mr-5 ml-2">
                                                <input
                                                    type="checkbox"
                                                    checked={(filter.shiftPeriod!.indexOf('43') >= 0) ? true : false}
                                                    onChange={() => handleCheckBox('shiftPeriod', 43)}
                                                    className="custom-control-input"
                                                    id="Afternoon"
                                                    name="Afternoon" />
                                                <label className="custom-control-label mb-0"
                                                    htmlFor="Afternoon">Lunch</label>
                                            </div>
                                            <div className="ml-2">
                                                <input
                                                    type="checkbox"
                                                    checked={(filter.shiftPeriod!.indexOf('44') >= 0) ? true : false}
                                                    onChange={() => handleCheckBox('shiftPeriod', 44)}
                                                    className="custom-control-input"
                                                    id="Dismissal"
                                                    name="Dismissal" />
                                                <label className="custom-control-label mb-0"
                                                    htmlFor="Dismissal">Dismissal</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6 form-group">
                                        <label htmlFor="firstStreet">Zone</label>
                                        <div
                                            className="custom-control custom-checkbox d-flex align-items-center mt-2">
                                            <div className="mr-5">
                                                <input
                                                    type="checkbox"
                                                    checked={(filter.zone!.indexOf('2') >= 0) ? true : false}
                                                    onChange={() => handleCheckBox('zone', 2)}
                                                    className="custom-control-input" id="North"
                                                    name="North" />
                                                <label className="custom-control-label mb-0"
                                                    htmlFor="North">North</label>
                                            </div>
                                            <div className="mr-5 ml-2">

                                                <input
                                                    type="checkbox"
                                                    checked={(filter.zone!.indexOf('1') >= 0) ? true : false}
                                                    onChange={() => handleCheckBox('zone', 1)}
                                                    className="custom-control-input" id="West"
                                                    name="West" />

                                                <label className="custom-control-label mb-0"
                                                    htmlFor="West">West</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-4 form-group">
                                        <label>School Board</label>
                                        <CustomSelectControl
                                            options={schoolBoards}
                                            onChange={e => onFilterDropdownChange(e, "schoolBoard")}
                                            value={filter.schoolBoard}
                                            placeholder={"Select a School Board"}
                                            className={`custom-react-select--clientId`}
                                            isMulti={true}
                                        />
                                    </div>
                                    <div className="col-md-4 form-group">
                                        <label>Holidays</label>
                                        <CustomSelectControl
                                            options={holidays}
                                            onChange={e => onFilterDropdownChange(e, "holidayName")}
                                            value={filter.holidayName}
                                            placeholder={"Select a Holidays"}
                                            className={`custom-react-select--clientId`}
                                            isMulti={true}
                                        />
                                    </div>
                                    <div className="col-md-4 form-group">
                                        <label>Guard Coverage for Shift</label>
                                        <CustomSelectControl
                                            options={guardCoverageOpts}
                                            onChange={e => onFilterDropdownChange(e, "coverageForShift")}
                                            value={filter.coverageForShift}
                                            placeholder={"Select a Guard Coverage"}
                                            className={`custom-react-select--clientId`}
                                            isClearable
                                        />
                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col text-right">
                                        <button type="submit" className="btn btn-aqua-blue ml-auto mt-4">Create Report</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </form>
                </>
            }

            {showTable &&
                <AttendaceReportList
                    onBackClick={() => setShowTable(false)}
                    queryUrl={generatedQuery}
                    filter={filter}
                    options={[shiftVocabulary, zones.current, schoolBoards, holidays, guardCoverageOpts]}
                    hideTable={() => setShowTable(false)}
                />
            }

        </Layout>
    )
}

export default AttendaceReportDashboard
