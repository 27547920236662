import React, { FC, ReactNode } from 'react'
import Breadcrumbs, { BreadcrumbsData } from '../breadcrumbs/Breadcrumbs'
import Footer from '../footer/Footer'
import Navigation from '../navigation/Navigation'

type Props = {
    children: ReactNode
    breadcrumbs?: BreadcrumbsData | null
    className?: string
}

const Layout: FC<Props> = ({ children, breadcrumbs, className }) => {
    return (
        <>
            <header>
                <Navigation />
            </header>

            <main className="pb-5">
                {breadcrumbs && <Breadcrumbs
                    links={breadcrumbs.links}
                    currentPageTitle={breadcrumbs.currentPageTitle}
                    isHome={breadcrumbs.isHome}
                />}
                <div className={`container ${className ? className : ""}`}>
                    { children ? children : null }
                </div>
            </main>

            <Footer />
        </>
    )
}

export default Layout
