import React, {useContext, useState} from 'react'
import {Link} from 'react-router-dom'
import {SVGApprove, SVGEraser} from '../../../assets/icons/SvgIcons'
import {manageUnassignedDevicesContent} from '../../../content/administration/AdministrationContent'

import {IAvailableGuard, IGuardDevice} from '../../../common/interfaces/guards/IGuard'
import {DataService} from "../../../common/services/DataService";
import {NotificationTypes} from "../../../common/interfaces/INotification";
import {VocabularyEnum} from "../../../common/enums/VocabularyEnum";

import Layout from '../../../components/layout/Layout'
import BaseModal from '../../../components/modal/BaseModal'
import DataTable from '../../../components/tables/DataTable'
import SectionTitle from '../../../components/titles/SectionTitle'

import {momentDateFormat} from '../../../utils/DateFormatting'
import {useTableList} from "../../../customHooks/useTableList";
import AppContext from "../../../context/AppContext";
import WhithoutDevices from "../../guards/WhithoutDevices";
import {useListFilter} from "../../../customHooks/useListFilter";
import {matchOptionWithName} from "../../../utils/MatchOptionWithName";
import {useVocabulary} from "../../../customHooks/vocabulary/useVocabulary";

const ManageUnassignedDevices = () => {
    const appContext = useContext(AppContext)
    const {tableData, isLoading, fetchData, onClearFilterQuery, setTableData} = useTableList<IGuardDevice>('device/unassigned',)
    const {generateFilterQuery, filter, onFilterSearchClearClick, onSearchChange} = useListFilter("device/unassigned")
    const [searchIsActive, setSearchIsActive] = useState<boolean>(false)
    const [selectedItem, setSelectedItem] = useState<IGuardDevice>({} as IGuardDevice)
    const { vocabulary, getVocabulary } = useVocabulary(VocabularyEnum.deviceBrand, true)
    const [selectedGuard, setSelectedGuard] = useState<IAvailableGuard>({} as IAvailableGuard)
    const [modal, setModal] = useState<{ show: boolean, showSuccess: boolean, title: string }>({
        show: false,
        showSuccess: false,
        title: ""
    })

    const svc = new DataService<IGuardDevice>('device')

    const onAssignDeviceSubmit = async () => {
        appContext.showLoader(true)
        try {
            selectedItem.guardId = selectedGuard.id

            const response = await svc.update(selectedItem, selectedItem.id as number, 'assign')

            setModal({
                show: false,
                showSuccess: true,
                title: `<span class="font-weight-bold">${selectedItem.brandModel}</span> has been assigned to 
                <span class="font-weight-bold">${selectedGuard.fullName}</span>`
            })

            setTableData(tableData.filter(item => item.id !== response.data))

            setSelectedItem({} as IGuardDevice)
            setSelectedGuard({} as IAvailableGuard)
            appContext.showLoader(false)
        } catch (error: any) {
            setModal({
                show: false,
                showSuccess: false,
                title: ``
            })
            appContext.showLoader(false)
            appContext.showNotification(NotificationTypes.danger, error.message)
        }
    }

    const onAssignDeviceClick = (deviceInFocus: IGuardDevice) => {
        setSelectedItem(deviceInFocus)
        setModal({
            show: true,
            showSuccess: false,
            title: `Select a guard to assign <span class="font-weight-bold">${deviceInFocus.brandModel}</span>`
        })
    }

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            setSearchIsActive(true)
            fetchData(1, 0, generateFilterQuery(false), false, true)
        }
    }

    return (
        <Layout
            breadcrumbs={{
                links: [
                    {
                        title: 'Administration',
                        link: 'administration'
                    },
                    {
                        title: 'Users',
                        link: 'administration/manage-users'
                    }
                ],
                currentPageTitle: 'Unassigned Devices'
            }}
        >
            <div className="row">
                <div className="col-12 mb-4 d-flex flex-row flex-wrap align-items-center justify-content-between">
                    <SectionTitle title="Unassigned Devices"/>
                    <Link to="/administration/devices" className="btn btn-outline-aqua-blue ml-0 ml-md-3 mt-2 mt-md-0">View assigned devices</Link>
                </div>
            </div>

            <div className="row">

                <div className="col-12">
                    <form className="filters-form-group mb-4">
                        <div className="row">
                            <div className="col-12">
                                <h5>Search for a Guard or Device</h5>
                            </div>
                            <div className="col-12">
                                <div className="d-flex flex-row justify-content-start">
                                    <div className="form-group ml-0 mr-2 mb-0">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={filter.keyWord}
                                            placeholder={'Enter keyword(s)'}
                                            onChange={e => onSearchChange(e.target.value, "keyWord")}
                                            onKeyPress={(e) => handleKeyPress(e)}
                                        />
                                    </div>
                                    <button
                                        type="button"
                                        className="btn btn-aqua-blue"
                                        onClick={() => {
                                            setSearchIsActive(true);
                                            fetchData(1, 0, generateFilterQuery(false), false, true)}
                                        }>
                                        Search
                                    </button>
                                    {
                                        filter.keyWord !== "" &&
                                        <button
                                            type="button"
                                            className="btn btn-aqua-blue ml-2"
                                            onClick={() => {
                                                setSearchIsActive(false);
                                                onFilterSearchClearClick()
                                                onClearFilterQuery()
                                            }}>
                                            <SVGEraser/>
                                        </button>
                                    }
                                </div>
                            </div>

                        </div>
                    </form>

                    {
                        React.useMemo(() => (
                        <DataTable
                            thead={manageUnassignedDevicesContent.thead}
                            tbody={tableData ?
                                tableData.map(item => ({
                                    id: item.id,
                                    brandModel: matchOptionWithName(item.brand, vocabulary) + ` ${item.model}`,
                                    serialNumber: item.serialNumber,
                                    assignDate: momentDateFormat(item.assignDate as string),
                                }))
                                : []}
                            ignoreCols={[0]}
                            isLoading={isLoading}
                            tableClass={'table-info--notFixed'}
                            searchIsActive={searchIsActive}
                        >
                            {
                                (_id, rowItem: IGuardDevice) => (
                                    <>
                                        <td className="aling-middle">
                                            <div>
                                                <button
                                                    className={`btn btn-aqua-blue`}
                                                    onClick={() => onAssignDeviceClick(rowItem)}
                                                >
                                                    <SVGApprove/>
                                                </button>
                                            </div>
                                        </td>
                                    </>
                                )
                            }
                        </DataTable>
                        ), [tableData, isLoading, vocabulary, getVocabulary ])
                    }
                </div>
            </div>

            {
                modal.show && <BaseModal
                    show={modal.show}
                    onCancel={() => setModal({...modal, show: false})}
                    onSubmit={() => onAssignDeviceSubmit()}
                    submitBtnText={"Assign Device"}
                >
                    <WhithoutDevices
                        onSubmit={guard => setSelectedGuard(guard)}
                        selectedItem={selectedItem.body}
                        title={modal.title}
                    />
                </BaseModal>
            }
            {
                modal.showSuccess && <BaseModal
                    show={modal.showSuccess}
                    onCancel={() => setModal({...modal, showSuccess: false})}
                    cancelBtnText={"Close"}
                >
                    <h4 className="text-left mb-4" dangerouslySetInnerHTML={{__html: modal.title}}/>
                </BaseModal>
            }

        </Layout>
    )
}

export default ManageUnassignedDevices
