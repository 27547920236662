import React, {FC, useContext, useEffect} from 'react'
import { IVocabulary } from '../../../../common/interfaces/vocabulary/IVocabulary'
import { IJobReports } from '../../../../common/interfaces/jobs/IJob'
import DatePicker from "react-datepicker";
import { useLateOrAbsent } from '../../../../customHooks/useLateOrAbsent'
import { filterDateFormat, momentDateFormat, momentHoursFormat } from '../../../../utils/DateFormatting'
import { getHolidayField } from '../../../../utils/GetFieldFromTable'
import { matchOptionWithName } from '../../../../utils/MatchOptionWithName'
import DataTable from '../../../tables/DataTable'
import ReportActions from "../../../reports/ReportActions";
import { guardDetailsShiftReport } from '../../../../content/dashboard/TablesContent'
import { useListFilter } from '../../../../customHooks/useListFilter'
import { SVGCalendar, SVGEraser, SVGSearch } from '../../../../assets/icons/SvgIcons'
import { useDatapickerSvg } from '../../../../customHooks/useDatapickerSvg';
import GuardDetailsModalsContext from '../../../../context/guards/GuardDetailsModalsContext';
import { GuardIncludesEnum } from '../../../../common/enums/GuardEnums';
import { Roles } from 'common/enums/Roles';
import AppContext from 'context/AppContext';

type Props = {
    lateOrAbsences: number
    vocabulary: IVocabulary[]
    // TODO: Check How we can avoid any type
    shiftReportList: any
}

const GuardReportTab: FC<Props> = ({ lateOrAbsences, vocabulary, shiftReportList }) => {
    const { userId } = useContext(GuardDetailsModalsContext)
    const { generateFilterQuery, filter, onFilterDropdownChange, onFilterClearClick, setFilter } = useListFilter(`reports/guardshifts`)
    const { setLateOrAbsentValue } = useLateOrAbsent()
    const {showDatapicker, changeDatapicker} = useDatapickerSvg()
    const { currentPage, allRecords, perPage, onPagination, onPerPage, isLoading, fetchData, onClearFilterQuery, onSortCall } = shiftReportList
    const { currentUser } = useContext(AppContext)
    useEffect(() => {
        userId  !== undefined &&
        setFilter({
            ...filter,
            guardId: userId
        })
    }, [userId])

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement | HTMLFormElement | HTMLDivElement>) => {
        if (event.key === 'Enter') fetchData(1, 24, generateFilterQuery())
    }

    useEffect(() => {
        const listener = (event: any) => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                handleKeyPress(event);
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [filter]);

    return (
        <div>
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-3">
                <div>
                    <h5>Guard Shift Report</h5>
                    <h6># Lates or Absences: { lateOrAbsences }</h6>
                </div>
                <ReportActions
                    title="Guard Report:"
                    id={userId}
                    tabName={GuardIncludesEnum.report}
                    page="guard"
                    query={(filter.startDate && filter.endDate)
                        ? `&StartDate=${filter.startDate}&EndDate=${filter.endDate}`
                    : ''}
                />
            </div>
            <form className="filters-form-group filters-form-group--dashboard border-0"
                onKeyPress={(event) => handleKeyPress(event)}>
                <div className="row">
                    <div className="d-flex w-100 flex-wrap ml-1  ">
                        <div className="react-datepicker-custom-wrapper datepicker-width d-inline-block ml-2 mb-2 form-group">
                            <label className="mr-2" htmlFor="#">View From: </label>
                            <DatePicker
                                onKeyDown={(event) => handleKeyPress(event)}
                                selected={filter.startDate ? new Date(filter.startDate) : null}
                                onChange={date => {
                                    date && onFilterDropdownChange(filterDateFormat(date as Date), 'startDate')
                                    changeDatapicker(1)
                                }}
                                dateFormat="dd/MM/yyyy"
                                placeholderText="dd/mm/yyyy"
                                onInputClick={() => changeDatapicker(1)}
                                open={showDatapicker[1]}
                                onClickOutside={() => changeDatapicker(1)}
                                maxDate={new Date()}
                            />
                            <div onClick={() => changeDatapicker(1)}><SVGCalendar /></div>
                        </div>

                        <div className="react-datepicker-custom-wrapper datepicker-width d-inline-block ml-2 mb-2 ts form-group">
                            <label className="mr-2" htmlFor="#">To: </label>
                            <DatePicker
                                onKeyDown={(event) => handleKeyPress(event)}
                                selected={filter.endDate ? new Date(filter.endDate) : null}
                                onChange={date => {
                                    date && onFilterDropdownChange(filterDateFormat(date as Date), 'endDate')
                                    changeDatapicker(2)
                                }}
                                dateFormat="dd/MM/yyyy"
                                className="flex-basis-0"
                                placeholderText="dd/mm/yyyy"
                                onInputClick={() => changeDatapicker(2)}
                                open={showDatapicker[2]}
                                onClickOutside={() => changeDatapicker(2)}
                                maxDate={new Date()}
                            />
                            <div onClick={() => changeDatapicker(2)}><SVGCalendar /></div>
                        </div>

                        <button type="button" className="btn btn-aqua-blue ml-2"
                                onClick={() => { fetchData(1, 24, generateFilterQuery())}
                                }>
                            <SVGSearch />
                        </button>
                        {
                            filter.hasValue &&
                            <button type="button" className="btn btn-aqua-blue ml-2"
                                    onClick={() => {
                                        onClearFilterQuery()
                                        onFilterClearClick(undefined, { guardId: userId })
                                    }}>
                                <SVGEraser/>
                            </button>
                        }
                    </div>
                </div>
            </form>
            <div className="row">
                <div className="col-12">
                    {
                        React.useMemo(() => (
                            <DataTable
                                tableClass={"mt-4 table-info--lastColHolidays table-info--notFixed"}
                                responsive="xl"
                                thead={currentUser.role === Roles.Client ? guardDetailsShiftReport.thead.filter(th => th.label !== 'ASP Start Time') : guardDetailsShiftReport.thead}
                                tbody={shiftReportList.tableData ? shiftReportList.tableData.map((report: IJobReports) => ({
                                    id: report.id ? report.id : null,
                                    jobNameLink: `<a href="#/jobs/${report.jobId}/details">${report.jobName}</a>`,
                                    startDate: momentDateFormat(report.startDate),
                                    aspStartDate: momentHoursFormat(report.aspStartDate),
                                    timeFrom: momentHoursFormat(report.timeFrom),
                                    timeTo: momentHoursFormat(report.timeTo),
                                    shiftPeriod: matchOptionWithName(report.shiftPeriod, vocabulary),
                                    checkInDateTime: report.checkInDateTime ? `${momentHoursFormat(report.checkInDateTime)}` : '-',
                                    checkOutDateTime: report.checkOutDateTime ? `${momentHoursFormat(report.checkOutDateTime)}` : '-',
                                })) : []}
                                ignoreCols={currentUser.role === Roles.Client ? [0,3] :[0]}
                                defaultSortedColumn={1}
                                defaultSortDirection='down'
                                
                                pagination
                                sortOnBackend
                                showTableLengthData
                                currentPage={currentPage}
                                itemsCount={allRecords}
                                itemsPerPage={+perPage}
                                onPager={page => onPagination(page)}
                                onPerPage={value => onPerPage(value)}
                                tableName={"Past Job Shifts"}
                                isLoading={isLoading}
                                onSort={(orderBy, desc) => onSortCall(orderBy, desc, filter.keyWord)}
                            >
                                {
                                    reportId => (
                                        <>
                                            { setLateOrAbsentValue(reportId, shiftReportList.tableData) }
                                            <td className="align-middle">
                                                { getHolidayField(reportId, shiftReportList.tableData) }
                                            </td>
                                        </>
                                    )
                                }
                            </DataTable>
                        ), [shiftReportList.tableData, setLateOrAbsentValue, vocabulary])
                    }
                </div>
            </div>

        </div>
    )
}

export default GuardReportTab
