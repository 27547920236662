import React, { FC, useContext, useEffect, useState} from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import CardStatistic from '../../components/cards/CardStatistic'
import Layout from '../../components/layout/Layout'
import SectionTitle from '../../components/titles/SectionTitle'
import DataTable from '../../components/tables/DataTable'
import { guardsNotOnTimeContent } from '../../content/dashboard/TablesContent'
import { Guard_Attendance } from '../../content/dashboard/GuardAttendance'
import {useVocabulary} from "../../customHooks/vocabulary/useVocabulary";
import {VocabularyEnum} from "../../common/enums/VocabularyEnum";
import JobsActiveRightNow from '../../components/dashboard/JobsActiveRightNow'
import { useShiftPeriod } from '../../customHooks/useShiftPeriod'
import ShiftsRequireGuard from '../../context/shifts/ShiftsRequireGuard'
import ResourceDataService from '../../common/services/ResourceDataService'
import { IVocabulary } from '../../common/interfaces/vocabulary/IVocabulary'
import { momentDateFormat, momentHoursFormat, todayDateFormat, tomorrowDateFormat } from '../../utils/DateFormatting'
import { IJobReports, IJobsRightNow } from '../../common/interfaces/jobs/IJob'
import { useTableList } from '../../customHooks/useTableList'
import { IAvailableGuard } from '../../common/interfaces/guards/IGuard'
import { useListFilter } from '../../customHooks/useListFilter'
import { matchOptionWithName } from '../../utils/MatchOptionWithName'
import { useLateOrAbsent } from '../../customHooks/useLateOrAbsent'
import CustomSelectControl from '../../components/custom-select/CustomSelectControl'
import RequestsToDeactivateSchedules from '../../components/jobs/schedule-requests/RequestsToDeactivateSchedules'
import RequestsToDeactivateJobs from '../../components/jobs/job-requests/RequestsToDeactivateJobs'
import RequestsToDeactivateGuards from '../../components/guards/guard-requests/RequestsToDeactivateGuards'
import RequestsToDeactivateUsers from '../../components/administration/RequestsToDeactivateUsers'
import { generateQueryShiftUrl as dayQueryUrl } from '../../helpers/GenerateQueryUrl'
import { AuthService } from '../../common/auth/AuthService'
import { Roles } from '../../common/enums/Roles'
import AppContext from '../../context/AppContext'
import { SVGPencil } from 'assets/icons/SvgIcons'
import AddEditCommentModal from 'components/modal/ dashboard/AddEditCommentModal'
import { useVerifyRolesTHead } from 'customHooks/useVerifyRolesTHead'

import { useRole } from 'customHooks/useRole'
interface IGuardAttendance {
    totalGuards: number,
    guardAttendanceForClient: number,
    guardAttendanceForASP: number
}
const Dashboard: FC<RouteComponentProps> = ({ history }) => {
    const baseUrl = `job/shifts/${dayQueryUrl.currentDay}`;
    const baseAttendance = 'job/guard-attendance';

    const shiftsVocabulary = useVocabulary(VocabularyEnum.shift)
    const reasonsVocabulary = useVocabulary(VocabularyEnum.shiftReason)
    const bookLeaveVocabulary = useVocabulary(VocabularyEnum.bookLeave)
    const { vocabulary: guardDeactVocabulary } = useVocabulary(VocabularyEnum.guardDeactivation, true)
    const absentTable = useTableList<IJobReports>(`job/guard-absent`, 0);

    const jobsListTable = useTableList<IJobsRightNow>(`${baseUrl}`, 0, false);

    const jobsListFilter = useListFilter('')

    const { currentShiftPeriod, shiftPeriods } = useShiftPeriod()
    const [attendance, setAttendance] = useState<IGuardAttendance>({ totalGuards: 0, guardAttendanceForClient: 0, guardAttendanceForASP: 0})
    const [attendanceSelect, setAttendanceSelect] = useState<IVocabulary>({} as IVocabulary)
    const guardAttendanceSvc = new ResourceDataService<IGuardAttendance>({ url: '' })
    const [urlBasedOnShift, setUrlBasedOnShift] = useState<string>('')
    const { setLateOrAbsentValue } = useLateOrAbsent()
    const [reloadRequests, setReloadRequests] = useState<boolean>(false)
    const { currentUser } = useContext(AppContext)
    const [commentModal, setCommentModal] = useState({show: false, value: '', id: null});
    const { theadTable: lateOrAbsentTHead, addVerifyRoles } = useVerifyRolesTHead()
    const {isAdminRole, isClientRole} = useRole();

    useEffect(() => {
        reasonsVocabulary.getVocabulary()
        bookLeaveVocabulary.getVocabulary()
    }, [])

    useEffect(() => {
        addVerifyRoles(guardsNotOnTimeContent.thead)
    }, [guardsNotOnTimeContent.thead])

    useEffect(() => {
        if (urlBasedOnShift !== '' || Object.keys(currentShiftPeriod).length === 0) return;
        shiftsVocabulary.getVocabulary().then((vocabulary) => {
            setUrlBasedOnShift(generateBaseUrl(vocabulary))
            getAttendance(generateBaseUrl(vocabulary as IVocabulary[], baseAttendance))
        })
    }, [currentShiftPeriod])

    useEffect(() => {
        if (shiftsVocabulary.vocabulary.length !== 0 && Object.keys(currentShiftPeriod).length !== 0) {
            let activeOpt: IVocabulary | undefined;
            if (currentShiftPeriod.id === 4) {
                activeOpt = shiftsVocabulary.vocabulary.find(item => item.name.includes(shiftPeriods[0].label.split(' ')[0]));
            } else {
                activeOpt = shiftsVocabulary.vocabulary.find(item => item.name.includes(currentShiftPeriod.label.split(' ')[0]));
            }
            activeOpt && setAttendanceSelect(activeOpt)
        }
     }, [shiftsVocabulary.vocabulary, currentShiftPeriod])

    const generateBaseUrl = (vocabulary: IVocabulary[] = shiftsVocabulary.vocabulary, url?: string) => {
        let completeUrl = url ? url : '';
        const result = vocabulary.find(item => item.name.includes(currentShiftPeriod.label.split(' ')[0]))

        if (result) {
            completeUrl += generateQueryShiftUrl(result.id).currentDay
        } else {
            const morningShift = vocabulary.find(item => item.name.includes(shiftPeriods[0].label.split(' ')[0]));
            morningShift && (completeUrl += generateQueryShiftUrl(morningShift.id).nextDay)
        }
        return completeUrl
    }

    const generateQueryShiftUrl = (shiftId: number) => {
        return {
            currentDay: `?StartDate=${todayDateFormat()}&EndDate=${todayDateFormat()}&ShiftPeriod=${shiftId}`,
            nextDay: `?StartDate=${tomorrowDateFormat()}&EndDate=${tomorrowDateFormat()}&ShiftPeriod=${shiftId}`
        }
    }

    const getAttendance = async (queryUrl: string) => {
        try {
            const response = await guardAttendanceSvc.getAll(`${queryUrl}`)
            setAttendance(response.data as IGuardAttendance)
        } catch (error: any) { console.log(error) }
    }

    const onChangeAttendacePeriod = async (option: IVocabulary) => {
        setAttendanceSelect(option)
        let completeUrl = baseAttendance;
        currentShiftPeriod.id === 4 ?
            completeUrl += generateQueryShiftUrl(option.id).nextDay
        :
            completeUrl += generateQueryShiftUrl(option.id).currentDay;

        await getAttendance(completeUrl)
    }

    const onGuardAssignClick = (shiftId: number, guard: IAvailableGuard) => {
        jobsListTable.setTableData(jobsListTable.tableData.map((item: IJobsRightNow) => {
            if (item.shiftId === shiftId) {
                item.guardName = `${guard.firstName} ${guard.middleName} ${guard.lastName}`
                return item
            }
            return item
        }))
        onCheckActiveJobsSubmit()
    }

    const onCheckActiveJobsSubmit = () => {
        getAttendance(generateBaseUrl(shiftsVocabulary.vocabulary as IVocabulary[], baseAttendance))
        absentTable.fetchData(0,0)
    }

    useEffect(() => {
        setReloadRequests(false)
    }, [reloadRequests])

    const refreshRequests = () => {
        setReloadRequests(true)
        getAttendance(generateBaseUrl(shiftsVocabulary.vocabulary as IVocabulary[], baseAttendance))
        absentTable.fetchData(0,0)
        // setReloadRequests(false)
    }

    return (
        <Layout
        breadcrumbs={{ currentPageTitle: 'My Dashboard', isHome: true }}
        >
            <JobsActiveRightNow
                shiftVocabulary={shiftsVocabulary.vocabulary}
                generatedUrl={urlBasedOnShift}
                currentShiftPeriod={currentShiftPeriod}
                jobsListTable={jobsListTable}
                jobsListFilter={jobsListFilter}
                onCheckSubmit={() => onCheckActiveJobsSubmit()}
                reload={reloadRequests}
                onRefreshClick={() => refreshRequests()}
                commentActionCallback={async () => absentTable.fetchData(0,0)}
            />

            {
                React.useMemo(() => (
                    <section>
                        <div className="row">
                            <div className="col-12 py-4">
                                <SectionTitle title={`Today's Guard Attendance`}/>
                            </div>
                            <div className="col-md-12 pb-4">
                                <form className="filters-form-group mb-0 border-0 p-0">
                                    <CustomSelectControl
                                        options={shiftsVocabulary.vocabulary}
                                        value={attendanceSelect.id}
                                        onChange={(id, label) => onChangeAttendacePeriod({ id: +id, name: label as string })}
                                    />
                                </form>
                            </div>
                            <div className={`col-lg-${isAdminRole() ? 4 : 6} mb-3 col-md-12 `}>
                                <CardStatistic
                                    title={Guard_Attendance.totalGuards.title}
                                    description={Guard_Attendance.totalGuards.description}
                                    value={`${attendance.totalGuards}`}
                                />
                            </div>
                            {isAdminRole() && 
                                (
                                    <div className={`col-lg-${isAdminRole() ? 4 : 6} mb-3 col-md-12`}>
                                        <CardStatistic
                                            title={Guard_Attendance.guardAttendanceForASP.title}
                                            description={Guard_Attendance.guardAttendanceForASP.description}
                                            value={`${!isNaN(attendance.guardAttendanceForASP) && attendance.guardAttendanceForASP ? attendance.guardAttendanceForASP.toFixed(1) + '%' : '--'}`}
                                        />
                                    </div>
                                )
                            }
                            <div className={`col-lg-${isAdminRole() ? 4 : 6} mb-3 col-md-12 `}>
                                <CardStatistic
                                    title={Guard_Attendance.guardAttendanceForClient.title}
                                    description={Guard_Attendance.guardAttendanceForClient.description}
                                    value={`${!isNaN(attendance.guardAttendanceForClient) && attendance.guardAttendanceForClient ? attendance.guardAttendanceForClient.toFixed(1) + '%' : '--'}`}
                                />
                            </div>
                        </div>
                    </section>
                ), [attendance, currentShiftPeriod, shiftsVocabulary.vocabulary])
            }

            <section className="mt-3 mt-md-5 mb-2">
                <div className="row">
                    <div className="col-12 pb-4 d-flex flex-row flex-wrap justify-content-end justify-content-md-between">
                        <SectionTitle title="Shifts That Require A Guard to be Assigned Today"/>
                        <Link className="d-flex align-items-center font-weight-bold text-primary" to={'/jobs/unassigned'}>See All</Link>
                    </div>
                    <ShiftsRequireGuard
                        shiftVocabulary={shiftsVocabulary.vocabulary}
                        reasonVocabulary={reasonsVocabulary.vocabulary}
                        bookLeaveVocabulary={bookLeaveVocabulary.vocabulary}
                        guardDeactVocabulary={guardDeactVocabulary}
                        onSubmit={(shiftId, guard) => onGuardAssignClick(shiftId, guard)}
                        reload={reloadRequests}
                    />
                </div>
            </section>
            <section>
                <div className="row">
                    <div className="col-12 d-flex flex-row justify-content-between py-4">
                        <SectionTitle title="Guards Not on Time or Absent"/>
                    </div>
                   {
                       React.useMemo(() => (
                            <div className="col-12">
                                <DataTable
                                    thead={lateOrAbsentTHead}
                                    tbody={absentTable.tableData ?
                                        absentTable.tableData.map(item => (
                                            {
                                                id:item.id,
                                                jobNameLink: `<a href="#/jobs/${item.jobId}/details">${item.jobName}</a>`,
                                                guardName: item.guardName ? item.guardName : '-',
                                                time: `${momentHoursFormat(item.timeFrom)} - ${momentHoursFormat(item.timeTo)}`,
                                                period: matchOptionWithName(item.shiftPeriod, shiftsVocabulary.vocabulary),
                                                status: item.status,
                                                lastComment: item.lastComment || ''
                                            }
                                        ))
                                    : []}
                                    ignoreCols={[0, 5, 6, 7]}
                                    isLoading={absentTable.isLoading}
                                    defaultSortedColumn={3}
                                    tableClass={'table-info--notFixed'}
                                >
                                    {
                                        (id, rowItem) => (
                                            <>
                                                {setLateOrAbsentValue(id, absentTable.tableData)}
                                                <td className="align-middle p-2 mw-400">
                                                    {rowItem.lastComment || '-'}
                                                </td>
                                                {
                                                    !isClientRole() && 
                                                        <td className="align-middle">
                                                            <button
                                                                type="button"
                                                                className={`btn btn-aqua-blue`}
                                                                onClick={() => setCommentModal({show: true, value: rowItem.lastComment, id})}>
                                                                <SVGPencil/>
                                                            </button>
                                                        </td>
                                                }
                                            </>
                                        )
                                    }
                                </DataTable>
                            </div>

                       ), [absentTable.tableData, absentTable.isLoading, shiftsVocabulary.vocabulary, setLateOrAbsentValue])
                   }
                </div>
                {
                    commentModal.show && 
                        <AddEditCommentModal 
                            defaultValue={commentModal.value} 
                            show={commentModal.show}
                            title={'Edit a Comment for the City'}
                            submitText={'Edit Comment'}
                            onCancel={() => setCommentModal({} as {show: boolean, value: string, id: null})}
                            jobId={commentModal.id ?? 0} 
                            submitCallback={async () => absentTable.fetchData(0,0)}
                        />
                }
            </section>
            
            { AuthService.checkIfUserHasPermission([Roles.Admin, Roles.ASP], currentUser.role) &&
            <section className="mt-4">
                <div className="row">
                    <div className="col-md-6 d-flex flex-column py-4">
                        <div className="d-flex flex-row flex-wrap justify-content-end justify-content-md-between mb-4">
                            <SectionTitle title="Requests to Deactivate Schedules"/>
                            <Link className="d-flex align-items-center font-weight-bold text-primary" to={'/jobs/job-schedules/schedules/deactivate'}>See All</Link>
                        </div>
                        <RequestsToDeactivateSchedules
                            hideTheadCols={[2, 4, 5, 6]}
                            hideTbodyCols={[4, 6, 7, 8]}
                            pageSize={5}
                            onSuccess={() => refreshRequests()}
                            reload={reloadRequests}
                        />
                    </div>

                    <div className="col-md-6 d-flex flex-column py-4">
                        <div className="d-flex flex-row flex-wrap justify-content-end justify-content-md-between mb-4">
                            <SectionTitle title="Requests to Deactivate Jobs"/>
                            <Link className="d-flex align-items-center font-weight-bold text-primary" to={'/jobs/deactivate'}>See All</Link>
                        </div>
                        <RequestsToDeactivateJobs
                            hideTheadCols={[2, 3, 4, 5, 6]}
                            hideTbodyCols={[3, 4, 5, 6, 7]}
                            pageSize={5}
                            onSuccess={() => refreshRequests()}
                            reload={reloadRequests}
                        />
                    </div>

                    <div className="col-md-6 d-flex flex-column py-4">
                        <div className="d-flex flex-row flex-wrap justify-content-end justify-content-md-between mb-4">
                            <SectionTitle title="Requests to Deactivate Guards"/>
                            <Link className="d-flex align-items-center font-weight-bold text-primary" to={'/guards/deactivate'}>See All</Link>
                        </div>
                        <RequestsToDeactivateGuards
                            hideTheadCols={[2, 3, 4]}
                            hideTbodyCols={[3, 4, 5, 6, 7]}
                            pageSize={5}
                            onSuccess={() => refreshRequests()}
                            reload={reloadRequests}
                        />
                    </div>

                    <div className="col-md-6 d-flex flex-column py-4">
                        <div className="d-flex flex-row flex-wrap justify-content-end justify-content-md-between mb-4">
                            <SectionTitle title="Requests to Deactivate Users"/>
                            <Link className="d-flex align-items-center font-weight-bold text-primary" to={'/administration/manage-users/pending'}>See All</Link>
                        </div>
                        <RequestsToDeactivateUsers
                            hideTheadCols={[3, 4]}
                            hideTbodyCols={[4, 5]}
                            pageSize={5}
                            onSuccess={() => refreshRequests()}
                            reload={reloadRequests}
                        />
                    </div>
                </div>
            </section>
            }
        </Layout>
    )
}

export default Dashboard
