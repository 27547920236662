import { Redirect, Route, Switch } from 'react-router-dom';
import './App.scss';
import ProtectedRoute from './common/auth/ProtectedRoute';
import { Roles } from './common/enums/Roles';
import AppWrapper from './components/app-wrapper/AppWrapper';

import MyProfile from './pages/account/MyProfile';
import RecentActivity from './pages/account/RecentActivity'

import Dashboard from './pages/dashboard/Dashboard';

import ActiveGuardsList from './pages/guards/ActiveGuardsList';
import AddEditGuard from './pages/guards/AddEditGuard';
import GuardDetails from './pages/guards/GuardDetails';
import DeactivateGuards from './pages/guards/DeactivateGuards';
import DeactivatedGuards from './pages/guards/DeactivatedGuards';

import ActiveJobSchedules from './pages/jobs/ActiveJobSchedules';
import ActiveJobsList from './pages/jobs/ActiveJobsList';
import AddEditJob from './pages/jobs/AddEditJob';
import EditJobs from './pages/jobs/EditJobs';
import JobDetails from './pages/jobs/JobDetails';
import UnassignedJobShiftsPage from './pages/jobs/UnassignedJobShifts';

import ForgotPassword from './pages/public/ForgotPassword';
import SignIn from './pages/public/SignIn';
import ResetPassword from './pages/public/ResetPassword';

import Administration from './pages/administration/Administration';
import DeactivatedUsers from './pages/administration/users/DeactivatedUsers';
import DeactivateUsers from './pages/administration/users/DeactivateUsers';
import ManageSchoolsAndBoards from './pages/administration/schools/ManageSchoolsAndBoards';
import ManageInactiveSchools from './pages/administration/schools/ManageInactiveSchools';
import ManageHolidays from './pages/administration/ManageHolidays';
import ManageSettings from './pages/administration/ManageSettings';
import ManageDevices from './pages/administration/device/ManageDevices';
import ManageUnassignedDevices from './pages/administration/device/ManageUnassignedDevices';
import ManageNotifications from './pages/administration/ManageNotifications';
import DeactivateSchedules from './pages/administration/schedules/DeactivateSchedules';
import DeactivatedSchedules from './pages/administration/schedules/DeactivatedSchedules';
import ManageUsers from './pages/administration/users/ManageUsers';

import ActiveClientsList from './pages/clients/ActiveClientsList'
import RequestsToDeactivateClients from './pages/clients/RequestsToDeactiveClients'
import ClientDetails from './pages/clients/ClientDetails'
import AddClient from './pages/clients/AddClient';
import DeactiveJobs from "./pages/jobs/DeactivateJobs";
import DeactivedJobs from "./pages/jobs/DeactivatedJobs";
import DuplicateJobs from "./pages/jobs/DuplicateJobs";
import PendingJobSchedules from "./components/jobs/duplicate-job/PendingJobSchedules";
import ManageDropdownOptions from "./pages/administration/ManageDropdownOptions";
import ActivityReport from "./pages/administration/ActivityReport";
import ManageSchoolYears from "./pages/administration/schools/ManageSchoolYears";
import Reports from "./pages/reports/Reports";
import JobShiftReport from "./pages/reports/job-shift-reports/JobShiftReport";
// import EditJobSchedules from "./context/jobs/edit-job/EditJobSchedules";
import DevelopersPage from './pages/dev/DevelopersPage';
import GuardLocationReport from "./pages/reports/guard-location/GuardLocationReport";
import AttendaceReportDashboard from './pages/reports/attendace-report/AttendaceReportDashboard';
import ArrivalReports from './pages/reports/xguard-reports/ArrivalReports';
import MessagesFromGuards from './pages/reports/xguard-reports/MessagesFromGuards';
import IncidentReports from 'pages/reports/xguard-reports/IncidentReports';
import HealthAndSafetyChecks from 'pages/reports/xguard-reports/HealthAndSafetyChecks';
import GuardAppLeaveReports from 'pages/reports/xguard-reports/GuardAppLeaveReports';
import GuardAppProfileUpdateReports from 'pages/reports/xguard-reports/GuardAppProfileUpdateReports';
import GuardTestReports from 'pages/reports/xguard-reports/GuardTestReports';
import GuardLocationTrackingReports from 'pages/reports/xguard-reports/GuardLocationTrackingReports';
import Policy from 'pages/policy/Policy';
// import DevelopersPage from './pages/dev/DevelopersPage';

const App = () => {

  return (
    <AppWrapper>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/dashboard" /> }/>
        <Route exact path="/signin" component={SignIn} />
        <Route exact path="/password/forgot" component={ForgotPassword} />
        <Route exact path="/password/reset" component={ResetPassword} />
        <Route exact path="/privacy" component={Policy} />
        <ProtectedRoute exact path="/dashboard" component={Dashboard} roles={[Roles.ASP, Roles.Admin, Roles.Client, Roles.Supervisor]}/>

        {/* developers page */}
        <ProtectedRoute exact path="/dev-testing" component={DevelopersPage} roles={[Roles.Admin]}/>

        {/* Guards
         */}
        <ProtectedRoute exact path="/guards" component={ActiveGuardsList} roles={[Roles.ASP, Roles.Admin, Roles.Client, Roles.Supervisor]}/>
        <ProtectedRoute exact path="/guards/add" component={AddEditGuard} roles={[Roles.ASP, Roles.Admin]}/>
        <ProtectedRoute exact path="/guards/:id/details" component={GuardDetails} roles={[Roles.ASP, Roles.Admin, Roles.Client, Roles.Supervisor]}/>
        <ProtectedRoute exact path="/guards/:id/edit" component={AddEditGuard} roles={[Roles.ASP, Roles.Admin]}/>
        <ProtectedRoute exact path="/guards/:id/assignments" component={GuardDetails} roles={[Roles.ASP, Roles.Admin]}/>
        <ProtectedRoute exact path="/guards/deactivate" component={DeactivateGuards} roles={[Roles.Admin, Roles.ASP]}/>
        <ProtectedRoute exact path="/guards/deactivated" component={DeactivatedGuards} roles={[Roles.Admin, Roles.ASP]}/>

        {/* Clients */}
        <ProtectedRoute exact path="/clients" component={ActiveClientsList} roles={[Roles.ASP, Roles.Admin]}/>
        <ProtectedRoute exact path="/clients/deactivate" component={RequestsToDeactivateClients} roles={[Roles.Admin, Roles.ASP]}/>
        <ProtectedRoute exact path="/clients/add" component={AddClient} roles={[Roles.ASP, Roles.Admin]}/>
         {/* <ProtectedRoute exact path="/clients/:id/edit" component={AddGuard} roles={[Roles.ASP, Roles.Admin]}/> */}
        <ProtectedRoute exact path="/clients/:id/details" component={ClientDetails} roles={[Roles.Admin]}/>

        {/* Jobs */}
        <ProtectedRoute exact path="/jobs" component={ActiveJobsList} roles={[Roles.ASP, Roles.Admin, Roles.Client, Roles.Supervisor]}/>
        <ProtectedRoute exact path="/jobs/schedules" component={ActiveJobSchedules} roles={[Roles.ASP, Roles.Admin, Roles.Client, Roles.Supervisor]}/>
        <ProtectedRoute exact path="/jobs/edit" component={EditJobs} roles={[Roles.ASP, Roles.Admin, Roles.Client, Roles.Supervisor]}/>
        <ProtectedRoute exact path="/jobs/unassigned" component={UnassignedJobShiftsPage} roles={[Roles.ASP, Roles.Admin, Roles.Client, Roles.Supervisor]}/>
        <ProtectedRoute exact path="/jobs/add" component={AddEditJob} roles={[Roles.ASP, Roles.Admin]}/>
        <ProtectedRoute exact path="/jobs/:id/edit" component={AddEditJob} roles={[Roles.ASP, Roles.Admin]}/>
        <ProtectedRoute exact path="/jobs/:id/details" component={JobDetails} roles={[Roles.ASP, Roles.Admin, Roles.Client, Roles.Supervisor]}/>
        <ProtectedRoute exact path="/jobs/deactivate" component={DeactiveJobs} roles={[Roles.Admin, Roles.ASP]}/>
        <ProtectedRoute exact path="/jobs/deactivated" component={DeactivedJobs} roles={[Roles.Admin, Roles.ASP]}/>
        <ProtectedRoute exact path="/jobs/duplicate" component={DuplicateJobs} roles={[Roles.ASP, Roles.Admin]}/>
        <ProtectedRoute exact path="/jobs/duplicate/pending" component={PendingJobSchedules} roles={[Roles.ASP, Roles.Admin]}/>

        {/* Account */}
        <ProtectedRoute exact path="/account/my-profile" component={MyProfile} roles={[Roles.ASP, Roles.Admin, Roles.Client, Roles.Supervisor]}/>
        <ProtectedRoute exact path="/account/recent-activity" component={RecentActivity} roles={[Roles.ASP, Roles.Admin]}/>

        {/* Administration  */}
        <ProtectedRoute exact path="/administration" component={Administration} roles={[Roles.ASP, Roles.Admin]}/>
        <ProtectedRoute exact path="/administration/manage-users" component={ManageUsers} roles={[Roles.ASP, Roles.Admin]}/>
        <ProtectedRoute exact path="/administration/manage-users/deactivated-users" component={DeactivatedUsers} roles={[Roles.Admin]}/>
        <ProtectedRoute exact path="/administration/manage-users/pending" component={DeactivateUsers} roles={[Roles.Admin]}/>
        <ProtectedRoute exact path="/administration/schools-and-boards" component={ManageSchoolsAndBoards} roles={[Roles.ASP, Roles.Admin]}/>
        <ProtectedRoute exact path="/administration/schools-years" component={ManageSchoolYears} roles={[Roles.ASP, Roles.Admin]}/>
        <ProtectedRoute exact path="/administration/schools-and-boards/deactivated" component={ManageInactiveSchools} roles={[Roles.ASP, Roles.Admin]}/>
        <ProtectedRoute exact path="/administration/holidays" component={ManageHolidays} roles={[Roles.ASP, Roles.Admin]}/>
        <ProtectedRoute exact path="/administration/settings" component={ManageSettings} roles={[Roles.ASP, Roles.Admin]}/>
        <ProtectedRoute exact path="/administration/devices" component={ManageDevices} roles={[Roles.ASP, Roles.Admin]}/>
        <ProtectedRoute exact path="/administration/devices/unassigned" component={ManageUnassignedDevices} roles={[Roles.ASP, Roles.Admin]}/>
        <ProtectedRoute exact path="/jobs/job-schedules/schedules/deactivate" component={DeactivateSchedules} roles={[Roles.Admin, Roles.ASP]}/>
        <ProtectedRoute exact path="/jobs/job-schedules/deactivated" component={DeactivatedSchedules} roles={[Roles.Admin, Roles.ASP]}/>
        <ProtectedRoute exact path="/administration/notifications" component={ManageNotifications} roles={[Roles.ASP, Roles.Admin]}/>
        <ProtectedRoute exact path="/administration/dropdown" component={ManageDropdownOptions} roles={[Roles.ASP, Roles.Admin]}/>
        <ProtectedRoute exact path="/administration/activity-report" component={ActivityReport} roles={[Roles.Admin]}/>

        {/* 8.0 Reports */}
        <ProtectedRoute exact path="/reports" component={Reports} roles={[Roles.ASP, Roles.Admin, Roles.Supervisor, Roles.Client]}/>
        <ProtectedRoute exact path="/reports/shift" component={JobShiftReport} roles={[Roles.ASP, Roles.Admin, Roles.Supervisor]}/>
        <ProtectedRoute exact path="/reports/guard" component={GuardLocationReport} roles={[Roles.ASP, Roles.Admin]}/>
        <ProtectedRoute exact path="/reports/dailyattendance" component={AttendaceReportDashboard} roles={[Roles.ASP, Roles.Admin, Roles.Supervisor, Roles.Client]}/>
        <ProtectedRoute exact path="/reports/guard-arrival" component={ArrivalReports} roles={[Roles.ASP, Roles.Admin, Roles.Supervisor]}/>
        <ProtectedRoute exact path="/reports/guard-messages" component={MessagesFromGuards} roles={[Roles.ASP, Roles.Admin, Roles.Supervisor]}/>
        <ProtectedRoute exact path="/reports/guard-messages/:id" component={MessagesFromGuards} roles={[Roles.ASP, Roles.Admin]}/>
        <ProtectedRoute exact path="/reports/incidents" component={IncidentReports} roles={[Roles.ASP, Roles.Admin, Roles.Supervisor, Roles.Client]}/>
        <ProtectedRoute exact path="/reports/health-checks" component={HealthAndSafetyChecks} roles={[Roles.ASP, Roles.Admin, Roles.Supervisor]}/>
        <ProtectedRoute exact path="/reports/leave-requests" component={GuardAppLeaveReports} roles={[Roles.ASP, Roles.Admin]}/>
        <ProtectedRoute exact path="/reports/profile-update-requests" component={GuardAppProfileUpdateReports} roles={[Roles.ASP, Roles.Admin]}/>
        <ProtectedRoute exact path="/reports/guard-tests" component={GuardTestReports} roles={[Roles.ASP, Roles.Admin, Roles.Supervisor, Roles.Client]}/>
        <ProtectedRoute exact path="/reports/location-tracking" component={GuardLocationTrackingReports} roles={[Roles.ASP, Roles.Admin,  Roles.Supervisor]}/>

        <Route render={() => <Redirect to="/" />} />
      </Switch>
    </AppWrapper>
  )
}

export default App;
