import { useContext, useState } from 'react'
import { ICityDropdown } from '../common/interfaces/dropdowns/ICityDropdown'
import { IGuard } from '../common/interfaces/guards/IGuard'
import { NotificationTypes } from '../common/interfaces/INotification'
import { IJob } from '../common/interfaces/jobs/IJob'
import IAutocomplete from '../common/interfaces/maps/IAutocomplete'
import AppContext from '../context/AppContext'

const useGoogleApi = () => {
    const [count, setCount] = useState(0)
    const appContext = useContext(AppContext);
    
    const getLocationGeocoding = async (drag: boolean, model: IJob | IGuard, cities: ICityDropdown[], callback: (location: any) => void, forceLoad = false) => {
        const baseMapUrl = 'https://maps.googleapis.com/maps/api/geocode/json?'

        const cityName = model.cityId ? cities.find(el => model.cityId === el.id)!.name : "Toronto";

        if (model.firstStreet.length >= 2) {
            const firstStreet = (drag || model.secondStreet === '')
                ? `${model.firstStreet.replace(/\s+/g, " ")}`
                : `${model.firstStreet.replace(/\s+/g, " ").replace(/[0-9]/g, '')}`;

            const secondStreet = model.secondStreet ? `${model.secondStreet.replace(/\s+/g, " ")}` : ''
  
            if (count > 2 || forceLoad) {
                const requestUrl = `${baseMapUrl}address=` + encodeURIComponent(`${firstStreet}${secondStreet ? ' ' + secondStreet : ''} ${cityName.split(',')}`) + `&components=country:CA&key=${process.env.REACT_APP_MAP_KEY}`
                const response = await fetch(requestUrl);
                const data = await response.json();

                if (data.results.length) {
                    callback(data.results[0].geometry.location)
                }
            }
        }

    }

    const cityAutosuggestion = async (city: string): Promise<IAutocomplete | undefined> => {
        const baseUrl = `https://maps.googleapis.com/maps/api/place/autocomplete/json?`;
        try {
           if (city.length >= 2) {
            const requestUrl = baseUrl + `input=${city}&types=(cities)&components=country:CA&key=${process.env.REACT_APP_MAP_KEY}`
            const response = await fetch(requestUrl);
            const data = await response.json();
            return data
           } 
        } catch (error: any) {
            appContext.showNotification(NotificationTypes.danger, error)
        }
    }

    return {
        getLocationGeocoding, setCount, count, cityAutosuggestion
    }
}

export default useGoogleApi