import { VocabularyEnum } from "common/enums/VocabularyEnum";
import { incidentReportsFilter } from "common/models/XGuardReportsFilters";
import DataTable from "components/tables/DataTable";
import { incidentReportsThead } from "content/xguard-reports/tableContent";
import { useTableList } from "customHooks/useTableList";
import { useVocabulary } from "customHooks/vocabulary/useVocabulary";
import useXGuardReportsQuery from "customHooks/xguard-reports/useXGuardReportsQuery";
import { datePickerFormat, momentDateFormat, setHoursAndMinutes } from "utils/DateFormatting";
import { matchOptionWithName } from "utils/MatchOptionWithName";
import Layout from "../../../components/layout/Layout";
import SectionTitle from "../../../components/titles/SectionTitle";
import DatePicker from "react-datepicker";
import { SVGCalendar, SVGDownload } from "assets/icons/SvgIcons";
import { useDatapickerSvg } from "customHooks/useDatapickerSvg";
import AutocompleteField from "components/autosuggestField/AutocompleteField";
import useReportActions from "customHooks/reports/useReportActions";
import { useContext, useEffect, useMemo, useState } from "react";
import AppContext from "context/AppContext";
import { useDowloandFile } from "customHooks/useDownloadFile";
import { Reports } from "common/enums/Reports";
import ReportsDetailsModal from "components/modal/reports/ReportsDetailsModal";
import { IIncidentReport } from "common/interfaces/reports/xguard-reports/IIncident";
import { useMultipleCheckbox } from "customHooks/useMultipleCheckbox";
import MulTipleCheckbox from "components/miltiple-checkbox/miltiple-checkbox";
import { NotificationTypes } from "common/interfaces/INotification";
import { DataService } from "common/services/DataService";
import { AuthService } from "common/auth/AuthService";
import { Roles } from "common/enums/Roles";
import ReportOptCheckBox from "components/reports/ReportOptCheckBox";

const IncidentReports = () => {
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false)
  const [reportDetails, setReportDetails] = useState<IIncidentReport | undefined>(undefined)

  const { showLoader, showNotification, currentUser } = useContext(AppContext)
  const { fetchData: fetchReports, tableData: incidentReports,
    isLoading, currentPage, perPage, allRecords, onPagination, onSortCall,
    onPerPage } = useTableList<IIncidentReport>("reports/incident", 24, false)
  const { vocabulary: incidentTypes } = useVocabulary(VocabularyEnum.incidentTypesOnGuardMobileApp, true)
  const { vocabulary: shiftPeriods } = useVocabulary(VocabularyEnum.shift, true)
  const {generateQueryParams, onQueryParamChanged,
    queryParams} = useXGuardReportsQuery({
      baseUrl: "reports/incident",
      defaultParams: incidentReportsFilter
    })
  const {showDatapicker, changeDatapicker} = useDatapickerSvg()
  const { onChangeMultiSelectString } = useReportActions(queryParams);
  const { dowloandFile } = useDowloandFile()
  const {onChangeCheckbox, onRemoveCheckbox, allCheckbox} = useMultipleCheckbox()
  const [unresolvedOnly, setUnresolvedOnly] = useState(true);

  const handleCheckBox = (type: keyof IIncidentReport, index: number | string) => {
    onChangeMultiSelectString(type, index,
      (result, type) => {
        onQueryParamChanged(result, type as any)
      }
    )
  }

  const updateReportStatus = useMemo(() =>
    async (reportId: number, isResolved: boolean) => {
      const svc = new DataService({ url: `reports/resolve-incident-report` })
      showLoader(true)
      try {
        const body = { isResolved }
        await svc.update(body, reportId)
        await fetchReports(currentPage, 24, generateQueryParams())

        reportDetails && setReportDetails(Object.assign({
          ...reportDetails,
          isResolved: isResolved
        }))
        
        showLoader(false)
        showNotification(NotificationTypes.success,
          `Incident Report has been ${isResolved ? 'resolved' : 'unresolved'}`)
      } catch (error: any) {
        showNotification(NotificationTypes.danger, error.message)
        showLoader(false)
      }
    }
  , [currentPage, fetchReports, reportDetails, showLoader, showNotification, generateQueryParams])

  const onDownload = async () => {
    showLoader(true)
    await dowloandFile('report/incident',
    Reports.excel, Reports.download, '', '',
    `&${generateQueryParams().split("?")[1]}`, true)
    showLoader(false)  
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    fetchReports(1, 24, generateQueryParams())
    event.preventDefault();
  }

  const onTableRowClick = (rowItem: IIncidentReport) => {
    rowItem && setReportDetails(rowItem)
    setShowDetailsModal(true)
  }

  const onModalCancel = () => {
    setReportDetails(undefined)
    setShowDetailsModal(false)
  }

  const isASPUser = AuthService.checkIfUserHasPermission([Roles.ASP], currentUser.role)

  useEffect(() => {
    fetchReports(1, 24, generateQueryParams())
  }, [])

  useEffect(() => {
    onQueryParamChanged(unresolvedOnly, 'unresolvedOnly')
  }, [unresolvedOnly])

  return (
    <Layout
      breadcrumbs={{
        links: [{
            title: 'Reports',
            link: 'reports'
        }],
        currentPageTitle: 'Incident Reports'
      }}
      className={'reports-screen'}
    >
      <div className="row mb-3">
        <div className="col-12">
          <SectionTitle title={'Incident Reports From Guards'}/>
        </div>
      </div>

      <form
        onSubmit={handleSubmit}
      >
        <div className="row">
          {/* Start CheckBoxes */}
          <div className="col-md-4 form-group mb-md-4">
            <label htmlFor="firstStreet">Type of Incident:</label>
            <div>
            {
              incidentTypes ? incidentTypes.map(option => (
                !option.isDisabled &&
                <ReportOptCheckBox
                  optionType={queryParams.type}
                  option={option}
                  handleCheckBox={handleCheckBox}
                  optionKey={"type"}
                  key={option.id}
                />
              )) : null
            }
          </div>
            
        </div>

          {/* End CheckBoxes */}


          {/* Start Date Filters */}
          <div
            className="col-md-4 form-group mb-md-4">
              <div className="row d-flex flex-column">

                <div className="col-12 mb-3">
                  <label>From: </label>
                  <div
                      className="react-datepicker-custom-wrapper react-datepicker-custom-wrapper--full-width d-inline-block w-100">
                      <DatePicker
                          selected={datePickerFormat(queryParams.startDate)}
                          onChange={date => {
                            onQueryParamChanged(date ? setHoursAndMinutes(date as Date) : '', 'startDate')
                            changeDatapicker(1);
                          }}
                          dateFormat="dd/MM/yyyy"
                          onInputClick={() => changeDatapicker(1)}
                          open={showDatapicker[1]}
                          onClickOutside={() => changeDatapicker(1)}
                          placeholderText="dd/mm/yyyy"
                      />
                      <div onClick={() => changeDatapicker(1)}><SVGCalendar/></div>
                  </div>
                </div>
                <div className="col-12">
                  <label>To: </label>
                  <div
                    className="react-datepicker-custom-wrapper react-datepicker-custom-wrapper--full-width d-inline-block w-100">
                    <DatePicker
                      selected={datePickerFormat(queryParams.endDate)}
                      onChange={date => {
                        onQueryParamChanged(date ? setHoursAndMinutes(date as Date) : '', 'endDate')
                        changeDatapicker(2);
                      }}
                      dateFormat="dd/MM/yyyy"
                      onInputClick={() => changeDatapicker(2)}
                      open={showDatapicker[2]}
                      onClickOutside={() => changeDatapicker(2)}
                      placeholderText="dd/mm/yyyy"
                    />
                    <div onClick={() => changeDatapicker(2)}><SVGCalendar/></div>
                  </div>
                </div>

              </div>
          </div>
          {/* End Date Filters */}

          <div className="col-md-4 mb-md-4">

            <div className="row">
              {/* Start Guard Name Search */}
              <div className="col-12 form-group shfit-autosegest">
                <label htmlFor="guardNameOrEmployeeNumber">Guard Name or Employee ID:</label>
                <AutocompleteField
                  showButton={false}
                  defaultValue={!queryParams.guardNameOrEmployeeNumber ? true : false}
                  includes={'Guard'}
                  isAsync={true}
                  placeholder='Guard Name or Employee ID'
                  onSearch={async (value) => {
                      onQueryParamChanged(value, 'guardNameOrEmployeeNumber')
                  }}
                  onChange={(value) => onQueryParamChanged(value, 'guardNameOrEmployeeNumber')}
                  onClear={() => onQueryParamChanged('', 'guardNameOrEmployeeNumber')}/>
              </div>
              {/* End Guard Name Search */}

              {/* Start KeyWord Search */}
              <div className="col-12 form-group shfit-autosegest mb-md-3">
                <label htmlFor="keyword">Keyword(s):</label>
                  <AutocompleteField
                    showButton={false}
                    defaultValue={!queryParams.keyWord ? true : false}
                    placeholder='Enter Keyword(s)'
                    onSearch={async (value) => {
                      onQueryParamChanged(value, 'keyWord')
                    }}
                    onChange={(value) => onQueryParamChanged(value, 'keyWord')}
                    onClear={() => onQueryParamChanged('', 'keyWord')}/>
                </div>
              {/* End KeyWord Search */}
            </div>

          </div>

        </div>

        <div className="row mb-5">
          <div className="col-md-9 form-group text-right">
            {/* Start Checkbox */}
            <div
              className="custom-control custom-checkbox d-flex flex-wrap align-items-center mt-2">
              <div className="mr-5">
                <input
                    type="checkbox"
                    checked={unresolvedOnly}
                    onChange={() => setUnresolvedOnly(!unresolvedOnly)}
                    className="custom-control-input"
                    name="type"
                    id={`type-unresolvedOnly`}
                    />
                <label
                  className="custom-control-label mb-0"
                  htmlFor={`type-unresolvedOnly`}>
                    Show Unresolved
                </label>
              </div>
            </div>
            {/* End Checkbox */}

            {/* Start Checkbox */}
            <div
              className="custom-control custom-checkbox d-flex flex-wrap align-items-center mt-2">
              <div className="mr-5">
                <input
                    type="checkbox"
                    checked={queryParams.resolvedOnly === "true" ? true : false}
                    onChange={() => onQueryParamChanged(queryParams.resolvedOnly === "true" ? 'false' : 'true',
                    'resolvedOnly')}
                    className="custom-control-input"
                    name="type"
                    id={`type-resolvedOnly`}
                    />
                <label
                  className="custom-control-label mb-0"
                  htmlFor={`type-resolvedOnly`}>
                    Show Resolved
                </label>
              </div>
            </div>
            {/* End Checkbox */}
          </div>
          <div className="col-md-3 text-right">
            <button
              type="submit"
              className="btn btn-outline-aqua-blue mr-2"
              >
              Update Report
            </button>
            <button
              type="button"
              className="btn btn-aqua-blue"
              onClick={onDownload}
            ><SVGDownload /></button>
          </div>
        </div>

      </form>

      {
        useMemo(() => (
          <div className="row">
            <div className="col-12">
              <DataTable
                thead={incidentReportsThead.thead}
                tbody={incidentReports ? incidentReports.map(report => ({
                  id: report.id,
                  date: momentDateFormat(report.date) || "",
                  guardName: report.guardName,
                  employeeId: report.employeeId,
                  jobId: report.jobId || "-",
                  type: matchOptionWithName(report.type, incidentTypes),
                  directlyImpacted: report.directlyImpacted ? 'yes' : 'no',
                  message: report.message,
                  attachmentsCount: report.attachmentsCount,
                  attachments: report.attachments,
                  isResolved: report.isResolved
                })) : []}
                isLoading={isLoading}
                ignoreCols={[0, 9, 10]}
                currentPage={currentPage}
                itemsCount={allRecords}
                itemsPerPage={+perPage}
                onPager={page => onPagination(page)}
                onPerPage={value => onPerPage(value)}
                onColClick={(_key, _id, _item, _idIndx, trIndex) => trIndex !== undefined && onTableRowClick(incidentReports[trIndex])}
                onSort={(orderBy, desc) => onSortCall(orderBy, desc, queryParams.keyWord)}
                defaultSortedColumn={0}
                tableName={"Incident Reports"}
                tableClass={'table-info--notFixed table-incident-reports table-info-hover table-unassigned-job-shifts'}
                pagination
                sortOnBackend
                showTableLengthData
              >
                {
                  (_id, rowItem: IIncidentReport) => (
                    <>
                      <td className="align-middle">
                        { isASPUser ? (rowItem.isResolved ? 'Yes' : 'No') :
                        <div className="form-group mb-0">
                            <div className="custom-control custom-checkbox d-flex align-items-center">
                              <MulTipleCheckbox
                                id={_id.toString()}
                                onChange={id => {
                                  onChangeCheckbox(id)
                                  updateReportStatus(+id, true)
                                }}
                                onRemove={id => {
                                  onRemoveCheckbox(id)
                                  updateReportStatus(+id, false)
                                }}
                                allCheckboxes={allCheckbox}
                                defaultValue={rowItem.isResolved}
                              />
                            </div>
                        </div>
                        }
                      </td>
                    </>
                  )
                }
              </DataTable>
            </div>
          </div>
        ), [incidentReports, isLoading, currentPage, allRecords, perPage, incidentTypes, isASPUser,
          onPagination, onPerPage, onSortCall, queryParams.keyWord, allCheckbox, updateReportStatus,
          onChangeCheckbox, onRemoveCheckbox])
      }

      {
        showDetailsModal && reportDetails && 
        <ReportsDetailsModal
          report={reportDetails}
          title={'Incident Report Details'}
          onCancel={onModalCancel}
          vocabulary={[shiftPeriods, incidentTypes]}
          typeLabel={'Incident Type'}
          handleIsResolvedAction={updateReportStatus}
          isASPUser
        />
      }
    </Layout>
  )
}

export default IncidentReports
