import React, {FC, useEffect, useMemo, useState} from "react";

type Props = {
    id: string;
    onChange: (id: string, value?: boolean) => void;
    onRemove: (id: string, value?: boolean) => void;
    allCheckboxes?: Array<string | number>
    defaultValue?: boolean
    checkboxFlag?: boolean
    className?: string
    isValid?: boolean;
}

const MulTipleCheckbox: FC<Props> = ({ id, onChange, onRemove, allCheckboxes, defaultValue , checkboxFlag, className, isValid = true}) => {

    const [isChecked, setIsChecked] = useState(defaultValue ? defaultValue : false);

    useEffect(() => {
        allCheckboxes  && setIsChecked(allCheckboxes.some(item => +item === +id))
    }, [checkboxFlag])

    useEffect(() => {
        (allCheckboxes && !defaultValue) && setIsChecked(allCheckboxes.some(item => +item === +id))
    }, [])

    useEffect(() => {
      if(defaultValue) {
        setIsChecked(defaultValue);
      }  
    }, [defaultValue])

    useEffect(() => {
        (allCheckboxes?.length === 0 || allCheckboxes === undefined ) && !defaultValue
        && setIsChecked(false)
    }, [allCheckboxes])

    const handleChange = useMemo(() => 
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.checked;
            (isValid || !e.target.checked) && setIsChecked(e.target.checked);
            value ? onChange(id, !!value) : onRemove(id, !!value);
        }
    , [id, onChange, onRemove])

    return (
        <>
            {
                useMemo(() => (
                   <>
                        <input
                            type="checkbox"
                            className={ `custom-control-input ${className}` }
                            id={id}
                            name={id}
                            checked={isChecked}
                            onChange={handleChange}
                        />
                        <label className="custom-control-label mb-0" htmlFor={id}></label>
                   </>
                ), [handleChange, id, isChecked, className])
            }
        </>

    );
}
export default MulTipleCheckbox;
