import React, { FC, useEffect, useRef, useState } from 'react'

type Props = {
    image: string
    onChange: (value: string) => void
}

const ImageUpload: FC<Props> = ({ image, onChange }) => {

    const [file, setFile] = useState<File>()
    const [base64, setBase64] = useState(image)
    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        setBase64(image)
    }, [image])

    const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const trg = event.currentTarget as HTMLInputElement;
        trg.files && setFile(trg.files[0])
    }

    const setFocus = (): void => {
        const currentEl = inputRef.current;
        if (currentEl) {
            currentEl.click();
        }
    };

    useEffect(() => {
        if (file) {
            let img = new Image();
            img.src = URL.createObjectURL(file);
            img.crossOrigin = 'Anonymous';

            let canvas = document.createElement('canvas'),
            ctx = canvas.getContext('2d');
            img.onload = () => {
                canvas.height = 150;
                canvas.width = 230;
                const scale = Math.max(canvas.width / img.width, canvas.height / img.height);
                const x = (canvas.width / 2) - (img.width / 2) * scale;
                const y = (canvas.height / 2) - (img.height / 2) * scale;
                ctx &&  ctx.drawImage(img, x, y, img.width * scale, img.height * scale);;
                const toBase64 = canvas.toDataURL('image/png')
                setBase64(toBase64)
                onChange(toBase64)
            }
        }
    }, [file])

    return (
        <div className="upload-image d-flex flex-column align-items-center text-center">
            {
                base64 ? <img src={base64} alt="Guard" onClick={() => setFocus()} /> :
                <div className="upload-image__blank" onClick={() => setFocus()}></div>
            }
            <label className="upload-image__label mt-2" htmlFor="uploadImg">
                Add Picture
            </label>
            <input
                type="file"
                name="uploadImg"
                id="uploadImg"
                accept="image/x-png,image/jpeg"
                onChange={e => handleUpload(e)}
                ref={inputRef}
            />
        </div>
    )
}

export default ImageUpload
