import { useState } from 'react'

const useCustomForm = () => {
  const [formStatus, setFormStatus] = useState({ isSubmitting: false })

  const handleFormStatus = (action: 'submit' | 'success' | 'error') => {
    setFormStatus({ isSubmitting: action === 'submit' ? true : false })
  }
  
  return {
    formStatus, handleFormStatus
  }
}

export default useCustomForm