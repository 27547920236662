import { IClientDropdown } from 'common/interfaces/dropdowns/IClientDropDown';
import moment from 'moment';
import { useRef, useState } from 'react';
import { ISchoolYearDropdown } from '../common/interfaces/dropdowns/ISchoolYearDropdown';
import ResourceDataService from '../common/services/ResourceDataService';
import { momentDateFormat } from '../utils/DateFormatting';
interface IDropdownOpts {
    id: number | string;
    name: string;
}
export const useSchoolYear = () => {
    const [schoolYears, setSchoolYears] = useState<IDropdownOpts[]>([]);
    const defaultYear =  useRef<string | number>('')

    const getSchoolYears = async (callback?: (value: string | number, filterName: string) => void,
        getYearId?: (yearId: string | number) => void, fromCurrentOnly?: boolean
    ) => {
        const schoolSvc = new ResourceDataService<ISchoolYearDropdown[]>({ url: 'schoolyear' });
        try {
            const resSchoolYears = await schoolSvc.getAll();
            if (fromCurrentOnly) { // temp solution (later need to use code from multi tenancy)
                setSchoolYears(
                    resSchoolYears.data.filter(y => moment(y.endDate).isAfter(moment(new Date()))).map((year) => ({
                        id: year.id,
                        name: `${momentDateFormat(year.startDate)} - ${momentDateFormat(year.endDate)}`,
                    }))
                );
            } else {
                setSchoolYears(
                    resSchoolYears.data.map((year) => ({
                        id: year.id,
                        name: `${momentDateFormat(year.startDate)} - ${momentDateFormat(year.endDate)}`,
                    }))
                );
            }
            
            const currentYearId = getCurrentYears(resSchoolYears.data)?.id || "";
            defaultYear.current = currentYearId;
            callback && callback(currentYearId, 'schoolYearId')
            getYearId && getYearId(currentYearId);
        } catch (error: any) {
            console.log(error);
        }
    };

    const getCurrentYears = (years: ISchoolYearDropdown[]) => {
        const currentTime = new Date().getTime();
        let nearestFutureYear: ISchoolYearDropdown | undefined;
      
        for (const year of years) {
          const startDate = new Date(year.startDate).getTime();
          const endDate = new Date(year.endDate).getTime();
      
          if (startDate <= currentTime && endDate >= currentTime) {
                return { id: year.id, name: `${momentDateFormat(year.startDate)} - ${momentDateFormat(year.endDate)}` } as IDropdownOpts;
          }
      
          if (startDate > currentTime && (!nearestFutureYear || startDate < new Date(nearestFutureYear.startDate).getTime())) {
            nearestFutureYear = year;
          }
        }
      
        if (nearestFutureYear) {
          return { id: nearestFutureYear.id, name: `${momentDateFormat(nearestFutureYear.startDate)} - ${momentDateFormat(nearestFutureYear.endDate)}` } as IDropdownOpts;
        }
        const lastYear = years[years.length - 1];
        if (lastYear) {
            return {id: lastYear.id, name: `${momentDateFormat(lastYear.startDate)} - ${momentDateFormat(lastYear.endDate)}`} as IDropdownOpts
        }
      
        return null;
    };
    
    const getCurrentYear = (years: ISchoolYearDropdown[]) => {
        const currentYear = years.find(year => moment().isBetween(
            moment(year.startDate),
            moment(year.endDate),
            null, '[]'
            ));
            return currentYear;
    }

    const getSchoolYearByClientIdAndYear = (clients: IClientDropdown[], clientId: string, schoolYear: string) => {
        const client = clients
                    .find(client => client.id === +clientId)?.schoolYears
                    .find(year => year.id === +schoolYear);
        if (client) {
            return `${momentDateFormat(client.startDate)} - ${momentDateFormat(client.endDate)}`
        }
    }

    return { schoolYears, getSchoolYears, defaultYear, getCurrentYear, getSchoolYearByClientIdAndYear };
};
