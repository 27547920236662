import { FC, useEffect, useState } from 'react'
// import TimePicker from 'react-time-picker';
import { SVGPencil } from '../../../assets/icons/SvgIcons';
import { IAvailableGuard } from '../../../common/interfaces/guards/IGuard';
import { IJobAssignedShifts } from '../../../common/interfaces/jobs/IJob';
import { IJobModalProps } from '../../../common/interfaces/jobs/IJobModal';
import { momentDateFormat, momentHoursFormat, timeWithoutSecondsFormat, UTCtimeToLocal } from '../../../utils/DateFormatting';
import { matchOptionWithName } from '../../../utils/MatchOptionWithName';
import AvailableGuards from '../../guards/AvailableGuards';
import BaseModal from '../BaseModal';
import CustomTimePicker from "../../custom-time-picker/CustomTimePicker";
import { useVocabulary } from 'customHooks/vocabulary/useVocabulary';
import { VocabularyEnum } from 'common/enums/VocabularyEnum';
import { useModals } from 'customHooks/modals/useModals';
import { EditJobShiftsModals } from 'common/models/Modals';
import { IBaseShift } from 'common/interfaces/jobs/IShifts';
import { IJobShiftsModals } from 'common/interfaces/jobs/IJobModals';

interface IProps extends IJobModalProps<IBaseShift> {
    modalTitle?: string,
    modals?: IJobShiftsModals,
    setJobShiftsModals?: (modal: IJobShiftsModals) => void,
}

const EditShiftModal: FC<IProps> = ({ onClose, onSubmit, shift, jobName, modalTitle, modals, setJobShiftsModals }) => {
    const [shiftForEditing, setShiftForEditing] = useState<IJobAssignedShifts>(shift as IJobAssignedShifts)
    const { vocabulary } = useVocabulary(VocabularyEnum.shift, true)
    const { modals: { showEditConfirmation, showEditModal, showGuardsModal }, setModals } = useModals({
        defaultState: new EditJobShiftsModals({ showEditModal: true })
    })
    // useEffect(() => {
    //     setShiftForEditing(shift as IJobAssignedShifts)
    // }, [shift])

    const onGuardAssignedClick = (newGuard: IAvailableGuard) => {
        setShiftForEditing({
            ...shiftForEditing,
            guardId: newGuard.id,
            assignedGuard: `${newGuard.firstName} ${newGuard.middleName} ${newGuard.lastName}`,
            firstName: newGuard.firstName,
            middleName: newGuard.middleName,
            lastName: newGuard.lastName
        })
        setModals(new EditJobShiftsModals({ showEditModal: true }))
    }

    const onSaveChangesClick = () => {
        setModals(new EditJobShiftsModals({ showEditConfirmation: true }))
    }

    const guardName = (shiftInFocus: IJobAssignedShifts) => { 
        let guardName = ''
        if (shiftInFocus.assignedGuard) {
            guardName = shiftInFocus.assignedGuard
        } else if (shiftInFocus.firstName || shiftInFocus.lastName) {
            guardName = `${shiftInFocus.firstName} ${shiftInFocus.lastName}`
        } else if (shiftInFocus.guardName?.trim()) {
            guardName = shiftInFocus.guardName
        } else {
            guardName = '--'
        }
        return guardName;
    };
    

    return (
        <>
            <BaseModal
                show={showEditModal}
                onCancel={() => onClose()}
                onSubmit={() => onSaveChangesClick()}
                submitBtnText={"Save"}
            >
                <h4 className="mb-4">
                    { modalTitle ? modalTitle : 'Edit Assigned Job Shift'} <br />
                    <h5 className="font-weight-bold">{jobName}</h5>
                </h4>

                <div className="row mb-3 align-items-center">
                    <div className="col-4 details-information__title">
                        <h6 className="mb-0">Assigned Guard:</h6>
                    </div>
                    <div className="col-8 details-information__value d-flex flex-row align-items-center">
                        <p className="mb-0">{guardName(shiftForEditing)}</p>
                        <button type="button" className="btn btn-aqua-blue ml-2"
                            onClick={() => setModals(new EditJobShiftsModals({ showGuardsModal: true }))}
                        >
                            <SVGPencil />
                        </button>
                    </div>
                </div>

                <div className="row mb-3 align-items-center">
                    <div className="col-4 details-information__title">
                        <h6 className="mb-0">Date:</h6>
                    </div>
                    <div className="col-8 details-information__value d-flex flex-row align-items-center">
                        <p className="mb-0">{momentDateFormat(shiftForEditing.startDate)}</p>
                    </div>
                </div>

                <div className="row mb-3 align-items-center">
                    <div className="col-4 details-information__title">
                        <h6 className="mb-0">Shift:</h6>
                    </div>
                    <div className="col-8 details-information__value d-flex flex-row align-items-center">
                        <p className="mb-0">{matchOptionWithName(shiftForEditing.shiftPeriod, vocabulary)}</p>
                    </div>
                </div>

                <div className="row mb-3 align-items-center">
                    <div className="col-4 details-information__title">
                        <h6 className="mb-0">Start Time:</h6>
                    </div>
                    <div className="col-8 details-information__value d-flex flex-row align-items-center">
                        <p className="mb-0">
                            <CustomTimePicker
                                onChange={time => setShiftForEditing({ ...shiftForEditing, timeFrom: timeWithoutSecondsFormat(time) })}
                                value={shiftForEditing.timeFrom ? UTCtimeToLocal(shiftForEditing.timeFrom) : UTCtimeToLocal()}
                                index={1} />
                        </p>
                    </div>
                </div>

                <div className="row mb-3 align-items-center">
                    <div className="col-4 details-information__title">
                        <h6 className="mb-0">End Time:</h6>
                    </div>
                    <div className="col-8 details-information__value d-flex flex-row align-items-center">
                        <p className="mb-0">
                            <CustomTimePicker
                                onChange={time => setShiftForEditing({ ...shiftForEditing, timeTo: timeWithoutSecondsFormat(time) })}
                                value={shiftForEditing.timeTo ? UTCtimeToLocal(shiftForEditing.timeTo) : UTCtimeToLocal()}
                                index={2} />
                        </p>
                    </div>
                </div>

            </BaseModal>

            {
                showGuardsModal && <BaseModal
                    show={showGuardsModal}
                    onCancel={() => setModals(new EditJobShiftsModals({ showEditModal: true }))}
                    className="available-guards-modal"
                    cancelBtnText={'Cancel'}
                >
                    <AvailableGuards
                        onSubmit={guard => onGuardAssignedClick(guard)}
                        title={`Assign Guard for Selected Job Shift for <h5 class="font-weight-bold">${jobName}</h5>`}
                        selectedItem={shiftForEditing}
                    />
                </BaseModal>
            }
            {/* Confirmation Modal */}
            {showEditConfirmation &&
                <BaseModal
                    show={showEditConfirmation}
                    onCancel={() => setModals(new EditJobShiftsModals({ showEditModal: true }))}
                    onSubmit={() => [
                      onSubmit && onSubmit(shiftForEditing),
                      setModals({ showEditConfirmation: false, showEditModal: false, showGuardsModal: false }),
                      (modals && setJobShiftsModals) && setJobShiftsModals({...modals, editSuccess: true}),
                    ]}
                    submitBtnText={"Confirm"}
                >
                    <h4>Confirmation Required</h4>
                    <p className="mb-3">You have requested the following job shift update for <span className="font-weight-bold"> {jobName}</span>:</p>
                    <div className="row align-items-center">
                        <div className="col-sm-4 details-information__title mb-3 "></div>
                        <div className="col-sm-4 details-information__title mb-3 ">
                            <h6 className="mb-0">From:</h6>
                        </div>
                        <div className="col-sm-4 details-information__title mb-3 ">
                            <h6 className="mb-0">To:</h6>
                        </div>

                        {/* Gyard */}
                        <div className="col-sm-4 details-information__title mb-3 ">
                            <h6 className="mb-0">Guard:</h6>
                        </div>
                        <div className="col-sm-4 details-information__value d-flex flex-row align-items-center mb-3 ">
                            <p className="mb-0">
                                {guardName(shift as IJobAssignedShifts)}
                            </p>
                        </div>
                        <div className="col-sm-4 details-information__value d-flex flex-row align-items-center mb-3 ">
                            <p className="mb-0">
                                {guardName(shiftForEditing)}
                            </p>
                        </div>

                        {/* Date */}
                        <div className="col-sm-4 details-information__title mb-3 ">
                            <h6 className="mb-0">Date:</h6>
                        </div>
                        <div className="col-sm-4 details-information__value d-flex flex-row align-items-center mb-3 ">
                            <p className="mb-0">
                                {momentDateFormat(shift?.startDate || '')}
                            </p>
                        </div>
                        <div className="col-sm-4 details-information__value d-flex flex-row align-items-center mb-3 ">
                            <p className="mb-0">
                                {momentDateFormat(shiftForEditing.startDate)}
                            </p>
                        </div>

                        {/* Start Time */}
                        <div className="col-sm-4 details-information__title mb-3 ">
                            <h6 className="mb-0">Start Time:</h6>
                        </div>
                        <div className="col-sm-4 details-information__value d-flex flex-row align-items-center mb-3 ">
                            <p className="mb-0">
                                {momentHoursFormat(shift?.timeFrom || '')}
                            </p>
                        </div>
                        <div className="col-sm-4 details-information__value d-flex flex-row align-items-center mb-3 ">
                            <p className="mb-0">
                                {momentHoursFormat(shiftForEditing.timeFrom)}
                            </p>
                        </div>

                        {/* End Time */}
                        <div className="col-sm-4 details-information__title mb-3 ">
                            <h6 className="mb-0">End Time:</h6>
                        </div>
                        <div className="col-sm-4 details-information__value d-flex flex-row align-items-center mb-3 ">
                            <p className="mb-0">
                                {momentHoursFormat(shift?.timeTo || '')}
                            </p>
                        </div>
                        <div className="col-sm-4 details-information__value d-flex flex-row align-items-center mb-3 ">
                            <p className="mb-0">
                                {momentHoursFormat(shiftForEditing.timeTo)}
                            </p>
                        </div>

                        {/* Repeat */}
                        <div className="col-sm-4 details-information__title mb-3 ">
                            <h6 className="mb-0">Shift Period:</h6>
                        </div>
                        <div className="col-sm-4 details-information__value d-flex flex-row align-items-center mb-3 ">
                            <p className="mb-0">
                                {matchOptionWithName(shift?.shiftPeriod || '', vocabulary)}
                            </p>
                        </div>
                        <div className="col-sm-4 details-information__value d-flex flex-row align-items-center mb-3 ">
                            <p className="mb-0">
                                {matchOptionWithName(shiftForEditing.shiftPeriod, vocabulary)}
                            </p>
                        </div>

                    </div>
                </BaseModal>
            }
        </>
    )
}

export default EditShiftModal
