import React, { useContext } from 'react'
import {Link} from "react-router-dom";
import { SVGApprove } from '../../assets/icons/SvgIcons'
import { manageDeactivatedGuardsContent } from '../../content/administration/AdministrationContent'

import { IGuardRequested } from '../../common/interfaces/guards/IGuard'

import Layout from '../../components/layout/Layout'
import DataTable from '../../components/tables/DataTable'
import SectionTitle from '../../components/titles/SectionTitle'
import BaseModal from '../../components/modal/BaseModal'

import { useTableList } from '../../customHooks/useTableList'
import { momentDateFormat } from '../../utils/DateFormatting'
import useActivateDeactivate from '../../customHooks/useActivateDeactivate'
import { ActivationDeactivation } from '../../common/enums/Actions';
import { VocabularyEnum } from '../../common/enums/VocabularyEnum';
import { useVocabulary } from '../../customHooks/vocabulary/useVocabulary';
import DeactivateModal from '../../components/modal/users/DeactivateModal';
import { matchOptionWithName } from '../../utils/MatchOptionWithName';
import { IVocabulary } from '../../common/interfaces/vocabulary/IVocabulary';
import { AuthService } from '../../common/auth/AuthService';
import { Roles } from '../../common/enums/Roles';
import AppContext from '../../context/AppContext';
import {checkDate} from "../../utils/CheckDate";

const DeactivatedGuards = () => {
    const { tableData, isLoading, handleColClick, setTableData } = useTableList<IGuardRequested>('guard/inactive', 0)
    const { onRequestHandler, onRequestSubmitHandler, requestModals, modalText, onCloseRequestModals, itemInFocus } = useActivateDeactivate('guard');
    const { vocabulary } = useVocabulary(VocabularyEnum.guardActivation, true)
    const { vocabulary: deactivationReasons } = useVocabulary(VocabularyEnum.guardDeactivation, true)

    const onSuccessActivation = (guardId?: number) => guardId && setTableData(tableData.filter(row => row.id !== guardId))

    const { currentUser } = useContext(AppContext)
    const itemsIsVisible = AuthService.checkIfUserHasPermission([Roles.Admin], currentUser.role)

    return (
        <Layout
            breadcrumbs={{
                links: [{
                    title: `Guards`,
                    link: `/guards`
                },
                {
                    title: `Deactivate Guards`,
                    link: `guards/deactivate`
                }
                ],
                currentPageTitle: 'Deactivated Guards'
            }}
        >

        <div className="row">
            <div className="col-12 mb-4 d-flex flex-row flex-wrap align-items-center justify-content-between">
                <SectionTitle title="Deactivated Guards"/>
                <Link to="/guards/deactivate" className="btn btn-outline-aqua-blue ml-0 ml-md-3 mt-2 mt-md-0">View requests</Link>
            </div>
        </div>

        <div className="row">

            <div className="col-12">

            {
                React.useMemo(() => (
                    <DataTable
                        thead={manageDeactivatedGuardsContent.thead}
                        tbody={true ?
                            tableData.map(guard => ({
                                id: guard.id,
                                guardNameLink: `<a href="#/guards/${guard.id}/details">${guard.guardName}</a>`,
                                statusChangeDate: momentDateFormat(guard.statusChangeDate) || '-',
                                statusChangeReason: guard.statusChangeReason ? matchOptionWithName(guard.statusChangeReason, deactivationReasons) : '-',
                                statusChangeBy: guard.statusChangeBy || '-',
                                statusRequestDate: checkDate(momentDateFormat(guard.statusRequestDate)) || '-',
                                statusRequestedBy: guard.statusRequestedBy || '-',
                            }))
                        : []}
                        ignoreCols={[0]}
                        isLoading={isLoading}
                        tableClass={'table-info--notFixed'}
                        // onColClick={(key, trIndx) => handleColClick(key, trIndx, 'guardNameLink', 'guards')}
                    >
                        {
                            (_id, rowItem: IGuardRequested) => (
                                <>
                                    <td className="aling-middle">
                                        <div>
                                            <button
                                                className={`btn ${itemsIsVisible ? 'btn-aqua-blue' : 'btn-disabled'}`}
                                                onClick={() => {
                                                    itemsIsVisible && onRequestHandler(
                                                    {
                                                        title: `Are you sure you want to reactivate <span class="font-weight-bold">${rowItem.guardNameLink}</span>?`,
                                                        itemInFocus: rowItem,
                                                        type: ActivationDeactivation.activate
                                                    })
                                                }}
                                            >
                                                <SVGApprove />
                                            </button>
                                        </div>
                                    </td>
                                </>
                            )
                        }
                    </DataTable>
                ), [tableData, isLoading])
            }
            </div>
        </div>

            { (requestModals.showRequest) &&
                <DeactivateModal
                    onCancel={() => onCloseRequestModals()}
                    onSubmit={(reason: string | IVocabulary) => onRequestSubmitHandler(ActivationDeactivation.activate,
                        { itemData: null, successCallback: id => onSuccessActivation(id) },
                        `<span class="font-weight-bold">${itemInFocus.guardNameLink}</span> has been Activated.`,
                        reason
                    )}
                    title={modalText.title}
                    vocabulary={vocabulary}
                />
            }

            {
                requestModals.showSuccess && <BaseModal
                    show={requestModals.showSuccess}
                    onCancel={() => onCloseRequestModals()}
                    cancelBtnText={"Close"}
                >
                    <h4 className="text-left mb-4" dangerouslySetInnerHTML={{ __html: modalText.title }} />
                </BaseModal>
            }

        </Layout>
    )
}

export default DeactivatedGuards
