import { NotificationTypes } from "common/interfaces/INotification";
import { IVocabulary } from "common/interfaces/vocabulary/IVocabulary";
import { DataService } from "common/services/DataService";
import AppContext from "context/AppContext";
import { useContext } from "react";
import { checkIfIsOtherReason } from "utils/CheckOtherReason";

type TCheckActionsProps = {
    checkType: string, checkTime?: string, checkReason?: string, shiftId: string | number, reason?: string | IVocabulary,
}

export const useCheckGuard = () => {
    const checkSvc = new DataService({ url: 'shift' })
    const { showLoader, showNotification } = useContext(AppContext)

    const handleCheckActions = async (props: TCheckActionsProps, callBack: () => Promise<void>) => {
        const { checkTime, checkReason, shiftId, checkType, reason } = props; 
        showLoader(true)
        try {
            switch (checkType) {
                case 'checkIn':
                    await checkSvc.create({ time: checkTime || "", reason: checkReason || "" }, shiftId, 'check-in')
                    break;
                case 'checkOut':
                    await checkSvc.create({ time: checkTime || "" }, shiftId, 'check-out')
                    break;
                case 'absent':
                    await checkSvc.create({ reason: checkIfIsOtherReason(reason!) ? reason as string : (reason as IVocabulary).id }, shiftId, 'mark-absent')
                    break;
                default:
                    break;
            }
            callBack && callBack()

        } catch (error: any) {
            showLoader(false)
            showNotification(NotificationTypes.danger, error.message)
        }
    }

    const handleBulkAction = async (body: {[key: string]: any}, subUrl: string, callBack: () => Promise<void>) => {
        showLoader(true)
        try {
            await checkSvc.create(body, undefined, subUrl)
            callBack && callBack()
            showLoader(false);
        } catch (error: any) {
            showLoader(false)
            showNotification(NotificationTypes.danger, error.message)
        }
    }

    return {
        handleCheckActions,
        handleBulkAction
    }
}
