import { UTCTimeNow } from "utils/DateFormatting";
import { IClient } from "../interfaces/clients/IClient";

export class Client implements IClient {
    id = 0;
    organizationName = '';
    firstAddress = '';
    secondAddress? = '';
    phoneNumber = '';
    email = '';
    city = '';
    primaryContactName = '';
    postalCode = '';
    province = '45';
    aspStartDate = UTCTimeNow(true, true) as string;
    clientContacts = []
}