import React, { FC, useContext, useState } from 'react'
import { SVGCancel } from '../../../assets/icons/SvgIcons'
import { IBaseModalProps } from '../../../common/interfaces/IModal'
import { NotificationTypes } from '../../../common/interfaces/INotification'
import AppContext from '../../../context/AppContext'
import BaseModal from '../BaseModal'

interface Props extends IBaseModalProps {
    subTitle?: string
    exampleLink?: string
}

const UploadFileModal: FC<Props> = ({ onCancel, onSubmit, subTitle, exampleLink }) => {
    const [file, setFile] = useState<File>({} as File)
    const {showNotification} = useContext(AppContext)

    const onSaveDataClick = () => {
        if (file.size) {
            const data = new FormData()
            data.append('file', file);
            onSubmit && onSubmit(data)
        }
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target && event.target.files) {
            const uploadedFile = event.target.files[0] as File;
            if (uploadedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                setFile(uploadedFile as File)
            } else {
                showNotification(NotificationTypes.warning, 'Invalid file type')
            }
        }
    }

    return (
        <BaseModal
            show={true}
            onCancel={() => onCancel()}
            onSubmit={() => onSaveDataClick()}
            submitBtnText={'Save'}
            title="Upload Holidays"
            className="upload-file"
        >
            { subTitle && <p className="text-dark font-weight-bold mb-3">{subTitle} <a href={exampleLink ? exampleLink : '!#'} target="_blank" className="text-primary font-weight-bold">Use this template.</a></p> }
            <form>
                <div className="input-group justify-content-between align-items-center">
                    {
                        file.size ?
                        <>
                            <div className={'w-75 d-flex align-items-center'}>
                                { file.name }
                            </div>
                            <div>
                                <button
                                    className={`btn btn-aqua-blue`}
                                    onClick={() => setFile({} as File)}
                                >
                                    <SVGCancel/>
                                </button>
                            </div>
                        </>
                            :
                        <div className="custom-file">
                            <input type="file" onChange={e => onChange(e)} className="custom-file-input form-control" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01" />
                            <label className="custom-file-label" htmlFor="inputGroupFile01">Choose file</label>
                        </div>
                    }

                </div>
            </form>

        </BaseModal>
    )
}

export default UploadFileModal
