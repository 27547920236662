import moment from "moment";
import {FC} from "react";
import {useEffect} from "react";
import {useState} from "react";
import {useContext} from "react";
import {useHistory, useParams} from "react-router-dom";
import SimpleBar from "simplebar-react";
import { SVGCalendar, SVGCancel, SVGClose, SVGSearch, SVGWarning } from "../../../assets/icons/SvgIcons";
import {IAvailableGuard, IGuardBookLeaves} from "../../../common/interfaces/guards/IGuard";
import {IJobSchedule} from "../../../common/interfaces/jobs/IJob";
import JobContext from "../../../context/jobs/JobContext";
import WizardFormContext from "../../../context/jobs/WizardFormContext";
import { useBulk } from "../../../customHooks/useBulk";
import { useMultipleCheckbox } from "../../../customHooks/useMultipleCheckbox";
import {convertToKm} from "../../../utils/ConvertToKm";
import {momentDateFormat, momentHoursFormat} from "../../../utils/DateFormatting";
import {clearHighlight, highlightChoose} from "../../../utils/HighlightChoose";
import {matchOptionWithName} from "../../../utils/MatchOptionWithName";
import {matchWeekDays} from "../../../utils/MatchWeekDays";
import {searchInArray} from "../../../utils/Search";
import AutocompleteField from "../../autosuggestField/AutocompleteField";
import MulTipleCheckbox from "../../miltiple-checkbox/miltiple-checkbox";
import DataTable from "../../tables/DataTable";
import {assignedGuardsTable, assignGuardToScheduleTable, availableGuardsTable} from "../../../content/jobs/JobsContent";
import { DataService } from "common/services/DataService";

type Props = {
    updateSchedules: (schedules: IJobSchedule[]) => void;
};

type SelectedGuardState = {
    id: number | null;
    name: string;
};

const AssignGuards: FC<Props> = ({updateSchedules}) => {
    const contextJob = useContext(JobContext);
    const {job, vocabulary} = contextJob;
    const {stepHandler} = useContext(WizardFormContext);
    const [bookedLeaves, setBookedLeaves] = useState<IGuardBookLeaves[]>([]) 
    const history = useHistory();
    const params = useParams<{ id: string }>();
    const [ignoreleave, setIgnoreleave] = useState(true)
    const {onChangeCheckbox, onRemoveCheckbox, allCheckbox: selectedSchedules, setAllCheckbox} = useMultipleCheckbox()
    const { getGuardsForBulkScheduleAssigning } = useBulk();

    const [schedulesNeedsGuard, setSchedulesNeedsGuard] = useState<{
        original: IJobSchedule[];
        edited: IJobSchedule[];
    }>({original: [], edited: []});
    const [selectedItem, setSelectedItem] = useState<{ guard: SelectedGuardState}>({
        guard: {} as SelectedGuardState,
    });
    const [availableGuards, setAvailableGuards] = useState<{
        original: IAvailableGuard[];
        filtered: IAvailableGuard[];
    }>({original: [], filtered: []});
    const [buttonsState, setButtonsState] = useState<{ choose: boolean; next: boolean; select: boolean }>({
        choose: false,
        next: false,
        select: false,
    });
    const [guardsIsLoading, setGuardsIsLoading] = useState<boolean>(true);
    const [showGuards, setShowGuards] = useState<boolean>(false);

    useEffect(() => {
        const schedules =
            params?.id !== undefined
                ? [...job.jobSchedules]
                    .map((schedule) => {
                        schedule.guardId ? (schedule.assigned = true) : (schedule.assigned = false);
                        return schedule;
                    })
                    .filter((schedule) => schedule.assigned === false)
                    // .map((schedule) => {
                    //     if (moment.utc(schedule.endsOn).isSameOrAfter(moment.utc(), "day")) {
                    //         if (moment().isAfter(moment.utc(schedule.timeTo).local())) {
                    //             schedule.isInPast = true;
                    //         } else {
                    //             schedule.isInPast = false;
                    //         }
                    //     } else {
                    //         schedule.isInPast = false;
                    //     }
                    //     return schedule;
                    // })
                    // .filter((schedule) => !schedule.isInPast)
                : [...job.jobSchedules]
                    .filter((schedule) => schedule.assigned === false)
                    // .map((schedule) => {
                    //     if (moment.utc(schedule.endsOn).isSame(moment(), "day")) {
                    //         if (moment().isAfter(moment.utc(schedule.timeTo).local())) {
                    //             schedule.isInPast = true;
                    //         } else {
                    //             schedule.isInPast = false;
                    //         }
                    //     } else {
                    //         schedule.isInPast = false;
                    //     }
                    //     return schedule;
                    // })
                    // .filter((schedule) => !schedule.isInPast);

        setSchedulesNeedsGuard({
            original: schedules,
            edited: schedules,
        });
    }, []);

    useEffect(() => {
        setButtonsState({...buttonsState, choose: false});
    }, [buttonsState.select, selectedItem]);

    // Call request on disregard button changed
    useEffect(() => {
        selectedSchedules.length !==0 && getAvailableGuards()
    }, [ignoreleave])

    const onAssignSchedule = () => {
        setSelectedItem({
            guard: {id: null, name: ""},
        });
        setAvailableGuards({
            original: [],
            filtered: [],
        });
        setButtonsState({
            choose: false,
            next: false,
            select: false,
        });
        getAvailableGuards();
    };

    const onGuardSelected = (guardId: number) => {
        const alreadySelected = selectedItem.guard.id === guardId ? null : guardId;
        const guard = availableGuards.filtered.find((guard) => guard.id === guardId);

        setSelectedItem({
            ...selectedItem,
            guard: {
                id: alreadySelected,
                name: guard ? `${guard.firstName} ${guard.middleName} ${guard.lastName}` : "",
            },
        });
        setBookedLeaves([]);
        highlightChoose(guardId);
    };

    const unselectGuard = () => {
        setSelectedItem({ guard: {name: '', id: null}})
        setBookedLeaves([]);
    }

    const onSelectedGuardConfirm = () => {
        const editedSchedules = schedulesNeedsGuard.edited
            .filter((schedule) => schedule.assigned === false || schedule.guardId === null)
            .map((schedule) => {
                const condition = selectedSchedules.some(item => +item === schedule.uid || +item === schedule.id)
                if (condition) {
                    schedule.guardId = selectedItem.guard.id;
                    schedule.assignedGuard = selectedItem.guard.name;
                    return schedule;
                } else {
                    delete schedule.guardId;
                }
                return schedule;
            });
        setSchedulesNeedsGuard({...schedulesNeedsGuard, edited: editedSchedules});
        setButtonsState({...buttonsState, select: true});
        getGuardsBookedLeave()
    };

    const assignGuardToSchedule = (): IJobSchedule[] => {
        const schedules = job.jobSchedules.map((schedule) => {
            const condition = selectedSchedules.some(item => +item === schedule.uid || +item === schedule.id)
            if (
                (condition) &&
                schedule.guardId !== null &&
                schedule.assignedGuard !== ""
            ) {
                schedule.assigned = true;
                return schedule;
            }
            return schedule;
        });
        return schedules;
    };

    const onNext = () => stepHandler(true);

    const onBack = () => {
        stepHandler();
        if (buttonsState.choose) {
            const schedules = assignGuardToSchedule();
            updateSchedules(schedules);
        }
    };

    const onSave = (addAnother = false) => {
        const schedules = assignGuardToSchedule();

        setButtonsState({...buttonsState, choose: true});
        if (addAnother) {
            setBookedLeaves([])
            setAllCheckbox([])
            setSelectedItem({guard: {id: null, name: ""}});
            setButtonsState({...buttonsState, select: false, next: false});
            setShowGuards(false);
            setSchedulesNeedsGuard({
                ...schedulesNeedsGuard,
                original: schedules.filter((schedule) => schedule.assigned === false),
            });
            updateSchedules(schedules);
            const radioBtn = document.querySelector<HTMLInputElement>('table input[type="radio"]:checked');
            radioBtn && (radioBtn.checked = false);
        } else {
            setButtonsState({...buttonsState, next: true});
        }
    };

    useEffect(() => {
        if (!guardsIsLoading && availableGuards.original.length === 0) {
            setSelectedItem({
                guard: {id: null, name: ""},
            });
        }
    }, [availableGuards.original, guardsIsLoading, setAllCheckbox]);

    const getAvailableGuards = async () => {
        setGuardsIsLoading(true);
        setShowGuards(true);

        const filterResult: IJobSchedule[] = schedulesNeedsGuard.original
        .filter(schedule => selectedSchedules
        .some(checkbox => checkbox.toString().includes(
            schedule.id ? schedule.id.toString() : schedule.uid.toString()
        )))

        getGuardsForBulkScheduleAssigning(filterResult, {
            latitude: job.latitude,
            longititude: job.longititude,
            ignoreleave: ignoreleave
        } ,(response) => {
            setAvailableGuards({
                original: response.data,
                filtered: response.data,
            });
            if (response.data.length === 0) {
                setButtonsState({choose: false, next: true, select: false});
            } else {
                setButtonsState({choose: false, next: false, select: false});
            }
            setGuardsIsLoading(false);
        },
        () => setGuardsIsLoading(false))
    };

    const handleSearch = (value: string) => {
        onFilterClear()
        const filteredData = searchInArray<IAvailableGuard>(availableGuards.original, ["firstName", "middleName", "lastName"], value);
        filteredData && setAvailableGuards({ ...availableGuards, filtered: filteredData });
        if (filteredData.length === 1) {
            setTimeout(() => {
                onGuardSelected(filteredData[0].id)
            }, 0);
        }
    };

    const onFilterClear = () => {
        setAvailableGuards({ ...availableGuards, filtered: availableGuards.original });
        clearHighlight();
        setSelectedItem({
            guard: {} as SelectedGuardState,
        })
    };

    const getSuggestionsSearched = (value: string) => {
        if (value.trim().length < 2) {
            return [];
        }
        return searchInArray<IAvailableGuard>(availableGuards.original, ["firstName", "middleName", "lastName"], value.trim(), 2);
    };

    const getSuggestionsSearchedValue = ({firstName, lastName, middleName}: IAvailableGuard) => {
        return `${firstName} ${middleName} ${lastName}`.replace(/  +/g, " ");
    };

    const renderSuggestions = ({firstName, lastName, middleName}: IAvailableGuard) => (
        <span>{`${firstName} ${middleName} ${lastName}`.replace(/  +/g, " ")}</span>
    );

    const getGuardsBookedLeave = async () => {
        const guardBL = new DataService<any>({ url: 'guardbookedleave/check/schedules' });
        const schedules = schedulesNeedsGuard.edited.map(sch => ({startDate: sch.startDate, endDate: sch.endsOn, timeFrom: sch.timeFrom, timeTo: sch.timeTo, repeatOn: sch.repeatOn, shiftPeriod: sch.shiftPeriod}))
        try {
            const {data} = await guardBL.updateWithoutId({guardId: selectedItem.guard.id, schedules})
            setBookedLeaves(data)
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            {schedulesNeedsGuard.original.length !== 0 ? (
                <>
                    <div className='col-md-9 mx-auto'>
                        <h6 className='mt-4 mb-3'>
                            <span
                                className='list-counter mr-2 d-inline-flex align-items-center justify-content-center'>1</span>
                            Select the job schedule you want to assign.
                        </h6>
                    </div>
                    <div className='col-md-9 mx-auto'>
                        <DataTable
                            thead={assignedGuardsTable.thead}
                            ignoreCols={[0]}
                            tbody={schedulesNeedsGuard.original.map((schedule) => ({
                                id: schedule.id ? schedule.id : schedule.uid,
                                dates: `${momentDateFormat(schedule.startDate)} - ${momentDateFormat(schedule.endsOn)}`,
                                times: `${momentHoursFormat(schedule.timeFrom)} - ${momentHoursFormat(schedule.timeTo)}`,
                                shiftPeriod: matchOptionWithName(schedule.shiftPeriod, vocabulary),
                                repeat: `Every week: ${matchWeekDays(schedule.repeatOn)}`,
                            }))}
                            tableClass='available-guards-on-job table-info--notFixed table-unassigned-job-shifts'>
                            {(id) => (
                                <td className='align-middle'>
                                    <div className="form-group mb-0">
                                        <div className="custom-control custom-checkbox d-flex align-items-center">
                                            <MulTipleCheckbox
                                                className='custom-control-input--white'
                                                id={id}
                                                onChange={id => {
                                                    setShowGuards(false)
                                                    onChangeCheckbox(id)
                                                }}
                                                onRemove={id => {
                                                    setShowGuards(false)
                                                    onRemoveCheckbox(id)
                                                }}
                                                allCheckboxes={selectedSchedules}
                                            />
                                        </div>
                                    </div>
                                </td>
                            )}
                        </DataTable>
                        { selectedSchedules.length !== 0 &&
                            <div className="d-flex flex-row justify-content-end align-items-center mb-4">
                                <button className="btn btn-blue text-white"  onClick={() => onAssignSchedule()}>
                                    <SVGSearch color="#fff" className="mr-2"/> Search for available guards
                                </button>
                            </div>
                        }
                    </div>
                    
                </>
            ) : (
                <div className='col-md-9 mx-auto'>
                    <h4 className='py-4'>No available schedule for guard assignment</h4>
                </div>
            )}
            {showGuards && (
                <div className='col-md-9 mx-auto assign-guard-guards mt-5 mb-3'>
                    <div className="d-flex align-items-center justify-content-between flex-wrap">
                        <h6 className='mt-4 mb-3'>
                        <span
                            className='list-counter mr-2 d-inline-flex align-items-center justify-content-center'>2</span>
                            Select a guard to assign for this schedule

                        </h6>
                        <div className="custom-control custom-checkbox d-flex align-items-center mt-0 mt-sm-4 mb-3">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                id="setAsPrimary"
                                name='setAsPrimary'
                                checked={ignoreleave}
                                onChange={() => {
                                    setIgnoreleave(!ignoreleave)
                                    selectedItem.guard.id && onGuardSelected(selectedItem.guard.id)
                                } }
                            />
                            <label className="custom-control-label mb-0" htmlFor="setAsPrimary">Disregard booked leave by guards</label>
                        </div>
                    </div>


                    <SimpleBar style={{maxHeight: 350}}>
                        <DataTable
                            thead={availableGuardsTable.thead}
                            tbody={availableGuards.filtered.map((item) => ({
                                id: item.id,
                                guardName: `${item.firstName} ${item.lastName}`,
                                distance: convertToKm(item.distance || 0, false),
                                attendance: item.attendance && !isNaN(item.attendance) ? item.attendance.toFixed(1) : 0,
                            }))}
                            onColClick={(_key, id) => id && onGuardSelected(id)}
                            ignoreCols={[0, 2, 3]}
                            tableClass={"guard-for-schedule mb-0"}
                            isLoading={guardsIsLoading}
                        >
                            {
                                (_id, rowItem: IAvailableGuard, rowIndex) => (
                                    <>
                                        <td onClick={() => onGuardSelected(rowItem.id)}className="align-middle">{ rowItem.distance }km</td>
                                        <td onClick={() => onGuardSelected(rowItem.id)}className="align-middle">{ rowItem.attendance }%</td>
                                        <td className="aling-middle" onClick={() => onGuardSelected(rowItem.id)}>
                                            <div>
                                                {availableGuards.filtered[rowIndex].aspMobile ?
                                                (
                                                    <>
                                                        <svg className="mr-2" width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="7" y="7" width="10" height="8">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.6035 7C12.6035 7 11.6992 8.16972 11.4537 8.57358C11.2014 8.99061 11.099 9.21364 10.8293 8.91652C10.7134 8.78802 10.1379 8.4019 10.1379 8.4019C10.1379 8.4019 10.3381 10.4204 10.3297 10.7281C10.3211 11.0293 10.1769 11.164 9.99582 10.8358C9.86872 10.6074 9.14845 9.91518 9.14845 9.91518C9.14845 9.91518 9.00438 10.1204 8.80216 10.3805C8.65717 10.5662 8.7075 10.6222 8.4789 10.5455C8.23477 10.4645 7.32866 10.2577 7.32866 10.2577C7.32866 10.2577 7.59337 11.2682 7.66172 11.5602C7.67435 11.615 7.68435 11.6625 7.69093 11.704C7.71942 11.9024 7.6885 11.8531 7.38523 12.0459C7.10486 12.2234 7 12.3092 7 12.3092C7 12.3092 8.7246 13.4438 8.98254 13.672C9.24055 13.9008 9.16292 13.9815 9.02898 14.2812C8.88696 14.5988 8.71815 15 8.71815 15H14.3246C14.3246 15 14.2642 14.5988 14.2083 14.2812C14.1556 13.9815 14.0994 13.9008 14.4202 13.672C14.7397 13.4438 16.7712 12.3092 16.7712 12.3092C16.7712 12.3092 16.6897 12.2234 16.4583 12.0459C16.2067 11.8531 16.1622 11.9024 16.2456 11.704C16.263 11.6625 16.2849 11.615 16.3132 11.5602C16.4611 11.2682 17 10.2577 17 10.2577C17 10.2577 16.0369 10.4645 15.7717 10.5455C15.522 10.6222 15.5871 10.5662 15.4924 10.3805C15.3611 10.1204 15.2722 9.91518 15.2722 9.91518C15.2722 9.91518 14.3654 10.6074 14.1754 10.8358C13.9058 11.164 13.7973 11.0293 13.8706 10.7281C13.9467 10.4204 14.6939 8.4019 14.6939 8.4019C14.6939 8.4019 14.0133 8.78802 13.8622 8.91652C13.5121 9.21364 13.4704 8.99061 13.3314 8.57358C13.1953 8.16972 12.609 7 12.609 7H12.6035Z" fill="white"/>
                                                        </mask>
                                                        <g mask="url(#mask0)">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.6035 7C12.6035 7 11.6992 8.16972 11.4537 8.57358C11.2014 8.99061 11.099 9.21364 10.8293 8.91652C10.7134 8.78802 10.1379 8.4019 10.1379 8.4019C10.1379 8.4019 10.3381 10.4204 10.3297 10.7281C10.3211 11.0293 10.1769 11.164 9.99582 10.8358C9.86872 10.6074 9.14845 9.91518 9.14845 9.91518C9.14845 9.91518 9.00438 10.1204 8.80216 10.3805C8.65717 10.5662 8.7075 10.6222 8.4789 10.5455C8.23477 10.4645 7.32866 10.2577 7.32866 10.2577C7.32866 10.2577 7.59337 11.2682 7.66172 11.5602C7.67435 11.615 7.68435 11.6625 7.69093 11.704C7.71942 11.9024 7.6885 11.8531 7.38523 12.0459C7.10486 12.2234 7 12.3092 7 12.3092C7 12.3092 8.7246 13.4438 8.98254 13.672C9.24055 13.9008 9.16292 13.9815 9.02898 14.2812C8.88696 14.5988 8.71815 15 8.71815 15H14.3246C14.3246 15 14.2642 14.5988 14.2083 14.2812C14.1556 13.9815 14.0994 13.9008 14.4202 13.672C14.7397 13.4438 16.7712 12.3092 16.7712 12.3092C16.7712 12.3092 16.6897 12.2234 16.4583 12.0459C16.2067 11.8531 16.1622 11.9024 16.2456 11.704C16.263 11.6625 16.2849 11.615 16.3132 11.5602C16.4611 11.2682 17 10.2577 17 10.2577C17 10.2577 16.0369 10.4645 15.7717 10.5455C15.522 10.6222 15.5871 10.5662 15.4924 10.3805C15.3611 10.1204 15.2722 9.91518 15.2722 9.91518C15.2722 9.91518 14.3654 10.6074 14.1754 10.8358C13.9058 11.164 13.7973 11.0293 13.8706 10.7281C13.9467 10.4204 14.6939 8.4019 14.6939 8.4019C14.6939 8.4019 14.0133 8.78802 13.8622 8.91652C13.5121 9.21364 13.4704 8.99061 13.3314 8.57358C13.1953 8.16972 12.609 7 12.609 7H12.6035Z" fill="url(#paint0_linear)"/>
                                                        </g>
                                                        <rect x="5.5" y="0.5" width="13" height="23" rx="0.5" stroke="#283044"/>
                                                        <rect x="9" y="21" width="6" height="1" rx="0.5" fill="#283044"/>
                                                        <defs>
                                                        <linearGradient id="paint0_linear" x1="9.36814" y1="14.0562" x2="17.0086" y2="14.0562" gradientUnits="userSpaceOnUse">
                                                        <stop stop-color="#D82332" stop-opacity="0.01"/>
                                                        <stop offset="1" stop-color="#D82332"/>
                                                        </linearGradient>
                                                        </defs>
                                                        </svg>
                                                        <span>{availableGuards.filtered[rowIndex].aspMobile}</span>
                                                    </>
                                                )
                                                :(availableGuards.filtered[rowIndex].personalMobile || '-')
                                                }
                                            </div>
                                        </td>
                                    </>
                                )
                            }
                        </DataTable>
                    </SimpleBar>
                </div>
            )}

            {showGuards && schedulesNeedsGuard.original.length !== 0 && availableGuards.original.length !== 0 && (
                <div className='col-md-9 mx-auto mb-3 mb-sm-5 d-flex flex-wrap align-items-end w-100-md-0'>
                    <div className='form-group mb-0 w-100-md-0'>
                        <label>Or Search by Name</label>
                        <AutocompleteField
                            getSuggestions={getSuggestionsSearched}
                            getSuggestionValue={getSuggestionsSearchedValue}
                            renderSuggestion={renderSuggestions}
                            onClear={onFilterClear}
                            onSearch={handleSearch}
                            placeholder={"Enter guard name"}
                            buttonColorClass="blue"
                        />
                    </div>
                    {selectedItem.guard && selectedItem.guard.id  &&  (
                        <button type='button' className='btn btn-blue text-white d-block ml-auto px-5 mt-2 mt-sm-0 mb-2 mb-sm-0'
                                onClick={() => onSelectedGuardConfirm()}>
                            Select
                        </button>
                    )}
                </div>
            )}


            {bookedLeaves?.length > 0 && 
                <div className='col-md-9 mx-auto mb-3 mb-sm-5 d-flex flex-column w-100-md-0'>
                    {selectedItem.guard.name && <h6 className="font-arial-narrow fs-14">You have selected: <button onClick={unselectGuard} type="button" className="btn btn-badge ml-3" >{selectedItem.guard.name }  <SVGCancel className="ml-2" color="#000"/></button></h6>}
                    <div>
                        <div className="d-flex align-items-center mt-3 mb-3">
                            <div className="mr-2">
                                <SVGWarning/>
                            </div>
                            <span className="font-arial-narrow fs-14 fw-7 text-danger">
                                There is some leave booked during this schedule for this guard. If you save this assignment, the shifts for these days will be available under Unassigned Job Shifts.
                            </span>
                        </div>
                        <div>
                            <b className="font-arial-narrow fs-13">
                                Booked Leave:
                            </b>
                            {bookedLeaves.map(leave => (
                               <div className="d-flex align-items-center mt-2">
                                    <SVGCalendar className="mr-2" />
                                    <div>
                                        <span className="fs-13 font-arial-narrow">{momentDateFormat(leave.startDate)} </span>
                                        {momentDateFormat(leave.startDate) !== momentDateFormat(leave.endDate) && <span className="fs-13 font-arial-narrow">- {momentDateFormat(leave.endDate)}</span>}
                                    </div>
                               </div>
                            ))}
                        </div>
                    </div>
                </div>
            }
            {buttonsState.select && (
                <div className='col-md-9 mx-auto ml-auto mt-4 d-flex flex-row justify-content-end'>
                    {schedulesNeedsGuard.original.length > 1 && (
                        <button type='button' className='btn btn-outline-blue text-blue mr-3 d-block'
                                onClick={() => onSave(true)}>
                            Save and Add Another
                        </button>
                    )}
                    <button type='button' className='btn btn-blue text-white d-block' onClick={() => onSave()}>
                        Save
                    </button>
                </div>
            )}

            <div className='col-md-9 mx-auto d-flex justify-content-lg-between mt-4 bottom-nav-card'>
                <div className='d-flex flex-row mr-1'>
                    <button type='button' className='btn btn-outline-aqua-blue px-4 mr-2' onClick={() => onBack()}>
                        Back
                    </button>
                    <button type='button' className='btn btn-outline-aqua-blue px-4'
                            onClick={() => history.push("/jobs")}>
                        Cancel
                    </button>
                </div>
                {
                    // (buttonsState.next || schedulesNeedsGuard.original.length === 0) &&
                    <button type='button' className='btn btn-aqua-blue px-3 px-sm-4 ml--auto' onClick={() => onNext()}>
                        Next
                    </button>
                }
            </div>
        </>
    );
};

export default AssignGuards;
