import { useState, useEffect } from 'react';

interface PaginationProps<T> {
  items: T[];
}

const usePagination = <T, >({ items }: PaginationProps<T>) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pagesNum, setPagesNum] = useState<number>(0);
  const [itemsPerPage, setItemsPerPage] = useState<number>(24);

  useEffect(() => {
    const pages = itemsPerPage ? Math.ceil(items.length / itemsPerPage) : 0;
    setPagesNum(pages);
  }, [items.length, itemsPerPage]);

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

 

  // const indexOfLastItem = currentPage * itemsPerPage;
  // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // const currentItems = items.slice(indexOfFirstItem, indexOfLastItem);

  return { pagesNum, currentPage, paginate, setItemsPerPage, itemsPerPage };
};

export default usePagination;
