import { ConfigHelper } from '../../helpers/ConfigHelper';
import { IEntity } from '../interfaces/IEntity';
import { BaseDataService } from './BaseDataService';
import { IResourceServiceConfig } from './ResourceDataService';

interface IResourceData<T> extends IEntity {
    data: any;
}

export class DataService<T> extends BaseDataService {

    constructor(config: IResourceServiceConfig | string) {
        super(typeof (config) === 'string' ? ConfigHelper.getDefaultConfig(config) : ConfigHelper.ensureConfig(config));
    }

    create = async (obj: T, id: string | number | undefined = undefined, urlAfter: string | undefined = undefined): Promise<IResourceData<T>> => await super.request(`${this.url}${id !== undefined ? `/${id}` : ""}${urlAfter !== undefined ? `/${urlAfter}` : ""}`, 'POST', obj);

    updateWithoutId = async (obj: T, urlAfter: string | undefined = undefined): Promise<T> => await super.request(`${this.url}${urlAfter !==undefined ? `/${urlAfter}` : ""}`, 'PUT', obj);

    update = async (obj: T, id?: string | number, urlAfter: string | undefined = undefined): Promise<T> => await super.request(`${this.url}${id ? `/${id}` : ''}${urlAfter !==undefined ? `/${urlAfter}` : ""}`, 'PUT', obj);

    // updateQuery = async (urlAfter: string, param: string): Promise<T> => await super.request(`${this.url}/${urlAfter}?SchedulesId=${param}`, 'PUT');

    delete = async (id?: number | string, obj?: T): Promise<IResourceData<number>> => await super.request(`${this.url}/${id ? id : ''}`, 'DELETE', obj);

    // deleteQuery = async(query: string): Promise<T> => await super.request(`${this.url}?SchedulesId=${query}`, 'DELETE');

    updateWithQuery = async (obj: T, query?: string): Promise<T> => await super.request(`${this.url}${query ? `?${query}` : ''}`, 'PUT', obj);
}
