import moment from 'moment'
import React from 'react'
import CustomTimePicker from '../../components/custom-time-picker/CustomTimePicker'
import Layout from '../../components/layout/Layout'

function DevelopersPage() {
    return (
        <Layout>
            <h1 className="mb-5">Page for Developer</h1>
            <CustomTimePicker
                value={moment(moment.utc())}
                onChange={() => console.log('')}
            />
            {/* { console.log(moment(moment.utc()).format()) } */}
        </Layout>
    )
}

export default DevelopersPage
