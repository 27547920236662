export const checkIfSubMenuIsOffOfViewport = (target: HTMLElement, setStateCallBack?: () => void,
  ) => {
    // let dropDownMenuHeight: number | undefined = undefined;
    
    setStateCallBack && setStateCallBack();
    
    // setTimeout(() => {
    //   const dropDownMenu = target?.parentElement?.querySelector('.dropdown-menu');
    //   dropDownMenu && (dropDownMenuHeight = dropDownMenu.getBoundingClientRect().height);
    //   console.log(dropDownMenuHeight)
    // }, 0)

    if (target && target.classList.contains('sub-dropdown')) {
      // Check if subdropdown is off of viewport
      const subDropdownItem = Array.from(target.children).find(el => el.classList.contains('sub-dropdown-item'))
      const subDropdownBottomClass = 'sub-dropdown-item--bottom';

      if (subDropdownItem && !subDropdownItem.classList.contains(subDropdownBottomClass)) {
        subDropdownItem.classList.remove(subDropdownBottomClass);

        setTimeout(() => {
          const rectS = subDropdownItem.getBoundingClientRect();
          const  windowW = window.innerWidth;
          if (rectS.width === 0 || rectS.height === 0) return;
          if ((rectS.left + rectS.width) >= windowW) {
            subDropdownItem.classList.add(subDropdownBottomClass);
            // console.log('dropDownMenuHeight', dropDownMenuHeight);
            // dropdowHeightCallBack && dropDownMenuHeight && dropdowHeightCallBack(`${dropDownMenuHeight}px`); 
            // dropdowHeightCallBack && dropdowHeightCallBack(`${162}px`); 
            //  && {...(subDropdownItem as HTMLElement)}.style.add = '10px';
          } else {
            subDropdownItem.classList.remove(subDropdownBottomClass);
            // dropdowHeightCallBack && dropdowHeightCallBack(``)
          };
          subDropdownItem.classList.remove('invisible');
        }, 1)

      }
    }
}