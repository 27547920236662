export const clientUsersContent = {
    thead: [
        {
            label: "Full Name",
        },
        {
            label: "Client Name",
        },
        // {
        //     label: "Client Name",
        // },
        {
            label: "Position",
        },
        {
            label: "Email Address",
        },
        {
            label: "Phone",
        },
        {
            label: "Mobile",
        },
        {
            label: "Action",
        },
        {
            label: "Reset Password",
        }
    ],
    tbody: [
        {
            id: 1,
            name: "First Last Name",
            position: "[position]",
            email: "email@email.com",
            phone: "111-111-1111",
            mobile: "000-000-00000",
        },
    ]
}

export const clientContactsContent = {
    thead: [
        {
            label: "Full Name",
            sortable: true,
        },
        {
            label: "Position",
            sortable: true,
        },
        {
            label: "Email Address",
            sortable: true,
        },
        {
            label: "Phone",
            sortable: true,
        },
        {
            label: "Mobile",
            sortable: true,
        },
        {
            label: "Access",
        },
    ],
    tbody: [
        {
            id: 1,
            fullName: "First Last Name",
            position: "[position]",
            email: "email@email.com",
            phoneNumber: "111-111-1111",
            mobileNumber: "000-000-00000",
        },
    ]
}

export const aspUsersContent = {
    thead: [
        {
            label: "Full Name",
        },
        {
            label: "Position",
        },
        {
            label: "Email Address",
        },
        {
            label: "Phone",
        },
        {
            label: "Mobile",
        },
        {
            label: "Action",
        },
        {
            label: "Reset Password",
        }
    ],
    tbody: [
        {
            id: 2,
            name: "First Last Name",
            position: "[position]",
            email: "email@email.com",
            mobile: "000-000-00000",
            isActive: true,
        },
        {
            id: 2,
            name: "First Last Name",
            position: "[position]",
            email: "email@email.com",
            mobile: "000-000-00000",
            isActive: false,
        },
    ]
}