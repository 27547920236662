import React, {useContext, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {SVGCancel, SVGEraser, SVGUpload} from '../../assets/icons/SvgIcons'
import {manageHolidaysContent} from '../../content/administration/AdministrationContent'

import {IHoliday} from "../../common/interfaces/administration/IHoliday";
import ResourceDataService from '../../common/services/ResourceDataService'
import {NotificationTypes} from '../../common/interfaces/INotification'
import {DataService} from '../../common/services/DataService'
import {IDropdown} from '../../common/interfaces/dropdowns/IDropdown'
import {AddNewItemEnum} from '../../common/enums/AddNewItemEnum';
import {matchOptionWithName} from '../../utils/MatchOptionWithName';
import {useTableList} from '../../customHooks/useTableList'
import AppContext from '../../context/AppContext'

import RemoveHolidayModalBody from '../../components/modal/administration/RemoveHolidayModalBody'
import Layout from '../../components/layout/Layout'
import DataTable from '../../components/tables/DataTable'
import SectionTitle from '../../components/titles/SectionTitle'
import AddHolidayModal from '../../components/modal/administration/AddHolidayModal'
import RemoveBaseModal from '../../components/modal/administration/RemoveBaseModal'
import ReportActions from '../../components/reports/ReportActions'
import UploadFileModal from '../../components/modal/administration/UploadFileModal'
import BaseModal from '../../components/modal/BaseModal'
import CustomSelectControl from "../../components/custom-select/CustomSelectControl";
import {useListFilter} from "../../customHooks/useListFilter";
import { useSchoolYear } from 'customHooks/useSchoolYear';
import useCustomForm from 'customHooks/useCustomForm';

const ManageHolidays = () => {
    const {tableData, isLoading, fetchData, onClearFilterQuery, onSortCall} = useTableList<IHoliday>('holiday/list')
    const [holidayTypes, setHolidayTypes] = useState<IDropdown[]>([])
    const [schoolBoards, setSchoolBoards] = useState<IDropdown[]>([])
    const [holidayNames, setHolidayNames] = useState<IDropdown[]>([])
    const { schoolYears, getSchoolYears: setDefautlSchoolYears } = useSchoolYear();
    const [modal, setModal] = useState<{ add: boolean, upload: boolean }>({add: false, upload: false})
    const {generateFilterQuery, onFilterDropdownChange, filter, onFilterClearClick, onFilterDefaultSchoolYear} = useListFilter('holiday/list')
    const {showLoader, showNotification} = useContext(AppContext)
    const [optionInFocus, setOptionInFocus] = useState<IHoliday>({} as IHoliday)
    const svcHolidayTypes = new ResourceDataService<IDropdown[]>({url: 'holiday/types'})
    const svcSchoolBoards = new ResourceDataService<IDropdown[]>({url: 'school/dropdown'})
    const [filterQuery, setFilterQuery] = useState('')
    const holidaySvc = new DataService<IHoliday>({url: 'holiday'})
    const [modals, setModals] = useState<{ showSuccess: boolean, successTitle: string, confirm: boolean, itemFocus: IHoliday }>({
        showSuccess: false,
        successTitle: '',
        confirm: false,
        itemFocus: {} as IHoliday
    })
    const uploadSvc = new ResourceDataService({url: 'document/upload'})
    const svc = new DataService({url: 'holiday'})
    const { handleFormStatus, formStatus } = useCustomForm()

    useEffect(() => {
        setDefautlSchoolYears(onFilterDefaultSchoolYear, yearId => {
          fetchData(0, 0, generateFilterQuery(false, false, undefined, `&SchoolYearId=${yearId}`))
        });
        fetchAdditionalData()
    }, [])

    useEffect(() => {
        tableData.length !== 0 && mapHolidayNames(tableData)
    }, [tableData])


    const mapHolidayNames = (data: IHoliday[]) => {
        let names: IDropdown[] = []

        data.forEach((holiday, indx) => {
            if (!names.some(item => holiday.holidayName === item.name)) {
                names.push({
                    id: indx + 1,
                    name: holiday.holidayName ? holiday.holidayName : '',
                })
            }
        })

        setHolidayNames(names.sort((a, b) => a.name.localeCompare(b.name)))
    }
    const onActionColClick = async (rowItem: IHoliday) => {
        setOptionInFocus(rowItem)

        showLoader(true)
        try {
            setModals({
                ...modals,
                confirm: true,
                showSuccess: false,
                itemFocus: rowItem,
                successTitle: `Are you sure you want to remove options <span class="font-weight-bold">${rowItem.holidayName}</span>?`
            })
            showLoader(false)
        } catch (error: any) {
            showNotification(NotificationTypes.danger, error.message)
            showLoader(false)
        }

    }
    const onSubmitRemoveClick = async (rowItem: IHoliday) => {
        showLoader(true)
        try {
            await svc.update({ boardId: rowItem.boardId, reactivateShifts: rowItem?.reactivateShifts || false},
                rowItem.id, 'deactivate');
            setModals({
                ...modals,
                showSuccess: true,
                confirm: false,
                successTitle: `<span class="font-weight-bold">${rowItem.holidayName}</span> has been removed.`
            })
            fetchData(0, 0, generateFilterQuery())
            setOptionInFocus({} as IHoliday)
            showLoader(false)

        } catch (error: any) {
            showNotification(NotificationTypes.danger, error.message)
            showLoader(false)
        }
    }

    const fetchAdditionalData = async () => {
        try {
            let response = await svcHolidayTypes.getAll()
            setHolidayTypes(response.data.sort((a, b) => a.name.localeCompare(b.name)))

            response = await svcSchoolBoards.getAll()
            setSchoolBoards(response.data.map(board => ({
                id: board.id, name: board.name
            })))

        } catch (error: any) {
            console.log(error.message)
        }
    }

    const saveNewHoliday = async (newHoliday: IHoliday) => {
        showLoader(true)
        try {
            await holidaySvc.create(newHoliday);
            fetchData(0, 0, generateFilterQuery())
            setModal({...modal, add: false})
            showLoader(false)
            showNotification(NotificationTypes.success, `${newHoliday.holidayName} has been added`)
        } catch (error: any) {
            showNotification(NotificationTypes.danger, error.message)
            showLoader(false)
        }
    }
    const getQueryReports = () => {
        let query = '';
        if (filter.schoolBoardId) {
            query += `&schoolBoardId=${filter.schoolBoardId}`
        }
        if (filter.holidayType) {
            query += `&holidayType=${filter.holidayType}`
        }
        if (filter.schoolYearId) {
          query += `&SchoolYearId=${filter.schoolYearId}`
        }
        setFilterQuery(query)
    }

    const onAddBoardClick = () => setModal({...modal, add: true})

    const onSuccessNewItemAdded = (option: IDropdown, type: AddNewItemEnum) => {
        if (type === AddNewItemEnum.holidayName) {
            setHolidayNames([
                ...holidayNames,
                ...[option]
            ].sort((a, b) => a.name.localeCompare(b.name)))
        }

        if (type === AddNewItemEnum.holidayType) {
            setHolidayTypes([
                ...holidayTypes,
                ...[option]
            ].sort((a, b) => a.name.localeCompare(b.name)))
        }
    }

    const handleUploadFile = async (file: FormData) => {
        showLoader(true)
        try {
            await uploadSvc.sendFile(file);
            await fetchData(0, 0, generateFilterQuery());
            showNotification(NotificationTypes.success, `The file has been uploaded`)
            setModal({...modal, upload: false})
            showLoader(false)
        } catch (error: any) {
            showNotification(NotificationTypes.danger, error.message)
            showLoader(false)
        }
    }

    return (
        <Layout
            breadcrumbs={{
                links: [{
                    title: 'Administration',
                    link: 'administration'
                }],
                currentPageTitle: 'Manage Holidays'
            }}
        >
            <div className="row mb-4">
                <div className="col-12 d-flex flex-row align-items-center flex-wrap justify-content-between">
                    <SectionTitle title={`Manage Holidays`}/>
                </div>
            </div>
            <form className="filters-form-group mb-4" onKeyDown={(event) => {
                if (event.key === 'Enter') fetchData(1, 0, generateFilterQuery(true, false))
            }}>
                <div className="row">
                    <div className="col-12">
                        <h5>Filter Holiday List</h5>
                    </div>
                    <div className="d-flex w-100 flex-wrap ml-1 mr-1 holidays-filter">
                        <CustomSelectControl
                            options={schoolBoards}
                            onChange={e => onFilterDropdownChange(e, "schoolBoardId")}
                            value={filter.schoolBoardId}
                            placeholder={"Select a School Board(s)"}
                            className="flex-grow-0 ml-2 mb-2"
                        />

                        <CustomSelectControl
                            options={holidayTypes}
                            onChange={(_id, label) => onFilterDropdownChange(label as string, "holidayType")}
                            value={filter.holidayType}
                            placeholder={"Select a Holiday Type"}
                            className="flex-grow-0 ml-2 mb-2"
                            valueIsLabel
                        />

                        <CustomSelectControl
                            options={schoolYears}
                            onChange={(id) => onFilterDropdownChange(id, "schoolYearId")}
                            value={filter.schoolYearId}
                            placeholder={"Select a School Year"}
                            className="flex-grow-0 ml-2 mb-2"
                        />

                        <button type="button" className="btn btn-aqua-blue ml-2"
                                onClick={() => {
                                    fetchData(0, 0, generateFilterQuery())
                                    getQueryReports()
                                }}>
                            Filter Holidays
                        </button>
                        {
                            filter.hasValue &&
                            <button type="button" className="btn btn-aqua-blue ml-2"
                                    onClick={() => {
                                        onClearFilterQuery()
                                        setFilterQuery('')
                                        onFilterClearClick()
                                    }}>
                                <SVGEraser/>
                            </button>
                        }
                    </div>
                </div>
            </form>
            <div className="row mb-4">
                <div className="col-12 d-flex flex-row align-items-center flex-wrap justify-content-end">
                    <div className="d-flex flex-row flex-wrap align-items-center">
                        {
                            React.useMemo(() => (
                                <ReportActions 
                                    className="mr-3 mr-md-0 mt-2  mt-lg-0"
                                    page="holidays"
                                    query={filterQuery}
                                    hidePrint
                                    hidePdf
                                />
                            ), [filterQuery])
                        }
                        <div>
                            <button type="button" className="btn btn-aqua-blue ml-0 ml-sm-3 mt-2 mt-lg-0"
                                    onClick={() => onAddBoardClick()}>
                                Add a Holiday
                            </button>
                            <button type="button" className="btn btn-aqua-blue ml-2 ml-sm-3 mt-2 mt-lg-0"
                                    onClick={() => setModal({...modal, upload: true})}>
                                <SVGUpload />
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">

                <div className="col-12">

                    {
                        React.useMemo(() => (
                            <DataTable
                                thead={manageHolidaysContent.thead}
                                tbody={tableData ?
                                    tableData.map(item => ({
                                        id: item.holidayId,
                                        boardName: item.boardName ? matchOptionWithName(item.boardName as string, schoolBoards) : "-",
                                        holidayType: item.holidayType,
                                        holidayName: item.name ? item.name : item.holidayName,
                                        startDate: item.startDate,
                                        endDate: item.endDate,
                                        boardId: item.boardId || 0,
                                    }))
                                    : []}
                                ignoreCols={[0, 6]}
                                isLoading={isLoading}
                                tableClass={'table-info--notFixed'}
                                defaultSortedColumn={3}
                                onSort={(orderBy, desc) => onSortCall(orderBy, desc)}
                                sortOnBackend
                            >
                                {
                                    (_id, rowItem: IHoliday) => (
                                        <>
                                            <td className="aling-middle">
                                                <div>
                                                    <button
                                                        className={`btn btn-aqua-blue`}
                                                        onClick={() => onActionColClick(rowItem)}
                                                    >
                                                        <SVGCancel/>
                                                    </button>
                                                </div>
                                            </td>
                                        </>
                                    )
                                }
                            </DataTable>
                        ), [tableData, isLoading, schoolBoards])
                    }
                </div>
            </div>

            {modal.add &&
            <AddHolidayModal
                onCancel={() => setModal({...modal, add: false})}
                onSubmit={(holiday: IHoliday) => saveNewHoliday(holiday)}
                holidayTypes={holidayTypes}
                holidayNames={holidayNames}
                schoolBoards={schoolBoards}
                saveNewItem={(option: IDropdown, type: AddNewItemEnum) => onSuccessNewItemAdded(option, type)}
            />
            }

            {
                modal.upload && <UploadFileModal
                    onCancel={() => setModal({...modal, upload: false})}
                    onSubmit={(data:  FormData) => handleUploadFile(data)}
                    subTitle={"Upload a spreadsheet with holidays"}
                    exampleLink={'https://docs.google.com/spreadsheets/d/1tdgtnPBcEb3gNCL3VhV5iG_4lU5fJRXZ7xwd21xqgTs/edit#gid=1619430924'}
                />
            }

            {
                modals.confirm &&
                <RemoveBaseModal
                    onCancel={() => setModals({...modals, confirm: false})}
                    onSubmit={() => {
                        onSubmitRemoveClick(optionInFocus)
                    }}
                    title={modals.successTitle}
                >
                    <form>
                        <RemoveHolidayModalBody
                            selectedItem={optionInFocus as IHoliday}
                            checkboxChanged={value => setOptionInFocus({ ...optionInFocus, reactivateShifts: value })}
                        />
                    </form>
                </RemoveBaseModal>
            }

            {
                modals.showSuccess &&
                <BaseModal
                    show={modals.showSuccess}
                    cancelBtnText={'Close'}
                    onCancel={() => {
                        setModals({...modals, showSuccess: false})
                    }}
                    title={modals.successTitle}
                >
                    <div className="row">
                        <div className="col-12 d-flex justify-content-center">
                            <Link className="btn btn-aqua-blue mt-4" to="/jobs/unassigned">Go To List of Shifts in Need
                                of Guards</Link>
                        </div>
                    </div>
                </BaseModal>
            }

        </Layout>
    )
}

export default ManageHolidays
