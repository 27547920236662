import { useState } from "react";
import { IActiveJobSchedules, IJobsRightNow } from '../common/interfaces/jobs/IJob'
import { IActiveJob } from "../common/models/Job";

export const useSingleMarkerOnMap = (primaryKey: string = 'id') => {
    const [viewLocationInMap, setViewLocationInMap] = useState<boolean>(false);
    const [jobItemInFocus, setJobItemInFocus] = useState<IJobsRightNow | IActiveJobSchedules | IActiveJob>({} as IJobsRightNow | IActiveJobSchedules | IActiveJob)

    const onViewLocationInMapClick = (data: Array<IActiveJobSchedules | IActiveJob>, jobId: number) => {
        const result = data.find(job => job[primaryKey] === jobId)

        setViewLocationInMap(true)
        if (result) {
            setJobItemInFocus(result)
            setViewLocationInMap(true)
        }
    }

    return {
        viewLocationInMap, jobItemInFocus, onViewLocationInMapClick, setViewLocationInMap
    }
}