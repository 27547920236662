import ResourceDataService from 'common/services/ResourceDataService'
import React, {useEffect, useState} from 'react'
import {useContext} from 'react'
import { Link } from 'react-router-dom'
import {ISettings} from '../../common/interfaces/administration/ISettings'
import {NotificationTypes} from '../../common/interfaces/INotification'
import {IValidationBody, ValidationRules} from '../../common/interfaces/IValidation'
import {Settings} from '../../common/models/Administration'
import {DataService} from '../../common/services/DataService'
import Layout from '../../components/layout/Layout'
import SectionTitle from '../../components/titles/SectionTitle'
import AppContext from '../../context/AppContext'
import useGetModel from '../../customHooks/useGetModel'
import {useOnIputChange} from '../../customHooks/useOnInputChange'
import {highlightError, removeHighlightError, validateFiled} from '../../utils/Validation'

const ManageSettings = () => {
    const {onChange, hookState: formData, setHookState} = useOnIputChange(new Settings())
    const appContext = useContext(AppContext)
    const [covidTest, setCovidTest] = useState(false)

    const {getModelData} = useGetModel<ISettings>('setting')
    const get = new ResourceDataService<Array<{name: string, value: string}>>({url: 'setting/general'});
    const updateSvc = new DataService<ISettings>({url: 'setting'})
    const updateSettingValue = new DataService<{settingsName: string, settingsValue: string}>({url: 'setting/update-settings-value'})

    useEffect(() => {
        getModelData(data => setHookState(data))
    }, [])

    useEffect(() => {
      const fetchCovidTest = async () => {
        try {
          const response = await get.queryString('Settings=CovidTest');
          setCovidTest(response.data[0].value === 'true')
        } catch (error: any) {
          appContext.showNotification(NotificationTypes.danger, error.message)
        }
      }

      fetchCovidTest();
    }, [])

    const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        appContext.showLoader(false)

        let errors: IValidationBody[] = [];

        errors = [...errors, ...validateFiled(formData.distance, "distance", ValidationRules.required)]
        errors = [...errors, ...validateFiled(formData.bufferTime, "bufferTime", ValidationRules.required)]

        if (errors.length !== 0) {
            appContext.showNotification(NotificationTypes.warning, "Please fill in all fields correctly")
            highlightError(errors)
        } else {
            removeHighlightError()
            try {
                await updateSvc.updateWithoutId(formData)
                await updateSettingValue.update({settingsName: 'CovidTest', settingsValue: covidTest.toString()})
                appContext.showLoader(false)
                appContext.showNotification(NotificationTypes.success, "Settings has been successfully updated")
            } catch (error: any) {
                appContext.showLoader(false)
                appContext.showNotification(NotificationTypes.danger, error.message)
            }
        }
    }

    return (
        <Layout
            breadcrumbs={{
                links: [{
                    title: 'Administration',
                    link: 'administration'
                }],
                currentPageTitle: 'Manage Settings'
            }}
            className={"page-settings"}
        >
            <form onSubmit={e => onFormSubmit(e)}>
                <div className="row mb-4">
                    <div className="col-12 d-flex flex-row align-items-center flex-wrap justify-content-between">
                        <SectionTitle title={`Settings`}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <h5>Manage Buffer Start Time for Guards</h5>
                        <p>Client start times should be entered for all job schedules and guard schedules. A global
                            buffer time will be added to start times, as managed here:</p>
                    </div>

                    <div className="col-lg-7 mx-auto">
                        <div className="row d-flex align-items-start">
                            <div className="col-lg-8 form-group">
                                <label className="required mb-0  text-left text-md-right w-100" htmlFor="">How many
                                    minutes before the client's expected start time should the shift start time appear
                                    for a guard:</label>
                            </div>
                            <div className="col-lg-4">
                                <div className="form-group d-flex flex-row align-items-center">
                                    <input
                                        type="number"
                                        className="form-control"
                                        value={formData.bufferTime}
                                        onChange={e => onChange(e)}
                                        name="bufferTime"
                                        onBlur={e => +e.target.value < 0 && onChange('0', 'bufferTime')}
                                    />
                                    <p className=" ml-2 mb-0">minutes</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-3 mt-md-5">
                    <div className="col-12">
                        <h5>Manage Distance Maximum to Filter for Guards to Job Location</h5>
                        <p>Guards can be filtered in table and map on the Dashboard based on the distance between their
                            residence and the job location. This is the setting for the filter maximum distance to find
                            an appropriate Guard.</p>
                    </div>

                    <div className="col-lg-7 mx-auto">
                        <div className="row d-flex align-items-start">
                            <div className="col-lg-8 form-group">
                                <label className="required mb-0 text-left text-md-right w-100" htmlFor="">Maximum
                                    distance for "Close to Job" filter:</label>
                            </div>
                            <div className="col-lg-4">
                                <div className="form-group d-flex flex-row align-items-center">
                                    <input
                                        type="number"
                                        className="form-control"
                                        value={formData.distance}
                                        onChange={e => onChange(e)}
                                        name="distance"
                                        onBlur={e => +e.target.value < 0 && onChange('0', 'distance')}
                                    />
                                    <p className=" ml-2 mb-0">meters</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* New section */}
                <div className="row mt-3 mt-md-5">
                    <div className="col-12">
                        <h5>Manage Frequency to Send Healty and Safety Check</h5>
                        <p>Guards receive a Health and Safety Check via the Mobile App. This setting defines how often the check appears in the App.</p>
                    </div>

                    <div className="col-lg-12 mx-auto">
                        <div className="row d-flex align-items-start">
                            <div className="col-lg-6 form-group">
                                <label className="required mb-0 text-left text-md-right w-100" htmlFor="">
                                Automatically show the Check:
                                </label>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <div className="custom-control custom-control-radio custom-control-inline">
                                        <input type="radio" id="frequencyToSendHealtyAndSafetyCheck1" name="frequencyToSendHealtyAndSafetyCheck1" className="custom-control-input"
                                            checked={+formData.frequencyToSendHealtyAndSafetyCheck === 1 ? true : false}
                                            onChange={() => onChange(1, "frequencyToSendHealtyAndSafetyCheck")}
                                            />
                                        <label className="custom-control-label custom-control-radio-label" htmlFor="frequencyToSendHealtyAndSafetyCheck1">At the start of each day during which they have a shift</label>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className="custom-control custom-control-radio custom-control-inline">
                                        <input type="radio" id="frequencyToSendHealtyAndSafetyCheck2" name="frequencyToSendHealtyAndSafetyCheck2" className="custom-control-input"
                                            checked={+formData.frequencyToSendHealtyAndSafetyCheck === 2 ? true : false}
                                            onChange={() => onChange(2, "frequencyToSendHealtyAndSafetyCheck")}
                                            />
                                        <label className="custom-control-label custom-control-radio-label" htmlFor="frequencyToSendHealtyAndSafetyCheck2">At the start of each shift for each Guard</label>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="custom-control custom-control-radio custom-control-inline">
                                        <input type="radio" id="frequencyToSendHealtyAndSafetyCheck3" name="frequencyToSendHealtyAndSafetyCheck3" className="custom-control-input"
                                               checked={+formData.frequencyToSendHealtyAndSafetyCheck === 3 ? true : false}
                                               onChange={() => onChange(3, "frequencyToSendHealtyAndSafetyCheck")}
                                        />
                                        <label className="custom-control-label custom-control-radio-label" htmlFor="frequencyToSendHealtyAndSafetyCheck3">Not scheduled</label>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-3 mt-md-5">
                    <div className="col-12">
                          <h5>Manage Availability of Covid Screening Questions on Mobile App</h5>
                          <p>When turned on, the Guards will be presented with the Covid screening questions before the
                            first shift of each day. They will not be able to Check-In without completing the screening
                            questions first, with all replies being negative. The screening questions are managed by
                            the Support Dev Team, and can be viewed on the <Link className="text-decoration-none" to="/reports/guard-tests">Covid Screenings Report</Link> page.
                          </p>
                    </div>

                    <div className="col-lg-12 mx-auto">
                          <div className="row d-flex align-items-start">
                              <div className="col-lg-6 form-group">
                                  <label className="required mb-0  text-left text-md-right w-100" htmlFor="">
                                    Show Screening Questions on App:
                                  </label>
                              </div>
                              <div className="col-lg-6">
                                Off
                                <label className="switch mx-2">
                                  <input type="checkbox" checked={covidTest} onChange={() => setCovidTest(!covidTest)} />
                                  <div className="slider round"></div>
                                </label>
                                On
                              </div>
                          </div>
                    </div>
                </div>

                <div className="row mt-3 mt-md-5">
                    <div className="col-12 text-right">
                        <button type="submit" className="btn btn-aqua-blue">Save Settings</button>
                    </div>
                </div>
            </form>
        </Layout>
    )
}

export default ManageSettings
