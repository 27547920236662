import { IUrlBuilder, IQueryParams } from "../common/interfaces/IUrlBuilder";

export default class UrlHelper implements IUrlBuilder {

  getQueryParams(params: IQueryParams, getQueryParams = true): string {
    let url = getQueryParams ? `?` : ``;
    
    if (params.id) {
      url = `/${params.id}?`
    }

    if (params.filter) {
      for (let param of params.filter) {
        url += `&${param.key}=${param.value}`
      }
    }

    if (params.include) {
      for (let param of params.include) {
        url += `&include=${param}`
      }
    }

    if (params.page) {
      url += `&PageNumber=${params.page}`;
    }

    if (params.take) {
      url += `&PageSize=${params.take}`;
    }

    if (params.orderBy) {
      url += `&OrderBy=${params.orderBy}`;
    }

    if (params.desc) {
      url += `&Desc=${params.desc}`;
    }

    if (params.request) {
      url += `&request=${params.desc}`;
    }

    if (params.customQuery) {
      url += params.customQuery;
    }

    return url;
  }
}
