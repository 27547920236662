import React, { FC, useState } from 'react'
import moment from 'moment'
import { momentHoursFormat } from '../../utils/DateFormatting'
import { SVGRefresh } from '../../assets/icons/SvgIcons'

type Props = {
    onRefresh: () => void
}

const LastRefreshBtn: FC<Props> = ({ onRefresh }) => {
    const [lastRefresh, setLastRefresh] = useState<string>(momentHoursFormat(moment().toDate()))
    
    return (
        <button type="button" className="btn btn-outline-aqua-blue d-flex align-item-center font-weight-normal"
        onClick={() => {
            onRefresh();
            setLastRefresh(momentHoursFormat(moment().toDate()));
        }}>
            <SVGRefresh className="mr-3" />
            Last Page Refresh: { lastRefresh }
        </button>
    )
}

export default LastRefreshBtn
