import React, { FC, useContext, useEffect } from 'react'
import { useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { NotificationTypes } from '../../common/interfaces/INotification';
import { IValidationBody, ValidationRules } from '../../common/interfaces/IValidation';
import { DataService } from '../../common/services/DataService';
import BaseStartScreen from '../../components/base-start-screen/BaseStartScreen';
import AppContext from '../../context/AppContext';
import { useOnIputChange } from '../../customHooks/useOnInputChange';
import { highlightError, removeHighlightError, validateFiled } from '../../utils/Validation';
import queryString from 'query-string'

type QueryData = { email: string, token: string, type?: string }

const ResetPassword: FC<RouteComponentProps> = (props) => {
    const {onChange , hookState} = useOnIputChange({ newPassword: '', confirmNewPassword: ''})
    const formData = hookState;
    const history = useHistory();
    const [passwordError, setPasswordError] = useState<boolean>(false)
    const svc = new DataService({ url: 'account/reset-password', omitToken: true })
    const { showLoader, showNotification, currentUser } = useContext(AppContext)
    const [queryData, setQueryData] = useState<QueryData>({ email: '', token: '' })

    useEffect(() => {
        Object.keys(currentUser).length !== 0 && history.push('/dashboard')
    }, [])
    
    useEffect(() => {
        const parsed = queryString.parse(props.location.search) as QueryData;
        (Object.keys(parsed).length === 0) ?
        history.push('/signin') : 
        setQueryData(parsed)
    }, [])

    const validForm = () => {
        let errors: IValidationBody[] = [];

        errors = [...errors,...validateFiled(formData.newPassword as string, 'newPassword', ValidationRules.required)]
        errors = [...errors,...validateFiled(formData.newPassword as string, 'confirmNewPassword', ValidationRules.required)]
        errors = [...errors, ...validateFiled('', `matchPasswords`, ValidationRules.passwordMatch, [formData.newPassword, formData.confirmNewPassword])]

        if (errors.length === 0) {
            removeHighlightError()
            return true
        } else {
            setPasswordError(errors.some(err => err.fieldName.includes('matchPasswords')) ? true : false)
            highlightError([{ fieldName: 'newPassword', errorMessage: '' }, { fieldName: 'confirmNewPassword', errorMessage: '' }])
            return false
        }
    }

    const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        if (validForm()) {
            showLoader(true)
            try {
                const body = {
                    password: formData.newPassword,
                    token: queryData.token.replace(/ /g, '+'),
                    email: queryData.email,
                }
                await svc.create(body)
                history.push('/signin')
                showLoader(false)
                showNotification(NotificationTypes.success, 'Success. Your Password has been changed.')
            } catch (err: any) {
                showNotification(NotificationTypes.danger, err.message)
                showLoader(false)
            }
        }
    }

    return (
        <BaseStartScreen
            title={queryData.type ? 'New Password' : 'Reset password'}
            agreement={''}
            linkTitle={'Sign In'}
            formValid={false}
            link={'/signin'}
            bottomText={'Already have an account?'}
            >
                <div className="form-group ">
                    <label htmlFor="newPassword">New Password</label>
                    <input type="password" name="newPassword" className="form-control" id="newPassword" placeholder="Enter New Password" 
                    autoComplete="nope"
                    value={formData.newPassword}
                        onChange={e => onChange(e)}
                    />
                </div>

                <div className="form-group ">
                    <label htmlFor="confirmNewPassword">Confirm New Password</label>
                    <input type="password" name="confirmNewPassword" className="form-control" id="confirmNewPassword" placeholder="Confirm New Password" 
                    autoComplete="nope"
                    value={formData.confirmNewPassword}
                        onChange={e => onChange(e)}
                    />
                </div>

                {
                    passwordError && 
                    <div className="form-group ml-auto">
                        <small className="text-danger pb-4">New password and confirmation password should match</small>
                    </div>
                }

                <div className="form-group mb-3">
                    <button 
                        type="submit"
                        className="btn btn-aqua-blue w-100 d-block"
                        onClick={e => handleSubmit(e)}
                    >
                        Set New Passowrd</button>
                </div>
        </BaseStartScreen>
    )
}

export default ResetPassword
