import { FC } from 'react';
import { OverlayTrigger, Tooltip, } from 'react-bootstrap'

type Props = {
    bodyText: string
    keyValue?: any
    children: any
}

const CustomTooltip: FC<Props> = ({ bodyText, keyValue, children }) => {
    return (
        <OverlayTrigger
            key={`custom-tooltip-${keyValue ? keyValue : '0'}`}
            placement={'top'}
            delay={{ show: 250, hide: 200 }}
            overlay={
                <Tooltip className="table-info__tooltip" id={`custom-tooltip-${keyValue ? keyValue : '0'}`}>
                    <span dangerouslySetInnerHTML={{ __html: bodyText }} />
                </Tooltip>
            }
        >
            { children }
        </OverlayTrigger>
    )
}

export default CustomTooltip
