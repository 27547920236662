import React, { FC, useContext, useEffect } from 'react'
import { IGuardRequested } from '../../../common/interfaces/guards/IGuard'
import { useTableList } from '../../../customHooks/useTableList'
import useActivateDeactivate from '../../../customHooks/useActivateDeactivate'
import { SVGApprove, SVGCancel } from '../../../assets/icons/SvgIcons'
import DataTable from '../../../components/tables/DataTable'
import { manageDeactivateGuardsContent } from '../../../content/administration/AdministrationContent'
import { momentDateFormat } from '../../../utils/DateFormatting'
import BaseModal from '../../../components/modal/BaseModal'
import { ActivationDeactivation } from '../../../common/enums/Actions'
import { VocabularyEnum } from '../../../common/enums/VocabularyEnum'
import { useVocabulary } from '../../../customHooks/vocabulary/useVocabulary'
import { matchOptionWithName } from '../../../utils/MatchOptionWithName'
import { IBaseRequestToDeactivationProps } from '../../../common/interfaces/IBaseActivationDeactivation'
import { Link } from 'react-router-dom'
import { AuthService } from '../../../common/auth/AuthService'
import { Roles } from '../../../common/enums/Roles'
import AppContext from '../../../context/AppContext'

interface IProps extends IBaseRequestToDeactivationProps { }

const RequestsToDeactivateGuards: FC<IProps> = ({ hideTbodyCols, hideTheadCols, pageSize, onSuccess, reload }) => {
    const { tableData, isLoading, setTableData, handleColClick, fetchData } = useTableList<IGuardRequested>('guard/requested', pageSize ? pageSize : 0)
    const { onRequestHandler, onRequestSubmitHandler, requestModals, modalText, onCloseRequestModals, itemInFocus, onSuccessResponse } = useActivateDeactivate<IGuardRequested>('guard');
    const { vocabulary: deactivationReasons } = useVocabulary(VocabularyEnum.guardDeactivation, true)

    useEffect(() => {
        reload && fetchData(1, pageSize ? pageSize : 0, 'guard/requested')
    }, [reload])

    const { currentUser } = useContext(AppContext)
    const itemsIsVisible = AuthService.checkIfUserHasPermission([Roles.Admin], currentUser.role)

    return (
        <>
            {
                React.useMemo(() => (
                    <DataTable
                        thead={manageDeactivateGuardsContent.thead}
                        tbody={tableData ?
                            tableData.map(guard => ({
                                id: guard.id,
                                guardNameLink: `<a href="#/guards/${guard.id}/details">${guard.guardName}</a>`,
                                statusRequestDate: guard.statusRequestDate ? momentDateFormat(guard.statusRequestDate) : '-',
                                statusRequestedBy: guard.statusRequestedBy || '-',
                                assignedJobs: guard.assignedJobs.length !== 0 ? guard.assignedJobs.map((job, i, arr) => <Link to={`/jobs/${job.jobId}/details`}>{job.jobName.trim()}{i !== arr.length - 1 && ', '}</Link>) : [],
                                statusChangeReason: guard.statusChangeReason ? matchOptionWithName(guard.statusChangeReason, deactivationReasons) : '-',
                            }))
                            : []}
                        ignoreCols={[...[0], ...hideTbodyCols || []]}
                        ignoreTheadCols={hideTheadCols}
                        isLoading={isLoading}
                        tableClass={'table-info--notFixed'}
                    // onColClick={(key, trIndx) => handleColClick(key, trIndx, 'guardNameLink', 'guards')}
                    >
                        {
                            (_id, rowItem: IGuardRequested) => (
                                <>
                                    <td className="aling-middle">
                                        <div>
                                            <button
                                                className={`btn ${itemsIsVisible ? 'btn-aqua-blue' : 'btn-disabled'}`}
                                                onClick={() => itemsIsVisible && onRequestHandler(
                                                    {
                                                        title: `Are you sure you want to deactivate  <span class="font-weight-bold">${rowItem.guardNameLink}</span>?
                                                            <br />
                                                            ${rowItem.assignedJobs.length !== 0 ? '<small class="text-danger">This guard is currently assigned to job(s)</small>' : ''}
                                                        `,
                                                        itemInFocus: rowItem
                                                    }
                                                )}
                                            >
                                                <SVGApprove />
                                            </button>
                                            <button
                                                className={`btn ${itemsIsVisible ? 'btn-aqua-blue' : 'btn-disabled'} ml-2`}
                                                onClick={() => itemsIsVisible && onRequestHandler(
                                                    {
                                                        title: `Are you sure you want to decline the deactivation request for <span class="font-weight-bold">${rowItem.guardNameLink}</span>?`,
                                                        itemInFocus: rowItem,
                                                        type: ActivationDeactivation.disapprove
                                                    }
                                                )}
                                            >
                                                <SVGCancel />
                                            </button>
                                        </div>
                                    </td>
                                </>
                            )
                        }
                    </DataTable>
                ), [tableData, isLoading, deactivationReasons])
            }

            {
                (requestModals.showRequest || requestModals.showDissaprove) && <BaseModal
                    show={requestModals.showRequest || requestModals.showDissaprove}
                    onCancel={() => onCloseRequestModals()}
                    onSubmit={() => onRequestSubmitHandler(requestModals.showRequest ? ActivationDeactivation.approve : ActivationDeactivation.disapprove,
                        { itemData: null, successCallback: id => { onSuccess && onSuccess(); setTableData(onSuccessResponse<IGuardRequested>(id, tableData)) } },
                        requestModals.showRequest ?
                            `<span class="font-weight-bold">${itemInFocus.guardNameLink}</span> has been deactivated.
                        An automatic message has been sent to the guard and affected shifts have been added to the list of shifts.<br>
                        <a class="text-aqua-blue text-center d-block pt-2" href="#/jobs/unassigned">Shifts in Need of Guards</a>` :
                            `<span class="font-weight-bold">${itemInFocus.guardNameLink}</span> has not been deactivated.`
                    )}
                    submitBtnText={"Confirm"}
                >
                    <h4 className="text-center" dangerouslySetInnerHTML={{ __html: modalText.title }} />
                </BaseModal>
            }

            {
                requestModals.showSuccess && <BaseModal
                    show={requestModals.showSuccess}
                    onCancel={() => onCloseRequestModals()}
                    cancelBtnText={'Close'}
                >
                    <h4 className="text-center" dangerouslySetInnerHTML={{ __html: modalText.title }} />
                </BaseModal>
            }
        </>
    )
}

export default RequestsToDeactivateGuards
