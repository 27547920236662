import React, {useContext, useEffect, useMemo, useState} from 'react'
import {SVGPencil} from '../../../assets/icons/SvgIcons'
import {NotificationTypes} from '../../../common/interfaces/INotification'
import {DataService} from '../../../common/services/DataService'
import Layout from '../../../components/layout/Layout'
import DataTable from '../../../components/tables/DataTable'
import SectionTitle from '../../../components/titles/SectionTitle'

import {useTableList} from '../../../customHooks/useTableList'
import AppContext from '../../../context/AppContext'
import AddEditSchoolYearModal from "../../../components/modal/administration/AddEditSchoolYearModal";
import {ISchoolYear} from "../../../common/interfaces/administration/ISchoolYear";
import ResourceDataService from "../../../common/services/ResourceDataService";
import {IClientDropdown} from "../../../common/interfaces/dropdowns/IClientDropDown";
import {momentDateFormat} from '../../../utils/DateFormatting'
import {manageSchoolYear} from "../../../content/administration/AdministrationContent";

const ManageSchoolYears = () => {
    const {tableData, isLoading, onSortCall, fetchData} = useTableList<ISchoolYear>('schoolyear')

    const {showLoader, showNotification} = useContext(AppContext)
    const schoolYearSvc = new DataService<ISchoolYear>({url: 'schoolyear'})
    const [yearsModals, setYearsModals] = useState<{ show: boolean, isEditMode: boolean, showEditSuccess: boolean, itemInFocus: ISchoolYear }>({
        show: false,
        isEditMode: false,
        showEditSuccess: false,
        itemInFocus: {} as ISchoolYear
    });
    const [clients, setClients] = useState<IClientDropdown[]>([] as IClientDropdown[]);
    const appContext = useContext(AppContext)


    useEffect(() => {
        getOptionsForDropdowns();
    }, [])

    const getOptionsForDropdowns = useMemo(() =>
            async () => {
                const clientSvc = new ResourceDataService<IClientDropdown[]>({url: "client/dropdown"})
                try {
                    const resClients = await clientSvc.getAll();
                    setClients(resClients.data)
                } catch (e: any) {
                    appContext.showNotification(NotificationTypes.danger, e.message)
                }
            }
        , [])
    const onUserEditSubmit = async (newSchoolYear: ISchoolYear) => {
        appContext.showLoader(true)
        try {
            await schoolYearSvc.update(newSchoolYear, newSchoolYear.id)
            fetchData(0, 0)
            showLoader(false)
            showNotification(NotificationTypes.success, `School year has been updated`)
            await getOptionsForDropdowns();
            setYearsModals({
                ...yearsModals,
                show: false
            })
        } catch (error: any) {
            appContext.showNotification(NotificationTypes.danger, error.message)
            appContext.showLoader(false)
        }
    }

    const saveNewSchoolYear = async (newSchoolYear: ISchoolYear) => {
        showLoader(true)
        try {
            await schoolYearSvc.create(newSchoolYear)
            fetchData(0, 0)
            showLoader(false)
            showNotification(NotificationTypes.success, `School year has been added`)
            await getOptionsForDropdowns();
            setYearsModals({
                ...yearsModals,
                show: false
            })
        } catch (error: any) {
            showLoader(false)
            showNotification(NotificationTypes.danger, error.message)
        }
    }

    const onAddYearClick = () => {
        setYearsModals({
            ...yearsModals,
            show: true,
            isEditMode: false
        })
    }

    const onEditYearClick = (schoolYear: ISchoolYear, type?: string) => {
        if (type === 'edit') {
            setYearsModals({
                ...yearsModals,
                show: true,
                isEditMode: true,
                itemInFocus: schoolYear
            })
        }
    }

    return (
        <Layout
            breadcrumbs={{
                links: [{
                    title: 'Administration',
                    link: 'administration'
                }],
                currentPageTitle: 'Manage School Years'
            }}
        >
            <div className="row mb-4">
                <div className="col-12 d-flex flex-row align-items-center flex-wrap justify-content-between">
                    <SectionTitle title={`Manage School Years`}/>
                    <button type="button" className="btn btn-aqua-blue ml-0 ml-sm-3 mt-2 mt-sm-0"
                            onClick={() => onAddYearClick()}>
                        Add a School Year
                    </button>
                </div>
            </div>
            <div className="row">
                <div className="col-12">

                    {
                        React.useMemo(() => (
                            <DataTable
                                thead={manageSchoolYear.thead}
                                tbody={tableData ?
                                    tableData.map(item => ({
                                        id: item.id,
                                        clientName: item.clientName,
                                        schoolYear: `${momentDateFormat(item.startDate)} - ${momentDateFormat(item.endDate)} `,
                                        startDate: item.startDate,
                                        endDate: item.endDate,
                                        clientId: item.clientId
                                    }))
                                    : []}
                                ignoreCols={[0, 5]}
                                isLoading={isLoading}
                                tableClass={'table-info--notFixed'}
                                onSort={(orderBy, desc) => onSortCall(orderBy, desc)}
                                defaultSortedColumn={1}
                                sortOnBackend={true}
                            >
                                {
                                    (_id, rowItem) => (
                                        <td className="aling-middle">
                                            <div>
                                                <button
                                                    className={`btn btn-aqua-blue`}
                                                    onClick={() => onEditYearClick(rowItem, 'edit')}
                                                >
                                                    <SVGPencil/>
                                                </button>
                                            </div>
                                        </td>
                                    )
                                }
                            </DataTable>
                        ), [tableData, isLoading])
                    }
                </div>
            </div>

            {yearsModals.show &&
            <AddEditSchoolYearModal
                onCancel={() => setYearsModals({
                    ...yearsModals,
                    show: false
                })}
                onSubmit={(newSchoolYear: ISchoolYear) => yearsModals.isEditMode ? onUserEditSubmit(newSchoolYear) : saveNewSchoolYear(newSchoolYear)}
                titleBody={yearsModals.isEditMode ? 'Edit School Year' : 'Add a School Year'}
                selectedSchoolYear={yearsModals.itemInFocus ? yearsModals.itemInFocus : undefined}
                isEditMode={yearsModals.isEditMode}
                clients={clients}
            />
            }


        </Layout>
    )
}

export default ManageSchoolYears
