import { TheadData } from "components/tables/DataTable"
import { SortDataTypesEnum } from "../../customHooks/useSortableData"

// Request to Deactivate Jobs
export const deactivateJobsContent = {
    thead: [
        {
            label: "Job Name",
            // sortable: true,
            dataType: "string"
        },
        {
            label: "Request Date",
            // sortable: true,
            dataType: "Date"
        },
        {
            label: "Approve",
        },
    ],
    tbody: [
        {
            job: "Marion Manor",
            date: "12 January 2020",
        },
        {
            job: "Millbrook Moor",
            date: "15 July 2020",
        },
    ]
}

// Guards Not On Time or Absent
export const guardsNotOnTimeContent = {
    thead: [
        {
            label: "Job Name",
            sortable: true,
            dataType: SortDataTypesEnum.string
        },
        {
            label: "Guard Name",
            sortable: true,
            dataType: SortDataTypesEnum.string
        },
        {
            label: "Times",
            sortable: true,
            dataType: SortDataTypesEnum.string
        },
        {
            label: "Shift Period",
            sortable: true,
            dataType: SortDataTypesEnum.string
        },
        {
            label: "Status",
            sortable: true,
            dataType: SortDataTypesEnum.status,
            tooltip: true,
            tooltipText: "Minutes here are based on ASP Start Time."
        },
        {
            label: "Comment",
            sortable: true,
            dataType: SortDataTypesEnum.string
        },
        {
            label: "Edit Comment",
            invisible: true,
        }
    ],
    tbody: [
        {
            name: "Freddy Mercury",
            date: "12 October 2020",
            shifTime: "00:00AM - 00:00 PM",
            shift: "Morning",
            status: "Absent"
        },
        {
            name: "Geddy Lee",
            date: "1 October 2020",
            shifTime: "00:00AM - 00:00 PM",
            shift: "Afternoon",
            status: "Late 10 minutes"
        }
    ]
}

// Jobs Active Right Now
export const jobsActiveNowContent = {
    thead: [
        {
            label: "Job Location",
            dataType: SortDataTypesEnum.string,
            sortable: true
        },
        {
            label: "School Board",
            dataType: SortDataTypesEnum.string,
            sortable: true
        },
        {
            label: "School",
            dataType: SortDataTypesEnum.string,
            sortable: true
        },
        {
            label: "Zone",
            dataType: SortDataTypesEnum.string,
            sortable: true
        },
        {
            label: "Guard Name",
            sortable: true
        },
        {
            label: "ASP Start <br>Time",
            dataType: SortDataTypesEnum.time,
            sortable: true
        },
        {
            label: "Shift Time",
        },
        {
            label: "Shift Status",
        },
        {
            label: "Late/Absence-<br />Last 30 days",
        },
        {
            label: "",
        },
    ],
    tbody: [
        {
            location: "Mariupol",
            schoolboard: "Schoolboard",
            school: "Prince Passsage & Clearance Route",
            zone: "West",
            guard: "Ciano Vilareyo",
            startTime: "01:00PM",
            shift: "01:00PM - 02:00 PM",
        },
        {
            location: "Alchevsk",
            schoolboard: "Schoolboard",
            school: "Prince Passsage & Clearance Route",
            zone: "West",
            guard: "Ciano Vilareyo",
            startTime: "01:00PM",
            shift: "01:00PM - 02:00 PM",
        },
        {
            location: "Yalta",
            schoolboard: "Schoolboard",
            school: "Prince Passsage & Clearance Route",
            zone: "West",
            guard: "Ciano Vilareyo",
            startTime: "05:00PM",
            shift: "05:00PM - 08:00 PM",
        },
        {
            location: "Ternopil",
            schoolboard: "Schoolboard",
            school: "Prince Passsage & Clearance Route",
            zone: "North",
            guard: "Ciano Vilareyo",
            startTime: "02:00PM",
            shift: "02:00PM - 03:00 PM",
        },
        {
            location: "Dnipro",
            schoolboard: "Schoolboard",
            school: "Prince Passsage & Clearance Route",
            zone: "East",
            guard: "Ciano Vilareyo",
            startTime: "09:00PM",
            shift: "09:00PM - 10:00 PM",
        }
    ]
}

// For Today
export const forTodayContent = {
    thead: [
        {
            label: "Job Name",
            dataType: "string",
            // sortable: true
        },
        {
            label: "Date",
            dataType: "date",
            // sortable: true
        },
        {
            label: "Holiday",
            dataType: "string",
            // sortable: true
        },
        {
            label: "Shift",
            dataType: "date",
            // sortable: true
        },
        {
            label: "Shift",
            dataType: "string",
            // sortable: true
        },
        {
            label: "Add Guard",
        },
    ],
    tbody: [
        {
            job: "Rock Hill",
            date: "2 October 2020",
            holiday: "Civic Holiday",
            shiftTime: "00:00 AM - 00:00 PM",
            shift: "Morning"
        },
        {
            job: "St Stephen's Moor",
            date: "1 October 2020",
            holiday: "Civic Holiday",
            shiftTime: "00:00 AM - 00:00 PM",
            shift: "Afternoon"
        },
        {
            job: "Bracey Rise",
            date: "19 October 2020",
            holiday: "Civic Holiday",
            shiftTime: "00:00 AM - 00:00 PM",
            shift: "Afternoon"
        }
    ]
}

export const activeGuardsListContent = {
    thead: [
      {
        label: "Employee ID",
        fieldName: 'employeeId',
        sortable: true,
    },
    {
        label: "Full Name",
        fieldName: 'fullNameLink',
        sortable: true,
    },
    {
        label: "Mobile Number",
        fieldName: 'personalMobile',
        sortable: true,
    },
    {
        label: "ASP Start Date",
        fieldName: 'aspStartDate',
        sortable: true,
    },
    {
        label: "Assigned <br />Schedules",
        fieldName: 'assignedSchedules',
        sortable: true,
    },
    {
        label: "Late/Absence-<br />Last 30 days",
        fieldName: 'latesOrAbsences',
        sortable: true,
    },
    {
        label: "Last Check-In",
        fieldName: 'lastCheckIn',
        sortable: true,
    },
  ],
}

export const bookedLeaveContent = {
    thead: [
        {
            label: "Leave Start Date",
            dataType: SortDataTypesEnum.date,
            sortable: true,
        },
        {
            label: "Leave End Date",
            dataType: SortDataTypesEnum.date,
            sortable: true,
        },
        {
            label: "Shift Period(s)",
            sortable: true,
        },
        {
            label: "Reason",
            dataType: SortDataTypesEnum.string,
            sortable: true,
        },
        {
			label: 'Date Request <br> Received',
            dataType: SortDataTypesEnum.date,
			sortable: true,
		},
        {
			label: 'Jobs Affected',
			sortable: true,
		},
		{
			label: 'Status',
			sortable: true,
		},
		{
			label: 'Date of Last <br> Status Update',
			sortable: true,
		},
		{
			label: 'Approve',
		},
    ] as TheadData[],
}

export const availablilityScheduleContent = {
  thead: [
      {label: "Start and End Dates"},
      {label: "Start and End Times"},
      {label: "Repeat"},
      {label: "Action"},
  ],
}

export const leaveRequestsPendingContent = {
	thead: [
		{
			label: 'Date Request <br> Received',
			sortable: true,
		},
		{
			label: 'Guard Name',
			sortable: true,
		},
		{
			label: 'Employee <br> ID',
			sortable: true,
		},
		{
			label: 'Leave Start Date',
			sortable: true,
		},
		{
			label: 'Leave End Date',
			sortable: true,
		},
		{
			label: 'Shift Period(s)',
			sortable: true,
		},
		{
			label: 'Reason',
			sortable: true,
		},
		{
			label: 'Jobs Affected',
			sortable: true,
		},
		{
			label: 'Status',
			sortable: true,
		},
		{
			label: 'Date of Last <br> Status Update',
			sortable: true,
		},
		{
			label: 'Approve',
		},
	],
};

export const assignedSchedulesContent = {
    thead: [
        {
            label: "Job Name",
        },
        {
            label: "Start and End Dates",
        },
        {
            label: "ASP Start time",
        },
        {
            label: "Start and End Times",
        },
        {
            label: "Repeat",
        },
        {
            label: "Unassign Guard from Job Schedule"
        },
    ],
}

export const guardReportContet = {
    thead: [
        {
            label: "Date",
            // sortable: true,
        },
        {
            label: "Shift",
            // sortable: true,
        },
        {
            label: "Job Name",
        },
        {
            label: "Status",
        },
        {
            label: "",
        }
    ],
    tbody: [
        {
            date: "dd month yyyy",
            shift: "00:00 AM - 00:00 PM",
            status: "Late 5 minutes"
        },
        {
            date: "dd month yyyy",
            shift: "00:00 AM - 00:00 PM",
            status: "Absent"
        },
        {
            date: "dd month yyyy",
            shift: "00:00 AM - 00:00 PM",
            status: "Late 10 minutes"
        },
        {
            date: "dd month yyyy",
            shift: "00:00 AM - 00:00 PM",
            status: "Late 30 minutes"
        }
    ]
}

export const availableSchedulesContet = {
    thead: [
        {
            label: "Job Name",
            sortable: true
        },
        {
            label: "Distance",
            sortable: true
        },
        {
            label: "Start and End Date",
            sortable: true
        },
        {
          label: "ASP Start Time",
          sortable: true
        },
        {
            label: "Start and End Times",
            sortable: true
        },
        {
            label: "Shift Period",
            sortable: true
        },
        {
            label: "Repeat",
        },
        {
            label: "Assign",
        }
    ],
    tbody: [
        {
            id: 1,
            jobName: "Street and Street",
            distance: 0,
            startDate: new Date(),
            endDate: new Date(),
            timeForm: new Date(),
            timeTo: new Date(),
            repeatOn: '1;2;3'
        },
    ]
}

export const availableJobShiftsContet = {
    thead: [
        {
            label: "Job Name",
            sortable: true
        },
        {
            label: "Distance",
            sortable: true
        },
        {
            label: "Date",
            sortable: true
        },
        {
            label: "ASP Start Time",
            sortable: true
        },
        {
            label: "Start and End Times",
            sortable: true
        },
        {
            label: "Shift Period",
            sortable: true
        },
        {
            label: "Assign",
        },
        {
            label: "",
        }
    ],
    tbody: [
        {
            jobName: "Street and Street",
            date: "dd month yyyy",
            startTime: "00:00 AM",
            endTime: "00:00 PM"
        },
        {
            jobName: "Street and Street",
            date: "dd month yyyy",
            startTime: "00:00 AM",
            endTime: "00:00 PM"
        },
        {
            jobName: "Street and Street",
            date: "dd month yyyy",
            startTime: "00:00 AM",
            endTime: "00:00 PM"
        },
        {
            jobName: "Street and Street",
            date: "dd month yyyy",
            startTime: "00:00 AM",
            endTime: "00:00 PM"
        }
    ]
}

export const assignedShiftsContent = {
    thead: [
        {
            label: "Job Name",
        },
        {
            label: "Date",
        },
        {
            label: "Start Time",
        },
        {
            label: "End Time",
        },
        {
            label: "Unassign Guard from Shift",
        },
        {
            label: "",
        }
    ]
}

// export const unAssignedJobShiftsContent = {
//     thead: [
//         { label: "Job Name", sortable: true, dataType: SortDataTypesEnum.string },
//         { label: "Date", sortable: true, dataType: SortDataTypesEnum.date },
//         { label: "Reason", sortable: true, dataType: SortDataTypesEnum.string },
//         { label: "Shift Times" },
//         { label: "Shift Period", sortable: true, dataType: SortDataTypesEnum.string },
//         { label: "Assign Guard" },
//         { label: "Bulk Action", invisible: AuthService.checkIfUserHasPermission([Roles.ASP, Roles.Admin], appContext.currentUser.role) }
//     ]
// }

export const editJobsShiftsContent = {
    thead: [
        {label: "Job Name",sortable: true, dataType: SortDataTypesEnum.string},
        {label: "Assigned Guard",sortable: true, dataType: SortDataTypesEnum.string},
        {label: "Shift Date",sortable: true, dataType: SortDataTypesEnum.date},
        {label: "ASP Start Time",sortable: true, dataType: SortDataTypesEnum.time},
        {label: "Start Time",sortable: true, dataType: SortDataTypesEnum.time},
        {label: "End Time",sortable: true, dataType: SortDataTypesEnum.time},
        {label: "Shift Period",sortable: true, dataType: SortDataTypesEnum.string},
        {label: "Edit Shift"},
        {label: "Unassign Guard <br /> from Shift"},
        {label: "Replace <br />Guard"},
        {label: "Bulk Action"},
        {label: ""}
    ]
}

export const jobShiftsReport = {
    thead: [
        {label: "Edit<br>Shift"},
        {label: "Job ID"},
        {label: "Job Name",sortable: true, fieldName: 'jobName', dataType: SortDataTypesEnum.string},
        {label: "Date",sortable: true, fieldName: 'date', dataType: SortDataTypesEnum.date},
        {label: "Shift Period",sortable: true, fieldName: 'shiftPeriod', dataType: SortDataTypesEnum.string},
        {label: "ASP Start Time",sortable: true, fieldName: 'aspStartTime', dataType: SortDataTypesEnum.time},
        {label: "Shift Start Time",sortable: true, fieldName: 'timeFrom', dataType: SortDataTypesEnum.time},
        {label: "Shift End Time",sortable: true, fieldName: 'timeTo', dataType: SortDataTypesEnum.time},
        {label: "Zone"},
        {label: "School Board",sortable: true, fieldName: 'schoolBoard', dataType: SortDataTypesEnum.string},
        {label: "Holiday"},
        {label: "Guard Employee #",sortable: true, fieldName: 'guardEmployeeNumber', dataType: SortDataTypesEnum.string},
        {label: "Guard Name",sortable: true, fieldName: 'guardName', dataType: SortDataTypesEnum.string},
        {label: "Guard Status",},
        {label: "Check-In Status",sortable: true, fieldName: 'checkInStatus', dataType: SortDataTypesEnum.string},
        {label: "Check-In Method",sortable: true, fieldName: 'checkInType', dataType: SortDataTypesEnum.string},
        {label: "Check-In<br>Time",},
        {label: "Minutes<br>Late from<br>ASP Time",},
        {label: "Check-Out Status",sortable: true, fieldName: 'checkOutStatus', dataType: SortDataTypesEnum.string},
        {label: "Check-Out Method",sortable: true, fieldName: 'checkOutType', dataType: SortDataTypesEnum.string},
        {label: "Check-Out Time",},
        {label: "Absent<br>Type",},
        {label: "Check-Out Latitude",},
        {label: "Check-Out Longitude",},
        {label: "Distance<br>at<br>Checkout",},
        {label: "Geofence<br>(m)"},
        {label: "ASP Mobile",},
        {label: "App<br>Version", sortable: true, fieldName: 'version', dataType: SortDataTypesEnum.string},
        {label: "App<br>Build", sortable: true, fieldName: 'build', dataType: SortDataTypesEnum.string},
        {label: "Device OS",  sortable: true, fieldName: 'operationSystem', dataType: SortDataTypesEnum.string},
        {label: "Shift Update Comment" },
        {label: "Last Client Comment" },
    ]
}
export const guardLocationReport = {
    thead: [
        {label: "Guard Name"},
        {label: "Employee Id"},
        {label: "Job Name"},
        {label: "Job Id"},
        {label: "Date"},
        {label: "Time"},
        {label: "Distance from Job"},
        {label: "Check-Out Latitude"},
        {label: "Check-Out Longitude"},
    ]
}

export const jobDetailsShiftReport = {
    thead: [
        { label: "Edit<br />Shift" },
        { label: "Guard Name", sortable: true },
        { label: "Shift Date", sortable: true },
        { label: "ASP <br />Start Time", sortable: true },
        { label: "Shift <br />Start Time", sortable: true },
        { label: "Shift <br /> End Time", sortable: true },
        { label: "Shift Period", sortable: true },
        { label: "Late/Absent", sortable: true },
        { label: "Check-In Time", sortable: true},
        { label: "Check-Out Time", sortable: true},
        { label: "Shift Update<br />Comment"},
        { label: "" }
    ]
}

export const guardDetailsShiftReport = {
    thead: [
        { label: "Job Name", sortable: true },
        { label: "Shift Date", sortable: true },
        { label: "ASP Start Time", sortable: true },
        { label: "Shift Start Time", sortable: true },
        { label: "Shift End Time", sortable: true },
        { label: "Shift Period", sortable: true },
        { label: "Check-In Time", sortable: true },
        { label: "Check-Out Time", sortable: true },
        { label: "Late/Absent", sortable: true },
        { label: "" },
    ]
}

export const addShiftToScheduleThead = {
    thead: [
        { label: "Shift Date", sortable: true },
        { label: "Assigned Guard", sortable: true },
        { label: "ASP Start Time", sortable: true },
        { label: "Start Time", sortable: true },
        { label: "End Time", sortable: true },
        { label: "Shift Period", sortable: true },
        { label: "Add Shift" },
        { label: "" },
    ]
}

export const viewShiftsOfScheduleThead = {
    thead: [
        { label: "Shift Date", sortable: true },
        { label: "Assigned Guard", sortable: true },
        { label: "ASP Start Time", sortable: true },
        { label: "Start Time", sortable: true },
        { label: "End Time", sortable: true },
        { label: "Shift Period", sortable: true },
        { label: "Action" },
        { label: "" },
    ]
}
