import firebase from 'firebase/app';
import 'firebase/messaging';

let msg = null;

const fbConfig = {
    apiKey: "AIzaSyCT-yqIYxjV1ZQ1PgLuUi78H9CgXfP87M0",
    authDomain: "xguard-app-6761a.firebaseapp.com",
    projectId: "xguard-app-6761a",
    storageBucket: "xguard-app-6761a.appspot.com",
    messagingSenderId: "702646271673",
    appId: "1:702646271673:web:620441a632640f8309a568"
};

firebase.initializeApp(fbConfig);

if (firebase.messaging.isSupported()) {
    msg = firebase.messaging();
}

export const messaging = msg;