import React, { FC, useState } from 'react'

import BaseModal from '../../BaseModal'
import WysiwygEditor from '../../../wysiwyg/WysiwygEditor'

import { IBaseModalProps } from '../../../../common/interfaces/IModal'

import { convertToHtml, createContentStateFromHtml } from '../../../../utils/Converting'
import { IBaseNotification } from '../../../../common/interfaces/administration/INotificaion';
import { EditorState } from 'draft-js';
import { IValidationBody, ValidationRules } from '../../../../common/interfaces/IValidation'
import { validateFiled, highlightError, removeHighlightError } from '../../../../utils/Validation'

interface Props extends IBaseModalProps {
    notification: IBaseNotification
    showRecipient: boolean
}

const EditNotificationModal: FC<Props> = ({ onCancel, onSubmit, title, notification, showRecipient }): JSX.Element => {
    const [state, setState] = useState<IBaseNotification>({
        ...notification,
        message: EditorState.createWithContent(createContentStateFromHtml(notification.message as string))
    })

    const onChangeHandler = (value: string | EditorState, key: string) => {
        setState({
            ...state,
            [key]: value
        })
    }

    const formIsValid = (skipFields: string[]) => {
        let errors: IValidationBody[] = [];

        for (let [field, value] of Object.entries(state)) {
            if (!skipFields.includes(field)) {
                errors = [...errors, ...validateFiled(value as string, field, ValidationRules.required)]
            }
        }
        (convertToHtml(state.message as EditorState) as string).trim() === '<p></p>' && (errors = [...errors, ...[{ fieldName: 'content-editor__wrapper', errorMessage: '' }]])
        if (errors.length !== 0) {
            highlightError(errors)
            return false
        }
        else {
            removeHighlightError()
            return true
        }
    }

    const onSaveDataClick = () => {
        if (formIsValid([showRecipient ? "" : 'recipient'])) {
            onSubmit && onSubmit({
                ...state,
                message: convertToHtml(state.message as EditorState)
            })
        }
    }

    return (
        <BaseModal
            show={true}
            onCancel={() => onCancel()}
            onSubmit={() => onSaveDataClick()}
            submitBtnText={'Save'}
            title={title}
            className={"modal-notifications-editor"}
        >
            <form>
                { showRecipient && 
                <div className="form-group">
                    <label className="required mb-0" htmlFor="subject">Recipient email address:</label>
                    <small className="d-block mb-2">To add more than one email address, separate with a semi-colon <span className="font-weight-bold">";"</span></small>
                    <input 
                        type="email"
                        className="form-control"
                        placeholder="Notification recipient email address"
                        value={state.recipient || ""}
                        name="recipient"
                        onChange={ev => onChangeHandler(ev.target.value, 'recipient')}
                    />
                </div>
                }
                <div className="form-group">
                    <label className="required" htmlFor="subject">Subject header:</label>
                    <input 
                        type="text"
                        className="form-control"
                        placeholder="Enter notification subject header"
                        name="subject"
                        value={state.subject}
                        onChange={ev => onChangeHandler(ev.target.value, 'subject')}
                    />
                </div>
                { state.message && <div className="form-group d-flex flex-column align-items-start">
                    <label className="required mb-0" htmlFor="subject">Notification body:</label>
                    <small className="mb-2">Add links in the format www.domain.com, without "https"</small>
                    <WysiwygEditor
                        value={state.message as EditorState}
                        onChange={value => onChangeHandler(value, 'message')}
                    />
                </div>}
            </form>
        </BaseModal>
    )
}

export default EditNotificationModal