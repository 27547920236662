import React, { useContext } from 'react'
import { SVGApprove } from '../../../assets/icons/SvgIcons'
import { deactivatedUsersContent } from '../../../content/manage-users/DeactivatedUsers'

import { AuthService } from '../../../common/auth/AuthService'
import { ActivationDeactivation } from '../../../common/enums/Actions'
import { Roles } from '../../../common/enums/Roles'
import { IDeactivatedUsers } from '../../../common/interfaces/manage-users/IDeactivatedUser'

import AppContext from '../../../context/AppContext'
import useActivateDeactivate from '../../../customHooks/useActivateDeactivate'
import { useTableList } from '../../../customHooks/useTableList'
import { momentDateFormat } from '../../../utils/DateFormatting'

import Layout from '../../../components/layout/Layout'
import RemoveBaseModal from '../../../components/modal/administration/RemoveBaseModal'
import BaseModal from '../../../components/modal/BaseModal'
import DataTable from '../../../components/tables/DataTable'
import SectionTitle from '../../../components/titles/SectionTitle'
import { Link } from 'react-router-dom'
import { useVocabulary } from '../../../customHooks/vocabulary/useVocabulary'
import { VocabularyEnum } from '../../../common/enums/VocabularyEnum'
import { useMatchArrayOfReasons } from '../../../customHooks/useMatchArrayOfReasons'

const DeactivatedUsers = () => {
    const { currentUser } = useContext(AppContext)
    const { tableData, isLoading, setTableData } = useTableList<IDeactivatedUsers>('user/inactive', 0)
    const { matchUsersReasonIdWithName } = useMatchArrayOfReasons();
    const { onRequestHandler, onRequestSubmitHandler, requestModals, modalText, onCloseRequestModals, itemInFocus, onSuccessResponse } = useActivateDeactivate<IDeactivatedUsers>('user');
    const { vocabulary: clientDeactVocabulary } = useVocabulary(VocabularyEnum.clientDeactivationReason, true)
    const { vocabulary: aspDeactVocabulary } = useVocabulary(VocabularyEnum.aspUserDeactivationReason, true)
    const { vocabulary: adminDeactVocabulary } = useVocabulary(VocabularyEnum.aspAdminDeactivationReason, true)
    const { vocabulary: clientContactVocabulary } = useVocabulary(VocabularyEnum.clientContactDeactivationReason, true)

    return (
        <Layout
        breadcrumbs={{
            links: [{
                title: 'Users',
                link: 'administration/manage-users'
            },
            ],
            currentPageTitle: 'Deactivated Users'
        }}
        >
            <div className="row mb-2 mb-md-4">
                <div className="col-12 mb-4 d-flex flex-row flex-wrap align-items-center justify-content-between">
                    <SectionTitle title="Deactivated Users"/>
                    <Link to="/administration/manage-users" className="btn btn-outline-aqua-blue ml-0 ml-md-3 mt-2 mt-md-0">View active users</Link>
                </div>
            </div>
            <div className="row">

                <div className="col-12">

                {
                    React.useMemo(() => (
                        <DataTable
                            thead={deactivatedUsersContent.thead}
                            tbody={tableData ?
                                tableData.map(user => (
                                    {
                                        id: user.id,
                                        contactName: user.fullName,
                                        fullName: user.fullName,
                                        userType: user.userType,
                                        statusChangeDate: user.statusChangeDate ? momentDateFormat(user.statusChangeDate) : '-',
                                        statusChangeReason: user.statusChangeReason ? matchUsersReasonIdWithName(user.statusChangeReason, clientDeactVocabulary, aspDeactVocabulary, adminDeactVocabulary, clientContactVocabulary) : '-',
                                        statusRequestedBy: user.statusRequestedBy || '-',
                                        statusRequestDate: user.statusRequestDate ? momentDateFormat(user.statusRequestDate) : '-',
                                        statusChangeBy: user.statusChangeBy,
                                    }
                                ))
                                .sort((a, b) => a.contactName.localeCompare(b.contactName))
                                : []}
                            ignoreCols={[0, 2]}
                            isLoading={isLoading}
                            tableClass={'table-info--notFixed'}
                        >
                            {
                                (_id, rowItem: IDeactivatedUsers) => (
                                    <>
                                        <td className="aling-middle">
                                            <div>
                                                <button
                                                    className={`btn ${AuthService.checkIfUserHasPermission([Roles.Admin], currentUser.role) ? 'btn-aqua-blue' : 'btn-disabled'}`}
                                                    onClick={() => {
                                                        AuthService.checkIfUserHasPermission([Roles.Admin], currentUser.role) && onRequestHandler(
                                                        {
                                                            title: `Are you sure you want to reactivate <span class="font-weight-bold">${rowItem.fullName}</span>?`,
                                                            itemInFocus: rowItem,
                                                            type: ActivationDeactivation.activate
                                                        })
                                                    }}
                                                >
                                                    <SVGApprove />
                                                </button>
                                            </div>
                                        </td>
                                    </>
                                )
                            }
                        </DataTable>
                    ), [tableData, isLoading])
                }

                </div>

                {
                requestModals.showRequest &&
                    <RemoveBaseModal
                        onCancel={() => onCloseRequestModals()}
                        onSubmit={() => onRequestSubmitHandler(ActivationDeactivation.activate, { itemData: null, successCallback: id =>  setTableData(onSuccessResponse<IDeactivatedUsers>(id.toString(), tableData)) })}
                        title={modalText.title}
                    >
                    </RemoveBaseModal>
                }

                {
                    requestModals.showSuccess &&
                    <BaseModal
                        show={requestModals.showSuccess}
                        onCancel={() => onCloseRequestModals()}
                        cancelBtnText={"Close"}
                        title={`<span class="font-weight-bold">${itemInFocus.fullName}</span> has been activated`}
                    >
                    </BaseModal>
                }

            </div>
        </Layout>
    )
}

export default DeactivatedUsers
