import React, { useEffect, useMemo, useState } from 'react'
import Layout from '../../components/layout/Layout'
import EditJobChoose from './edit-job/EditJobChoose'
import EditJobShifts from './edit-job/EditJobShifts'
import queryString from 'query-string'
import { useHistory } from "react-router-dom";
import { IClientDropdown } from '../../common/interfaces/dropdowns/IClientDropDown'
import { NotificationTypes } from '../../common/interfaces/INotification'
import ResourceDataService from '../../common/services/ResourceDataService'
import { useContext } from 'react'
import AppContext from '../../context/AppContext'
import { AuthService } from '../../common/auth/AuthService'
import { Roles } from '../../common/enums/Roles'
import EditJobSchedules from "./edit-job/EditJobSchedules";

export type QueryParamsState = { client: string, schoolYear: string, schedules?: string }

const EditJobs = () => {
    const appContext = useContext(AppContext)
    const history = useHistory()
    const [activeScreen, setActiveScreen] = useState<string>("choose")
    const [queryParams, setQueryParams] = useState<QueryParamsState>({} as QueryParamsState)
    const [clients, setClients] = useState<IClientDropdown[]>([] as IClientDropdown[]);

    useEffect(() => {
        setActiveScreen("choose")
        getOptionsForDropdowns();
    }, [])

    useEffect(() => {
        parseQuery()
    }, [history.location.search])

    const parseQuery = () => {
        const parsed = queryString.parse(history.location.search) as QueryParamsState
        if (Object.keys(parsed).length !== 0 && parsed.schedules === 'false') {
            setQueryParams({ client: parsed.client, schoolYear: parsed.schoolYear, schedules: parsed.schedules })
            setActiveScreen('editshifts')
        } else if (parsed.schedules === 'true') {
            setQueryParams({ client: parsed.client, schoolYear: parsed.schoolYear, schedules: parsed.schedules })
            setActiveScreen('editschedules')
        }
        else {
            setActiveScreen("choose")
        }
    }

    const getOptionsForDropdowns = useMemo(() =>
        async () => {
            appContext.showLoader(true)
            const clientSvc = new ResourceDataService<IClientDropdown[]>({ url: "client/dropdown" })
            try {
                const resClients = await clientSvc.getAll();
                setClients(resClients.data)
                appContext.showLoader(false)
            } catch (e: any) {
                appContext.showLoader(false)
                appContext.showNotification(NotificationTypes.danger, e.message)
            }
        }
        , [])

    const renderContent = (activeScreen: string) => {
        switch (activeScreen) {
            case 'choose' :
                return   <EditJobChoose clients={clients} onNext={() => parseQuery()} />
            case 'editshifts' :
                return  <EditJobShifts clients={clients} queryParams={queryParams}/>
            case 'editschedules' :
                return  <EditJobSchedules clients={clients} queryParams={queryParams}/>
            default :
                return null;
        }
    }

    const renderBreadcrumbs = (activeScreen: string) => {
        const baseLinks = [{
            title: 'Jobs',
            link: 'jobs'
        }]
        switch (activeScreen) {
            case 'choose' :
                return  {
                    links: baseLinks,
                    currentPageTitle: 'Edit Job'
                }
            case 'editshifts' :
                if (AuthService.checkIfUserHasPermission([Roles.Admin, Roles.ASP], appContext.currentUser.role)) {
                    return  {
                        links: [...baseLinks, ...[{
                            title: 'Edit Job',
                            link: 'jobs/edit'
                        }]],
                        currentPageTitle: 'Edit Job Shifts'
                    }
                } else  return  {
                    links: baseLinks,
                    currentPageTitle: AuthService.checkIfUserHasPermission([Roles.Admin, Roles.ASP], appContext.currentUser.role) ? 'Edit Job Shifts' : 'Job Shifts'
                }
            case 'editschedules' :
                if (AuthService.checkIfUserHasPermission([Roles.Admin, Roles.ASP], appContext.currentUser.role)) {
                    return  {
                        links: [...baseLinks, ...[{
                            title: 'Edit Job',
                            link: 'jobs/edit'
                        }]],
                        currentPageTitle: 'Edit Job Schedules'
                    }
                } else  return  {
                    links: baseLinks,
                    currentPageTitle: AuthService.checkIfUserHasPermission([Roles.Admin, Roles.ASP], appContext.currentUser.role) ? 'Edit Job Schedules' : 'Job Schedules'
                }

            default :
                return null;
        }
    }

    return (
        <Layout
            breadcrumbs={renderBreadcrumbs(activeScreen)}
        >
            { renderContent(activeScreen) }
        </Layout>
    )
}

export default EditJobs
