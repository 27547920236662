import React, { FC } from 'react'
import { IAvailableGuard } from '../../../common/interfaces/guards/IGuard'
// import { IGuardShift } from '../../../common/interfaces/guards/IShift'
import { IVocabulary } from '../../../common/interfaces/vocabulary/IVocabulary'
import { IJobAssignedSchedules, IJobShiftsInNeedOfGuards, IJobsRightNow } from '../../../common/interfaces/jobs/IJob'
import { momentDateFormat, momentHoursFormat } from '../../../utils/DateFormatting'
import { matchOptionWithName } from '../../../utils/MatchOptionWithName'
import BaseModal from '../BaseModal'
import { matchWeekDays } from '../../../utils/MatchWeekDays'

interface IProps {
    show: boolean
    onClose: () => void
    selectedGuard: IAvailableGuard | string,
    selectedShift: any,
    vocabulary?: IVocabulary[]
    title?: string
    warning?: string
    isSchedule?: boolean
}

const ShiftAssignedSuccessModal: FC<IProps> = ({ show, onClose, selectedGuard, selectedShift, vocabulary, warning, title, isSchedule }) => {

    const guardName = typeof selectedGuard === "string" ? selectedGuard : `${selectedGuard.firstName} ${selectedGuard.middleName} ${selectedGuard.lastName}`
    return (
        <BaseModal
            show={show}
            onCancel={() => onClose()}
            cancelBtnText={"Close"}
        >
            <h4 className="text-left mb-4"
                dangerouslySetInnerHTML={{ __html: title ? title :
                    `You have successfully assigned <span class="font-weight-bold"> ${guardName} </span> to
                    the following schedule: `
                }}
            />
               
            {/* </h4> */}
            <div className="row mb-3">
                <div className="col-md-4 form-group details-information__title mb-0 d-flex align-items-center">
                    <h6>Job Name: </h6>
                </div>
                <div className="col-md-8 form-group mb-0">
                    <p className="mb-0">{ selectedShift.jobName }</p>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-4 form-group details-information__title mb-0 d-flex align-items-center">
                    <h6>{ isSchedule ? 'Dates:' : 'Date:' } </h6>
                </div>
                <div className="col-md-8 form-group mb-0">
                    <p className="mb-0">{ momentDateFormat(selectedShift.startDate) } { (selectedShift as IJobAssignedSchedules).endsOn ? ` - ${momentDateFormat((selectedShift as IJobAssignedSchedules).endsOn)}` : ''} </p>
                </div>
            </div>
            {
                selectedShift.aspStartDate &&
                <div className="row mb-3">
                    <div className="col-md-4 form-group details-information__title mb-0 d-flex align-items-center">
                        <h6>ASP Start Time: </h6>
                    </div>
                    <div className="col-md-8 form-group mb-0">
                        <p className="mb-0">{ momentHoursFormat(selectedShift.aspStartDate)}</p>
                    </div>
                </div>
            }
            <div className="row mb-3">
                <div className="col-md-4 form-group details-information__title mb-0 d-flex align-items-center">
                    <h6>Shift Times: </h6>
                </div>
                <div className="col-md-8 form-group mb-0">
                    <p className="mb-0">{ `${momentHoursFormat(selectedShift.timeFrom)} - ${momentHoursFormat(selectedShift.timeTo)}` }</p>
                </div>
            </div>
            {
                vocabulary && selectedShift.shiftPeriod &&
                <div className="row mb-3">
                    <div className="col-md-4 form-group details-information__title mb-0 d-flex align-items-center">
                        <h6>Shift Period: </h6>
                    </div>
                    <div className="col-md-8 form-group mb-0">
                        <p className="mb-0">{ matchOptionWithName(selectedShift.shiftPeriod, vocabulary) }</p>
                    </div>
                </div>
            }

            {
                selectedShift.repeatOn &&
                <div className="row mb-3">
                    <div className="col-md-4 form-group details-information__title mb-0 d-flex align-items-center">
                        <h6>Repeat: </h6>
                    </div>
                    <div className="col-md-8 form-group mb-0">
                        <p className="mb-0">Every week: { matchWeekDays(selectedShift.repeatOn) }</p>
                    </div>
                </div>
            }


            { selectedShift.replacedGuard && 
                <div className="row mb-3">
                    <div className="col-md-4 form-group details-information__title mb-0 d-flex align-items-center">
                        <h6>Replaced Guard:</h6>
                    </div>
                    <div className="col-md-8 form-group mb-0">
                        <p className="mb-0 font-weight-bold">{ selectedShift.replacedGuard }</p>
                    </div>
                </div>
            }
            
            <div className="row mb-3">
                <div className="col-md-4 form-group details-information__title mb-0 d-flex align-items-center">
                    <h6>New Guard: </h6>
                </div>
                <div className="col-md-8 form-group mb-0">
                    <p className="mb-0 font-weight-bold">{ guardName }</p>
                </div>
            </div>
            <div className="d-flex flex-row align-items-center pt-2">
                <small className="text-danger">
                    { warning ? warning : 'A notification with the details has been sent to the guard.' }
                </small>
            </div>
        </BaseModal>
    )
}

export default ShiftAssignedSuccessModal
