import React, { FC, ReactNode } from 'react'
import { Button } from 'react-bootstrap'
import Modal from 'react-bootstrap/esm/Modal'
import { IBaseModalProps } from '../../common/interfaces/IModal'

interface IProps extends IBaseModalProps {
    children?: ReactNode | Element
    show: boolean
    onSubmit?: (data?: any) => void
    title?: string
    headerClassName?: string,
    cancelBtnText?: string
    submitBtnText?: string
    className?: string
    showCancelBtn?: boolean
    showExitBtn?: boolean
    classNameButton?: string
    bodyClassName?: string
    modalStyle?: { [index: string]: string | number }
    titleClassName?: string;
}

const BaseModal: FC<IProps> = ({ show, onSubmit, onCancel, title, headerClassName, children, cancelBtnText, submitBtnText, className,
    showCancelBtn = true, showExitBtn, classNameButton = '', bodyClassName, modalStyle, titleClassName}) =>
    <Modal show={show} onHide={onCancel} centered className={className ? className : ""} style={modalStyle ? modalStyle : {}}>
        {
            title &&
            <Modal.Header className={headerClassName ? `${headerClassName} border-0` : `border-0`}>
                <Modal.Title dangerouslySetInnerHTML={{ __html: title }} className={titleClassName} />
            </Modal.Header>
        }
        <Modal.Body className={bodyClassName ? bodyClassName : ""}>
            { children && children }
        </Modal.Body>
        {

            !showExitBtn &&
            <Modal.Footer className={`border-0 d-flex ${(onSubmit && !classNameButton) ? "justify-content-between" : "justify-content-start"} ${classNameButton ? classNameButton : ""}`}>
                { showCancelBtn && <Button variant="outline-aqua-blue btn-cancel" type="button" className="footer-modal-button" onClick={onCancel}>{ cancelBtnText ? cancelBtnText : 'Cancel'}</Button> }
                { onSubmit && <Button variant="aqua-blue btn-submit" type="button" className="footer-modal-button" onClick={onSubmit}>{ submitBtnText ? submitBtnText : 'Submit'}</Button> }
            </Modal.Footer>
        }
    </Modal>

export default BaseModal
