import { SVGGuardNotify } from 'assets/icons/SvgIcons'
import { IPushNotificaion } from 'common/interfaces/firebase/IPushNotificaion'
import { IBaseModalProps } from 'common/interfaces/IModal'
import { DataService } from 'common/services/DataService'
import { FC } from 'react'
import { useHistory } from 'react-router-dom'
import BaseModal from '../BaseModal'

interface IProps extends IBaseModalProps {
    message: IPushNotificaion,
    // lastMessage?: IPushNotificaion
    topMargin: number
}

const ContactFormSubmissionModal: FC<IProps> = ({ onCancel, message: { data }, topMargin }) => {
    const history = useHistory();

    const logActivityToReport = async () => {
        const svc = new DataService({ url: 'log/guard-message-from-browser' });
        await svc.create({ guardId: data.guardId })
        return;
    }

    const onGoToMessagesClick = async (ev:  React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        ev.preventDefault();
        await logActivityToReport();
        onCancel();
        history.push(`/reports/guard-messages/${data.messageId}`)
    }

    return (
        <BaseModal
            show
            cancelBtnText={"Close"}
            onCancel={() => onCancel() }
            showExitBtn={true}
            bodyClassName='guard-message--border custom_body'
            modalStyle={{ margin: `${topMargin}px` }}
        >
            <div className="container-main__submission">
                <button type="button" className="close btn-close-modal" aria-label="Close" onClick={onCancel}>
                    <span aria-hidden="true">&times;</span>
                </button>

                <div className="px-3">
                    <div className="row flex-nowrap">
                        <div className="col-auto p-0 mr-3">
                            <SVGGuardNotify />
                        </div>
                        <div className="col-10 p-0 modal-body-content">
                            <div className='modal-body-content_title'>
                                <span className="font-weight-bold">Guard {data.guardName} has sent a message</span>
                            </div>
                            <h5 className="d-flex mb-0 text-center modal-body-content_subtitle mt-4">
                                Go to <a
                                href="!#"
                                className="pl-2"
                                onClick={ev => onGoToMessagesClick(ev)}>
                                    <u>Messages From Guards</u>
                                </a>
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
        </BaseModal>
    )
}

export default ContactFormSubmissionModal