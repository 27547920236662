import { useContext, useEffect, useMemo, useState } from "react"
import { VocabularyEnum } from "../../common/enums/VocabularyEnum"
import { NotificationTypes } from "../../common/interfaces/INotification"
import { IVocabulary } from "../../common/interfaces/vocabulary/IVocabulary"
import { Vocabulary } from "../../common/models/Vocabulary"
import ResourceDataService from "../../common/services/ResourceDataService"
import AppContext from "../../context/AppContext"

export const useVocabulary = (type: VocabularyEnum, fetchOnLoad = false) => {
    const [vocabulary, setVocabulary] = useState<IVocabulary[]>([new Vocabulary()]);

    const svc = new ResourceDataService<IVocabulary[]>({ url: `vocabulary/${type}` })
    const context = useContext(AppContext)

    useEffect(() => {
        fetchOnLoad && getVocabulary()
    }, [])

    const getVocabulary = useMemo(() => 
        async () => {
            try {
                const res = await svc.getAll();
                setVocabulary(res.data)
                return res.data
            } catch (e: any) {
                context.showNotification(NotificationTypes.danger, e.message)
            }
        }
    , [type])

    return { vocabulary, getVocabulary }
}
