import React, { FC } from 'react'
import UserActivityTable from '../../components/administration/UserActivityTable'

import Layout from '../../components/layout/Layout'
import SectionTitle from '../../components/titles/SectionTitle'

const RecentActivity: FC = () => {

    return (
        <Layout
            breadcrumbs={{  links: [{
                title: 'My Profile',
                link: 'account/my-profile'
            }],
            currentPageTitle: 'Recent Activity'
            }}
        >

            <div className="row mb-4">
                <div className="col-12 mb-4 d-flex flex-row align-items-center justify-content-between">
                    <SectionTitle title="Recent Activity"/>
                </div>
            </div>

            <UserActivityTable baseUrl="log?ShowAll=false" allActivity={false} hideFilterByUser={true} />

        </Layout>
    )
}

export default RecentActivity
