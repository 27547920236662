import { useContext, useEffect, useState } from "react";
import { NotificationTypes } from "../common/interfaces/INotification";
import { IQueryParams } from "../common/interfaces/IUrlBuilder";
import ResourceDataService from "../common/services/ResourceDataService";
import AppContext from "../context/AppContext";
import { useParams } from "react-router-dom";
import { DataService } from "../common/services/DataService";

type RouteParams =  { id: string }
type Model = {
    [index: string]: any
}

export function useModelDetails<T extends Model>(resourceUrl?: string | null,  defaultModel?: any, params?: IQueryParams) {

    const urlParams = useParams<RouteParams>()
    const context = useContext(AppContext)
    const [isLoading, setLoading] = useState<boolean>(true)
    const [detailsModel, setDetailsModel] = useState<T>(defaultModel);
    const [userId, ] = useState<string>(params?.id  ? `${params?.id}` : urlParams.id)

    const resourceSvc = new ResourceDataService<T>({ url: resourceUrl ? resourceUrl : ""})

    useEffect(() => {
        (userId && resourceUrl !==null ) && fetchData(+userId)
    }, [])

    const fetchData = async (id?: number) => {
        context.showLoader(true)
        try {
            const res = id !== undefined ? await resourceSvc.query({id: id, ...params}) : await resourceSvc.query({...params})
            setDetailsModel(res.data)
            setLoading(false)
            context.showLoader(false)
        } catch (e: any) {
            setLoading(false)
            context.showLoader(false)
            context.showNotification(NotificationTypes.danger, e.message)
        }
    }

    const onChildArrDelete = async (childName: string, identifier: number, url: string) => {
        const dataSvc = new DataService<T>({ url })

        try {
            setLoading(true)
            const modelCopy= {...detailsModel}
            const modelCopyChild: any[] = (modelCopy[childName] as any[]).filter(el => el.id !== identifier)
            await dataSvc.delete(identifier)
            setDetailsModel({...detailsModel, [childName]: modelCopyChild})
            setLoading(false)
            return {...detailsModel, [childName]: modelCopyChild} as T
        } catch (e: any) {
            context.showNotification(NotificationTypes.danger, e.message)
            setLoading(false)
        }
    }

    return {
        isLoading, detailsModel, userId, onChildArrDelete, setDetailsModel, fetchData
    }
}
