import moment from 'moment'
import { Link } from 'react-router-dom'
import { momentNowCustomFormat } from 'utils/DateFormatting';
import Logo from '../../assets/images/asp-logo-sm.svg'

const Footer = () => {
    const year = momentNowCustomFormat("Y");

    return (
       <footer className="mt-auto">
           <div className="container">
               <div className="row">
                   <div className="footer__brand col-md-4 d-flex flex-sm-row align-items-center justify-content-center justify-content-center justify-content-md-start">
                       <Link to="/dashboard">
                            <img src={Logo} alt="Brand Logo"/>
                       </Link>
                       <p className="mb-0 text-dark-lighter ml-2 ml-md-0">by A.S.P. Security Services </p>
                   </div>
                   <div className="footer__brand col-md-4 text-center mt-2 mt-md-0">
                    <small className="text-dark-lighter ml-2 ml-md-0">Copyright {year} A.S.P. Security Services </small>
                   </div>
                   <div className="footer__external col-md-4 text-right d-flex justify-content-center justify-content-md-end mt-2 mt-md-0">
                        <a href="http://www.security-asp.com" target="_blank" rel="noreferrer">www.security-asp.com</a>
                   </div>
               </div>
           </div>
       </footer>
    )
}

export default Footer
