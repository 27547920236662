import React, { FC, useContext } from 'react'
import { Dropdown } from 'react-bootstrap'
import { SVGMoreDots } from '../../assets/icons/SvgIcons'
import { AuthService } from '../../common/auth/AuthService'
import { Roles } from '../../common/enums/Roles'
import AppContext from '../../context/AppContext'

type Props = {
    onEdit?: () => void
    onDeactivate?: () => void
    onDelete?: () => void
    key: any
}

const ActionDropdown: FC<Props> = ({ onEdit, onDeactivate, onDelete, key }) => {
    const { currentUser } = useContext(AppContext)
    return (
        <Dropdown>
            <Dropdown.Toggle
                id={`dropdown-action-${key}`}
                className={'btn btn-aqua-blue'}
            >
                <SVGMoreDots />
            </Dropdown.Toggle>

           
            <Dropdown.Menu rootCloseEvent="mousedown">
                { onEdit && AuthService.checkIfUserHasPermission([Roles.Admin], currentUser.role) && <Dropdown.Item className="justify-content-start" onClick={e => onEdit()}>Edit</Dropdown.Item> }
                { onDeactivate && <Dropdown.Item className="justify-content-start" onClick={e => onDeactivate()}>
                    {
                        AuthService.checkIfUserHasPermission([Roles.Admin], currentUser.role) ? 'Deactivate' : 'Request to Deactivate'
                    }
                </Dropdown.Item> }
                { onDelete && <Dropdown.Item className="justify-content-start" onClick={e => onDelete()}>
                    {
                        AuthService.checkIfUserHasPermission([Roles.Admin], currentUser.role) ? 'Delete' : 'Request to Delete'
                    }
                </Dropdown.Item> }
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default ActionDropdown
