import { useState } from "react";
import { queryUrlXGuardReports } from "../../helpers/XGuardReportQueryUrl";

interface IBaseFilter {
  [index: string]: string
}

interface IProps{
  baseUrl: string
  defaultParams?: any
  urlAlreadyHasQuery?: boolean
}

const useXGuardReportsQuery = <T extends IBaseFilter>({ baseUrl, defaultParams,
  urlAlreadyHasQuery = false}: IProps) => {
  const [queryParams, setQueryParams] = useState<T>(defaultParams ? defaultParams : {} as T)

  const onQueryParamChanged = (value: string | number | boolean, queryName: string) => {
    setQueryParams({
      ...queryParams, [queryName]: value
    } as T)
  }

  const generateQueryParams = (extraParams?: {[key: string]: string}): string => {
    let query = queryParams ? `${baseUrl}?` : baseUrl;

    query+= queryUrlXGuardReports({...queryParams, ...extraParams});;

    return query.replace('?&', urlAlreadyHasQuery ? '&' : '?');
  }

  return {
    onQueryParamChanged, queryParams, generateQueryParams
  }

}

export default useXGuardReportsQuery;
