import { IGuard, IGuardAvailability } from 'common/interfaces/guards/IGuard';
import { IBaseModalProps } from 'common/interfaces/IModal';
import { Guard } from 'common/models/Guard';
import ScheduleForm from 'components/schedule/ScheduleForm';
import { useCalendarFormV2 } from 'customHooks/useCalendarFormV2';
import { useHolidays } from 'customHooks/useHolidays';
import React, { FC, useEffect, useState } from 'react'
import { objIsEmpty } from 'utils/Validation';
import BaseModal from '../BaseModal';

interface IProps extends IBaseModalProps {
  availabilitySchedule?: IGuardAvailability;
  guardDetails: IGuard,
  onCancel: () => void,
  onAvailabilityFormSubmitted: (availability: IGuardAvailability, actionType: 'edit' | 'add') => void,
  validationError: boolean,
}

const AddEditGuardAvilabilityModal: FC<IProps> = ({
  availabilitySchedule,
  guardDetails,
  onCancel,
  onAvailabilityFormSubmitted,
  validationError,
}) => {

  const { allHolidays, getAllHolidays, prepopulateHolidays } = useHolidays();

  const { setCalendarFormData: setGuardData, onCalendarChange,
    onHolidayChange, onRemoveItemFromCalendar, trackHolidays, holidays,
    calendarFormData: guard
  } = useCalendarFormV2<IGuard>("guardAvailabilities", !objIsEmpty(availabilitySchedule) ? guardDetails : new Guard(), allHolidays)

  const [availability, setAvailability] = useState<IGuardAvailability>({} as IGuardAvailability)

  useEffect(() => {
    getAllHolidays()
  }, [])

  useEffect(() => {
    setAvailability(guard.guardAvailabilities
      .find(item => item.id === (availabilitySchedule ? availabilitySchedule.id as number : 1) || item.uid === 1) || {} as IGuardAvailability)
  }, [guard])

  useEffect(() => {
    if (!objIsEmpty(availabilitySchedule)) {
      const data = prepopulateHolidays(guardDetails,
        allHolidays.map(holiday => {
          holiday.remove = false;
          return holiday
        }), 'guardAvailabilities',
        (schedule, scheduleId) => trackHolidays(schedule, scheduleId)) as IGuard;
      setGuardData(data)
    }
  }, [allHolidays, holidays.current.original])

  const onMainModalSubmitHandler = () => {
    const schedule = {
      ...availability,
      holidays: availability.holidays.filter(holiday => holiday.remove),
      guardId: guardDetails.id
    }

    return schedule;
  }

  return (
    <BaseModal
      show={true}
      onCancel={onCancel}
      onSubmit={() => onAvailabilityFormSubmitted(onMainModalSubmitHandler(), !objIsEmpty(availabilitySchedule) ? 'edit' : 'add')}
      submitBtnText={`${!objIsEmpty(availabilitySchedule) ? 'Edit' : 'Add'}`}
      className={'edit-schedule-modal'}
      title={`${!objIsEmpty(availabilitySchedule) ? 'Edit' : 'Add'} Guard Availability`}
      bodyClassName="pt-0"
    >
      <form>
        {
          !objIsEmpty(availability) &&
          <ScheduleForm
            onChange={(event, fieldName) => {
              onCalendarChange(event, fieldName, availability.uid || availability.id)
            }}
            data={availability}
            hideEndDate={true}
            trackHolidays={(holidayId: number, yesNoValue: boolean) =>
              onHolidayChange(holidayId, yesNoValue, availability.uid as number, 'guardAvailabilities', guard)
            }
            holidaysTitle={`There is/are holiday(s) that fall within this guard availability schedule. Do you want to <span class="font-weight-bold">remove holiday(s)</span> from guard availability?`}
            onRemoveItemFromCalendar={scheduleId => onRemoveItemFromCalendar(scheduleId, 'guardAvailabilities', guard)}
            tooltipDates={"Select or edit dates for the full periods that the Guard is available."}
            tooltipHours={"Select the hour, minutes and period of day (AM or PM)."}
            tooltipRepeatOn={"Select the days of the week during which this schedule should repeat weekly."}
            className="mt-0"
          />
        }
        {validationError && <div className='text-danger'>This change affects one or more shifts. Please update this guard's assigned schedules/shifts before
        editing this availability schedule.</div>}
      </form>
    </BaseModal>
  )
}

export default AddEditGuardAvilabilityModal