import {SortDataTypesEnum} from "../../customHooks/useSortableData"

export const manageDeactivatedSchoolsContent = {
    thead: [
        {
            label: "School Name",
        },
        {
            label: "School Address",
        },
        {
            label: "School Board",
        },
        {
            label: "Deactivation Date",
        },
        {
            label: "Deactivaeted By",
        },
        {
            label: "Reactivate",
        }
    ],
}

export const manageSchoolYear = {
    thead: [
        {label: "Client Name", dataType: SortDataTypesEnum.string, sortable: true},
        {label: "School Year", sortable: true, dataType: SortDataTypesEnum.date},
        {label: "Start Date", sortable: true, dataType: SortDataTypesEnum.date},
        {label: "End Date", sortable: true, dataType: SortDataTypesEnum.date},
        {label: "Edit School Year"}
    ]
}

export const unassignedShifts = {
    thead: [
        { label: "Job Name", dataType: SortDataTypesEnum.string,sortable: true },
        { label: "Date", sortable: true, dataType: SortDataTypesEnum.date },
        { label: "ASP Start Time", sortable: true, dataType: SortDataTypesEnum.time, invisible: true},
        { label: "Shift Start Time", sortable: true, dataType: SortDataTypesEnum.time  },
        { label: "Shift End Time", sortable: true, dataType: SortDataTypesEnum.time  },
        { label: "Shift Period", dataType: SortDataTypesEnum.string, sortable: true },
        { label: "Reason", sortable: true },
        { label: "Assign Guard", invisible: true  },
        { label: "Bulk Action", invisible: true }
    ]
}

export const manageSchoolsContent = {
    thead: [
        {
            label: "School Name",
            sortable: true,
            dataType: SortDataTypesEnum.string
        },
        {
            label: "School Address",
        },
        {
            label: "School Board",
            sortable: true,
            dataType: SortDataTypesEnum.string
        },
        {
            label: "Edit",
        },
        {
            label: "Deactivate",
        }
    ],
    tbody: [
        {
            id: "id",
            name: "School Name",
            firstStreet: "Address 1",
            secondStreet: "Address 2",
            city: "City",
            province: "PR",
            postalCode: "X0X 0X0",
            board: "Toronto District School Board"
        },
        {
            id: "id2",
            name: "School Name2",
            firstStreet: "Address 1",
            secondStreet: "Address 2",
            city: "City",
            province: "PR",
            postalCode: "X0X 0X0",
            board: "Toronto District School Board"
        },
        {
            id: "i3",
            name: "School Name3",
            firstStreet: "Address 1",
            secondStreet: "Address 2",
            city: "City",
            province: "PR",
            postalCode: "X0X 0X0",
            board: "Toronto District School Board"
        },
    ]
}

export const manageHolidaysContent = {
    thead: [
        {
            label: "School Board",
            sortable: true,
            dataType: SortDataTypesEnum.string
        },
        {
            label: "Holiday Type",
            sortable: true,
            dataType: SortDataTypesEnum.string
        },
        {
            label: "Holiday Name",
            sortable: true,
            dataType: SortDataTypesEnum.string
        },
        {
            label: "Start Date",
            sortable: true,
            dataType: SortDataTypesEnum.date
        },
        {
            label: "End Date",
            sortable: true,
            dataType: SortDataTypesEnum.date
        },
        {
            label: "Remove",
            sortable: false,
        }
    ],
    tbody: [
        {
            id: "id",
            boardName: "Toronto District School Board",
            holidayType: "Public",
            holidayName: "Victoria Day",
            startDate: "2021-01-19T07:52:10.112Z",
            endDate: "2021-01-20T07:52:10.112Z",
        },
        {
            id: "id2",
            boardName: "Toronto District School Board",
            holidayType: "Public",
            holidayName: "Victoria Day",
            startDate: "2021-01-19T07:52:10.112Z",
            endDate: "2021-01-20T07:52:10.112Z",
        },
        {
            id: "id3",
            boardName: "Toronto District School Board",
            holidayType: "Public",
            holidayName: "Victoria Day",
            startDate: "2021-01-22T07:52:10.112Z",
            endDate: null,
        },
    ]
}

export const manageDevicesContent = {
    thead: [
        {
            label: "Employee ID",
            sortable: true,
        },
        {
            label: "Guard Name",
            sortable: true,
        },
        {
            label: "Device Brand and Model Name",
            sortable: true,
        },
        {
            label: "Serial Number",
            sortable: true,
        },
        {
            label: "ASP Mobile",
            sortable: true,
        },
        {
            label: "Assigned Date",
            sortable: true,
        },
        {
            label: "Unassign Device",
        },
    ],
    tbody: [
        {
            id: "id",
            fullName: "Freddy M Mercury",
            brand: "Samsung",
            model: "S10",
            serialNumber: "XX00XX000X",
            assignDate: "2021-01-20T07:52:10.112Z",
        },
        {
            id: "id2",
            fullName: "Freddy M Mercury",
            brand: "Samsung",
            model: "S10",
            serialNumber: "XX00XX000X",
            assignDate: "2021-01-20T07:52:10.112Z",
        },
        {
            id: "id3",
            fullName: "Geddy Lee",
            brand: "Samsung",
            model: "S10",
            serialNumber: "XX00XX000X",
            assignDate: "2021-02-20T07:52:10.112Z",
        },
    ]
}

export const manageUnassignedDevicesContent = {
    thead: [
        {
            label: "Device Brand and Model Name",
        },
        {
            label: "Serial Number",
        },
        {
            label: "Unassigned Date",
        },
        {
            label: "Assign Device",
        },
    ],
    tbody: [
        {
            id: "id",
            brand: "Samsung",
            model: "S10",
            serialNumber: "XX00XX000X",
            assignDate: "2021-01-20T07:52:10.112Z",
        },
        {
            id: "id2",
            brand: "Samsung",
            model: "S10",
            serialNumber: "XX00XX000X",
            assignDate: "2021-01-20T07:52:10.112Z",
        },
        {
            id: "id3",
            brand: "Samsung",
            model: "S10",
            serialNumber: "XX00XX000X",
            assignDate: "2021-02-20T07:52:10.112Z",
        },
    ]
}

export const manageDeactivateSchedulesContent = {
    thead: [
        {
            label: "Job Name",
        },
        {
            label: "Start and End Dates",
        },
        {
            label: "Repeat",
        },
        {
            label: "Request Date",
        },
        {
            label: "Requested By",
        },
        {
            label: "Assigned Guards",
        },
        {
            label: "Reason for Deactivation",
        },
        {
            label: "Approve",
        },
    ]
}

export const manageDeactivatedSchedulesContent = {
    thead: [
        {
            label: "Job Name",
        },
        // {
        //     label: "Start and End Dates",
        // },
        // {
        //     label: "Repeat",
        // },∂
        {
            label: "Deactivation Date",
        },
        {
            label: "Deactivation Approved By",
        },
        {
            label: "Request Date",
        },

        {
            label: "Requested By",
        },
        {
            label: "Reason for Deactivation",
        },
        {
            label: "Reactivate Schedule",
        },
    ]
}


export const manageNotificationsContent = {
    thead: [
        {
            label: "Notification",
        },
        {
            label: "Email Settings and Content",
        },
        {
            label: "Status",
        },
    ],
    tbody: [
        {
            id: 1,
            notification: "Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt",
            isActive: true,
        },
        {
            id: 2,
            notification: "Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt",
            isActive: false,
        },
        {
            id: 3,
            notification: "Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt",
            isActive: true,
        },
        {
            id: 4,
            notification: "Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt",
            isActive: true,
        },
    ]
}

export const manageDeactivateGuardsContent = {
    thead: [
        {
            label: "Guard Name",
        },
        {
            label: "Request Date",
        },
        {
            label: "Requested By",
        },
        {
            label: "Jobs Currently Assigned",
        },
        {
            label: "Reason for Deactivation",
        },
        {
            label: "Approve",
        },
    ],
}

export const manageDeactivateUsersContent = {
    thead: [
        {
            label: "Full Name",
        },
        {
            label: "User Type",
        },
        {
            label: "Request Date",
        },
        {
            label: "Requested By",
        },
        {
            label: "Reason for Deactivation",
        },
        {
            label: "Approve",
        },
    ],
}

export const manageDeactivatedGuardsContent = {
    thead: [
        {
            label: "Guard Name",
        },
        {
            label: "Deactivation Date",
        },
        {
            label: "Reason for Deactivation",
        },
        {
            label: "Deactivation Approved By",
        },
        {
            label: "Request Date",
        },
        {
            label: "Requested By",
        },
        {
            label: "Reactivate Guard",
        },
    ],
}


export const recentActivityContent = {
    thead: [
        {
            label: "Date",
            dataType: SortDataTypesEnum.date, sortable: true
        },
        {
            label: "Time",
            dataType: SortDataTypesEnum.time, sortable: true
        },
        {
            label: "Event",
        },
        {
            label: "Completed By",
            sortable: true
        },
    ],
}
