import React, { FC } from 'react'
import { useState } from 'react'
import { AdminCustomMessageTypes } from '../../../../common/enums/Administration'
import { IBaseModalProps } from '../../../../common/interfaces/IModal'
import CustomSelectControl from '../../../custom-select/CustomSelectControl'
import BaseModal from '../../BaseModal'
import ConfirmNotificationModal from './ConfirmClientNotificationModal'
import { IClient } from '../../../../common/interfaces/clients/IClient'
import CustomBaseNotificationEmail from './CustomBaseNotificationEmail'
import { CustomClientNotification } from '../../../../common/models/CustomNotification'
import ResourceDataService from '../../../../common/services/ResourceDataService'
import { useEffect } from 'react'
import { convertToHtml } from '../../../../utils/Converting'
import useSendCustomNotification from '../../../../customHooks/useSendCustomNotification'
import { IValidationBody, ValidationRules } from '../../../../common/interfaces/IValidation'
import { highlightError, removeHighlightError, validateFiled } from '../../../../utils/Validation'

interface Props extends IBaseModalProps {
    type: AdminCustomMessageTypes
    clients?: IClient[]
}

const CustomMessageToClients: FC<Props> = ({ onCancel, onSubmit, title, clients }) => {
    const [modals, setModals] = useState<{ main: boolean, confirm: boolean, success: boolean }>({ main: true, confirm: false, success: false });
    const [clientContacts, setClientContacts] = useState<Array<{ id: number, fullName: string }>>([{ id: 0, fullName: '' }])
    const resourceSvc = new ResourceDataService<Array<{ id: number, fullName: string }>>({ url: 'client/contacts' })

    const { stepOneOpts, stepTwoOpts, handleRadioBtns, decodeRadioBtns, state, onChangeHandler, setState  } = useSendCustomNotification<CustomClientNotification>(new CustomClientNotification(), 'client')

    useEffect(() => {
        if (stepTwoOpts.custom && state.clients !== '') {
            fetchClientContatcs()
        }
        if (state.clients === '') {
            setClientContacts([])
        }
    }, [stepTwoOpts, state.clients])

    const fetchClientContatcs = async () => {
        try {
            const res = await resourceSvc.queryString(`clientsId=${state.clients}`)
            setClientContacts(res.data)
        } catch (error: any) {
            console.log(error)
        }
    }

    const formIsValid = (formData:CustomClientNotification) => {
        let errors: IValidationBody[] = [];

        errors = [...errors, ...validateFiled(formData.clients, "clients", ValidationRules.required)]
        errors = [...errors, ...validateFiled(formData.subject, "subject", ValidationRules.required)]
        formData.message.trim() === '<p></p>' && (errors = [...errors, ...[{ fieldName: 'content-editor__wrapper', errorMessage: '' }]])
        stepTwoOpts.custom && (errors = [...errors, ...validateFiled(formData.clientContacts, "clientContacts", ValidationRules.required)])

        if (errors.length !== 0) {
            highlightError(errors)
            return false
        }
        else {
            removeHighlightError()
            return true
        }
    }

    const onSaveDataClick = () => {
        const body  = {
            ...state,
            howSendMessage: decodeRadioBtns().howSendMessage,
            toWhomeSendMessage: decodeRadioBtns().toWhomeSendMessage,
            message: convertToHtml(state.editor)
        }

        if (!formIsValid(body)) return;

        setState(body)
        setModals({ ...modals, main: false, confirm: true })
    }

    const onConfirmBtnClick = () =>  onSubmit && onSubmit(state)

    return (
        <>
        <BaseModal
            show={modals.main}
            onCancel={() => onCancel()}
            onSubmit={() => onSaveDataClick()}
            submitBtnText={'Send'}
            title={title}
            className="custom-message"
        >
            <form onSubmit={e => e.preventDefault()}>
                <h6 className="mt-0 mt-md-3 mb-3 font-weight-bold text-dark">
                    <span className="list-counter mr-2 d-inline-flex align-items-center justify-content-center">1</span>
                    How do you want to send your message?
                </h6>

                <div className="form-group">
                    <div className="custom-control custom-control-radio custom-control-inline">
                        <input type="radio" id="sendEmail" name="sendEmail" className="custom-control-input"
                            checked={!!stepOneOpts.sendEmail}
                            onChange={e => handleRadioBtns(1, "sendEmail")}
                            />
                        <label className="custom-control-label custom-control-radio-label" htmlFor="sendEmail">Send email to all</label>
                    </div>
                </div>

                <h6 className="mt-4 mb-3 font-weight-bold text-dark">
                    <span className="list-counter mr-2 d-inline-flex align-items-center justify-content-center">2</span>
                    To which clients do you want your message to go?
                </h6>

                <div className="form-group">
                    <CustomSelectControl
                        options={clients ? clients.map(client => ({
                            id: client.id,
                            name: client.organizationName
                        })) : []}
                        onChange={val => onChangeHandler(val, 'clients')}
                        value={state.clients}
                        isMulti={true}
                        className={`custom-react-select--clients`}
                    />
                </div>


                <h6 className="mt-4 mb-3 font-weight-bold text-dark">
                    <span className="list-counter mr-2 d-inline-flex align-items-center justify-content-center">3</span>
                    To which contacts do you want your message to go?
                </h6>

                <div className="form-group">
                    <div className="custom-control custom-control-radio custom-control-inline">
                        <input type="radio" id="all" name="all" className="custom-control-input"
                            checked={!!stepTwoOpts.all}
                            onChange={e => handleRadioBtns(2, "all")}
                            />
                        <label className="custom-control-label custom-control-radio-label" htmlFor="all">All contacts for selected clients</label>
                    </div>
                </div>

                <div className="form-group">
                    <div className="custom-control custom-control-radio custom-control-inline">
                        <input type="radio" id="sub" name="sub" className="custom-control-input"
                            checked={!!stepTwoOpts.sub}
                            onChange={() => handleRadioBtns(2, "sub")}
                            />
                        <label className="custom-control-label custom-control-radio-label" htmlFor="sub">All contacts for clients that have access to platform</label>
                    </div>
                </div>

                <div className="form-group">
                    <div className="custom-control custom-control-radio custom-control-inline">
                        <input type="radio" id="custom" name="custom" className="custom-control-input"
                            checked={!!stepTwoOpts.custom}
                            onChange={() => handleRadioBtns(2, "custom")}
                            />
                        <label className="custom-control-label custom-control-radio-label" htmlFor="custom">Select contacts to whom this message should be sent</label>
                    </div>
                </div>

                { clientContacts.length !== 0 && stepTwoOpts.custom &&
                    <div className="form-group">
                        <CustomSelectControl
                            options={clientContacts ? clientContacts.map(client => ({
                                id: client.id,
                                name: client.fullName
                            })) : []}
                            onChange={val => onChangeHandler(val, 'clientContacts')}
                            value={state.clientContacts}
                            isMulti={true}
                            className={`custom-react-select--clientContacts`}
                        />
                    </div>
                }

                <CustomBaseNotificationEmail
                    onChangeHandler={(field, key) => onChangeHandler(field, key)}
                    fields={{ subject: state.subject, message: state.editor }}
                />

            </form>
        </BaseModal>

        {
            modals.confirm &&
            <ConfirmNotificationModal
                onCancel={() => setModals({ ...modals, confirm: false, main: true})}
                onSubmit={() => onConfirmBtnClick()}
                data={state}
                clients={clients}
                contacts={clientContacts}
            />
        }
        </>
    )
}

export default CustomMessageToClients
