import {LoadScript, GoogleMap, Marker, Circle} from '@react-google-maps/api'
import {FC} from 'react'
import { useHistory } from 'react-router-dom'
import Pin from '../../assets/icons/jobPin.svg'
import GuardPin from '../../assets/icons/guard.svg'
import { circleOptions } from '../../common/interfaces/maps/IMaps'

type Props = {
    lat: number
    lng: number
    radius?: number
    removeLoadScript?: boolean
    draggable?: boolean
    draggableMarker?: Function
    markerIsALink?: string
    showGuardPin?: boolean
}

const GeofenceMap: FC<Props> = ({lat, lng, radius, removeLoadScript, draggable = false,
    draggableMarker, markerIsALink, showGuardPin}) => {
    const history = useHistory();

    const moveDraggableMarker = (e: any) => {
        if (draggableMarker) {
            let lat = e.latLng.lat();
            let lng = e.latLng.lng();
            if (lat && lng) {
                draggableMarker(lat, lng)
            }
        }
    }

    const renderMap = () => (
        <GoogleMap
            zoom={15}
            center={{lat, lng}}
            options={{
                streetViewControl: false
            }}
            id="job-location-map"
        >
            <Marker 
                position={{lat, lng}}
                draggable={draggable}
                onDragEnd={(e) => moveDraggableMarker(e)}
                icon={{ url: showGuardPin ? GuardPin : Pin }}
                onClick={() => markerIsALink && history.push(markerIsALink)}
            />
           
            { radius && <Circle
                radius={radius}
                center={{lat, lng}}
                options={circleOptions}
            />
            }
        </GoogleMap>
    )

    return (
        <>
            {
                removeLoadScript ? renderMap() :
                    <LoadScript googleMapsApiKey={process.env.REACT_APP_MAP_KEY as string}>
                        {renderMap()}
                    </LoadScript>
            }
        </>
    )
}

export default GeofenceMap
