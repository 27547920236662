import React, { FC } from 'react';
import { Dropdown } from 'react-bootstrap';
import { SVGMoreDots } from '../../assets/icons/SvgIcons';
import { LeaveRequestReportModals } from 'common/enums/Modals';
import moment from 'moment';

type TProps = {
	id: number | string;
	openModal: (type: LeaveRequestReportModals, id: number | string) => void;
    startDate: Date | string;
    status: string;
};

const LeaveRequestActions: FC<TProps> = ({ openModal, id, status, startDate }) => {
    const now = moment();
	const start = moment(startDate, 'MMM DD, Y');
	const isPending = status === 'Pending';
	const isApproved = status === 'Approved';
	const endOfDay = now.startOf("day").subtract(1, 'day');
	const isStartAfterNow = start.isAfter(endOfDay);

	if (!isStartAfterNow || status === 'Expired' ||  status === 'Denied') {
		return null;
	}

	return (
		<Dropdown>
			<Dropdown.Toggle className={'btn-aqua-blue'}>
				<SVGMoreDots width={16} height={16} />
			</Dropdown.Toggle>

			<Dropdown.Menu>
				{isPending && (
					<>
						<Dropdown.Item onClick={(e) => openModal(LeaveRequestReportModals.approve, id)}>
							Approve
						</Dropdown.Item>
						<Dropdown.Item onClick={(e) => openModal(LeaveRequestReportModals.deny, id)}>
							Deny
						</Dropdown.Item>
					</>
				)}

				{isApproved && (
					<Dropdown.Item onClick={(e) => openModal(LeaveRequestReportModals.deny, id)}>
						Change Approval to Deny
					</Dropdown.Item>
				)}

				{(isPending || isApproved) && (
					<Dropdown.Item onClick={(e) => openModal(LeaveRequestReportModals.editDates, id)}>
						Edit Dates
					</Dropdown.Item>
				)}
				
			</Dropdown.Menu>
		</Dropdown>
	);
};

export default LeaveRequestActions;
