import { FC } from 'react';
import { Link } from 'react-router-dom';
import { IBaseModalProps } from 'common/interfaces/IModal';
import BaseModal from '../BaseModal';
import { IPushNotificaion } from 'common/interfaces/firebase/IPushNotificaion';
import { SVGGuardSubmission } from 'assets/icons/SvgIcons';
import { momentDateFormat, momentHoursFormat } from 'utils/DateFormatting';

interface IProps extends IBaseModalProps {
	message: IPushNotificaion;
	topMargin: number;
}

const GuardCovidTestFailed: FC<IProps> = ({ onCancel, message: { data }, topMargin }) => {
	const classes = 'guard-safety__border guard-safety__bg-warn custom_body';
	return (
		<BaseModal
			show
			cancelBtnText={'Close'}
			onCancel={onCancel}
			showCancelBtn={false}
			showExitBtn={true}
			bodyClassName={classes}
			modalStyle={{ margin: `${topMargin}px` }}>
			<div className='guard-safety'>
				<button
					type='button'
					className='close btn-close-modal'
					aria-label='Close'
					onClick={() => onCancel()}>
					<span aria-hidden='true'>&times;</span>
				</button>
				<div className='row flex-nowrap'>
					<div className='col-auto pr-0'>
						<SVGGuardSubmission className='mr-3' />
					</div>
					<div className='col-11 p-0'>
						<h2 className='guard-safety__title mb-4 font-weight-bold text-danger'>
							Guard Covid Test Failed
						</h2>
						<h5 className='d-flex align-items-center guard-safety__sub mb-3'>
							<span className='font-weight-bold text-nowrap mr-2'>
								Please contact
							</span>
							<Link
								className='font-weight-bold pr-2 text-nowrap'
								to={`/guards/${data.guardId}/details`}
								onClick={() => onCancel()}>
								<u>{data.guardName}</u>
							</Link>
						</h5>
						<div className='row'>
							<div className='col-4 details-information__title'>
								<h6>Phone:</h6>
							</div>
							<div className='col-8 details-information__value'>
								<p className='mb-0'>{data.phone}</p>
							</div>
						</div>
						<div className='row flex-nowrap'>
							<div className='col-4 details-information__title'>
								<h6 className='text-nowrap'>Employee ID:</h6>
							</div>
							<div className='col-8 details-information__value'>
								<p className='mb-0'>{data.employeeId}</p>
							</div>
						</div>
						<div className='row flex-nowrap'>
							<div className='col-4 details-information__title'>
								<h6 className='text-nowrap'>Date:</h6>
							</div>
							<div className='col-8 details-information__value'>
								<p className='mb-0'>{momentDateFormat(data.alertDateTime)} - {momentHoursFormat(data.alertDateTime)}</p>
							</div>
						</div>
						{data.jobName && (
							<div className='row flex-nowrap'>
								<div className='col-4 details-information__title'>
									<h6 className='text-nowrap'>Job:</h6>
								</div>
								<div className='col-8 details-information__value'>
									<Link
										className='font-weight-bold pr-2 text-nowrap'
										to={`/jobs/${data.jobId}/details`}
										onClick={() => onCancel()}>
										<u className='mb-0'>{data.jobName}</u>
									</Link>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</BaseModal>
	);
};

export default GuardCovidTestFailed;
