import React from 'react'
import {Link} from 'react-router-dom'
import RequestsToDeactivateGuards from '../../components/guards/guard-requests/RequestsToDeactivateGuards'
import Layout from '../../components/layout/Layout'
import SectionTitle from '../../components/titles/SectionTitle'

const DeactivateGuards = () => {

    return (
        <Layout
            breadcrumbs={{
                links: [{
                    title: `Guards`,
                    link: `/guards`
                }],
                currentPageTitle: 'Deactivate Guards'
            }}
        >
            <div className="row">
                <div className="col-12 mb-4 d-flex flex-row flex-wrap align-items-center justify-content-between">
                    <SectionTitle title="Deactivation Requests for Guards"/>
                    <Link to="/guards/deactivated" className="btn btn-outline-aqua-blue ml-0 ml-md-3 mt-2 mt-md-0">View
                        inactive guards</Link>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <RequestsToDeactivateGuards/>
                </div>
            </div>
        </Layout>
    )
}

export default DeactivateGuards
