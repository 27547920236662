export const clientsContent = {
    thead: [
        {
            label: "Client Name",
        },
        {
            label: "Primary Contact Name",
        },
        {
            label: "Address",
        },
        {
            label: "Client Email",
        },
        {
            label: "Client Phone",
        },
    ],
}

export const inactiveClientsContent = {
    thead: [
        {
            label: "Client Name",
        },
        {
            label: "Request Date",
        },
        {
            label: "Requested By",
        },
        // {
        //     label: "Deactivation Reason",
        // },
        {
            label: "Reason for Deactivation",
        },
        {
            label: "Approve",
        },
    ],
}