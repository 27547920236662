import moment from 'moment'
import { IJobsRightNow } from '../common/interfaces/jobs/IJob';
import { useRole } from './useRole';

type BaseModel = {
    checkInDateTime: string
    checkOutDateTime: string
    aspStartDate: string
    timeFrom: string
    timeTo: string
    shiftStatus: {[index: string]: boolean}
};


export const useCheckLate = () => {
    const { isASPAndAdminRole, isClientRole } = useRole()
    
    const parsedDate = (date: string, _: number) => {
        // const parsed = date.split("T").pop()
        // const today = shiftPeriod === 4 ? moment().add(1, 'day').format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")
        // const today = moment().format("YYYY-MM-DD")
        // return `${today}T${parsed}`
        return date
    }

    const checkLate = {
        checkInOnTime: (checkTime: string, aspStartTime: string, shiftPeriod: number) => (
            moment.utc(checkTime).isBefore(moment.utc(parsedDate(aspStartTime, shiftPeriod))) ||
            moment.utc(checkTime).isSame(moment.utc(parsedDate(aspStartTime, shiftPeriod)))
        ),
        checkInBuffer: (checkTime: string, shiftStartTime: string, aspStartTime: string, shiftPeriod: number) => (
            moment.utc(checkTime).isBetween(moment.utc(parsedDate(aspStartTime, shiftPeriod)), moment.utc(parsedDate(shiftStartTime, shiftPeriod))) ||
            moment.utc(checkTime).isSame(moment.utc(parsedDate(shiftStartTime, shiftPeriod))) 
        ),
        checkInLate: (checkTime: string, shiftStartTime: string, shiftPeriod: number) => (
            moment.utc(checkTime).isAfter(moment.utc(parsedDate(shiftStartTime, shiftPeriod)))
        ),
        checkOutOnTime: (checkTime: string, shiftEndTime: string, shiftPeriod: number) => (
            moment.utc(checkTime).isSameOrAfter(moment.utc(parsedDate(shiftEndTime, shiftPeriod)))
        ),
        checkOutLate: (checkTime: string, shiftEndTime: string, shiftPeriod: number) => (
            moment.utc(checkTime).isBefore(moment.utc(parsedDate(shiftEndTime, shiftPeriod)))
        ),
    }

    function checkInClassNames<T extends BaseModel>(shift: T, isNotAClient: boolean, shiftPeriod: number): string {
        if (shift.checkInDateTime === null) return '';

        if (shift.shiftStatus.absent) return '';

        if (checkLate.checkInOnTime(shift.checkInDateTime, shift.aspStartDate, shiftPeriod)) return 'check-onTime';
        
        if (checkLate.checkInBuffer(shift.checkInDateTime, shift.timeFrom, shift.aspStartDate, shiftPeriod))
           return isNotAClient ? 'check-buffer' : 'check-onTime';

        if (checkLate.checkInLate(shift.checkInDateTime, shift.timeFrom, shiftPeriod)) return 'check-late';

        return ''
    }

    function checkOutClassNames<T extends BaseModel>(shift: T, shiftPeriod: number): string {
        if (shift.checkOutDateTime === null) return '';
        if (checkLate.checkOutOnTime(shift.checkOutDateTime, shift.timeTo, shiftPeriod)) return 'check-onTime';
        if (checkLate.checkOutLate(shift.checkOutDateTime, shift.timeTo, shiftPeriod)) return 'check-late';
        return ''
    }

    function setShiftStatus <T extends IJobsRightNow>(shiftId: number, shiftData: T[], currShiftId: number, showAsForClient?: boolean) {
        const shift = shiftData.find((shift:T) => shift.shiftId === shiftId) as T

        if (shift) {
            return {
                guardIconClass: shift.shiftStatus.failedTest ? (isClientRole() ? 'guard-assigned' : "guard-test-failed") : (shift.guardName ? 'guard-assigned' : 'guard-unassigned'),
                assigned: shift.guardName ? true : false,
                tentantCheckIn: shift.shiftStatus.tentantCheckIn,
                checkIn: shift.shiftStatus.checkIn,
                checkOut: shift.shiftStatus.checkOut,
                absent: shift.shiftStatus.absent,
                latesOrAbsences: shift.latesOrAbsences ? shift.latesOrAbsences : 0,
                checkInClassName: (shift.shiftStatus.tentantCheckIn && isASPAndAdminRole() && !showAsForClient)
                        ? 'check-tentant' : checkInClassNames(shift, showAsForClient ? !showAsForClient : isASPAndAdminRole(), currShiftId),
                checkOutClassName: checkOutClassNames(shift, currShiftId)
            }
        }
    }

    return {
        checkLate, checkInClassNames, checkOutClassNames, setShiftStatus, parsedDate
    }
}