import { AuthService } from "../auth/AuthService";
import { IError } from "../interfaces/IError";
import { IServiceConfig } from "../interfaces/IServiceConfig";
import { ErrorResponse } from "../models/Error";
import {Reports} from "../enums/Reports";

export class BaseDataService {
    protected credentials: RequestCredentials = 'same-origin';
    protected config: IServiceConfig;
    protected url: string;
    protected baseUrl: string = process.env.REACT_APP_API_URL as string;
    protected headers: any
    protected omitToken: boolean | undefined

    constructor(config: IServiceConfig) {
        this.config = config;
        this.url = `${this.baseUrl}/${this.config.urlBuilder && this.config.url}`;
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            this.credentials = "same-origin"
        }
        this.omitToken = this.config.omitToken
        this.headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };
    }

    protected async request (url:string, method = "GET", body: any = null, headers = this.headers, responseType?: string, isNotStringify?: boolean) {
        const options: any = {
            method,
            headers: this.omitToken ? headers : {
                ...headers,
                'Authorization': `Bearer ${await AuthService.getAccessToken()}`
            },
            credentials: this.credentials,
        };

        (method !== "GET" && body) && (options.body = isNotStringify ? body : JSON.stringify(body))

        try {
            let response = await fetch(url, options);
            if (!response.ok) {
                const error: IError = await response.json()
                return await Promise.reject(new ErrorResponse(error.Errors ? error.Errors.join(',') : error.Message));
            } else {
                if (responseType === Reports.blob) {
                    return await response.blob()
                } else {
                    return await response.json()
                }

            }
        } catch (error: any) {
            return Promise.reject(new Error(error))
        }
    };
}
