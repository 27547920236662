import React, { FC, useContext, useEffect } from 'react'
import SectionTitle from 'components/titles/SectionTitle'
import { useTableList } from 'customHooks/useTableList'
import AppContext from 'context/AppContext'
import { IAttendenceReport } from 'common/interfaces/reports/IReport'
import { groupBy, mapValues, omit } from "lodash"
import { attendanceDateFormat, momentDateFormat } from 'utils/DateFormatting'
import { IShiftJobReportModel } from 'common/interfaces/IDataFilter'
import { IVocabulary } from 'common/interfaces/vocabulary/IVocabulary'
import { matchOptionWithName } from 'utils/MatchOptionWithName'
import { NotificationTypes } from 'common/interfaces/INotification'
import { SVGDownload } from 'assets/icons/SvgIcons'
import { useDowloandFile } from 'customHooks/useDownloadFile'
import { Reports } from 'common/enums/Reports'

type Props = {
    onBackClick: () => void
    queryUrl: string
    filter: IShiftJobReportModel
    options: Array<IVocabulary[]>
    hideTable: () => void;
}

const AttendaceReportList: FC<Props> = ({ onBackClick, queryUrl, filter, options, hideTable }) => {
    const { tableData, fetchData, isLoading, setTableData, setLoading } = useTableList(queryUrl, 0, false)
    const { showLoader, showNotification } = useContext(AppContext)
    const reports = tableData as any
    const { dowloandFile } = useDowloandFile()

    useEffect(() => {
        configData();
    }, [queryUrl])

    useEffect(() => {
        showLoader(isLoading)
    }, [isLoading])

    const onDownload = async () => {
          // TODO: Check functionality after back-end updates
        showLoader(true)
        await dowloandFile('report/dailyattendance', Reports.excel,
        Reports.download, '', '', `&${queryUrl.split("?")[1]}`, true)
        showLoader(false)
    }

    const configData = async () => {
        setLoading(true)
        try {
            const response = await fetchData(0,0, undefined, true, true, false) as any;
            if (response) {
                response.jobs = await Promise.all((response as IAttendenceReport).jobs.map(job => {
                    job.infoModified = mapValues(groupBy(job.info, 'startDate'),
                    clist => clist.map(report => omit(report, 'startDate')))
                    return job
                }))
                setTableData(response);
            }
            // !response && hideTable()
            setLoading(false)
        } catch (error: any) {
            setLoading(false)
            // hideTable()
            showNotification(NotificationTypes.danger, error.message)
        }
    }

    const hightlightCol = (shiftInfo: string, isLate?: boolean) => (
        shiftInfo.toLowerCase().includes("no coverage") ? `px-2 py-1 highlighted--yellow ${isLate ? 'highlighted--blue' : ''}` : `${isLate ? 'px-2 py-1 highlighted--blue' : ''}`
    );

    const summaryRow = (columnName: string, summaryObjKey: string) => {
        const summary = (Object.entries((reports as IAttendenceReport).summary));
        const showTotal = summary.some(([_key, value]) => !!value.Total[summaryObjKey])
        return <tr>
            <td className={`text-center align-middle`}><div dangerouslySetInnerHTML={{ __html: columnName }} /></td>
            <td className={`text-center align-middle`}>
                <div>
                AM<br /> LN <br /> PM
                {
                    showTotal &&
                    <>
                        <br /> For The Day
                    </>
                }
                </div>
            </td>
            {
                reports && !isLoading && summary.map(([_key, value]) => (
                    <>
                        <td className={`text-center align-middle`}>
                            <div>
                                { value.AM[summaryObjKey]  !== -1 ? value.AM[summaryObjKey] : '-' || 0}<br />
                                { value.LN[summaryObjKey]  !== -1 ? value.LN[summaryObjKey] : '-' || 0}<br />
                                { value.PM[summaryObjKey] !== -1 ? value.PM[summaryObjKey] : '-' || 0}<br />
                                {/* { !!value.Total[summaryObjKey] ? <>
                                    <br />{value.Total[summaryObjKey]}
                                </> : null} */}
                                {  summaryObjKey === 'coveragePercentages' ? value.Total[summaryObjKey] !== -1 ? value.Total[summaryObjKey] : '-' || 0  : null }
                            </div>
                        </td>
                    </>
                ))
            }
        </tr>
    }

    return (
        <>
            <div className="row mb-3">
                <div className="col-12 d-flex flex-row justify-content-between">
                    <SectionTitle title={`Attendance Report: ${momentDateFormat(filter.startDate)} - ${momentDateFormat(filter.endDate)}`}/>
                    <div>
                        <button
                            type="button"
                            className="btn btn-aqua-blue mr-2"
                            onClick={onDownload}
                        ><SVGDownload /></button>
                         <button
                            type="button"
                            className="btn btn-outline-aqua-blue px-md-4"
                            onClick={onBackClick}
                        >Back</button>
                    </div>
                </div>
            </div>

            <div className="row mb-4">

                <div className="col-md-6">
                    <div className="row mb-3">
                        <div className="col-4 details-information__title mb-0">
                            <h6 className="mb-0">Job Name or ID</h6>
                        </div>
                        <div className="col-8 details-information__value">
                            <p className="mb-0">{ filter.jobNameOrId || "-"}</p>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-4 details-information__title mb-0">
                            <h6 className="mb-0">Shift Period(s):</h6>
                        </div>
                        <div className="col-8 details-information__value">
                            <p className="mb-0">{ matchOptionWithName(filter.shiftPeriod, options[0]) || "-" }</p>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-4 details-information__title mb-0">
                            <h6 className="mb-0">Zone(s):</h6>
                        </div>
                        <div className="col-8 details-information__value">
                            <p className="mb-0">{ matchOptionWithName(filter.zone as string, options[1]) || "-" }</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="row mb-3">
                        <div className="col-5 details-information__title mb-0">
                            <h6 className="mb-0">School Board(s):</h6>
                        </div>
                        <div className="col-7 details-information__value">
                            <p className="mb-0">{ matchOptionWithName(filter.schoolBoard || "" as string, options[2]) || "-"}</p>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-5 details-information__title mb-0">
                            <h6 className="mb-0">Holidays(s):</h6>
                        </div>
                        <div className="col-7 details-information__value">
                            <p className="mb-0">{ matchOptionWithName(filter.holidayName as string, options[3]) || "-" }</p>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-5 details-information__title mb-0">
                            <h6 className="mb-0">Guard Coverage for Shift(s):</h6>
                        </div>
                        <div className="col-7 details-information__value">
                            <p className="mb-0">{ matchOptionWithName(filter.coverageForShift as string, options[4]) || "-" }</p>
                        </div>
                    </div>
                </div>

            </div>

            {/* Start Table */}
            <div className="row mb-5">
                <div className="col-12 main-table-container">
                    <div className="table-responsive table-wrapper">
                        { reports.length !==0 && !isLoading &&
                        <table className="table table-info table-sticky table-info--notFixed table-daily-attendance">
                            <thead>
                                <tr>
                                    <th className="text-center align-middle"><div>Intersection</div></th>
                                    <th className="text-center align-middle"><div>Shift</div></th>
                                    {
                                        (reports as IAttendenceReport).allDates.map(date => (
                                            <th className="text-center align-middle"><div>{ attendanceDateFormat(date) }</div></th>
                                        ))
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                <>
                                    { reports.jobs.length !== 0 ?
                                        <>
                                        {
                                            (reports as IAttendenceReport).jobs.map(job =>
                                                <tr>

                                                    <td className={`text-center align-middle`}><div>{ job.jobName }</div></td>
                                                    <td className={`text-center align-middle`}><div>{ job.shift }</div></td>
                                                    {
                                                        job.infoModified && Object.entries(job.infoModified).map(([_key, items]) =>
                                                            <td className={`text-center align-middle`}>
                                                                <div className="flex-column">
                                                                { items.map(item =>
                                                                    <>
                                                                        <p
                                                                            className={`mb-1 ${hightlightCol(item.shiftInfo, item.isLate)}`}
                                                                            dangerouslySetInnerHTML={{__html: item.shiftInfo }}
                                                                        />
                                                                    </>
                                                                ) }
                                                                </div>
                                                            </td>
                                                        )
                                                    }
                                                </tr>
                                            )
                                        }
                                        { summaryRow("Locations with PA Days/Holidays/No School", "noSchool") }
                                        { summaryRow("Total Coverage Required", "totalCoverageRequired") }
                                        { summaryRow("Open Shifts", "noCoverage") }
                                        { summaryRow("Total Coverage Provided", "totalCoverageProvided") }
                                        { summaryRow("Coverage Percentages", "coveragePercentages") }
                                        <tr>
                                            <td className={`text-center align-middle`}>Total Crosswalks</td>
                                            <td className={`text-center align-middle`}></td>
                                            {
                                                reports && !isLoading && (Object.entries((reports as IAttendenceReport).totalCrosswalks)).map(([_key, value]) => (
                                                    <>
                                                        <td className={`text-center align-middle`}>
                                                            <div>{ value.totalCrosswalks || 0}</div>
                                                        </td>
                                                    </>
                                                ))
                                            }
                                        </tr>
                                    </>
                                    :
                                        <tr>
                                            <td colSpan={reports.allDates.length + 2} className={`text-center align-middle`}>
                                                <div>  No data found. </div>
                                            </td>
                                        </tr>
                                    }
                                </>
                            </tbody>
                        </table>
                        }
                    </div>
                </div>
            </div>
            {/* End Table */}
        </>
    )
}

export default AttendaceReportList
