import { useEffect, useContext, useState, FC } from "react"
import { IAvailableGuard } from "../common/interfaces/guards/IGuard"
import { NotificationTypes } from "../common/interfaces/INotification"
import { IJobSchedule } from "../common/interfaces/jobs/IJob"
import { DataService } from "../common/services/DataService"
import ResourceDataService from "../common/services/ResourceDataService"
import AppContext from "../context/AppContext"

export const useBulk = () => {
    const { showNotification, showLoader } = useContext(AppContext)
    const [availableGuards, setAvailableGuards] = useState<IAvailableGuard[]>([] as IAvailableGuard[])
    const [bulkModalData, setBulkModalData] = useState<{ shifts: any[], guard: IAvailableGuard}>({ shifts: [], guard: {} as IAvailableGuard })

    const convertCheckboxesToShifts = (allCheckbox: (string | number)[], tableData: any[]) => {
        if (allCheckbox && allCheckbox.length !== 0 && tableData && tableData.length !== 0) {
            const copiedShifts = Array.from(tableData);
            let mergedShifts = [
                ...bulkModalData.shifts,
                ...copiedShifts
            ].filter(shift => allCheckbox.some(item => +item === shift.id))

            mergedShifts = [...mergedShifts.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i)]

            setBulkModalData({
                ...bulkModalData,
                shifts: mergedShifts
            })
        } else {
            setBulkModalData({
                ...bulkModalData,
                shifts: []
            })
        }
    }

    const getGuardsForBulkAssigning = async (shiftIds: string, callBack?: () => void) => {
        const svc = new ResourceDataService({ url: 'guard/bulkshifts' })
        showLoader(true)
        try {
            const response = await svc.queryString(`Shifts=${shiftIds}`)
            setAvailableGuards(response.data as IAvailableGuard[])
            callBack && callBack()
            // setAvailableGuards([])
            showLoader(false)
            // return response.data as IAvailableGuard[]
        } catch (err: any) {
            showLoader(false)
            showNotification(NotificationTypes.danger, err.message)
        }
    }

    const bulkAssignGuardToShift = async (body: { guardId: number }, shiftIds: string, callBack?: () => void) => {
        const svc = new DataService<{ guardId: number }>({ url: 'shift/bulkassign' })
        showLoader(true)
        try {
            await svc.updateWithQuery(body, `shiftId=${shiftIds}`)
            callBack && callBack()
            showLoader(false)
        } catch (err: any) {
            showLoader(false)
            showNotification(NotificationTypes.danger, err.message)
        }
    }

    // const bulkUnassignGuardFromShifts = async (body: { guardId: number }, shiftIds: string, callBack?: () => void) => {
    //     const svc = new DataService<{ guardId: number }>({ url: 'shift/bulk-unassign' })
    //     showLoader(true)
    //     try {
    //         await svc.updateWithQuery(body, `shiftId=${shiftIds}`)
    //         callBack && callBack()
    //         showLoader(false)
    //     } catch (err: any) {
    //         showLoader(false)
    //         showNotification(NotificationTypes.danger, err.message)
    //     }
    // }

    const getGuardsForBulkScheduleAssigning = async (selectedSchedules: IJobSchedule[], additionalInfo: any,
        callBack?: (result: any) => void,
        callBackErr?: () => void) => {
        // TODO: Avoid any types
        const svc = new DataService({ url: 'guard/bulkschedules' })
        const body = {
            "schedules": selectedSchedules.map((schedule: IJobSchedule) => (
                {
                    "startDate": schedule.startDate,
                    "endDate": schedule.endsOn,
                    "timeFrom": schedule.timeFrom,
                    "timeTo": schedule.timeTo,
                    "repeatOn": schedule.repeatOn,
                    "shiftPeriod": schedule.shiftPeriod,
                    "lat": Number(additionalInfo.latitude),
                    "long": Number(additionalInfo.longititude),
                    "ignoreLeaves": additionalInfo.ignoreleave
                }
            )),
            "lat": Number(additionalInfo.latitude),
            "long": Number(additionalInfo.longititude),
            "ignoreLeaves": additionalInfo.ignoreleave
        }

        showLoader(true)
        try {
            const result = await svc.updateWithoutId(body)
            callBack && callBack(result)
            showLoader(false)
        } catch (err: any) {
            callBackErr && callBackErr()
            showLoader(false)
            showNotification(NotificationTypes.danger, err.message)
        }
    }

    return {
        getGuardsForBulkAssigning, availableGuards, bulkAssignGuardToShift,
        getGuardsForBulkScheduleAssigning, bulkModalData, setBulkModalData,
        convertCheckboxesToShifts,
    }
}