import moment from "moment"
import { useEffect, useState } from "react"
import { filterDateFormat } from "../utils/DateFormatting"

type Model = {
    [index: string]: any
}

export const useFromToFilter = <T extends Model>(data: T[]) => {
    const [originalData, setOriginalData] = useState<T[]>(data)
    const [filteredData, setFilteredData] = useState<T[]>(data)
    const [formEmpty, setFormEmpty] = useState<boolean>(true)
    const [filterQuery, setFiterQuery] = useState<string>('')
    
    useEffect(() => {
        setOriginalData(data)
        setFilteredData(data)
    }, [data])

    const generateFilterQuery = (from?: Date, to?: Date) => {
        let query = ""
        if (from) {
            query+= `&StartDate=${from}`
        }
        if (to) {
            query+= `&EndDate=${to}`
        }
        setFiterQuery(query)
    }

    const onFilterItem = (item: Model, from?: Date, to?: Date, byKeyword = '', byKeyWordValue = '') => {
        const dateFormat = (date: string | Date) => new Date(moment(date).utc().format("MM-DD-YYYY")).getTime();
            if (from !== null && to !== null) {
                if ((from === null || to === null) && (byKeyword !== '' && byKeyWordValue !== '')) {
                    return item[byKeyword].toLowerCase().includes(byKeyWordValue.toLowerCase())
                } else if (byKeyword === '' && byKeyWordValue === '') {
                    return ((dateFormat(item.startDate) >= dateFormat(from!) || dateFormat(item.endDate) >= dateFormat(from!)) && ( dateFormat(item.startDate)  <= dateFormat(to!)));
                } else {
                    return (
                        (dateFormat(item.startDate) >= dateFormat(from!) && dateFormat(item.endDate || item.startDate) <= dateFormat(to!)) &&
                        item[byKeyword].toLowerCase().includes(byKeyWordValue.toLowerCase())
                    )
                }
            } else {
                if (to && byKeyword && byKeyWordValue) {
                    return (dateFormat(item.startDate) <= dateFormat(to) &&
                    item[byKeyword].toLowerCase().includes(byKeyWordValue.toLowerCase()));
                } else if (from && byKeyword && byKeyWordValue) {
                    return (dateFormat(item.startDate) >= dateFormat(from) &&
                    item[byKeyword].toLowerCase().includes(byKeyWordValue.toLowerCase()));
                }
                else if (byKeyword && byKeyWordValue) {
                    return item[byKeyword].toLowerCase().includes(byKeyWordValue.toLowerCase());
                } else if (from) {
                    return dateFormat(item.startDate) >= dateFormat(from) || dateFormat(item.endDate) >= dateFormat(from);
                } else if (to) {
                    return dateFormat(item.startDate) <= dateFormat(to);
                } else { return false }
            }
    }

    const onFilter = (from?: Date, to?: Date, byKeyword = '', byKeyWordValue = '') => {
        let result: T[] = []
        generateFilterQuery(from, to)
        result = [...data].filter(item => onFilterItem(item, from, to, byKeyword, byKeyWordValue));
        setFormEmpty(false)
        setFilteredData(result)
    }

    const onFilterMultiTable = (from?: Date, to?: Date, byKeyword = '', byKeyWordValue = '', key = '') => {
        let result: T[] = []
        generateFilterQuery(from, to)
        result = [...data].map(schedule => ({...schedule, [key]: schedule[key].filter((item: Model) => onFilterItem(item, from, to, byKeyword, byKeyWordValue))}));
        setFormEmpty(false)
        setFilteredData(result)
    }

    const onClear = () => {
        setFormEmpty(true)
        setFilteredData(originalData)
    }

    return {
        onFilter, filteredData, formEmpty, onClear, setOriginalData, setFilteredData, filterQuery, onFilterMultiTable
    }
}
