import { useState } from "react"

export const useMultipleCheckbox = () => {
    const [allCheckbox, setAllCheckbox] = useState([] as Array<string | number>)
    const [clearAllCheckboxes, setClearAllCheckboxes] = useState<boolean>(false)
    const [checkboxFlag, setCheckboxFlag] = useState<boolean>(true)

    const onChangeCheckbox = (id: string) => {
        setAllCheckbox(prevState => [...prevState, id])
    }
    const onRemoveCheckbox = (id: string) => {
        setAllCheckbox(prevState => prevState.filter(el => el !== id));
    }
    const arrayInStringWith = () => allCheckbox.join(';')
    const clearAllCheckbox = () => setAllCheckbox([])
    const selectAllCheckboxes = (checkboxIdArray?: number[] | undefined) => {
        if(Array.isArray(checkboxIdArray)){
            checkboxFlag && setAllCheckbox([...checkboxIdArray])
            !checkboxFlag && clearAllCheckbox()
            setCheckboxFlag(prev => !prev)
        }
    }

    return {
        allCheckbox, setAllCheckbox, onChangeCheckbox, onRemoveCheckbox, arrayInStringWith, clearAllCheckbox, setClearAllCheckboxes, clearAllCheckboxes, selectAllCheckboxes,
        checkboxFlag
    }
}
