import React from 'react'
import { IGuardDevice } from '../../common/interfaces/guards/IGuard';

interface IAssignedDeviceContext {
    device: IGuardDevice | undefined
    onChange: (value: React.ChangeEvent<HTMLInputElement> | string | Date | boolean, fieldName?: string) => void;
    onSubmit: (obj: IGuardDevice, create?: boolean) => void
}

const AssignedDeviceContext = React.createContext<IAssignedDeviceContext>({} as IAssignedDeviceContext);

export default AssignedDeviceContext;