import { SVGCalendar, SVGDownload, SVGInfo, SVGWarning } from 'assets/icons/SvgIcons';
import { Reports } from 'common/enums/Reports';
import { VocabularyEnum } from 'common/enums/VocabularyEnum';
import { IGuardAppLeaveRequestReport } from 'common/interfaces/reports/xguard-reports/iGuardAppLeave';
import AutocompleteField from 'components/autosuggestField/AutocompleteField';
import Layout from 'components/layout/Layout';
import DataTable from 'components/tables/DataTable';
import SectionTitle from 'components/titles/SectionTitle';
import { guardAppLeaveRequestsThead } from 'content/xguard-reports/tableContent';
import AppContext from 'context/AppContext';
import { useDatapickerSvg } from 'customHooks/useDatapickerSvg';
import { useDowloandFile } from 'customHooks/useDownloadFile';
import { useTableList } from 'customHooks/useTableList';
import { useVocabulary } from 'customHooks/vocabulary/useVocabulary';
import useXGuardReportsQuery from 'customHooks/xguard-reports/useXGuardReportsQuery';
import { useContext, useEffect, useState } from 'react';
import { datePickerFormat, setHoursAndMinutes, momentDateFormat } from 'utils/DateFormatting';
import { matchOptionWithName } from 'utils/MatchOptionWithName';
import DatePicker from 'react-datepicker';
import CustomSelectControl from 'components/custom-select/CustomSelectControl';
import { LeaveStatusOpts, typeOLeaveOpts } from 'content/dropdownOptionsConsts';
import BaseModal from 'components/modal/BaseModal';
import { jobFromStrToLink } from 'utils/Converting';
import { useSchoolYear } from 'customHooks/useSchoolYear';
import LeaveRequestActions from 'components/reports/LeaveRequestActions';
import useActionModals from 'customHooks/modals/useActionModals';
import {
	LeaveRequestModalInitialState,
	LeaveRequestModalType,
	leaveRequestModalReducer,
} from 'common/reducers/modals/LeaveRequestReducer';
import useLeaveReportActions, { BookLeaveBody } from 'customHooks/reports/useLeaveReportActions';
import { LeaveRequestReportModals } from 'common/enums/Modals';
import { Link } from 'react-router-dom';
import { BookLeaveModalContext } from 'context/guards/BookLeaveContext';
import BookLeaveModal, { LeaveTypeState } from 'components/modal/guards/BookLeaveModal';
import { inputDataChecker } from 'utils/InputDataChecker';
import CustomTooltip from 'components/custom-tooltip/CustomTooltip';
type BookLeaveActionArgs = {
	id: string;
	request?: 'approve' | 'disapprove';
	rowItem: IGuardAppLeaveRequestReport;
	modalType: LeaveRequestModalType;
};

const DefaultBookLeaveOptionsState = { singleDay: false, singleShift: false, multipleDays: false };
const GuardAppLeaveReports = () => {
	const { showLoader } = useContext(AppContext);
	const {
		fetchData: fetchReports,
		tableData: incidentReports,
		isLoading,
		currentPage,
		perPage,
		allRecords,
		onPagination,
		onSortCall,
		onPerPage,
	} = useTableList<IGuardAppLeaveRequestReport>('guardbookedleave/requests', 24, false, {desc: 'down', orderBy: 'startDate', isSorted: false, keyword: ''});
	const { vocabulary: shiftPeriods } = useVocabulary(VocabularyEnum.shift, true);
	const { vocabulary: reasons } = useVocabulary(VocabularyEnum.bookLeave, true);
	const { generateQueryParams, onQueryParamChanged, queryParams } = useXGuardReportsQuery({
		baseUrl: 'guardbookedleave/requests',
		defaultParams: { status: 2 },
	});
	const { showDatapicker, changeDatapicker } = useDatapickerSvg();
	const { dowloandFile } = useDowloandFile();

	const { approveOrDisapproveGuardBookedLeave, checkGuardShifts, updateGuardBookedLeave } = useLeaveReportActions();
	const { getSchoolYears: setDefaultSchoolYears, schoolYears } = useSchoolYear();
	const { approve, deny, updateDates, onModal, approveSuccess, updateSuccess } = useActionModals<
		LeaveRequestModalType,
		IGuardAppLeaveRequestReport
	>({ initialState: LeaveRequestModalInitialState, modalReducer: leaveRequestModalReducer });
	const [otherReason, setOtherReason] = useState<string>('');
	const [bookLeave, setBookLeave] = useState<BookLeaveBody>({} as BookLeaveBody);
	const [hasShifts, setHasShifts] = useState<boolean>(false);
	const [bookLeaveOptionsState, setBookLeaveOptionsState] = useState<LeaveTypeState>(DefaultBookLeaveOptionsState);
	useEffect(() => {
		setDefaultSchoolYears(
			onQueryParamChanged,
			(yearId) => fetchReports(1, 24, generateQueryParams(yearId ? { schoolYearId: `${yearId}`,  } : {})),
			true
		);
	}, []);
	const onDownload = async () => {
		showLoader(true);
		await dowloandFile(
			'report/leave-requests',
			Reports.excel,
			Reports.download,
			'',
			'',
			`&${generateQueryParams().split('?')[1]}`,
			true
		);
		showLoader(false);
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		fetchReports(1, 24, generateQueryParams());
		event.preventDefault();
	};

	const onDeny = ({ id, request = 'disapprove', rowItem, modalType }: BookLeaveActionArgs) => {
		approveOrDisapproveGuardBookedLeave(id, request, async () => {
			onModal(modalType).openModal(id, rowItem);
			await fetchReports(1, 24, generateQueryParams());
		});
	};

	const onApprove = async ({ id, rowItem, modalType }: BookLeaveActionArgs) => {
		const bookLeaveData = {
			shifts: rowItem.shifts,
			startDate: rowItem.startDate,
			endDate: rowItem.endDate,
			guardId: rowItem.guardId?.toString() || '',
			reason: rowItem.reason,
		};
		const hasShifts = await checkGuardShifts(id, bookLeaveData);
		onModal(modalType).openModal(id, { ...rowItem, hasShifts });
	};

	const onApproveSubmit = async (id: string, rowItem: IGuardAppLeaveRequestReport) => {
		onModal('approve').closeModal();
		approveOrDisapproveGuardBookedLeave(id, 'approve', async () => {
			onModal('approveSuccess').openModal(id, rowItem);
			await fetchReports(1, 24, generateQueryParams());
		});
	};

	const onEditDates = async (id: string, rowItem: IGuardAppLeaveRequestReport) => {
		const bookLeaveData = {
			shifts: rowItem.shifts,
			startDate: rowItem.startDate,
			endDate: rowItem.endDate,
			guardId: rowItem.guardId?.toString() || '',
			reason: rowItem.reason,
		};
		setBookLeave(bookLeaveData);
		setBookLeaveOptionsState((prev) =>
			rowItem.startDate === rowItem.endDate ? { ...prev, singleDay: true } : { ...prev, multipleDays: true }
		);
		onModal('updateDates').openModal(id, rowItem);
		setHasShifts(await checkGuardShifts(id, bookLeaveData));
	};

	const onCloseEditDates = () => {
		setBookLeaveOptionsState(DefaultBookLeaveOptionsState);
		onModal('updateDates').closeModal();
		setBookLeave({} as BookLeaveBody);
		setHasShifts(false);
	};

	const onSaveBookedLeave = async (id: string, rowItem: IGuardAppLeaveRequestReport) => {
		await updateGuardBookedLeave(
			id,
			{ ...bookLeave, reason: +bookLeave.reason !== -1 ? bookLeave.reason : otherReason },
			async () => {
				onCloseEditDates();
				rowItem.status === 'Pending' && (await approveOrDisapproveGuardBookedLeave(id, 'approve', () => {}));
				await fetchReports(1, 24, generateQueryParams());
				onModal('updateSuccess').openModal(id, rowItem);
			}
		);
	};

	const onActionItemPressed = (type: LeaveRequestReportModals, id: string, rowItem: IGuardAppLeaveRequestReport) => {
		if (type === LeaveRequestReportModals.deny) {
			onDeny({ id, rowItem, modalType: type });
		}

		if (type === LeaveRequestReportModals.approve) {
			onApprove({ id, rowItem, modalType: type });
		}

		if (type === LeaveRequestReportModals.editDates) {
			onEditDates(id, rowItem);
		}
	};

	const handleChanges = async (
		value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string | Date | boolean,
		fieldName?: string
	) => {
		const res = inputDataChecker(value, fieldName);
		setBookLeave((prev) => ({ ...prev, [res.field]: res.value }));
		if (['startDate', 'endDate', 'shifts'].includes(res.field)) {
			setHasShifts(await checkGuardShifts(`${updateDates.id}`, { ...bookLeave, [res.field]: res.value }));
		}
	};

	return (
		<Layout
			breadcrumbs={{
				links: [
					{
						title: 'Reports',
						link: 'reports',
					},
				],
				currentPageTitle: 'Guard Leave Report',
			}}
			className={'reports-screen'}
		>
			<div className="row mb-3">
				<div className="col-12">
					<SectionTitle title={'Guard Leave Report'} />
				</div>
			</div>

			<form onSubmit={handleSubmit}>
				<div className="row">
					{/* Start Date Filters */}
					<div className="col-md-4 form-group mb-md-4">
						<div className="row d-flex flex-column">
							<div className="col-12 mb-3">
								<label>Leave From: </label>
								<div className='d-flex'>
									<div className="react-datepicker-custom-wrapper react-datepicker-custom-wrapper--full-width d-inline-block w-100">
										<DatePicker
											selected={datePickerFormat(queryParams.startDate)}
											onChange={(date) => {
												onQueryParamChanged(
													date ? setHoursAndMinutes(date as Date) : '',
													'startDate'
												);
												changeDatapicker(1);
											}}
											dateFormat="dd/MM/yyyy"
											onInputClick={() => changeDatapicker(1)}
											open={showDatapicker[1]}
											onClickOutside={() => changeDatapicker(1)}
											placeholderText="dd/mm/yyyy"
										/>
										<div onClick={() => changeDatapicker(1)}>
											<SVGCalendar />
										</div>
									</div>
									<CustomTooltip
										bodyText={
											'Please note that the results will display any booked leaves that overlap with the selected date range. This means that if any of the booked leaves have dates that fall within the selected range, they will be included in the results.'
										}
									>
										<button type="button" className="btn btn-transparent px-0 ml-3">
											<SVGInfo />
										</button>
									</CustomTooltip>
								</div>
							</div>
							<div className="col-12">
								<label>To: </label>
								<div className="d-flex">
									<div className="react-datepicker-custom-wrapper react-datepicker-custom-wrapper--full-width d-inline-block w-100 mr-3">
										<DatePicker
											selected={datePickerFormat(queryParams.endDate)}
											onChange={(date) => {
												onQueryParamChanged(
													date ? setHoursAndMinutes(date as Date) : '',
													'endDate'
												);
												changeDatapicker(2);
											}}
											dateFormat="dd/MM/yyyy"
											onInputClick={() => changeDatapicker(2)}
											open={showDatapicker[2]}
											onClickOutside={() => changeDatapicker(2)}
											placeholderText="dd/mm/yyyy"
										/>
										<div onClick={() => changeDatapicker(2)}>
											<SVGCalendar />
										</div>
									</div>
									<div style={{width: '26px'}}></div>
								</div>
							</div>
						</div>
					</div>
					{/* End Date Filters */}

					<div className="col-md-4 mb-md-4">
						<div className="row">
							<div className="col-12 form-group">
								<label>Type of Leave:</label>
								<CustomSelectControl
									options={typeOLeaveOpts || []}
									value={queryParams.type}
									placeholder={'Select Leave Type'}
									onChange={(e) => onQueryParamChanged(e, 'type')}
									className="flex-grow-0"
								/>
							</div>

							{/* Start Guard Name Search */}
							<div className="col-12 form-group shfit-autosegest">
								<label htmlFor="guardNameOrEmployeeNumber">Guard Name or Employee ID:</label>
								<AutocompleteField
									showButton={false}
									defaultValue={!queryParams.guardNameOrEmployeeNumber ? true : false}
									includes={'Guard'}
									isAsync={true}
									placeholder="Guard Name or Employee ID"
									onSearch={async (value) => {
										onQueryParamChanged(value, 'guardNameOrEmployeeNumber');
									}}
									onChange={(value) => onQueryParamChanged(value, 'guardNameOrEmployeeNumber')}
									onClear={() => onQueryParamChanged('', 'guardNameOrEmployeeNumber')}
								/>
							</div>
							{/* End Guard Name Search */}
						</div>
					</div>

					<div className="col-md-4 form-group mb-md-4">
						<div className="row d-flex flex-column">
							<div className="col-12 form-group">
								<label>Leave Status:</label>
								<CustomSelectControl
									options={LeaveStatusOpts || []}
									value={queryParams.status}
									placeholder={'Select Leave Status'}
									onChange={(e) => onQueryParamChanged(e, 'status')}
									className="flex-grow-0"
								/>
							</div>
							<div className="col-12 mb-3">
								<label>School Year</label>
								<CustomSelectControl
									options={schoolYears}
									onChange={(value) => onQueryParamChanged(value, 'schoolYearId')}
									value={queryParams.schoolYearId}
									placeholder={'Select a School Year'}
									className={`custom-react-select--schoolYearId`}
								/>
							</div>
						</div>
					</div>
				</div>

				<div className="row mb-5">
					<div className="col-12 text-right">
						<button type="submit" className="btn btn-outline-aqua-blue mr-2">
							Update Report
						</button>
						<button type="button" className="btn btn-aqua-blue" onClick={onDownload}>
							<SVGDownload />
						</button>
					</div>
				</div>
			</form>

			<div className="row">
				<div className="col-12">
					<DataTable
						thead={guardAppLeaveRequestsThead.thead}
						tbody={
							incidentReports
								? incidentReports.map((report) => ({
										id: report.id,
										startDate: momentDateFormat(report.startDate),
										endDate:
											momentDateFormat(report.startDate) === momentDateFormat(report.endDate)
												? ''
												: momentDateFormat(report.endDate),
										shifts: momentDateFormat(report.startDate) === momentDateFormat(report.endDate) ? matchOptionWithName(report.shifts, shiftPeriods) : '',
										guardName: `<a href="/#/guards/${report.guardId}/details" target="_blank">${report.guardName}</a>`,
										employeeId: `<a href="/#/guards/${report.guardId}/details" target="_blank">${report.employeeId}</a>`,
										reason: matchOptionWithName(report.reason, reasons),
										requestedOn: momentDateFormat(report.requestedOn) || '',
										jobsAffected: jobFromStrToLink(report.jobsAffected),
										status: report.status,
										lastStatusUpdateOn: report.dateOfLastStatusUpdate
											? momentDateFormat(report.dateOfLastStatusUpdate)
											: '',
								  }))
								: []
						}
						isLoading={isLoading}
						ignoreCols={[0, 11]}
						currentPage={currentPage}
						itemsCount={allRecords}
						itemsPerPage={+perPage}
						onPager={(page) => onPagination(page)}
						onPerPage={(value) => onPerPage(value)}
						// onColClick={(key, trIndex, rowItem) => onTableRowClick(rowItem)}
						onSort={(orderBy, desc) => onSortCall(orderBy, desc, queryParams.keyWord)}
						defaultSortedColumn={0}
						defaultSortDirection='up'
						tableName={'Guard Leaves'}
						tableClass={'table-info--notFixed'}
						pagination
						sortOnBackend
						showTableLengthData
					>
						{(_id, rowItem: IGuardAppLeaveRequestReport) => (
							<td className="aling-middle">
								<div>
									<LeaveRequestActions
										openModal={(type, id) =>
											onActionItemPressed(
												type,
												`${id}`,
												incidentReports.find((bl) => bl.id.toString() === id.toString()) ||
													({} as IGuardAppLeaveRequestReport)
											)
										}
										id={_id}
										startDate={rowItem.startDate}
										status={rowItem.status}
									/>
								</div>
							</td>
						)}
					</DataTable>
				</div>
			</div>

			{approve.isOpen && (
				<BaseModal
					show={approve.isOpen}
					onCancel={() => onModal('approve').closeModal()}
					onSubmit={() =>
						onApproveSubmit(`${approve.id}`, approve.data || ({} as IGuardAppLeaveRequestReport))
					}
					submitBtnText="Yes"
					cancelBtnText="No"
					titleClassName="text-center"
					title={`Do you want to approve the Leave Request for <span class="font-weight-bold">${approve.data?.guardName}</span>?`}
				>
					{approve.data?.hasShifts && (
						<div className="d-flex align-items-center mt-3 mb-3">
							<div className="mr-2">
								<SVGWarning />
							</div>
							<small className="text-danger">
								This Guard is currently assigned to shift(s) during this leave. If you confirm this
								update, the affected shifts will be unassigned. A notification will be sent to the Guard
								and Resource Planning
							</small>
						</div>
					)}
				</BaseModal>
			)}

			{deny.isOpen && (
				<BaseModal
					show={deny.isOpen}
					onCancel={() => onModal('deny').closeModal()}
					cancelBtnText={'Close'}
					titleClassName="text-center"
					title={`The Leave Request for <span class="font-weight-bold">${deny.data?.guardName}</span> has been denied.`}
				>
					<p className="text-center">An automatic message has been sent to the guard.</p>
				</BaseModal>
			)}

			{approveSuccess.isOpen && (
				<BaseModal
					show={approveSuccess.isOpen}
					onCancel={() => onModal('approveSuccess').closeModal()}
					cancelBtnText={'Close'}
					titleClassName="text-center"
					title={`The Leave Request for <span class="font-weight-bold">${approveSuccess.data?.guardName}</span> has been approved.`}
				>
					<p className="text-center">
						An automatic message has been sent to the guard and affected shifts have added to the list of{' '}
						<Link to="/jobs/unassigned" className="text-decoration-underline text-aqua-blue">
							Shifts in Need of Guards
						</Link>
						.
					</p>
				</BaseModal>
			)}

			{updateSuccess.isOpen && (
				<BaseModal
					show={updateSuccess.isOpen}
					onCancel={() => onModal('updateSuccess').closeModal()}
					cancelBtnText={'Close'}
					title={`This Leave Request has been updated.`}
				></BaseModal>
			)}
			{updateDates.isOpen && (
				<BaseModal
					show={updateDates.isOpen}
					onCancel={onCloseEditDates}
					onSubmit={() =>
						onSaveBookedLeave(`${updateDates.id}`, updateDates.data || ({} as IGuardAppLeaveRequestReport))
					}
					submitBtnText={'Yes'}
					titleClassName="text-center"
					title={
						updateDates.data?.status === 'Pending'
							? `Edit and Approve a Leave Request for <span class="font-weight-bold">${updateDates.data?.guardName}</span`
							: `Edit a Booked Leave for <span class="font-weight-bold">${updateDates.data?.guardName}</span`
					}
				>
					<BookLeaveModalContext.Provider
						value={{
							reasons: [...reasons, ...[{ id: -1, name: 'Other' }]],
							shifts: shiftPeriods,
							bookLeave: bookLeave,
							guardAssignedDuringLeave: hasShifts,
							onChange: (e, name) => handleChanges(e, name),
							otherReason,
							setOtherReason,
							bookLeaveType: bookLeaveOptionsState,
							createMode: false,
						}}
					>
						<BookLeaveModal />
					</BookLeaveModalContext.Provider>
				</BaseModal>
			)}
		</Layout>
	);
};

export default GuardAppLeaveReports;
