import React, { useContext, useEffect } from 'react'
import DatePicker from "react-datepicker";
import { SVGCalendar } from '../../../../assets/icons/SvgIcons';
import { BookLeaveModalContext } from '../../../../context/guards/BookLeaveContext';
import { datePickerFormat, momentAddDays, setHoursAndMinutes } from '../../../../utils/DateFormatting';
import LeaveReason from './LeaveReason';
import {useDatapickerSvg} from "../../../../customHooks/useDatapickerSvg";
import moment from 'moment';

const MultipleDays = () => {
    const context = useContext(BookLeaveModalContext)
    const { bookLeave, onChange} = context
    const {showDatapicker, changeDatapicker} = useDatapickerSvg()
    useEffect(() => {
      if (moment(bookLeave?.startDate).isAfter(bookLeave?.endDate)) {
        onChange(momentAddDays(bookLeave?.startDate?.toString() || ''), 'endDate')
      }
    }, [bookLeave?.endDate, bookLeave?.startDate])
    
    return (
        <>
            <div className="row mb-3">
                <div className="col-md-3 form-group mb-0 d-flex align-items-center">
                    <label className="mb-0">Start Date: </label>
                </div>
                <div className="col-md-9 form-group mb-0">
                    <div className="react-datepicker-custom-wrapper d-inline-block">
                        <DatePicker
                            dateFormat="dd/MM/yyyy"
                            minDate={new Date()}
                            selected={bookLeave?.startDate ? datePickerFormat(bookLeave.startDate) : null}
                            placeholderText="dd/mm/yyyy"
                            onChange={date => {
                                onChange(setHoursAndMinutes(date as Date), "startDate")
                                changeDatapicker(2)
                            }}
                            onInputClick={() => changeDatapicker(2)}
                            open={showDatapicker[2]}
                            onClickOutside={() => changeDatapicker(2)}
                        />
                        <div onClick={() => changeDatapicker(2)}><SVGCalendar /></div>
                    </div>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-md-3 form-group mb-0 d-flex align-items-center">
                    <label className="mb-0">End Date: </label>
                </div>
                <div className="col-md-9 form-group mb-0">
                    <div className="react-datepicker-custom-wrapper d-inline-block">
                        <DatePicker
                            minDate={bookLeave?.startDate ? datePickerFormat(momentAddDays(bookLeave?.startDate?.toString())) : new Date()}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="dd/mm/yyyy"
                            selected={bookLeave?.endDate ? datePickerFormat(bookLeave.endDate) : null}
                            onChange={date => {
                                onChange(setHoursAndMinutes(date as Date), "endDate")
                                changeDatapicker(1)
                            }}
                            onInputClick={() => changeDatapicker(1)}
                            open={showDatapicker[1]}
                            onClickOutside={() => changeDatapicker(1)}
                        />
                        <div onClick={() => changeDatapicker(1)}><SVGCalendar /></div>
                    </div>
                </div>
            </div>

            <LeaveReason />
        </>
    )
}

export default MultipleDays
