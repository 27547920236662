import {useState} from "react";

export const useDatapickerSvg = () => {
    //  TODO: Oleh to refactor logic

    const [showDatapicker, setShowDataPicker] = useState([false,false,false,false,false,false])


    const changeDatapicker = (index: number) => {
        setShowDataPicker({
            ...showDatapicker,
            [index]: !showDatapicker[index]
        })
    }

    return {showDatapicker, changeDatapicker}

}
