import { AddNewItemEnum } from "../../common/enums/AddNewItemEnum"

export const manageDropdownsTabContent = {
    tabs: [
        {
            id: 1,
            objKey: AddNewItemEnum.GuardDeactivationReason,
            tabTitle: "Reasons for Guard Deactivation",
            tabContentTitle: "Reasons for Guard Deactivation",
            modalTitle: "Add an option to Reasons for <span class='font-weight-bold'>Guard Deactivation</span>",
            modalPlaceholder: "Enter Option Text",
            modalSuccess: "New reason has been created",
        },
        {
            id: 2,
            objKey: AddNewItemEnum.GuardActivationReason,
            tabTitle: "Reasons for Guard Activation",
            tabContentTitle: "Reasons for Guard Activation",
            modalTitle: "Add an option to Reasons for <span class='font-weight-bold'>Guard Activation</span>",
            modalPlaceholder: "Enter Option Text",
            modalSuccess: "New reason has been created",
        },
        {
            id: 3,
            objKey: AddNewItemEnum.GuardAbsenceReason,
            tabTitle: "Reasons for Guard Absence",
            tabContentTitle: "Reasons for Guard Absence",
            modalTitle: "Add an option to Reasons for <span class='font-weight-bold'>Guard Absence</span>",
            modalPlaceholder: "Enter Option Text",
            modalSuccess: "New reason has been created",
        },
        {
            id: 4,
            objKey: AddNewItemEnum.GuardReplacementReason,
            tabTitle: "Reasons for Guard Replacement",
            tabContentTitle: "Reasons for Guard Replacement",
            modalTitle: "Add an option to Reasons for <span class='font-weight-bold'>Guard Replacement</span>",
            modalPlaceholder: "Enter Option Text",
            modalSuccess: "New reason has been created",
        },
        {
            id: 5,
            objKey: AddNewItemEnum.GuardBookedLeaveReason,
            tabTitle: "Reasons for Guard Leave Booking",
            tabContentTitle: "Reasons for Guard Leave Booking",
            modalTitle: "Add an option to Reasons for <span class='font-weight-bold'>Guard Leave Booking</span>",
            modalPlaceholder: "Enter Option Text",
            modalSuccess: "New reason has been created",
        },
        {
            id: 6,
            objKey: AddNewItemEnum.GuardRemoveJobScheduleReason,
            tabTitle: "Reasons to Remove a Job Schedule from a Guard or to Remove a Guard from a Job Schedule",
            tabContentTitle: "Reasons to Remove a Job Schedule from a Guard or to Remove a Guard from a Job Schedule",
            modalTitle: "Add an option to Reasons for <span class='font-weight-bold'>Remove a Job Schedule from a Guard</span>",
            modalPlaceholder: "Enter Option Text",
            modalSuccess: "New reason has been created",
        },
        {
            id: 7,
            objKey: AddNewItemEnum.GuardRemoveJobShiftReason,
            tabTitle: "Reasons to Remove a Job Shift from a Guard or to Remove a Guard from a Job Shift",
            tabContentTitle: "Reasons to Remove a Job Shift from a Guard or to Remove a Guard from a Job Shift",
            modalTitle: "Add an option to Reasons for <span class='font-weight-bold'>Remove a Job Shift from a Guard or to Remove a Guard from a Job Shift</span>",
            modalPlaceholder: "Enter Option Text",
            modalSuccess: "New reason has been created",
        },
        {
            id: 8,
            objKey: AddNewItemEnum.ClientDeactivationReason,
            tabTitle: "Reasons for Client Deactivation",
            tabContentTitle: "Reasons for Client Deactivation",
            modalTitle: "Add an option to Reasons for <span class='font-weight-bold'>Client Deactivation</span>",
            modalPlaceholder: "Enter Option Text",
            modalSuccess: "New reason has been created",
        },
        {
            id: 9,
            objKey: AddNewItemEnum.ClientContactDeactivationReason,
            tabTitle: "Reasons for Client Contact Deactivation",
            tabContentTitle: "Reasons for Client Contact Deactivation",
            modalTitle: "Add an option to Reasons for <span class='font-weight-bold'>Client Contact Deactivation</span>",
            modalPlaceholder: "Enter Option Text",
            modalSuccess: "New reason has been created",
        },
        {
            id: 10,
            objKey: AddNewItemEnum.ASPUserDeactivationReason,
            tabTitle: "Reasons for ASP User Deactivation",
            tabContentTitle: "Reasons for ASP User Deactivation",
            modalTitle: "Add an option to Reasons for <span class='font-weight-bold'>ASP User Deactivation</span>",
            modalPlaceholder: "Enter Option Text",
            modalSuccess: "New reason has been created",
        },
        {
            id: 11,
            objKey: AddNewItemEnum.ASPAdminDeactivationReason,
            tabTitle: "Reasons for ASP Admin Deactivation",
            tabContentTitle: "Reasons for ASP Admin Deactivation",
            modalTitle: "Add an option to Reasons for <span class='font-weight-bold'>ASP Admin Deactivation</span>",
            modalPlaceholder: "Enter Option Text",
            modalSuccess: "New reason has been created",
        },
        {
            id: 12,
            objKey: AddNewItemEnum.JobActivationReason,
            tabTitle: "Reasons for Job Activation",
            tabContentTitle: "Reasons for Job Activation",
            modalTitle: "Add an option to Reasons for <span class='font-weight-bold'>Job Activation</span>",
            modalPlaceholder: "Enter Option Text",
            modalSuccess: "New reason has been created",
        },
        {
            id: 13,
            objKey: AddNewItemEnum.JobDeactivationReason,
            tabTitle: "Reasons for Job Deactivation",
            tabContentTitle: "Reasons for Job Deactivation",
            modalTitle: "Add an option to Reasons for <span class='font-weight-bold'>Job Deactivation</span>",
            modalPlaceholder: "Enter Option Text",
            modalSuccess: "New reason has been created",
        },
        {
            id: 14,
            objKey: AddNewItemEnum.ScheduleDeactivationReason,
            tabTitle: "Reasons for Schedule Deactivation",
            tabContentTitle: "Reasons for Schedule Deactivation",
            modalTitle: "Add an option to Reasons for <span class='font-weight-bold'>Schedule Deactivation</span>",
            modalPlaceholder: "Enter Option Text",
            modalSuccess: "New reason has been created",
        },
        {
            id: 15,
            objKey: AddNewItemEnum.DeviceBrand,
            tabTitle: "Options for device brands",
            tabContentTitle: "Device Brand Options",
            modalTitle: "Add an option to <span class='font-weight-bold'>Device Brand Options</span>",
            modalPlaceholder: "Enter Option Text",
            modalSuccess: "New reason has been created",
        },
        {
            id: 16,
            objKey: AddNewItemEnum.IncidentType,
            tabTitle: "Options of Incident Types on Guard's Mobile App",
            tabContentTitle: "Incident Types on Guard Mobile App",
            modalTitle: "Add an option to <span class='font-weight-bold'>Incident Types on Guard Mobile App</span>",
            modalPlaceholder: "Enter Option Text",
            modalSuccess: "New option has been created",
        },
        {
            id: 17,
            objKey: AddNewItemEnum.ContactFormTopicsOnGuardMobileApp,
            tabTitle: "Options for Contact Form Topic on Guard's Mobile App",
            tabContentTitle: "Options for Contact Form Topic on Guard's Mobile App",
            modalTitle: "Add an option to <span class='font-weight-bold'>Options for Contact Form</span>",
            modalPlaceholder: "Enter Option Text",
            modalSuccess: "New option has been created",
        },
        {
            id: 18,
            objKey: AddNewItemEnum.ArrivalTypesOnGuardMobileApp,
            tabTitle: "Options for Guard Arrival Types on Guard's Mobile App",
            tabContentTitle: "Options for Guard Arrival Types on Guard's Mobile App",
            modalTitle: "Add an option to <span class='font-weight-bold'>Guard Arrival Types</span>",
            modalPlaceholder: "Enter Option Text",
            modalSuccess: "New option has been created",
        },
    ]
}

export const manageOptionsTableContent = {
    thead: [
        {
            label: "Reason Option",
        },
        {
            label: "Edit",
        },
        {
            label: "Remove",
        }

    ]
}
